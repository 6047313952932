import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { ModalRight } from "@/components/Modal/ModalRight";
import React, { useEffect, useMemo, useState } from "react";
import { useCompanyTeam } from "@/hooks/company/useCompanyTeam";
import { CloseOutlined } from "@mui/icons-material";
import { ActiveApplicantFilter, AnsweredFilter, ChatFilters } from "./types";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { DatePicker } from "@/components/DatePicker";

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (filters: ChatFilters) => void;
  filters: ChatFilters;
  defaultFilters: ChatFilters;
}

export function FilterModal({ isOpen, onClose, filters: filtersProp, onChange, defaultFilters }: FilterModalProps) {
  const [filters, setFilters] = useState<ChatFilters>(filtersProp);
  const { data: companyTeamData = [] } = useCompanyTeam();
  const companyTeam = useMemo(() => companyTeamData.filter((member) => member.isActive), [companyTeamData]);
  const { applicantActivationEnabled } = useFeatureFlags();

  const handleApplyFilters = () => {
    onChange(filters);
    onClose();
  };

  const handleCleanFilters = () => {
    setFilters(defaultFilters);
    onChange(defaultFilters);
    onClose();
  };

  const handleTicketStatusOpenCheckboxChange = () => {
    const allChecked = Object.values(filters.ticketStatus.open).every((value) => value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      ticketStatus: {
        ...prevFilters.ticketStatus,
        open: {
          abandoned: !allChecked,
          AIService: !allChecked,
          redirectedHuman: !allChecked,
          initialTriage: !allChecked,
        },
      },
    }));
  };

  const isTicketStatusOpenChecked = Object.values(filters.ticketStatus.open).every((value) => value);
  const isTicketStatusOpenIndeterminate =
    Object.values(filters.ticketStatus.open).some((value) => value) && !isTicketStatusOpenChecked;

  useEffect(() => {
    if (isOpen) {
      setFilters(filtersProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <ModalRight
      title={"Filtrar conversas"}
      size={"SMALL"}
      onClose={onClose}
      footer={
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            onClick={handleApplyFilters}
            sx={{
              width: "100%",
            }}
          >
            Filtrar
          </Button>
          <Button
            onClick={handleCleanFilters}
            sx={{
              width: "100%",
            }}
          >
            Limpar Filtros
          </Button>
        </Box>
      }
      open={isOpen}
      sx={{
        "& .MuiSvgIcon-fontSizeMedium": {
          width: "20px",
          height: "20px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          paddingBottom: 3,
        }}
      >
        <FilterSection
          title="Status"
          description="Filtre conversas que pelo status de seu atendimento, você também pode exibir conversas que não estão em atendimento."
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTicketStatusOpenChecked}
                    indeterminate={isTicketStatusOpenIndeterminate}
                    onChange={handleTicketStatusOpenCheckboxChange}
                  />
                }
                label="Em atendimento"
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  ml: 3,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.ticketStatus.open.abandoned}
                      onChange={() =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          ticketStatus: {
                            ...prevFilters.ticketStatus,
                            open: {
                              ...prevFilters.ticketStatus.open,
                              abandoned: !prevFilters.ticketStatus.open.abandoned,
                            },
                          },
                        }))
                      }
                    />
                  }
                  label="Abandonado"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.ticketStatus.open.AIService}
                      onChange={() =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          ticketStatus: {
                            ...prevFilters.ticketStatus,
                            open: {
                              ...prevFilters.ticketStatus.open,
                              AIService: !prevFilters.ticketStatus.open.AIService,
                            },
                          },
                        }))
                      }
                    />
                  }
                  label="Atendimento IA"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.ticketStatus.open.redirectedHuman}
                      onChange={() =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          ticketStatus: {
                            ...prevFilters.ticketStatus,
                            open: {
                              ...prevFilters.ticketStatus.open,
                              redirectedHuman: !prevFilters.ticketStatus.open.redirectedHuman,
                            },
                          },
                        }))
                      }
                    />
                  }
                  label="Direcionado humano"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.ticketStatus.open.initialTriage}
                      onChange={() =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          ticketStatus: {
                            ...prevFilters.ticketStatus,
                            open: {
                              ...prevFilters.ticketStatus.open,
                              initialTriage: !prevFilters.ticketStatus.open.initialTriage,
                            },
                          },
                        }))
                      }
                    />
                  }
                  label="Triagem inicial"
                />
              </Box>
            </Box>

            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.ticketStatus.closed}
                  onChange={() =>
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      ticketStatus: { ...prevFilters.ticketStatus, closed: !prevFilters.ticketStatus.closed },
                    }))
                  }
                />
              }
              label="Atendimento encerrado"
            />
          </Box>
        </FilterSection>
        {applicantActivationEnabled && (
          <FilterSection title="Tipo de contato" description="Filtre conversas pelo tipo de contato do seu cliente">
            <ButtonGroup size="small">
              <Button
                variant={filters.activeApplicant === ActiveApplicantFilter.INACTIVE ? "contained" : "outlined"}
                onClick={() =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    activeApplicant: ActiveApplicantFilter.INACTIVE,
                  }))
                }
              >
                Novo contato
              </Button>
              <Button
                variant={filters.activeApplicant === ActiveApplicantFilter.ACTIVE ? "contained" : "outlined"}
                onClick={() =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    activeApplicant: ActiveApplicantFilter.ACTIVE,
                  }))
                }
              >
                Cliente ativo
              </Button>
              <Button
                variant={filters.activeApplicant === ActiveApplicantFilter.ALL ? "contained" : "outlined"}
                onClick={() =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    activeApplicant: ActiveApplicantFilter.ALL,
                  }))
                }
              >
                Todos
              </Button>
            </ButtonGroup>
          </FilterSection>
        )}
        <FilterSection
          title="Status da resposta"
          description="Filtre conversas que já foram ou não respondidas por usuários da sua plataforma Lexter"
        >
          <ButtonGroup size="small">
            <Button
              variant={filters.answered === AnsweredFilter.ANSWERED ? "contained" : "outlined"}
              onClick={() =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  answered: AnsweredFilter.ANSWERED,
                }))
              }
            >
              Respondida
            </Button>
            <Button
              variant={filters.answered === AnsweredFilter.UNANSWERED ? "contained" : "outlined"}
              onClick={() =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  answered: AnsweredFilter.UNANSWERED,
                }))
              }
            >
              Não respondida
            </Button>
            <Button
              variant={filters.answered === AnsweredFilter.ALL ? "contained" : "outlined"}
              onClick={() =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  answered: AnsweredFilter.ALL,
                }))
              }
            >
              Todas
            </Button>
          </ButtonGroup>
        </FilterSection>

        <FilterSection
          title="Responsável"
          description="Exibir apenas conversas que foram marcadas como pertencentes ás pessoas:"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexWrap: "wrap",
              }}
            >
              {filters.responsibles?.map((responsible) => (
                <Chip
                  key={responsible.userId}
                  label={responsible.name}
                  onDelete={() => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      responsibles: prevFilters.responsibles?.filter((item) => item.userId !== responsible.userId),
                    }));
                  }}
                  sx={{
                    backgroundColor: "secondary.main",
                    color: "text.primary",
                  }}
                  deleteIcon={<CloseOutlined />}
                />
              ))}
            </Box>

            <Autocomplete
              renderInput={(params) => <TextField {...params} placeholder="Comece a digitar para buscar" />}
              multiple
              options={companyTeam}
              value={filters.responsibles}
              noOptionsText="Nenhuma opção encontrada"
              disablePortal
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.userId === value.userId}
              onChange={(_, selectedOptions) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  responsibles: selectedOptions,
                }));
              }}
              sx={{
                "& .MuiChip-root": {
                  visibility: "hidden",
                  display: "none",
                  color: "red",
                },
                "& .MuiChip-label": {
                  display: "none",
                  visibility: "hidden",
                  color: "red",
                },
              }}
            />

            <FilterSection
              title="Início da Conversa (Chat)"
              description="Exibir apenas conversas de requerentes cuja primeira mensagem foi enviada entre as datas selecionadas."
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
                  <Box sx={{ width: "28px", display: "flex", alignItems: "center" }}>
                    <Typography variant="body">De</Typography>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <DatePicker
                      selected={filters.chatCreationDateStart ?? null}
                      onChange={(date: number | null) => {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          chatCreationDateStart: date || undefined,
                        }));
                      }}
                      placeholder="Conversas iniciadas a partir de..."
                    />
                  </Box>
                </Box>

                <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
                  <Box sx={{ width: "28px", display: "flex", alignItems: "center" }}>
                    <Typography variant="body">Até</Typography>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <DatePicker
                      selected={filters.chatCreationDateEnd ?? null}
                      onChange={(date: number | null) => {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          chatCreationDateEnd: date || undefined,
                        }));
                      }}
                      placeholder="Conversas iniciadas até..."
                    />
                  </Box>
                </Box>
              </Box>
            </FilterSection>
          </Box>
        </FilterSection>
      </Box>
    </ModalRight>
  );
}

const FilterSection = ({
  title,
  description,
  children,
}: {
  title: string;
  description: string | React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        <Typography
          sx={{
            fontSize: "16px",
          }}
          variant="body1"
        >
          {title}
        </Typography>

        <Typography
          sx={{
            fontSize: "12px",
          }}
          variant="body2"
        >
          {description}
        </Typography>
      </Box>

      <Box>{children}</Box>
    </Box>
  );
};
