import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { ApprovalRequest } from "@/services/approvalRequests/types";
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import { StatusChip } from "./StatusChip";
import { formatTimeUnits } from "../../utils/formatters";

interface ReviewerInfoProps {
  request: ApprovalRequest;
  children?: React.ReactNode;
}

export const ReviewInfo: React.FC<ReviewerInfoProps> = ({ request, children }) => {
  if (!request.reviewed_at || !request.reviewer_id) return null;

  const reviewTime = formatDistanceToNow(request.reviewed_at, {
    locale: ptBR,
    addSuffix: true,
  });

  const timeDiff = Math.abs(new Date(request.reviewed_at!).getTime() - new Date(request.created_at).getTime());
  const formattedTime = formatTimeUnits(timeDiff);

  return (
    <>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <Typography variant="h6" fontWeight={600}>
          Detalhes da Revisão
        </Typography>
        <StatusChip status={request.status} />
      </Box>
      <Paper variant="outlined" sx={{ p: 2, mb: 3, bgcolor: "#f8f9fa" }}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" flexWrap="wrap" gap={2}>
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#666", fontWeight: 500 }}>
                Revisado por
              </Typography>
              <Typography variant="body1" sx={{ color: "#333", fontWeight: 600 }}>
                {request.reviewer_id}
              </Typography>
            </Box>

            <Box>
              <Typography variant="subtitle2" sx={{ color: "#666", fontWeight: 500 }}>
                Tempo até revisão
              </Typography>
              <Typography variant="body1" sx={{ color: "#333", fontStyle: "italic" }}>
                {formattedTime}
              </Typography>
            </Box>

            <Box>
              <Typography variant="subtitle2" sx={{ color: "#666", fontWeight: 500 }}>
                Data da revisão
              </Typography>
              <Typography variant="body1" sx={{ color: "#333" }}>
                {reviewTime}
              </Typography>
            </Box>
          </Box>
          {children}
        </Box>
      </Paper>
    </>
  );
};
