import JSZip from "jszip";

export async function getDocxPageCount(file: File): Promise<number | null> {
  try {
    const zip = await JSZip.loadAsync(file);
    const docPropsText = await zip.file("docProps/app.xml")?.async("string");

    if (!docPropsText) return null;

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(docPropsText, "text/xml");
    const pages = xmlDoc.getElementsByTagName("Pages")[0]?.childNodes[0]?.nodeValue;

    return Number(pages);
  } catch {
    return null;
  }
}
