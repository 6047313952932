import React, { useEffect, useMemo, useState } from "react";
// External libraries
import * as MUI from "@mui/material";
import * as MUIIcons from "@mui/icons-material";

// Internal imports
import * as Types from "@/services/approvalRequests/types";
import { scrollbarStyles } from "@/theme/mixins/scrollbar";
import { useApprovalManagement } from "@/hooks/useApprovalRequests/useApprovalRequests";

interface ApprovalRequestProps {
  onClose: () => void;
  requests: Types.ApprovalRequest[];
  editedMessages?: Record<string, string>;
  onMessageEdit?: (requestId: string, message: string) => void;
  onRequestChange?: () => void;
}

interface SendWhatsappPendingViewProps {
  request: Types.ApprovalRequest;
  loading: boolean;
  editedMessage?: string;
  onMessageEdit?: (requestId: string, message: string) => void;
  onRequestChange?: () => void;
}

interface WhatsappPayload {
  action: {
    message: string;
    [key: string]: unknown;
  };
  [key: string]: unknown;
}

const RequestChangesModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: (instructions: string) => void;
  loading: boolean;
}> = ({ open, onClose, onSubmit, loading }) => {
  const [instructions, setInstructions] = useState("");

  const handleSubmit = () => {
    onSubmit(instructions);
    setInstructions("");
  };
  return (
    <MUI.Dialog open={open} onClose={loading ? undefined : onClose} maxWidth="md" fullWidth>
      <MUI.DialogTitle variant="h6">Solicitar Ajustes</MUI.DialogTitle>
      <MUI.DialogContent sx={{ p: 2 }}>
        <MUI.Box mt={2}>
          <MUI.Typography variant="subtitle2" sx={{ color: "text.secondary", fontWeight: 500, mb: 1 }}>
            Instruções de Alteração
          </MUI.Typography>
          <MUI.TextField
            fullWidth
            disabled={loading}
            onChange={(e) => setInstructions(e.target.value)}
            placeholder="Ex: Adicione mais detalhes sobre a urgência da consulta e mencione a necessidade do laudo médico"
            multiline
            rows={4}
            maxRows={4}
            value={instructions}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderColor: "common.lightShade",
              },
            }}
          />
        </MUI.Box>
      </MUI.DialogContent>
      <MUI.DialogActions sx={{ p: 2, gap: 2 }}>
        <MUI.Button
          onClick={onClose}
          disabled={loading}
          variant="text"
          sx={{
            color: "text.secondary",
            "&:hover": {
              backgroundColor: "grey.100",
            },
          }}
        >
          Cancelar
        </MUI.Button>
        <MUI.Button
          onClick={handleSubmit}
          disabled={loading || !instructions}
          variant="contained"
          startIcon={loading && <MUI.CircularProgress size={20} color="inherit" />}
          sx={{
            backgroundColor: "primary.main",
            color: "common.white",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          {loading ? "Enviando..." : "Enviar Ajustes"}
        </MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
};

const SendWhatsappPendingView: React.FC<SendWhatsappPendingViewProps> = ({
  request,
  loading,
  editedMessage,
  onMessageEdit,
  onRequestChange,
}) => {
  const { approve, requestReprocessing, deleteRequest } = useApprovalManagement();
  const payload = request.output?.payload as WhatsappPayload | undefined;
  const originalMessage = payload?.action?.message || "";
  const [message, setMessage] = useState(editedMessage ?? originalMessage);
  const [showRequestChanges, setShowRequestChanges] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const hasChanges = message !== originalMessage;

  const handleMessageChange = (newMessage: string) => {
    setMessage(newMessage);
    onMessageEdit?.(request.id, newMessage);
  };

  const handleApprove = async () => {
    try {
      setIsProcessing(true);
      if (hasChanges && payload) {
        const editedValue: Types.ProcessSkillOutput = {
          ...request.output,
          payload: {
            ...payload,
            action: {
              ...payload.action,
              message,
            },
          },
        };
        await approve(request, editedValue);
      } else {
        await approve(request);
      }
      onRequestChange?.();
    } catch (error) {
      // Handle error
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRequestChanges = async (instructions: string) => {
    try {
      setIsProcessing(true);
      await requestReprocessing(request, instructions);
      setShowRequestChanges(false);
      onRequestChange?.();
    } catch (error) {
      // Handle error
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsProcessing(true);
      await deleteRequest(request);
      setShowDeleteConfirmation(false);
      onRequestChange?.();
    } catch (error) {
      // Handle error
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <MUI.Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <MUI.TextField
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderColor: hasChanges ? "primary.main" : "common.lightShade",
            "&:hover": {
              borderColor: hasChanges ? "primary.dark" : "common.lightShade",
            },
          },
        }}
        label="Mensagem a ser enviada"
        disabled={loading || isProcessing}
        onChange={(e) => handleMessageChange(e.target.value)}
        placeholder="Mensagem a ser enviada..."
        multiline
        minRows={8}
        maxRows={16}
        value={message}
      />

      <MUI.Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <MUI.Button
          disabled={loading || isProcessing}
          variant="text"
          onClick={() => setShowDeleteConfirmation(true)}
          sx={{
            color: "error.main",
            "&:hover": {
              backgroundColor: "error.light",
            },
          }}
        >
          Excluir
        </MUI.Button>

        <MUI.Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <MUI.Button
            disabled={loading || isProcessing}
            variant="text"
            onClick={() => setShowRequestChanges(true)}
            sx={{
              color: "text.secondary",
              "&:hover": {
                backgroundColor: "grey.100",
              },
            }}
          >
            Solicitar Ajustes
          </MUI.Button>
          <MUI.Button
            disabled={loading || isProcessing || (!hasChanges && !message)}
            variant={hasChanges ? "contained" : "text"}
            onClick={handleApprove}
            startIcon={isProcessing && <MUI.CircularProgress size={20} color="inherit" />}
            sx={
              hasChanges
                ? {
                    backgroundColor: "primary.main",
                    color: "common.white",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }
                : {
                    color: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.light",
                    },
                  }
            }
          >
            {isProcessing ? "Enviando..." : hasChanges ? "Enviar com Edições" : "Enviar Mensagem"}
          </MUI.Button>
        </MUI.Box>
      </MUI.Box>

      <RequestChangesModal
        open={showRequestChanges}
        onClose={() => setShowRequestChanges(false)}
        onSubmit={handleRequestChanges}
        loading={isProcessing}
      />

      <MUI.Dialog open={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)}>
        <MUI.DialogTitle>Confirmar exclusão</MUI.DialogTitle>
        <MUI.DialogContent>
          <MUI.Typography>
            Tem certeza que deseja excluir esta solicitação? Esta ação não pode ser desfeita.
          </MUI.Typography>
        </MUI.DialogContent>
        <MUI.DialogActions>
          <MUI.Button
            onClick={() => setShowDeleteConfirmation(false)}
            disabled={isProcessing}
            sx={{ color: "text.secondary" }}
          >
            Cancelar
          </MUI.Button>
          <MUI.Button
            onClick={handleDelete}
            disabled={isProcessing}
            color="error"
            variant="contained"
            startIcon={isProcessing && <MUI.CircularProgress size={20} color="inherit" />}
          >
            {isProcessing ? "Excluindo..." : "Excluir"}
          </MUI.Button>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.Box>
  );
};

interface PendingViewProps {
  request: Types.ApprovalRequest;
  loading: boolean;
  editedMessage?: string;
  onMessageEdit?: (requestId: string, message: string) => void;
  onRequestChange?: () => void;
}

const PendingView: React.FC<PendingViewProps> = ({
  request,
  loading,
  editedMessage,
  onMessageEdit,
  onRequestChange,
}) => {
  if (request.request_type === "SEND_WHATSAPP_MESSAGE") {
    return (
      <SendWhatsappPendingView
        request={request}
        loading={loading}
        editedMessage={editedMessage}
        onMessageEdit={onMessageEdit}
        onRequestChange={onRequestChange}
      />
    );
  }

  return (
    <MUI.Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
      <MUI.Typography variant="h6" sx={{ color: "text.secondary" }}>
        Pending view for {request.request_type} is not implemented yet
      </MUI.Typography>
    </MUI.Box>
  );
};

export const ApprovalRequest: React.FC<ApprovalRequestProps> = ({
  onClose,
  requests,
  editedMessages = {},
  onMessageEdit,
  onRequestChange,
}) => {
  const { loading } = useApprovalManagement();
  const pendingRequests = useMemo(() => requests.filter((req) => req.status === "PENDING"), [requests]);
  const completedRequests = useMemo(() => requests.filter((req) => req.status !== "PENDING"), [requests]);
  const [selectedRequestId, setSelectedRequestId] = useState<string | null>(pendingRequests[0]?.id ?? null);

  useEffect(() => {
    setSelectedRequestId(pendingRequests[0]?.id ?? null);
  }, [pendingRequests]);

  const selectedRequest = useMemo(
    () => pendingRequests.find((req) => req.id === selectedRequestId),
    [pendingRequests, selectedRequestId]
  );

  return (
    <MUI.Box
      sx={{
        backgroundColor: "common.white",
        height: "100%",
        display: "grid",
        gridTemplateRows: "min-content minmax(0, 1fr)",
      }}
    >
      <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 1, px: 2, py: 1 }}>
        <MUI.Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <MUI.Typography variant="h6" sx={{ fontWeight: "400", color: "text.secondary" }}>
            Aprovar Mensagem
          </MUI.Typography>
          <MUI.IconButton
            onClick={onClose}
            size="small"
            sx={{
              "&:hover": {
                backgroundColor: "grey.100",
              },
            }}
          >
            <MUIIcons.Close sx={{ width: "20px", height: "20px", color: "grey.600" }} />
          </MUI.IconButton>
        </MUI.Box>

        <MUI.Typography variant="body1" sx={{ color: "text.secondary" }}>
          Aprove ou rejeite mensagens pendentes.
        </MUI.Typography>
      </MUI.Box>

      <MUI.Box
        sx={{
          width: "100%",
          height: "100%",
          ...scrollbarStyles,
        }}
      >
        {pendingRequests.length > 0 && (
          <MUI.Box sx={{ px: 2, py: 1, height: "100%" }}>
            {pendingRequests.length > 1 && (
              <MUI.Box sx={{ mb: 2 }}>
                <MUI.Typography variant="subtitle2" sx={{ mb: 1, color: "text.secondary" }}>
                  Selecione a solicitação pendente:
                </MUI.Typography>
                <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  {pendingRequests.map((request) => (
                    <MUI.Box
                      key={request.id}
                      onClick={() => setSelectedRequestId(request.id)}
                      sx={{
                        p: 1.5,
                        borderRadius: 1,
                        border: "1px solid",
                        borderColor: request.id === selectedRequestId ? "primary.main" : "grey.200",
                        backgroundColor: request.id === selectedRequestId ? "primary.light" : "common.white",
                        cursor: "pointer",
                        "&:hover": {
                          borderColor: "primary.main",
                        },
                      }}
                    >
                      <MUI.Typography variant="body2" sx={{ color: "text.primary", fontWeight: 500 }}>
                        {request.request_type === "SEND_WHATSAPP_MESSAGE"
                          ? "Aprovação de Mensagem"
                          : request.request_type}
                      </MUI.Typography>
                      <MUI.Typography variant="body2" sx={{ color: "text.secondary" }}>
                        Criado em: {new Date(request.created_at).toLocaleString()}
                      </MUI.Typography>
                    </MUI.Box>
                  ))}
                </MUI.Box>
              </MUI.Box>
            )}
            {selectedRequest && (
              <MUI.Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  height: pendingRequests.length > 1 ? "calc(100% - 180px)" : "100%",
                }}
              >
                <PendingView
                  key={selectedRequest.id}
                  request={selectedRequest}
                  loading={loading}
                  editedMessage={editedMessages[selectedRequest.id]}
                  onMessageEdit={onMessageEdit}
                  onRequestChange={onRequestChange}
                />
              </MUI.Box>
            )}
          </MUI.Box>
        )}

        {completedRequests.length > 0 && (
          <MUI.Box sx={{ px: 2, py: 1 }}>
            <MUI.Typography variant="subtitle2" sx={{ mb: 1, color: "grey.700" }}>
              Concluídas
            </MUI.Typography>
            <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {completedRequests.map((request) => (
                <MUI.Box
                  key={request.id}
                  sx={{
                    p: 1,
                    bgcolor: "grey.50",
                    borderRadius: "8px",
                    "&:hover": {
                      bgcolor: "grey.100",
                    },
                  }}
                >
                  <MUI.Typography variant="body2" sx={{ color: "grey.800" }}>
                    Status: {request.status}
                  </MUI.Typography>
                  <MUI.Typography variant="body2" sx={{ color: "grey.600" }}>
                    Tipo: {request.request_type}
                  </MUI.Typography>
                </MUI.Box>
              ))}
            </MUI.Box>
          </MUI.Box>
        )}
      </MUI.Box>
    </MUI.Box>
  );
};
