import { Box, Typography } from "@mui/material";
import { ModalRight } from "@/components/Modal/ModalRight";
import React, { useEffect } from "react";
import CollaborationSVG from "@/assets/svgs/collaboration.svg?react";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { logger } from "@/core/logger";
import { Phone } from "@/core/Phone";
import { LexZapCompany } from "@/hooks/lexZap/types";

export function NumberManagementModal({
  onClose,
  open,
  connectionData,
}: {
  onClose: () => void;
  open: boolean;
  connectionData: LexZapCompany;
}) {
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");

  useEffect(() => {
    if (!connectionData?.phoneNumber) return;
    const phoneNumber = Phone.new(connectionData.phoneNumber.substring(2));
    if (phoneNumber.isFailure) {
      setPhoneNumber(connectionData.phoneNumber);
    }
    setPhoneNumber(phoneNumber.getValue().toString());
  }, [connectionData]);

  const handleCopy = async () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(phoneNumber);
        return;
      } catch (err) {
        if (err instanceof Error) {
          logger.error("Falha ao copiar texto.", { error: err });
        }
      }
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight title={"Gestão de número"} size={"SMALL"} onClose={onClose} open={open}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "start",
            paddingX: "24px",
            gap: "16px",
          }}
        >
          <CollaborationSVG />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 1,
            }}
          >
            <Typography variant="body1">Seu número é:</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">{phoneNumber}</Typography>
              <ContentCopyIcon
                sx={{
                  cursor: "pointer",
                }}
                fontSize="small"
                onClick={handleCopy}
              />
            </Box>

            <Typography variant="body1">
              Esse é o número com o qual seus clientes devem se comunicar para serem atendidos pelo assistente Lexter.
              <br />
              <br />
              Você pode configurar o número onde você recebe notificação sobre novas conversas em Configurações -&gt;
              Meus dados
            </Typography>
          </Box>
        </Box>
      </ModalRight>
    </Box>
  );
}
