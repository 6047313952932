export const tableStyles = {
  root: {
    width: "100%",
    "& .row-success": {
      animation: "highlight-success 2s",
      "@keyframes highlight-success": {
        "0%": { backgroundColor: "rgba(76, 175, 80, 0.3)" },
        "100%": { backgroundColor: "transparent" },
      },
    },
    "& .row-error": {
      animation: "highlight-error 2s",
      "@keyframes highlight-error": {
        "0%": { backgroundColor: "rgba(244, 67, 54, 0.3)" },
        "100%": { backgroundColor: "transparent" },
      },
    },
    "& .editable-cell": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
    "& .non-editable-cell": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  dataGrid: {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#f5f5f5",
      borderRadius: "8px 8px 0 0",
    },
    "& .MuiDataGrid-virtualScroller": {
      backgroundColor: "#ffffff",
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "1px solid rgba(224, 224, 224, 1)",
      backgroundColor: "#f5f5f5",
      borderRadius: "0 0 8px 8px",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "1px solid rgba(224, 224, 224, 0.5)",
    },
  },
};
