import { ActivityAreaOption } from "@/hooks/company/types";
import { CompanyMember } from "@/hooks/company/types";

export enum AnsweredFilter {
  ANSWERED,
  UNANSWERED,
  ALL,
}

export enum CaseStatusFilter {
  ACTIVE,
  INACTIVE,
  ALL,
}

export enum ActiveApplicantFilter {
  ACTIVE,
  INACTIVE,
  ALL,
}

export interface ChatFilters {
  ticketStatus: {
    open: {
      abandoned: boolean;
      AIService: boolean;
      redirectedHuman: boolean;
      initialTriage: boolean;
    };
    closed: boolean;
  };
  activeApplicant: ActiveApplicantFilter;
  answered: AnsweredFilter;
  caseStatus: CaseStatusFilter;
  responsibles: CompanyMember[];
  areas: ActivityAreaOption[];
  chatCreationDateStart?: number;
  chatCreationDateEnd?: number;
}
