import React from "react";
import { DialogModal } from "@/components/DialogModal";
import { Typography } from "@mui/material";

interface RemoveDocumentCaseModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const RemoveCaseFileCaseModal = ({ isOpen, onConfirm, onCancel }: RemoveDocumentCaseModalProps) => {
  return (
    <DialogModal
      open={isOpen}
      onClose={onCancel}
      title={"Remover arquivo"}
      description={
        <Typography variant="body1" sx={{ color: "text.primary" }}>
          Confirmando esse arquivo deixará de ser exibido nessa listagem. Ele seguirá disponível em seu local de origem.
        </Typography>
      }
      descriptionTextAlign="left"
      buttonsDirection="row"
      buttons={[
        {
          label: "Cancelar",
          onClick: onCancel,
          sx: {
            color: "primary.main",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
        {
          label: "Confirmar",
          onClick: onConfirm,
          sx: {
            backgroundColor: "primary.main",
            color: "common.black",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            fontWeight: 600,
          },
        },
      ]}
      variant="error"
    />
  );
};
