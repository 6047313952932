import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSocket } from "@/contexts/WebSocketContext";
import { WhatsappUpdateType } from "@/contexts/WebSocketContext/types/whatsapp.types";
import { LexZapChat, Ticket } from "../types";
import { getMessagesQueryKey } from "../useLexZapMessages";
import { useImpersonation } from "@/hooks/useImpersonation";
import { AdminLexZapService, LexZapService } from "@/services/lexZap";
import { useApplicants } from "@/hooks/applicants/useApplicants";
import { getTicketsQueryKey, useLexZapGetTickets } from "../useLexZapGetTickets";
import { getChatStage } from "./utils/getChatStage";
import { upsertLexZapMessage } from "../useLexZapMessages/utils/upsertLexZapMessage";

interface UseLexZapChatsProps {
  disabled?: boolean;
}

export const getChatsQueryKey = () => ["lexZapChats"];

export const useLexZapChats = (props?: UseLexZapChatsProps) => {
  const queryClient = useQueryClient();
  const { impersonatedUser } = useImpersonation();
  const { data: applicants } = useApplicants();
  const { data: tickets } = useLexZapGetTickets();

  useSocket({
    onWhatsAppUpdate: (update) => {
      if (impersonatedUser) {
        return;
      }

      if (update.type === WhatsappUpdateType.CHAT_UPDATE) {
        queryClient.setQueryData<LexZapChat[]>(getChatsQueryKey(), (oldChats = []) => {
          const chatExists = oldChats.some((chat) => chat.endClientNumber === update.applicantPhoneNumber);
          if (chatExists) {
            return oldChats.map((chat) => {
              if (chat.endClientNumber !== update.applicantPhoneNumber) return chat;

              const updatedChat: LexZapChat = {
                ...chat,
                ...update.updatedFields,
              };

              return updatedChat;
            });
          } else {
            const newChat: LexZapChat = {
              ...update.updatedFields,
              endClientNumber: update.applicantPhoneNumber,
              createdAt: update.updatedFields.createdAt || Date.now(),
            };

            return [...oldChats, newChat];
          }
        });
      }

      if (update.type === WhatsappUpdateType.TICKET_UPDATE) {
        queryClient.setQueryData(getTicketsQueryKey(), (old: Ticket[] | undefined) => {
          const { ticketId, updatedFields } = update;

          if (!old) return [];
          if (old.find((ticket) => ticket.id === ticketId)) {
            return old.map((ticket) =>
              ticket.id === ticketId
                ? {
                    ...ticket,
                    ...updatedFields,
                  }
                : ticket
            );
          } else {
            return [...old, updatedFields];
          }
        });
      }
    },
  });

  const { data, ...query } = useQuery<LexZapChat[]>({
    queryKey: getChatsQueryKey(),
    queryFn: async () => {
      let chats: LexZapChat[] = [];
      if (impersonatedUser) {
        chats = await AdminLexZapService.getLexZapChats({ companyId: impersonatedUser.companyId });
      } else {
        chats = await LexZapService.getLexZapChats();
      }

      const cachedChats = queryClient.getQueryData<LexZapChat[]>(getChatsQueryKey());

      return chats.map<LexZapChat>((chat) => {
        if (chat.lastMessage) {
          const lastMessage = chat.lastMessage;
          upsertLexZapMessage({
            queryClient,
            applicantPhoneNumber: chat.endClientNumber,
            where: (msg) => msg.id === lastMessage.id,
            upsertFn: () => lastMessage,
          });
        }

        const cachedChat = cachedChats?.find((item) => item.endClientNumber === chat.endClientNumber);
        if (!cachedChat?.hasMessagesFetched) {
          void queryClient.invalidateQueries({
            queryKey: getMessagesQueryKey({ applicantPhoneNumber: chat.endClientNumber }),
          });
        }

        return {
          ...cachedChat,
          ...chat,
        };
      });
    },
    enabled: !props?.disabled,
    staleTime: 1000 * 60,
  });

  return {
    ...query,
    isLoading: query.isLoading,
    data: data
      ?.filter((chat) => !chat.lawyerChat)
      .map<LexZapChat>((chat) => {
        const applicant = applicants?.find((item) => item.phoneNumber === chat.endClientNumber);

        let chatTickets = tickets?.filter((ticket) => ticket.applicantPhoneNumber === chat.endClientNumber);

        if (!chatTickets?.length) {
          chatTickets = chat.lastTicket ? [chat.lastTicket] : [];
        }

        const sortedChatTickets = chatTickets.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        const lastTicket = !!chat.lastTicket
          ? chatTickets.find((ticket) => ticket.id === chat.lastTicket?.id) || chat.lastTicket
          : chatTickets[0];

        const lastMessageDate = Math.max(
          chat.lastLawyerMessageDate || 0,
          chat.lastEndClientMessageDate || 0,
          chat.lastAgentMessageDate || 0,
          // chat.lastMessage?.timestamp || 0,
          chat.lastMessageDate || 0
        );

        return {
          ...chat,
          applicantName: applicant?.name || chat.applicantName,
          lastTicket,
          lastMessageDate,
          applicant,
          chatStage: getChatStage({ chat, lastTicket }),
          tickets: sortedChatTickets,
        };
      }),
  };
};
