import { SxProps } from "@mui/material";

const cellStyles = {
  "& .MuiDataGrid-cell": {
    padding: "12px",
    borderBottom: "1px solid",
    borderColor: "common.lightShade",
    fontSize: "0.875rem",
    color: "text.primary",
    backgroundColor: "common.white",
    display: "flex",
    alignItems: "center",
    cursor: "default",
    "&:focus": {
      outline: "none",
    },
    transition: "cursor 0.1s ease-in-out, background-color 0.2s ease",
    "&:hover": {
      cursor: "default",
    },
  },
  "& .editable-cell": {
    backgroundColor: "grey.50",
    cursor: "pointer !important",
    "&:hover": {
      backgroundColor: "grey.100",
      opacity: 1,
      color: "text.primary",
    },
  },
  "& .non-editable-cell": {
    backgroundColor: "grey.100",
    color: "text.primary",
    cursor: "default",
  },
  "& .MuiDataGrid-cell--editing": {
    bgcolor: "common.white",
    boxShadow: "inset 0 0 0 2px",
    color: "text.primary",
    "& .MuiInputBase-root": {
      height: "100%",
      fontSize: "0.875rem",
      backgroundColor: "common.softBlue",
      "& .MuiInputBase-input": {
        color: "text.primary",
      },
      "&:hover": {
        backgroundColor: "secondary.main",
      },
      "&.Mui-focused": {
        backgroundColor: "secondary.light",
        boxShadow: "none",
      },
    },
  },
  "& .MuiDataGrid-cell .selectable-text": {
    cursor: "text",
    userSelect: "text",
  },
  "& .actions": {
    cursor: "default",
    pointerEvents: "auto",
    "& .MuiButtonBase-root": {
      cursor: "pointer !important",
      pointerEvents: "auto",
    },
  },
};

const headerStyles = {
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "common.dorian",
    fontWeight: 600,
    borderBottom: "2px solid",
    borderColor: "common.lightShade",
    color: "text.primary",
    whiteSpace: "normal",
    lineHeight: "normal",
    "& .MuiDataGrid-columnHeaderTitle": {
      whiteSpace: "normal",
      lineHeight: "1.2",
      overflow: "visible",
      fontFamily: "lato, kommon grotesk extended, sans-serif",
      fontSize: "14px",
      fontWeight: 700,
    },
    "&:focus": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-columnHeaders": {
    maxHeight: "none !important",
    minHeight: "56px !important",
  },
};

const rowStyles = {
  "& .MuiDataGrid-row": {
    backgroundColor: "common.white",
    marginBottom: "4px",
    borderRadius: "4px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
    "&:hover": {
      backgroundColor: "grey.50",
    },
  },
  "& .Mui-selected": {
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: "grey.50",
    },
  },
  "& .row-success": {
    backgroundColor: "success.light",
    transition: "background-color 0.5s ease",
    animation: "pulseSuccess 1.5s ease-in-out",
    "@keyframes pulseSuccess": {
      "0%": { backgroundColor: "success.main" },
      "50%": { backgroundColor: "success.light" },
      "100%": { backgroundColor: "success.light" },
    },
  },
  "& .row-error": {
    backgroundColor: "error.light",
    transition: "background-color 0.5s ease",
  },
  "& .MuiDataGrid-row .actions": {
    opacity: 1,
  },
};

const toolbarStyles = {
  "& .MuiDataGrid-toolbarContainer": {
    padding: "8px 0",
    backgroundColor: "common.dorian",
    borderBottom: "1px solid",
    borderColor: "common.lightShade",
    width: "99%",
    "& .MuiButton-root": {
      color: "text.secondary",
      fontSize: "0.875rem",
      fontFamily: "lato, kommon grotesk extended, sans-serif",
      "&:hover": {
        backgroundColor: "grey.100",
      },
    },
    "& .MuiFormControl-root": {
      minWidth: 200,
      "& .MuiInputBase-root": {
        borderRadius: 1,
        backgroundColor: "common.white",
        fontSize: "0.875rem",
        "& .MuiInputBase-input": {
          color: "text.secondary",
        },
        "&:hover": {
          backgroundColor: "grey.50",
        },
      },
    },
  },
};

const containerStyles = {
  "& .MuiDataGrid-root": {
    border: "1px solid",
    borderColor: "common.lightShade",
    borderRadius: 1,
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "2px solid",
    borderColor: "common.lightShade",
    backgroundColor: "common.dorian",
  },
  "& .MuiTablePagination-root": {
    color: "text.secondary",
    fontFamily: "lato, kommon grotesk extended, sans-serif",
  },
  "& .MuiDataGrid-virtualScroller": {
    backgroundColor: "common.white",
  },
  "& .MuiDataGrid-overlay": {
    backgroundColor: "common.white",
  },
};

// Button styles
const refreshButtonStyles = {
  ml: 2,
  height: "36px",
  textTransform: "none",
  borderRadius: "8px",
  fontFamily: "lato, kommon grotesk extended, sans-serif",
  fontWeight: 400,
  fontSize: "14px",
  "&:hover": {
    backgroundColor: "primary.light",
  },
};

// Additional component styles
const componentStyles = {
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  filtersContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 2,
  },
};

const longTextCellStyles = {
  "& .long-text-cell": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 5,
    WebkitBoxOrient: "vertical",
    lineHeight: 1.15,
    fontSize: "0.75rem",
    wordBreak: "break-word",
    whiteSpace: "pre-line",
    mt: 0,
    mb: 0,
    pt: 0.25,
  },
  "& .MuiDataGrid-cell.long-text-cell-container": {
    alignItems: "flex-start",
    padding: "8px",
  },
};

export const tableStyles: {
  root: SxProps;
  dataGrid: SxProps;
  refreshButton: SxProps;
  wrapper: SxProps;
  filtersContainer: SxProps;
} = {
  root: {
    width: "100%",
    px: 3,
  },
  dataGrid: {
    ...containerStyles,
    ...cellStyles,
    ...headerStyles,
    ...rowStyles,
    ...toolbarStyles,
    ...longTextCellStyles,
    flex: 1,
    minHeight: 0,
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "grey.50",
    },
    "& .row-success": {
      backgroundColor: "success.light",
      "&:hover": {
        backgroundColor: "success.main",
      },
    },
    "& .row-error": {
      backgroundColor: "error.light",
      "&:hover": {
        backgroundColor: "error.main",
      },
    },
  },
  refreshButton: refreshButtonStyles,
  wrapper: componentStyles.wrapper,
  filtersContainer: componentStyles.filtersContainer,
};
