import { alpha, Box, IconButton, MenuItem, Popover, Typography, useTheme } from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { formatChatMessage } from "format-chat-message";
import parseStringToHtml from "html-react-parser";
import { CaseMessageType, ChatMode, ChatStatus, LexZapChat } from "@/hooks/lexZap/types";
import { dayFromTimestamp } from "../../../../utils/dayFromTimestamp";
import { MessageStatus } from "@/components/LexZap/MessageStatus";
import { formatPhoneNumber } from "@/utils/lexZap/formatPhoneNumber";
import { MoreVert as MenuIcon, Warning as WarningIcon } from "@mui/icons-material";
import { useDeactivateAi } from "@/hooks/lexZap/useDeactivateAi";
import { useActivateAi } from "@/hooks/lexZap/useActivateAi";
import { DeactivateAiModal } from "../DeactivateAiModal";
import { WebToast } from "@/components/core/Toast";
import { logger } from "@/core/logger";
import { getSnippetLabel } from "./utils/getSnippetLabel";
import { TicketDetailsModal } from "../TicketDetailsModal";
import { useUpdateTicket } from "@/hooks/lexZap/useUpdateTicket";
import { useAuthContext } from "@/contexts/AuthContext";
import { ArchiveUnarchiveChatModal } from "../ArchiveUnarchiveChatModal";
import { useArchiveChat } from "@/hooks/lexZap/useArchiveChat";
import { useUnarchiveChat } from "@/hooks/lexZap/useUnarchiveChat";
import { ActivateAiModal } from "../ActivateAiModal";
import { useLexZapMessages } from "@/hooks/lexZap/useLexZapMessages";
import { ChatStage } from "./components/ChatStage";
import { useMarkChatAsUnanswered } from "@/hooks/lexZap/useMarkChatAsUnanswered";
import { useMarkChatAsAnswered } from "@/hooks/lexZap/useMarkChatAsAnswered";
import { useCompanyMember } from "@/hooks/company/useCompanyMember";

interface ChatItemProps {
  isActive: boolean;
  onClick?: () => void;
  chat: LexZapChat;
  hasApprovalRequest?: boolean;
}

export const ChatItem = ({ isActive, chat, onClick, hasApprovalRequest }: ChatItemProps) => {
  const optionsMenuAnchorEl = useRef<HTMLElement>(null);
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const [isDeactivateAiModalOpen, setIsDeactivateAiModalOpen] = useState(false);
  const [isActivateAiModalOpen, setIsActivateAiModalOpen] = useState(false);
  const [isTicketDetailsModalOpen, setIsTicketDetailsModalOpen] = useState(false);
  const [archiveUnarchiveModal, setArchiveUnarchiveModal] = useState<"archive" | "unarchive" | null>(null);
  const { mutateAsync: deactivateAi, isPending: isDeactivatingAi } = useDeactivateAi();
  const { mutateAsync: activateAi, isPending: isActivatingAi } = useActivateAi();
  const { mutateAsync: archiveChat, isPending: isArchivingChat } = useArchiveChat();
  const { mutateAsync: unarchiveChat, isPending: isUnarchivingChat } = useUnarchiveChat();
  const { mutateAsync: updateTicket } = useUpdateTicket();
  const { user } = useAuthContext();
  const { data: messages } = useLexZapMessages({ applicantPhoneNumber: chat.endClientNumber, disabled: true });
  const { mutateAsync: markChatAsAnswered, isPending: isMarkingChatAsAnswered } = useMarkChatAsAnswered();
  const { mutateAsync: markChatAsUnanswered, isPending: isMarkingChatAsUnanswered } = useMarkChatAsUnanswered();

  const lastMessage = useMemo(() => {
    if (!messages || !messages.length) return null;
    return messages[messages.length - 1];
  }, [messages]);

  const theme = useTheme();
  const bgColor = theme.palette.grey["50"];

  const lastMessageBody = useMemo(() => {
    if (!lastMessage) return "";

    switch (lastMessage.type) {
      case CaseMessageType.AUDIO:
        return "Áudio";
      case CaseMessageType.IMAGE:
        return "Foto";
      case CaseMessageType.DOCUMENT:
        return "Documento";
      case CaseMessageType.SNIPPET:
        return getSnippetLabel(lastMessage.snippetInfo);
      case CaseMessageType.TEXT:
      case CaseMessageType.TEMPLATE:
        return lastMessage.body;
      default:
        return lastMessage.type;
    }
  }, [lastMessage]);

  // Menu handlers
  const openOptionsMenu = () => setOptionsMenuOpen(true);
  const closeOptionsMenu = () => setOptionsMenuOpen(false);

  const handleMarkChatAsAnswered = async () => {
    try {
      await markChatAsAnswered({ applicantPhoneNumber: chat.endClientNumber });
    } catch (error) {
      logger.error("Error in handleMarkChatAsAnswered:", { error });
      WebToast.error("Erro ao marcar conversa como respondida");
    }
  };

  const handleMarkChatAsUnanswered = async () => {
    try {
      await markChatAsUnanswered({ applicantPhoneNumber: chat.endClientNumber });
    } catch (error) {
      logger.error("Error in handleMarkChatAsUnanswered:", { error });
      WebToast.error("Erro ao marcar conversa como não respondida");
    }
  };

  const handleDeactivateAi = async () => {
    try {
      await deactivateAi({ applicantPhoneNumber: chat.endClientNumber });
      closeOptionsMenu();
      closeDeactivateAiModal();
      WebToast.success("Assistente IA desativado com sucesso");
    } catch (error) {
      closeDeactivateAiModal();
      closeOptionsMenu();
      logger.error("Error in handleDeactivateAi:", { error });
      WebToast.error("Erro ao desativar assistente IA");
    }
  };

  const handleActivateAi = async () => {
    try {
      await activateAi({ applicantPhoneNumber: chat.endClientNumber });
      WebToast.success("Assistente IA reativado com sucesso");
    } catch (error) {
      logger.error("Error in handleActivateAi:", { error });
      WebToast.error("Erro ao reativar assistente IA");
    }
    closeOptionsMenu();
    closeActivateAiModal();
  };

  const closeActivateAiModal = () => setIsActivateAiModalOpen(false);
  const handleOpenDeactivateAiModal = () => setIsDeactivateAiModalOpen(true);
  const handleOpenActivateAiModal = () => setIsActivateAiModalOpen(true);
  const closeDeactivateAiModal = () => {
    setIsDeactivateAiModalOpen(false);
    closeOptionsMenu();
  };

  const handleOpenTicketDetailsModal = () => setIsTicketDetailsModalOpen(true);
  const handleCloseTicketDetailsModal = () => {
    setIsTicketDetailsModalOpen(false);
    closeOptionsMenu();
  };

  const handleTakeOverTicket = async () => {
    const ticket = chat.lastTicket;
    if (!ticket || !user) return;

    try {
      await updateTicket({
        ticketId: ticket.id,
        applicantPhoneNumber: ticket.applicantPhoneNumber,
        updateFields: { responsibleUserId: user.userId },
      });
      return WebToast.success("Atendimento assumido com sucesso");
    } catch (err) {
      return WebToast.error("Falha ao assumir atendimento");
    }
  };

  const handleOpenArchiveChatModal = (mode: "archive" | "unarchive") => setArchiveUnarchiveModal(mode);
  const handleCloseArchiveChatModal = () => {
    setArchiveUnarchiveModal(null);
    closeOptionsMenu();
  };

  const handleArchiveChat = async () => {
    try {
      await archiveChat({ applicantPhoneNumber: chat.endClientNumber });
      closeOptionsMenu();
      handleCloseArchiveChatModal();
      WebToast.success("Conversa arquivada com sucesso");
    } catch (error) {
      closeOptionsMenu();
      handleCloseArchiveChatModal();
      WebToast.error("Erro ao arquivar conversa");
    }
  };

  const handleUnarchiveChat = async () => {
    try {
      await unarchiveChat({ applicantPhoneNumber: chat.endClientNumber });
      closeOptionsMenu();
      handleCloseArchiveChatModal();
      WebToast.success("Conversa desarquivada com sucesso");
    } catch (error) {
      closeOptionsMenu();
      handleCloseArchiveChatModal();
      WebToast.error("Erro ao desarquivar conversa");
    }
  };

  const { data: responsibleUser } = useCompanyMember({ memberId: chat.lastTicket?.responsibleUserId });

  return (
    <Box sx={{ position: "relative", borderBottom: "1px solid", borderColor: "grey.100" }}>
      <Box
        onClick={onClick}
        sx={{
          backgroundColor: isActive ? bgColor : undefined,
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
          px: 1.5,
          py: 1.5,
          cursor: "pointer",
          transition: "background-color 0.2s",
          "&:hover": {
            backgroundColor: bgColor ? alpha(bgColor, 0.4) : undefined,
            "& .waiting-response": { display: "none" },
            "& .expand-icon": { display: "inline-flex" },
          },
          "&:active": {
            backgroundColor: bgColor ? alpha(bgColor, 0.7) : undefined,
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 0.5 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <ChatStage chat={chat} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {!!responsibleUser && (
              <Typography variant="body" color="grey.700" sx={{ fontSize: "14px" }}>
                {responsibleUser?.name}
              </Typography>
            )}
            {hasApprovalRequest && (
              <WarningIcon
                sx={{
                  color: "warning.main",
                  width: 20,
                  height: 20,
                }}
              />
            )}
          </Box>
        </Box>

        <Box sx={{ display: "grid", gridTemplateColumns: "1fr auto", gap: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5, minWidth: 0 }}>
            <Typography variant="body" color="#09132C" sx={{ fontSize: "16px", fontWeight: 500 }}>
              {chat.applicantName || formatPhoneNumber(chat.endClientNumber)}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              {!!lastMessage && <MessageStatus message={lastMessage} />}
              <Typography
                variant="body"
                sx={{ color: "common.shade", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
              >
                {parseStringToHtml(
                  formatChatMessage({
                    message: lastMessageBody,
                    shouldFormat: { breakline: false, url: false, phone: false },
                  })
                )}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end" }}
          >
            {!!lastMessage && (
              <Typography variant="body2" sx={{ whiteSpace: "nowrap", color: "common.shade" }}>
                {dayFromTimestamp(lastMessage.timestamp, { displayTodayAsTime: true })}
              </Typography>
            )}

            <Box
              ref={optionsMenuAnchorEl}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: 24, height: 24 }}
            >
              {!!chat.unansweredMessagesCount && (
                <Typography
                  className="waiting-response"
                  variant="body1"
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: "orange.light.15",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    color: "common.coral",
                    border: "1px solid",
                    borderColor: "primary.light",
                  }}
                >
                  {chat.unansweredMessagesCount !== -1 ? chat.unansweredMessagesCount : ""}
                </Typography>
              )}

              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  openOptionsMenu();
                }}
                className="expand-icon"
                sx={{
                  m: -1,
                  display: chat.unansweredMessagesCount ? "none" : "inline-flex",
                }}
              >
                <MenuIcon sx={{ width: 24, height: 24, color: "text.secondary" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>

      <Popover
        open={optionsMenuOpen}
        onClose={closeOptionsMenu}
        anchorEl={optionsMenuAnchorEl.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiModal-backdrop": { backgroundColor: "transparent" },
          "& .MuiBackdrop-root": { backgroundColor: "transparent" },
        }}
      >
        <MenuItem onClick={handleOpenTicketDetailsModal}>
          <Typography variant="body1">Gerenciar conversa</Typography>
        </MenuItem>
        {chat.chatMode === ChatMode.MANUAL ? (
          <MenuItem onClick={handleOpenActivateAiModal}>
            <Typography variant="body1">Reativar assistente IA</Typography>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleOpenDeactivateAiModal}>
            <Typography variant="body1">Desativar assistente IA</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleTakeOverTicket} disabled={chat.lastTicket?.responsibleUserId === user?.userId}>
          <Typography variant="body1">Assumir conversa</Typography>
        </MenuItem>
        {chat.status === ChatStatus.ARCHIVED ? (
          <MenuItem onClick={() => handleOpenArchiveChatModal("unarchive")}>
            <Typography variant="body1">Desarquivar conversa</Typography>
          </MenuItem>
        ) : (
          <MenuItem onClick={() => handleOpenArchiveChatModal("archive")}>
            <Typography variant="body1">Arquivar conversa</Typography>
          </MenuItem>
        )}
        {!!chat.unansweredMessagesCount ? (
          <MenuItem onClick={handleMarkChatAsAnswered} disabled={isMarkingChatAsAnswered || isMarkingChatAsUnanswered}>
            <Typography variant="body1">Marcar como respondido</Typography>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={handleMarkChatAsUnanswered}
            disabled={isMarkingChatAsAnswered || isMarkingChatAsUnanswered}
          >
            <Typography variant="body1">Marcar como não respondido</Typography>
          </MenuItem>
        )}
      </Popover>

      <DeactivateAiModal
        isOpen={isDeactivateAiModalOpen}
        onConfirm={handleDeactivateAi}
        onCancel={closeDeactivateAiModal}
        isLoading={isDeactivatingAi}
      />

      <ActivateAiModal
        isOpen={isActivateAiModalOpen}
        onConfirm={handleActivateAi}
        onCancel={closeActivateAiModal}
        isLoading={isActivatingAi}
      />

      {isTicketDetailsModalOpen && chat.lastTicket && (
        <TicketDetailsModal onClose={handleCloseTicketDetailsModal} ticket={chat.lastTicket} />
      )}

      {archiveUnarchiveModal && (
        <ArchiveUnarchiveChatModal
          onCancel={handleCloseArchiveChatModal}
          onConfirm={archiveUnarchiveModal === "archive" ? handleArchiveChat : handleUnarchiveChat}
          isOpen={true}
          isLoading={isArchivingChat || isUnarchivingChat}
          action={archiveUnarchiveModal}
        />
      )}
    </Box>
  );
};
