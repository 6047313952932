import { useMutation } from "@tanstack/react-query";
import { MessageDTO } from "@/contexts/MessagesContext";
import { logger } from "@/core/logger";
import { ThreadService } from "@/services/thread";

export const useSendMessage = () => {
  return useMutation({
    mutationKey: ["sendMessage"],
    mutationFn: async ({
      threadId,
      messages,
      isOnboarding = false,
      content = "",
    }: {
      threadId: string;
      messages: MessageDTO[];
      isOnboarding?: boolean;
      content?: string;
    }) => {
      logger.info("Sending message", { messageContext: { messages } });
      return await ThreadService.sendMessage(threadId, messages, isOnboarding, content);
    },
    onError: (error) => {
      logger.error("Error in useSendMessage:", { error });
    },
  });
};
