import { LexterCopilotAuthService } from "@/services/lexterCopilotAuth";
import { useAuthStore } from "@/stores/useAuthStore";
import { useQuery } from "@tanstack/react-query";

export const useUserQuery = () => {
  const isLoggedIn = useAuthStore((state) => !!state.token);

  return useQuery({
    queryKey: ["user", { isLoggedIn }],
    queryFn: async () => (isLoggedIn ? await LexterCopilotAuthService.getUserData() : null),
  });
};
