import { env } from "@/constants/environment";
import {
  AcceptTermsParams,
  AddActionOfInterestPayload,
  CancelExecutionPayload,
  CheckCreditsParams,
  CheckCreditsResponse,
  CreditsApiResponse,
  CreditsHistoryApiResponseItem,
  DeletePrecedentPayload,
  GetDocumentResponseType,
  GetMessagesByThreadPayload,
  GetMessagesByThreadResponse,
  GetRecordsPayload,
  PrecedentPresignedUrlPayload,
  PrecedentPresignedUrlResponse,
  RenamePrecedentPayload,
  HandlePaymentPayload,
  SavePrecedentPayload,
  UserProfile,
  CheckCouponPayload,
  GetPaymentUserPortalPayload,
  RestorePlanPayload,
  SendReferralsPayload,
  ListReferralsPayload,
  ReferralApiResponseItem,
  GetLexZapMessagesParams,
  SendLexZapMessageParams,
  CaseStatus,
} from "./types";
import { getPaginationHeaders } from "@/core/api/utils/getPaginationHeaders";
import { authenticatedCopilotServer, authenticatedLexterApi } from "@/core/api";
import { Evaluation, SkillsPayload, UserInputDTO } from "@/contexts/WebSocketContext";
import { FlowMessageAction } from "@/contexts/MessagesContext";
import axios from "axios";
import { logger } from "@/core/logger";
import { Case, LexZapCompany } from "../lexZap/types";
import { Paginated } from "@/core/api/types";
import { ThreadRecordWithThreadId } from "../records/types";
import { LexZapMessagePage } from "@/services/lexZap/types";
import { ReasonForCaseArchive } from "@/services/case/types";
import { PaginationPayload } from "@/core/api/types";

export const useApi = () => {
  const getCredits = async () => {
    const response = await authenticatedCopilotServer.get<CreditsApiResponse>("/credits");
    return response.data;
  };

  const checkCredits = async ({ action }: CheckCreditsParams) => {
    const response = await authenticatedCopilotServer.post<CheckCreditsResponse>("/credits/check", { action });
    return response.data;
  };

  const getCreditsHistory = async (): Promise<CreditsHistoryApiResponseItem[]> => {
    const response = await authenticatedCopilotServer.get<CreditsHistoryApiResponseItem[]>("/credits/history");
    return response.data;
  };

  const acceptTerms = async ({ version }: AcceptTermsParams): Promise<void> => {
    await authenticatedCopilotServer.put("/acceptTerms", { version });
  };

  const getSignedUrl = async ({ name }: { name: string }): Promise<{ url: string; location: string }> => {
    const response = await authenticatedCopilotServer.post(`${env.API}/url`, { name });
    return response.data;
  };

  const createDocument = async ({ name, location }: { name: string; location: string }): Promise<string> => {
    const response = await authenticatedCopilotServer.post(`${env.API}/files`, { name, location });
    return response.data.toString();
  };

  const addActionOfInterest = async ({ userId, companyId, token, ...payload }: AddActionOfInterestPayload) => {
    const customHeader = token ? { Authorization: `bearer ${token}` } : {};

    await authenticatedLexterApi.put(
      `/users/${userId}/actionsOfInterest`,
      {
        ...payload,
      },
      {
        headers: { ...customHeader, "x-company-id": companyId },
      }
    );
  };

  const handlePayment = async ({ ...payload }: HandlePaymentPayload) => {
    const response = await authenticatedLexterApi.post(
      `/payments`,
      {
        ...payload,
      },
      {
        headers: { "x-company-id": payload.companyId },
      }
    );

    return response.data;
  };

  const sendReferrals = async ({
    companyId,
    emails,
    resendReferral,
  }: SendReferralsPayload): Promise<{ id: string }[]> => {
    const response = await authenticatedLexterApi.post(
      `/referrals`,
      {
        emails,
        resendReferral,
      },
      {
        headers: { "x-company-id": companyId },
      }
    );

    return response.data;
  };

  const listReferrals = async ({
    companyId,
    pagination,
  }: ListReferralsPayload): Promise<Paginated<ReferralApiResponseItem>> => {
    const response = await authenticatedLexterApi.get(`/referrals`, {
      headers: { "x-company-id": companyId, ...getPaginationHeaders(pagination) },
    });

    return {
      data: response.data,
      totalResults: Number(response.headers["x-pagination-total-results"]),
      pageSize: Number(response.headers["x-pagination-page-size"]),
      page: Number(response.headers["x-pagination-page"]),
    };
  };

  const checkCoupon = async ({ couponCode, planName, companyId }: CheckCouponPayload) => {
    const response = await authenticatedLexterApi.get(`/payments/coupons/${couponCode}?planName=${planName}`, {
      headers: { "x-company-id": companyId },
    });
    return response.data;
  };

  const restorePlan = async ({ companyId, couponCode }: RestorePlanPayload) => {
    const response = await authenticatedLexterApi.put(
      `/companiesPlan/${companyId}/restore`,
      {
        couponCode,
      },
      { headers: { "x-company-id": companyId } }
    );
    return response.data;
  };

  const getPaymentUserPortal = async ({ companyId }: GetPaymentUserPortalPayload) => {
    const response = await authenticatedLexterApi.get(`/payments/userPortal/${companyId}`, {
      headers: { "x-company-id": companyId },
    });
    return response.data;
  };

  const updateUserProfile = async (userProfile: UserProfile) => {
    const response = await authenticatedLexterApi.put(`/users/${userProfile.userId}`, userProfile);
    return response.data;
  };

  const getDocumentById = async (documentId: number) => {
    const response = await authenticatedCopilotServer.get<GetDocumentResponseType>(`/documents/${documentId}/content`);
    return response.data;
  };

  const getDocuments = async () => {
    const response = await authenticatedCopilotServer.get<GetDocumentResponseType>(`/documents`);
    return response.data;
  };

  const saveEditedDocument = async (documentId: number, content: string) => {
    await authenticatedCopilotServer.post(`/documents/${documentId}/content`, { content });
  };

  const getRecords = async (props?: GetRecordsPayload) => {
    const { sortBy = "date", sortDirection = "desc", threadId, pagination, search } = props || {};

    const params = new URLSearchParams({
      sortDirection: sortDirection,
      sortBy: sortBy,
    });

    if (search) {
      params.append("search", search);
    }

    const query = params.toString();

    const url = `/threads/${threadId}/records?${query}`;

    try {
      const response = await authenticatedCopilotServer.get<Paginated<ThreadRecordWithThreadId>>(url, {
        headers: {
          ...getPaginationHeaders(pagination),
        },
      });
      return response.data;
    } catch (e) {
      logger.error("Error fetching documents", { error: e });
    }

    return undefined;
  };

  const updateDocumentName = async ({ documentId, documentName }: { documentId: number; documentName: string }) => {
    await authenticatedCopilotServer.put<GetDocumentResponseType>(`/documents/${documentId}/name`, {
      name: documentName,
    });
  };

  const archiveDocument = async (documentId: number) => {
    await authenticatedCopilotServer.post(`/documents/${documentId}/archive`);
  };

  const removeDocumentThread = async (documentId: number) => {
    await authenticatedCopilotServer.delete(`/documents/${documentId}/thread`);
  };

  const saveNewDocument = async ({ name, content, threadId }: { name: string; content: string; threadId: string }) => {
    const response = await authenticatedCopilotServer.post<{ id: number }>(`/documents`, { name, content, threadId });
    return { id: response.data.id };
  };

  const saveOpenDocument = async ({ threadId, documentId }: { documentId: number; threadId: string }) => {
    await authenticatedCopilotServer.put(`/threads/${threadId}/documents/open/${documentId}`);
  };

  const deleteOpenDocument = async ({ threadId, documentId }: { documentId: number; threadId: string }) => {
    await authenticatedCopilotServer.delete(`/threads/${threadId}/documents/open/${documentId}`);
  };

  const toggleFavoriteThread = async (threadId: string) => {
    const response = await authenticatedCopilotServer.put(`/threads/${threadId}/favorite`);
    return response.data;
  };

  const savePrecedent = async (payload: SavePrecedentPayload) => {
    await authenticatedCopilotServer.post("/precedents", payload);
  };

  const getPrecedentPresignedUrl = async ({ id }: PrecedentPresignedUrlPayload) => {
    const response = await authenticatedCopilotServer.get<PrecedentPresignedUrlResponse>(
      `/precedents/${id}/presignedUrl`
    );
    return response.data;
  };

  const renamePrecedent = async ({ id, name }: RenamePrecedentPayload) => {
    await authenticatedCopilotServer.put(`/precedents/${id}/name`, { name });
  };

  const deletePrecedent = async ({ id }: DeletePrecedentPayload) => {
    await authenticatedCopilotServer.delete(`/precedents/${id}`);
  };

  const addFavoriteSkill = async ({ skillId }: { skillId: string }) => {
    try {
      const response = await authenticatedCopilotServer.put(`/skills/favorites/${skillId}`);
      return response.data;
    } catch (e) {
      logger.error("addFavoriteSkill", { error: e });
      throw e;
    }
  };

  const deleteFavoriteSkill = async ({ skillId }: { skillId: string }) => {
    try {
      const response = await authenticatedCopilotServer.delete(`/skills/favorites/${skillId}`);
      return response.data;
    } catch (e) {
      logger.error("deleteFavoriteSkill", { error: e });
      throw e;
    }
  };

  const getFavoriteSkills = async () => {
    try {
      const response = await authenticatedCopilotServer.get(`/skills/favorites`);
      return response.data;
    } catch (e) {
      logger.error("getFavoriteSkills", { error: e });
      throw e;
    }
  };

  const getMessages = async ({ threadId, pagination }: GetMessagesByThreadPayload) => {
    const response = await authenticatedCopilotServer.get<GetMessagesByThreadResponse>(
      `/threads/${threadId}/messages`,
      {
        headers: {
          ...getPaginationHeaders(pagination),
        },
      }
    );
    return {
      data: response.data.data,
      totalResults: response.data.totalResults,
    };
  };

  const evaluateMessage = async ({ messageId, evaluation }: { messageId: string; evaluation?: Evaluation }) => {
    const response = await authenticatedCopilotServer.post(`/messages/${messageId}/evaluate`, {
      evaluation: evaluation ?? null,
    });
    return response.data;
  };

  const generateThreadName = async ({
    threadId,
    data,
  }: {
    threadId: string;
    data: FlowMessageAction | SkillsPayload<UserInputDTO>;
  }) => {
    const response = await authenticatedCopilotServer.post<{ name: string }>(`/threads/${threadId}/name/generate`, {
      data,
    });
    return response.data;
  };

  const getLexZapCompanyById = async ({ companyId }: { companyId: string }) => {
    const response = await authenticatedCopilotServer.get<LexZapCompany>(`whatsapp-assistant/companies/${companyId}`);
    return response.data;
  };

  const getMyLexZapCompany = async () => {
    const response = await authenticatedCopilotServer.get<LexZapCompany>(`whatsapp-assistant/companies/me`);
    return response.data;
  };

  const getLexZapCases = async ({
    pagination,
    status,
    filter,
    applicantPhoneNumber,
  }: {
    pagination: PaginationPayload;
    status?: CaseStatus[];
    filter?: string;
    applicantPhoneNumber?: string;
  }) => {
    const response = await authenticatedCopilotServer.get<Case[]>(`/cases`, {
      headers: {
        ...getPaginationHeaders(pagination),
      },
      params: {
        statuses: status?.join(","),
        filter,
        applicantPhoneNumber,
      },
    });
    return {
      data: response.data,
      totalResults: Number(response.headers["x-pagination-total-results"]),
      pageSize: Number(response.headers["x-pagination-page-size"]),
      page: Number(response.headers["x-pagination-page"]),
    };
  };

  const getLexZapCaseById = async ({ caseId }: { caseId: string }) => {
    const response = await authenticatedCopilotServer.get<Case>(`/cases/${caseId}`);
    return response.data;
  };

  const getLexZapMessages = async ({ applicantPhoneNumber, limit, pageToken }: GetLexZapMessagesParams) => {
    const response = await authenticatedCopilotServer.get<LexZapMessagePage>(`/cases/chats/messages`, {
      params: { applicantPhoneNumber, limit, pageToken },
    });
    return response.data;
  };

  const sendLexZapMessage = async ({ message, applicantPhoneNumber, requestId }: SendLexZapMessageParams) => {
    const response = await authenticatedCopilotServer.post<{
      message: {
        id: string;
        text: string;
      };
    }>(`/cases/chats/messages`, { message, applicantPhoneNumber, requestId });
    return response.data;
  };

  const activateCase = async ({ caseId }: { caseId: string }) => {
    try {
      await authenticatedCopilotServer.post(`/cases/${caseId}/activate`);
    } catch (e) {
      logger.error("activateCase", { error: e });
      throw e;
    }
  };

  const archiveCase = async ({
    caseId,
    reasonType,
    reasonDetails,
  }: {
    caseId: string;
    reasonType?: ReasonForCaseArchive;
    reasonDetails?: string;
  }) => {
    try {
      await authenticatedCopilotServer.post(`/cases/${caseId}/archive`, { reasonType, reasonDetails });
    } catch (e) {
      logger.error("archiveCase", { error: e });
      throw e;
    }
  };

  const unarchiveCase = async ({ caseId }: { caseId: string }) => {
    try {
      await authenticatedCopilotServer.post(`/cases/${caseId}/unarchive`);
    } catch (e) {
      logger.error("unarchiveCase", { error: e });
      throw e;
    }
  };

  const deleteCase = async ({ caseId }: { caseId: string }) => {
    try {
      await authenticatedCopilotServer.delete(`/cases/${caseId}`);
    } catch (e) {
      logger.error("deleteCase", { error: e });
      throw e;
    }
  };

  const createCase = async ({
    name,
    applicantId,
    ticketId,
    summary,
    threadId,
  }: {
    name: string;
    applicantId?: string;
    ticketId?: string;
    summary?: string;
    threadId?: string;
  }) => {
    try {
      const response = await authenticatedCopilotServer.post(`/cases`, {
        name,
        applicantId,
        ticketId,
        summary,
        threadId,
      });
      return response.data;
    } catch (e) {
      logger.error("createCase", { error: e });
      throw e;
    }
  };

  const updateCase = async ({
    caseId,
    data,
  }: {
    caseId: string;
    data: {
      name: string;
      applicantId?: string | null;
    };
  }) => {
    try {
      await authenticatedCopilotServer.post(`/cases/${caseId}`, data);
    } catch (e) {
      logger.error("updateCase", { error: e });
      throw e;
    }
  };

  const signChatBucketUrl = async ({
    applicantPhoneNumber,
    fileName,
  }: {
    applicantPhoneNumber: string;
    fileName: string;
  }) => {
    try {
      const response = await authenticatedCopilotServer.post(`/cases/sign-url`, {
        applicantPhoneNumber,
        fileName,
      });

      return response.data;
    } catch (e) {
      logger.error("signChatBucketUrl", { error: e });
      throw e;
    }
  };

  const cancelExecution = async ({ executionId }: CancelExecutionPayload) => {
    logger.info("Canceling execution", { messageContext: { executionId } });
    try {
      logger.info("Canceling execution2");
      const { status, data } = await authenticatedCopilotServer.delete(`/executions/${executionId}`);
      logger.info("Canceling execution response", { messageContext: { status, data } });
      return { status };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        logger.error("Error canceling execution", { error });
        return {
          status: error.response.status,
          message: error.response.data.message || "Erro ao cancelar a execução",
        };
      }
      throw error;
    }
  };

  return {
    getCredits,
    checkCredits,
    getCreditsHistory,
    acceptTerms,
    getSignedUrl,
    createDocument,
    cancelExecution,
    updateUserProfile,
    getDocumentById,
    saveEditedDocument,
    getRecords,
    updateDocumentName,
    archiveDocument,
    saveNewDocument,
    getDocuments,
    toggleFavoriteThread,
    saveOpenDocument,
    deleteOpenDocument,
    addActionOfInterest,
    savePrecedent,
    getPrecedentPresignedUrl,
    renamePrecedent,
    deletePrecedent,
    addFavoriteSkill,
    getFavoriteSkills,
    deleteFavoriteSkill,
    handlePayment,
    checkCoupon,
    getPaymentUserPortal,
    getMessages,
    evaluateMessage,
    generateThreadName,
    restorePlan,
    sendReferrals,
    listReferrals,
    getLexZapCompanyById,
    getLexZapCases,
    getLexZapMessages,
    sendLexZapMessage,
    activateCase,
    archiveCase,
    deleteCase,
    unarchiveCase,
    updateCase,
    createCase,
    removeDocumentThread,
    signChatBucketUrl,
    getMyLexZapCompany,
    getLexZapCaseById,
  };
};
