import { InfiniteData, QueryClient } from "@tanstack/react-query";
import { CaseMessage } from "@/hooks/lexZap/types";
import { getMessagesQueryKey } from "../..";

export const updateLexZapMessage = ({
  queryClient,
  applicantPhoneNumber,
  where,
  updateFn,
}: {
  queryClient: QueryClient;
  applicantPhoneNumber: string;
  where: (message: CaseMessage) => boolean;
  updateFn: (message: CaseMessage) => CaseMessage;
}) => {
  const queryKey = getMessagesQueryKey({ applicantPhoneNumber });

  queryClient.setQueryData<InfiniteData<{ messages: CaseMessage[]; pageToken?: string }>>(queryKey, (oldData) => {
    if (!oldData) return oldData;

    const updatedPages = oldData.pages.map((page) => {
      const existentMessage = page.messages.find(where);
      if (existentMessage) {
        return {
          ...page,
          messages: page.messages.map((msg) => (msg.id === existentMessage.id ? updateFn(msg) : msg)),
        };
      }
      return page;
    });

    return { ...oldData, pages: updatedPages };
  });
};
