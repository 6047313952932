import React from "react";

import { AuthContext } from "./context";
import { useLogin } from "./hooks/useLogin";

export function AuthProvider({ children }: React.PropsWithChildren) {
  const { user, loading, loginWithEmailAndPassword, loginWithGoogle } = useLogin();

  return (
    <AuthContext.Provider
      value={{
        user: user || undefined,
        loading,
        loginWithEmailAndPassword,
        loginWithGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
