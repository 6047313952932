import React from "react";
import { ModalRight } from "@/components/Modal/ModalRight";
import { Typography, Box, FormControlLabel, Checkbox, TextField, Autocomplete, Chip, ButtonGroup } from "@mui/material";
import { Button } from "@/components/Button";
import { CloseOutlined } from "@mui/icons-material";
import { CaseFilters } from "./types";

interface FilterCasesModalProps {
  open: boolean;
  onClose: () => void;
  caseAreaOptions: string[];
  caseFilters: CaseFilters;
  setCaseFilters: (newFilters: CaseFilters) => void;
}

export const FilterCasesModal = ({
  open,
  onClose,
  caseAreaOptions,
  caseFilters,
  setCaseFilters,
}: FilterCasesModalProps) => {
  const [filters, setFilters] = React.useState<CaseFilters>(caseFilters);

  const handleApplyFilters = () => {
    setCaseFilters(filters);
    onClose();
  };

  return (
    <ModalRight
      open={open}
      onClose={onClose}
      title="Filtrar casos"
      size={"SMALL"}
      footer={
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
            }}
            onClick={handleApplyFilters}
          >
            Filtrar
          </Button>
        </Box>
      }
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <FilterSelection title="Origem do caso" description="Filtre casos pela origem de sua criação.">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: 2,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.origin.imported}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      origin: {
                        ...prev.origin,
                        imported: !prev.origin.imported,
                      },
                    }))
                  }
                />
              }
              label="Importado via OAB"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.origin.manual}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      origin: {
                        ...prev.origin,
                        manual: !prev.origin.manual,
                      },
                    }))
                  }
                />
              }
              label="Criado manualmente"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.origin.assistant}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      origin: {
                        ...prev.origin,
                        assistant: !prev.origin.assistant,
                      },
                    }))
                  }
                />
              }
              label="Atendimento Lexter"
            />
          </Box>
        </FilterSelection>

        <FilterSelection
          title="Status do processo"
          description="Filtre casos que possuem processo vinculado ou não. Você também pode ver todos os casos."
        >
          <ButtonGroup size="small">
            <Button
              variant={filters.boundStatus === "bound" ? "contained" : "outlined"}
              onClick={() =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  boundStatus: "bound",
                }))
              }
            >
              Vinculado
            </Button>
            <Button
              variant={filters.boundStatus === "unbound" ? "contained" : "outlined"}
              onClick={() =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  boundStatus: "unbound",
                }))
              }
            >
              Não vinculado
            </Button>
            <Button
              variant={filters.boundStatus === "all" ? "contained" : "outlined"}
              onClick={() =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  boundStatus: "all",
                }))
              }
            >
              Todos
            </Button>
          </ButtonGroup>
        </FilterSelection>

        <FilterSelection
          title="Casos das áreas:"
          description="Exibir apenas casos que foram marcados como pertencentes ás áreas:"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexWrap: "wrap",
              }}
            >
              {filters.legalAreas?.map((legalArea) => (
                <Chip
                  key={legalArea}
                  label={legalArea}
                  onDelete={() => {
                    setFilters((prev) => ({
                      ...prev,
                      legalAreas: prev.legalAreas?.filter((item) => item !== legalArea),
                    }));
                  }}
                  sx={{
                    backgroundColor: "secondary.main",
                    color: "text.primary",
                  }}
                  deleteIcon={<CloseOutlined />}
                />
              ))}
            </Box>

            <Autocomplete
              renderInput={(params) => <TextField {...params} placeholder="Comece a digitar para buscar" />}
              multiple
              options={caseAreaOptions}
              value={filters.legalAreas}
              noOptionsText="Nenhuma opção encontrada"
              disablePortal
              onChange={(_, selectedOptions) => {
                setFilters((prev) => ({
                  ...prev,
                  legalAreas: selectedOptions,
                }));
              }}
              sx={{
                "& .MuiChip-root": {
                  visibility: "hidden",
                  display: "none",
                  color: "red",
                },
                "& .MuiChip-label": {
                  display: "none",
                  visibility: "hidden",
                  color: "red",
                },
              }}
            />
          </Box>
        </FilterSelection>
      </Box>
    </ModalRight>
  );
};

const FilterSelection = ({
  title,
  description,
  children,
}: {
  title: string;
  description: string;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
      {children}
    </Box>
  );
};
