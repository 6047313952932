import { Box, IconButton, Modal, SxProps, Theme, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { ArrowBack } from "@mui/icons-material";

interface ModalRightProps {
  open: boolean;
  onClose: () => void;
  goBack?: () => void;
  title?: string;
  subtitle?: string;
  footer?: React.ReactNode;
  children: React.ReactElement;
  size?: "SMALL" | "MEDIUM" | "LARGE" | "EXLARGE";
  subHeader?: React.ReactNode;
  sx?: SxProps<Theme>;
}

export const ModalRight = ({
  open,
  onClose,
  goBack,
  title,
  subtitle,
  footer,
  children,
  size,
  subHeader,
  ...rest
}: ModalRightProps) => {
  let width: string;

  switch (size) {
    case "SMALL":
      width = "380px";
      break;
    case "LARGE":
      width = "640px";
      break;
    case "EXLARGE":
      width = "900px";
      break;
    case "MEDIUM":
      width = "500px";
      break;
    default:
      width = "380px";
  }

  const backgroundColor = goBack
    ? {
        "& .MuiModal-backdrop": {
          backgroundColor: "transparent",
        },
      }
    : undefined;

  return (
    <Modal {...rest} open={open} onClose={onClose} sx={{ ...backgroundColor, ...rest.sx }}>
      <Box
        sx={{
          width: width,
          backgroundColor: "background.paper",
          right: 0,
          position: "fixed",
          overflow: "hidden",
          height: "100vh",
          display: "grid",
          cursor: "default",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "min-content 1fr min-content",
          "&:focus-visible": {
            outline: "none",
          },
        }}
      >
        <Box
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {goBack && (
                  <IconButton
                    onClick={goBack}
                    sx={{
                      m: -1,
                    }}
                  >
                    <ArrowBack
                      sx={{
                        height: "24px",
                        width: "24px",
                        color: "text.secondary",
                      }}
                    />
                  </IconButton>
                )}
                <Typography variant="h3" sx={{ fontWeight: "400", color: "text.secondary" }}>
                  {title}
                </Typography>
              </Box>

              {!!subtitle && (
                <Typography variant="subtitle" sx={{ fontWeight: "400" }}>
                  {subtitle}
                </Typography>
              )}
            </Box>

            <CloseIcon
              sx={{ width: "24px", height: "24px", cursor: "pointer", color: "text.secondary" }}
              onClick={onClose}
            />
          </Box>

          {subHeader && <Box sx={{ display: "flex", alignItems: "center" }}>{subHeader}</Box>}
        </Box>

        <Box
          sx={{
            padding: 2,
            overflowY: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar": { width: "6px" },
            "&::-webkit-scrollbar-track": {
              borderRadius: "10px",
              backgroundColor: "common.white",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              backgroundColor: "text.secondary",
            },
          }}
        >
          {children}
        </Box>

        {footer && (
          <Box
            sx={{
              boxShadow: 24,
              padding: 2,
            }}
          >
            {footer}
          </Box>
        )}
      </Box>
    </Modal>
  );
};
