import { useQuery } from "@tanstack/react-query";
import { CaseService } from "@/services/case";
import { PlatformFile } from "./types";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

const CASE_PLATFORM_FILES_QUERY_KEY = ["casePlatformFiles"];

export const getCasePlatformFilesQueryKey = ({ caseId }: { caseId?: string }) =>
  [...CASE_PLATFORM_FILES_QUERY_KEY, caseId] as const;

export const useCasePlatformFiles = ({ caseId }: { caseId?: string }) => {
  const { fileSignAndUpload: fileSignAndUploadEnabled } = useFeatureFlags();
  return useQuery<PlatformFile[]>({
    queryKey: getCasePlatformFilesQueryKey({ caseId }),
    queryFn: async () => {
      if (!caseId) {
        return [];
      }
      return await CaseService.getCasePlatformFiles(caseId);
    },
    staleTime: 1000 * 60,
    enabled: !!caseId && fileSignAndUploadEnabled,
  });
};
