import { useNavigate } from "react-router-dom";

import { ROUTE_PATHS } from "@/routes/routePaths";

import { calculateDaysToEndPlan } from "@/utils/plans";

import { useCompanyTeam } from "@/hooks/company/useCompanyTeam";
import { useCredits } from "@/hooks/credits/useCredits";
import { PlanType } from "@/hooks/credits/types";
import { planTitles } from "@/hooks/credits";
import { useLexZapCases } from "../lexZap/useLexZapCases";

export const usePlanChips = () => {
  const { data: credits, isLoading: isLoadingCredits } = useCredits();
  // TODO: fetch only the count of active cases
  const { totalResults } = useLexZapCases({ status: ["ACTIVE"] });
  // TODO: fetch only the count of users
  const { data: users, isLoading: isLoadingUsers } = useCompanyTeam();
  const navigate = useNavigate();

  const plan = credits?.companyPlan?.plan;
  const nextBillingDate = credits?.companyPlan?.nextBillingDate;
  const planName = plan && planTitles[plan][0];
  const isTestingPlan = plan === PlanType.TRIAL || plan === PlanType.FREEMIUM;
  const activeCasesQuota = credits?.companyPlan?.limitQuotaCases || 0;
  const usersQuota = credits?.companyPlan?.limitQuotaUsers || 0;
  const activeCasesCount = totalResults || 0;
  const usersCount = users?.length || 0;
  const companyPlanStatus = credits?.companyPlan?.status;

  function handleOpenUsersScreen() {
    navigate(ROUTE_PATHS.CLIENT_CONFIG_MY_TEAM);
  }

  const isPlanExpired = calculateDaysToEndPlan(nextBillingDate) < 0;
  const isUsersQuotaReached = usersCount >= usersQuota || isPlanExpired;
  const isCaseQuotaReached = activeCasesCount >= activeCasesQuota || isPlanExpired;

  return {
    planName,
    isTestingPlan,
    nextBillingDate,
    activeCasesCount,
    activeCasesQuota,
    usersQuota,
    usersCount,
    plan,
    handleOpenUsersScreen,
    isCaseQuotaReached,
    isUsersQuotaReached,
    companyPlanStatus,
    isLoadingCredits,
    isLoadingUsers,
  };
};
