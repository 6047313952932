import * as React from "react";
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

// Components
import { Chat } from "@//components/Chat";
import { CreateChat } from "@//components/Chat/CreateChat";
import { Feedback } from "@//components/Feedback";
import { Login } from "@//components/Login";
import { CreateCase } from "@/components/Cases";
import { EditorPageLayout } from "@/components/EditorPageLayout";
import { ScreenSizeChecker } from "@/components/ScreenSizeChecker";
import { SessionSettingsToast } from "@/components/SessionSettingsToast";
import { Warnings } from "@/components/Warnings";

// Contexts
import { useAuthContext } from "@/contexts/AuthContext";
import { Permission } from "@/contexts/AuthContext/types";
import { MessagesProvider } from "@/contexts/MessagesContext";
import { PaymentProvider } from "@/contexts/PaymentContext";
import { PaywallProvider } from "@/contexts/PaywallContext";
import { ThreadProvider } from "@/contexts/ThreadContext";
import { WebEditorContextProvider } from "@/contexts/WebEditorContext";
import { WebSocketProvider } from "@/contexts/WebSocketContext";

// Hooks
import { useCheckTermsOfUse } from "@/hooks/useCheckTermsOfUse";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useIdentifyUserForFlags } from "@/hooks/useIdentifyUserForFlags";
import { useLoadingHandler } from "@/hooks/useLoadingHandler";
import { useDocumentOnboarding } from "@/hooks/skills/useDocumentOnboarding";
import { useUserGuiding } from "@/hooks/useUserGuiding";

// Pages
import { ActivateAccount } from "@/pages/ActivateAccount";
import { CallWithSpecialistPage } from "@/pages/CallWithSpecialist";
import { CasePage } from "@/pages/CasePage";
import { DocumentEditorModal } from "@/pages/CasePage/components/CaseDetails/components/DocumentEditorModal";
import { CasesLayout } from "@/pages/CasesLayout";
import { ClientConfig } from "@/pages/ClientConfig";
import { ClientConfigChangePasswordModal } from "@/pages/ClientConfig/ClientConfigChangePasswordModal";
import { ClientConfigMyDataModal, ClientConfigMyDataModalWhatsapp } from "@/pages/ClientConfig/ClientConfigMyDataModal";
import { CreditsHistory } from "@/pages/CreditsHistory";
import { DevToolsPage } from "@/pages/DevToolsPage";
import { ExportContactsGuide } from "@/pages/ExportContactsGuide";
import { GoogleSignUpWithPhone } from "@/pages/GoogleSignUpWithPhone";
import { Home } from "@/pages/Home";
import { InsideSalesQuestionsPage } from "@/pages/InsideSalesQuestions";
import { LegalProceedingMonitoringPage } from "@/pages/LegalProceedingMonitoring";
import { MyTeam } from "@/pages/MyTeam";
import { NotFound } from "@/pages/NotFound";
import { Plan } from "@/pages/Plans/Plan";
import { Plans } from "@/pages/Plans";
import { InternalPaymentPage } from "@/pages/Plans/InternalPaymentPage";
import { Referral } from "@/pages/Referral";
import { SessionSettings } from "@/pages/SessionSettings";
import { Signup } from "@/pages/Signup";
import { SignupFailure } from "@/pages/SignupFailure";
import { SignupSuccess } from "@/pages/SignupSuccess";
import { TermsOfUse } from "@/pages/TermsOfUse";
import { TicketPage } from "@/pages/TicketPage";
import { UsageHistory } from "@/pages/UsageHistory";
import { UsageHistoryV2 } from "@/pages/UsageHistoryV2";
import { Welcome } from "@/pages/Welcome";
import {
  WhatsappAssistantLayout,
  WhatsappAssistantInternalLayout,
  WhatsappAssistantInternalChatLayout,
} from "@/pages/WhatsappAssistantLayout";
import { LegalProceedingImportingByOABModal } from "@/pages/WhatsappAssistantCases/components/LegalProceedingImportingByOABModal";

// Admin Pages
import { ApprovalRequestsPage } from "@/pages/admin/ApprovalRequests";
import { InternalAgentsPage } from "@/pages/admin/InternalAgents";
import { ManageWhatsappUsers } from "@/pages/admin/ManageWhatsappUsers";
import { WhatsappDlqEvents } from "@/pages/admin/WhatsappDlqEvents";
import { ApplicantEventsPage } from "@/pages/admin/ApplicantEvents";

// Routes
import { PermissionedRoute } from "@/routes/components/PermissionedRoute";
import ConditionalLayout from "./components/ConditionalLayout";
import LoggedLayout from "./components/LoggedLayout";
import { AUTHENTICATED_ROUTES_PREFIX, EDITOR_ROUTES_PREFIX, ROUTE_PATHS } from "./routePaths";

// Services
import { ClarityService } from "@/services/clarity";
import { HeapService } from "@/services/heap";
import { logger } from "@/core/logger";

export const IndexRoutes = () => {
  const { loading, user } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  useIdentifyUserForFlags(user);
  useLoadingHandler({ loading });
  const flags = useFeatureFlags();
  const { isOnboarding } = useDocumentOnboarding();

  const previousLocation = location.state?.previousLocation;
  const isLoggedRoute = location.pathname.startsWith(AUTHENTICATED_ROUTES_PREFIX);

  useEffect(() => {
    if (isOnboarding) {
      navigate(ROUTE_PATHS.WELCOME);
    }
  }, [isOnboarding, navigate]);

  if (loading) return null;

  if (!isLoggedRoute && user) {
    return <Navigate to={location.state?.redirectTo ?? ROUTE_PATHS.HOME} />;
  }

  if (isLoggedRoute && !user) {
    return <Navigate to={ROUTE_PATHS.LOGIN} state={{ redirectTo: location.pathname + location.search }} />;
  }

  return (
    <ConditionalLayout>
      <Routes location={previousLocation || location}>
        {/* @TODO remover isso quando tirarmos o /taskpane.html como entrada inicial do addin */}
        <Route path="/taskpane.html" element={<Navigate to={ROUTE_PATHS.LOGIN} />} />

        <Route path={ROUTE_PATHS.LOGIN} element={<Login />} />
        <Route path={ROUTE_PATHS.SIGNUP} element={<Signup />} />
        <Route path={ROUTE_PATHS.ACTIVATE_ACCOUNT} element={<ActivateAccount />} />
        <Route path={ROUTE_PATHS.SIGNUP_SUCCESS} element={<SignupSuccess />} />
        <Route path={ROUTE_PATHS.ADD_PHONE} element={<GoogleSignUpWithPhone />} />
        <Route path={ROUTE_PATHS.SIGNUP_FAILURE} element={<SignupFailure />} />
        <Route path={ROUTE_PATHS.ONBOARDING_QUESTIONS} element={<InsideSalesQuestionsPage />} />

        <Route path={AUTHENTICATED_ROUTES_PREFIX} element={<LoggedWrapper />}>
          <Route
            path={EDITOR_ROUTES_PREFIX}
            element={
              <PermissionedRoute
                possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                element={<EditorPageLayout />}
              />
            }
          >
            <Route
              path={ROUTE_PATHS.CHAT}
              element={
                <PermissionedRoute
                  possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                  element={<CreateChat />}
                />
              }
            />
            <Route
              path={ROUTE_PATHS.THREAD}
              element={
                <PermissionedRoute
                  possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                  element={<Chat />}
                />
              }
            />
            <Route
              path={ROUTE_PATHS.FEEDBACK}
              element={
                <PermissionedRoute
                  possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                  element={<Feedback />}
                />
              }
            />
          </Route>

          <Route path={ROUTE_PATHS.CLIENT_CONFIG} element={<ClientConfig />}>
            <Route path={ROUTE_PATHS.CLIENT_CONFIG_MY_DATA} element={<ClientConfigMyDataModal />} />
            <Route
              path={ROUTE_PATHS.CLIENT_CONFIG_MY_DATA_WHATSAPP}
              element={
                <PermissionedRoute
                  possiblePermissions={[Permission.CUSTOMER_SERVICE_FULL_ACCESS]}
                  element={<ClientConfigMyDataModalWhatsapp />}
                />
              }
            />
            <Route path={ROUTE_PATHS.CLIENT_CONFIG_CHANGE_PASSWORD} element={<ClientConfigChangePasswordModal />} />
          </Route>

          {flags.legalProceedingsPageEnabled && (
            <>
              <Route
                path={ROUTE_PATHS.LEGAL_PROCEEDINGS_MONITORING}
                element={
                  <PermissionedRoute
                    possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                    element={<LegalProceedingMonitoringPage />}
                  />
                }
              />
            </>
          )}
          <Route
            path={ROUTE_PATHS.CLIENT_CONFIG_MY_TEAM}
            element={<PermissionedRoute possiblePermissions={[Permission.MANAGE_USERS]} element={<MyTeam />} />}
          />

          <>
            <Route
              path={ROUTE_PATHS.PLANS}
              element={<PermissionedRoute possiblePermissions={[Permission.MANAGE_PLANS]} element={<Plans />} />}
            />
            <Route
              path={ROUTE_PATHS.PLAN}
              element={<PermissionedRoute possiblePermissions={[Permission.MANAGE_PLANS]} element={<Plan />} />}
            />
            <Route
              path={ROUTE_PATHS.INTERNAL_PAYMENT}
              element={
                <PermissionedRoute possiblePermissions={[Permission.MANAGE_PLANS]} element={<InternalPaymentPage />} />
              }
            />
            <Route
              path={ROUTE_PATHS.CREDITS_HISTORY}
              element={
                <PermissionedRoute possiblePermissions={[Permission.MANAGE_PLANS]} element={<CreditsHistory />} />
              }
            />
          </>

          <Route path={ROUTE_PATHS.TERMS_OF_USE} element={<TermsOfUse />} />
          <Route path={ROUTE_PATHS.REFERRAL} element={<Referral />} />
          <Route path={ROUTE_PATHS.HOME} element={<Home />} />

          {flags.newUsageHistoryV2 ? (
            <Route
              path={ROUTE_PATHS.THREAD_HISTORY}
              element={
                <PermissionedRoute
                  possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                  element={<UsageHistoryV2 />}
                />
              }
            />
          ) : (
            <Route
              path={ROUTE_PATHS.THREAD_HISTORY}
              element={
                <PermissionedRoute
                  possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                  element={<UsageHistory />}
                />
              }
            />
          )}
          <Route path={ROUTE_PATHS.SESSION_SETTINGS} element={<SessionSettings />} />
          <Route path={ROUTE_PATHS.CALL_WITH_SPECIALIST} element={<CallWithSpecialistPage />} />
          <Route path={ROUTE_PATHS.WELCOME} element={<Welcome />} />
          <Route path={ROUTE_PATHS.DEV_TOOLS} element={<DevToolsPage />} />

          {flags.whatsAppAssistantEnabled && (
            <>
              <Route
                path={ROUTE_PATHS.CASES}
                element={
                  <PermissionedRoute
                    possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                    element={<CasesLayout />}
                  />
                }
              />
              <Route
                path={ROUTE_PATHS.ACTIVE_CASES}
                element={
                  <PermissionedRoute
                    possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                    element={<CasesLayout />}
                  />
                }
              />
              <Route
                path={ROUTE_PATHS.ARCHIVED_CASES}
                element={
                  <PermissionedRoute
                    possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                    element={<CasesLayout />}
                  />
                }
              />
              <Route
                path={ROUTE_PATHS.IMPORT_LEGAL_PROCEEDINGS_BY_OAB}
                element={
                  <PermissionedRoute
                    possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                    element={<LegalProceedingImportingByOABModal />}
                  />
                }
              />
              <Route
                path={ROUTE_PATHS.CREATE_CASE}
                element={
                  <PermissionedRoute
                    possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                    element={<CreateCase />}
                  />
                }
              />
              <Route
                path={ROUTE_PATHS.WHATSAPP_ASSISTANT}
                element={
                  <PermissionedRoute
                    possiblePermissions={[Permission.CUSTOMER_SERVICE_FULL_ACCESS, Permission.MANAGE_PLANS]}
                    element={<WhatsappAssistantLayout />}
                  />
                }
              />

              <Route
                path={ROUTE_PATHS.WHATSAPP_ASSISTANT_CHATS}
                element={
                  <PermissionedRoute
                    possiblePermissions={[Permission.CUSTOMER_SERVICE_FULL_ACCESS]}
                    element={<WhatsappAssistantLayout />}
                  />
                }
              />
              <Route
                id="caseDetails"
                path={ROUTE_PATHS.CASE_DETAILS}
                element={
                  <PermissionedRoute
                    possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                    element={<CasePage />}
                  />
                }
              />
              <Route
                id="caseDocumentDetails"
                path={ROUTE_PATHS.CASE_DOCUMENT_DETAILS}
                element={
                  <PermissionedRoute
                    possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                    element={<DocumentEditorModal />}
                  />
                }
              />
              <Route
                id="ticketDetails"
                path={ROUTE_PATHS.TICKET_DETAILS}
                element={
                  <PermissionedRoute
                    possiblePermissions={[Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS]}
                    element={<TicketPage />}
                  />
                }
              />
              <Route
                path={ROUTE_PATHS.WHATSAPP_ASSISTANT_EXPORT_CONTACTS_GUIDE}
                element={
                  <PermissionedRoute
                    possiblePermissions={[Permission.CUSTOMER_SERVICE_FULL_ACCESS]}
                    element={<ExportContactsGuide />}
                  />
                }
              />
            </>
          )}
          {flags.approvalRequestsEnabled && (
            <>
              <Route path={ROUTE_PATHS.WHATSAPP_ASSISTANT_INTERNAL} element={<WhatsappAssistantInternalLayout />} />
              <Route
                path={ROUTE_PATHS.WHATSAPP_ASSISTANT_CHATS_INTERNAL}
                element={<WhatsappAssistantInternalChatLayout />}
              />
            </>
          )}

          {!!user?.isAdmin && (
            <>
              <Route path={ROUTE_PATHS.WHATSAPP_USERS} element={<ManageWhatsappUsers />} />
              <Route path={ROUTE_PATHS.WHATSAPP_DLQ_EVENTS} element={<WhatsappDlqEvents />} />
              <Route path={ROUTE_PATHS.INTERNAL_AGENTS} element={<InternalAgentsPage />} />
              <Route path={ROUTE_PATHS.APPROVAL_REQUESTS} element={<ApprovalRequestsPage />} />
              <Route path={ROUTE_PATHS.APPLICANT_EVENTS} element={<ApplicantEventsPage />} />
            </>
          )}

          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="*" element={<Navigate to={ROUTE_PATHS.LOGIN} />} />
      </Routes>
    </ConditionalLayout>
  );
};

const LoggedWrapper = () => {
  useCheckTermsOfUse();
  const flags = useFeatureFlags();
  const { user } = useAuthContext();
  const userGuiding = useUserGuiding();

  useEffect(() => {
    if (user) {
      HeapService.identify(user.userId);
      HeapService.addUserProperties({
        UserEmail: user.userEmail,
        UserID: user.userId,
        CompanyID: user.companyId,
      });
      ClarityService.addUserId(user.userId);
    }

    userGuiding.initialize(user);

    return () => {
      userGuiding.remove();
    };
  }, []);

  useEffect(() => {
    try {
      HeapService.clearEventProperties();
      HeapService.addEventProperties(flags);
    } catch (e) {
      logger.debug(`HeapService error: ${JSON.stringify(e)}`);
    }
  }, [flags]);

  return (
    <ScreenSizeChecker>
      <WebSocketProvider>
        <PaymentProvider>
          <ThreadProvider>
            <WebEditorContextProvider>
              <PaywallProvider>
                <MessagesProvider>
                  <LoggedLayout>
                    <Warnings />
                    <SessionSettingsToast />
                    <Outlet />
                  </LoggedLayout>
                </MessagesProvider>
              </PaywallProvider>
            </WebEditorContextProvider>
          </ThreadProvider>
        </PaymentProvider>
      </WebSocketProvider>
    </ScreenSizeChecker>
  );
};
