import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useApprovalManagement } from "@/hooks/useApprovalRequests/useApprovalRequests";
import { ApprovalCard } from "./ApprovalCard";
import { ApprovalFilters } from "./ApprovalFilters";
import { ApprovalFilters as ApprovalFiltersType } from "@/services/approvalRequests/types";

export const ApprovalList: React.FC = () => {
  const { requests, loading, fetchRequests, filters, setFilters } = useApprovalManagement();

  useEffect(() => {
    void fetchRequests();
  }, [fetchRequests]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
        <CircularProgress size={24} color="secondary" />
      </Box>
    );
  }

  return (
    <Box>
      <ApprovalFilters
        filters={filters}
        onFilterChange={(newFilters: ApprovalFiltersType) => {
          setFilters(newFilters);
        }}
      />

      {requests.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
          <Typography variant="body2" color="text.secondary">
            Nenhuma solicitação de aprovação encontrada
          </Typography>
        </Box>
      ) : (
        <Box>
          <Box
            display="grid"
            gridTemplateColumns="100px 120px 180px 1fr 160px 180px 48px"
            gap={3}
            px={2}
            mb={2}
            sx={{
              "& .MuiTypography-root": {
                fontWeight: 600,
              },
            }}
          >
            <Typography variant="preTitle" color="text.secondary">
              ID
            </Typography>
            <Typography variant="preTitle" color="text.secondary">
              Status
            </Typography>
            <Typography variant="preTitle" color="text.secondary">
              Telefone
            </Typography>
            <Typography variant="preTitle" color="text.secondary">
              Tipo de Solicitação
            </Typography>
            <Typography variant="preTitle" color="text.secondary">
              Data
            </Typography>
            <Typography variant="preTitle" color="text.secondary">
              Revisão
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            {requests.map((request) => (
              <ApprovalCard key={request.id} request={request} onUpdate={fetchRequests} />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
