import { useState, useCallback, useEffect } from "react";
import { ApplicantConfigService } from "@/services/applicant_config";
import { WebToastColoredWithTitle } from "@/components/core/Toast";

export type StatusType = "done" | "pending" | "in_progress" | "blocked";

interface ChecklistItem {
  label: string;
  status: StatusType;
  subItems?: ChecklistItem[];
  description?: string;
  assignedTo?: string;
}

interface ChecklistPhase {
  label: string;
  items: ChecklistItem[];
}

interface ChecklistData {
  title: string;
  progress: number;
  nextStep: string;
  phases: ChecklistPhase[];
}

interface ApplicantConfig {
  dynamic_status?: string;
}

export const useChecklist = (phoneNumber: string) => {
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState<ApplicantConfig | null>(null);
  const [checklistData, setChecklistData] = useState<ChecklistData>({
    title: "Checklist do Caso",
    progress: 0,
    nextStep: "",
    phases: [],
  });

  const fetchChecklist = useCallback(async () => {
    if (!phoneNumber) return;

    setLoading(true);
    try {
      const config = await ApplicantConfigService.getByPhoneNumber(phoneNumber);
      setConfig(config);
      if (config.dynamic_status) {
        setChecklistData({
          title: "Checklist do Caso",
          progress: 0,
          nextStep: "",
          phases: [],
        });
      }
    } catch (error) {
      if ((error as Error).message !== "Applicant config not found") {
        WebToastColoredWithTitle.error(
          "Erro ao carregar checklist",
          "Não foi possível carregar as configurações do solicitante"
        );
      }
    } finally {
      setLoading(false);
    }
  }, [phoneNumber]);

  useEffect(() => {
    fetchChecklist();
  }, [fetchChecklist]);

  return {
    checklistData,
    loading,
    fetchChecklist,
    markdown: config?.dynamic_status || "",
  };
};
