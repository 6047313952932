import React, { useState } from "react";
import { Box, Typography, Paper, IconButton, Modal, Tooltip } from "@mui/material";
import { ApprovalRequest } from "@/services/approvalRequests/types";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { Link } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { JsonViewer } from "../shared/JsonViewer";
import { StatusChip } from "../shared/StatusChip";

interface ContextSectionProps {
  request: ApprovalRequest;
}

const InfoField: React.FC<{
  label: string;
  children: React.ReactNode;
}> = ({ label, children }) => (
  <Box>
    <Typography variant="body2" sx={{ color: "text.secondary", fontWeight: 500 }}>
      {label}
    </Typography>
    {children}
  </Box>
);

const JsonPreview: React.FC<{
  data: Record<string, unknown>;
  onCopy: () => void;
  onView: () => void;
}> = ({ data, onCopy, onView }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    <Tooltip
      title={<pre style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(data, null, 2)}</pre>}
      placement="top"
      PopperProps={{
        sx: {
          maxWidth: "none",
          "& .MuiTooltip-tooltip": {
            maxWidth: "800px",
            width: "fit-content",
          },
        },
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: "primary.main",
          cursor: "pointer",
          "&:hover": { textDecoration: "underline" },
        }}
        onClick={onView}
      >
        Visualizar
      </Typography>
    </Tooltip>
    <Tooltip title="Copiar JSON">
      <IconButton size="small" onClick={onCopy}>
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  </Box>
);

const formatDateTime = (date: Date) => {
  return date.toLocaleString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const ContextSection: React.FC<ContextSectionProps> = ({ request }) => {
  const [showOutputModal, setShowOutputModal] = useState(false);
  const whatsappLink = `${ROUTE_PATHS.WHATSAPP_ASSISTANT_CHATS}?phoneNumber=${request.applicant_phone_number}`;

  const handleCopyJson = () => {
    void navigator.clipboard.writeText(JSON.stringify(request.output, null, 2));
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom fontWeight={600}>
        Contexto
      </Typography>

      <Paper variant="outlined" sx={{ p: 2, bgcolor: "grey.50" }}>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
          <InfoField label="ID da Solicitação">
            <Typography variant="body1" sx={{ color: "text.primary", fontWeight: 600 }}>
              {request.id}
            </Typography>
          </InfoField>

          <InfoField label="Empresa">
            <Typography variant="body1" sx={{ color: "text.primary", fontWeight: 600 }}>
              {request.company_id}
            </Typography>
          </InfoField>

          <InfoField label="Telefone">
            <Link to={whatsappLink} style={{ textDecoration: "none" }}>
              <Typography
                variant="body1"
                sx={{ color: "primary.main", fontWeight: 600, "&:hover": { textDecoration: "underline" } }}
              >
                {request.applicant_phone_number}
              </Typography>
            </Link>
          </InfoField>

          <InfoField label="Subagente">
            <Typography variant="body1" sx={{ color: "text.primary", fontWeight: 600 }}>
              {request.subagent_name}
            </Typography>
          </InfoField>

          <InfoField label="Dados da Ação">
            {request.output && (
              <JsonPreview
                data={request.output as unknown as Record<string, unknown>}
                onCopy={handleCopyJson}
                onView={() => setShowOutputModal(true)}
              />
            )}
          </InfoField>

          <InfoField label="Data da Solicitação">
            <Typography variant="body1" sx={{ color: "text.primary" }}>
              {formatDateTime(request.created_at)}
            </Typography>
          </InfoField>

          {request.status === "PENDING" && (
            <InfoField label="Status">
              <Box mt={0.5}>
                <StatusChip status={request.status} />
              </Box>
            </InfoField>
          )}
        </Box>
      </Paper>

      <Modal
        open={showOutputModal}
        onClose={() => setShowOutputModal(false)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Paper sx={{ p: 3, maxWidth: "80vw", maxHeight: "80vh", overflow: "auto" }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">Dados da Ação</Typography>
            <IconButton onClick={handleCopyJson} title="Copiar JSON">
              <ContentCopyIcon />
            </IconButton>
          </Box>
          {request.output && <JsonViewer data={request.output as unknown as Record<string, unknown>} />}
        </Paper>
      </Modal>
    </Box>
  );
};
