import { Badge, Box, IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { Search } from "./components/Search";
import { ChatItem } from "./components/ChatItem";
import { EmptyList } from "./components/EmptyList";
import { ChatItemSkeleton } from "./components/ChatItem/skeleton";
import { ChatStatus, LexZapChat } from "@/hooks/lexZap/types";
import { FilterList as FilterIcon } from "@mui/icons-material";
import { useLexZapActiveChat } from "@/hooks/lexZap/useLexZapActiveChat";
import { scrollbarStyles } from "@/theme/mixins/scrollbar";
import { useSearchParams } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import { ApprovalRequest } from "@/services/approvalRequests/types";

interface ChatListProps {
  chats?: LexZapChat[];
  isLoading: boolean;
  requests: ApprovalRequest[];
  requestsLoading: boolean;
  onToggleFilters: () => void;
  tab: ChatStatus | string;
  onChangeTab: (tab: ChatStatus | string) => void;
  searchTerm: string;
  onChangeSearchTerm: (searchTerm: string) => void;
  filterChanges: number;
}

export const ChatList = ({
  chats = [],
  isLoading,
  requests,
  requestsLoading,
  onToggleFilters,
  tab,
  onChangeTab,
  onChangeSearchTerm,
  filterChanges,
}: ChatListProps) => {
  const { activeChat, setActiveChat } = useLexZapActiveChat();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (isLoading) return;

    if (!activeChat) {
      let newActiveChat: LexZapChat | undefined;

      const applicantPhoneNumber = searchParams.get("phoneNumber");
      if (applicantPhoneNumber) {
        newActiveChat = chats?.find((chat) => chat.endClientNumber === applicantPhoneNumber);
      }

      if (!newActiveChat && chats?.length) {
        newActiveChat = chats[0];
      }

      if (newActiveChat) {
        setActiveChat(newActiveChat);
      }
    }
  }, [isLoading, activeChat, chats, searchParams, setActiveChat]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "common.white",
        display: "flex",
        flexDirection: "column",
        borderLeft: "0px",
        borderRight: "1px solid",
        borderColor: "common.lightShade",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          padding: 1.5,
        }}
      >
        <Badge badgeContent={filterChanges} color="primary">
          <IconButton
            onClick={onToggleFilters}
            size="small"
            sx={{
              m: -0.5,
            }}
          >
            <FilterIcon
              sx={{
                color: "common.coral",
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
            />
          </IconButton>
        </Badge>

        <Search onChange={onChangeSearchTerm} label="Pesquisar" placeholder="Buscar conversa" />
      </Box>

      <Tabs
        value={tab}
        onChange={(_, newValue) => onChangeTab(newValue)}
        sx={{
          width: "100%",
          px: 1,
          minHeight: "36px",
          "& .MuiTab-root": {
            textTransform: "none",
            flex: 1,
            py: 0.5,
            minHeight: "36px",
            fontWeight: 600,
          },
        }}
      >
        <Tab label="Conversas" value={ChatStatus.ACTIVE} />
        <Tab label="Arquivados" value={ChatStatus.ARCHIVED} />
        <Tab
          label={
            <Box sx={{ position: "relative" }}>
              {requests.length > 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    top: -4,
                    right: -24,
                    backgroundColor: "warning.main",
                    color: "common.white",
                    borderRadius: "50%",
                    width: 20,
                    height: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "0.75rem",
                    fontWeight: "bold",
                    zIndex: 1,
                  }}
                >
                  {new Set(requests.map((req) => req.applicant_phone_number)).size}
                </Box>
              )}
              <Box sx={{ display: "flex", alignItems: "center" }}>Aprovações</Box>
            </Box>
          }
          value="approvals"
        />
      </Tabs>

      {isLoading || requestsLoading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingX: 1,
            paddingY: 0.5,
            gap: 0.5,
            ...scrollbarStyles,
          }}
        >
          {Array.from({ length: 10 }, (_, index) => (
            <ChatItemSkeleton key={index} isActive={index === 0} />
          ))}
        </Box>
      ) : (
        <>
          {!chats?.length ? (
            <EmptyList />
          ) : (
            <Box
              sx={{
                flex: 1,
                "[data-testid='virtuoso-scroller']": {
                  ...scrollbarStyles,
                },
              }}
            >
              <Virtuoso
                style={{ height: "100%", width: "100%" }}
                totalCount={chats.length}
                itemContent={(index) => {
                  const item = chats[index];

                  if (!item) {
                    return null;
                  }

                  return (
                    <Box
                      sx={{
                        width: "100%",
                        paddingX: 1,
                        pt: 1,
                        pb: index === chats.length - 1 ? 1 : 0,
                      }}
                    >
                      <ChatItem
                        onClick={() => setActiveChat(item)}
                        chat={item}
                        isActive={item.endClientNumber === activeChat?.endClientNumber}
                        hasApprovalRequest={requests.some(
                          (request) => request.applicant_phone_number === item.endClientNumber
                        )}
                      />
                    </Box>
                  );
                }}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
