import React from "react";
import { Box, Button, Chip, Skeleton, Typography } from "@mui/material";
import { ApplicantModal } from "@/components/Applicants";
import { LexZapChat } from "@/hooks/lexZap/types";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { formatPhoneNumber } from "@/utils/lexZap/formatPhoneNumber";

interface ChatHeaderProps {
  chat?: LexZapChat | null;
  isLoading?: boolean;
  onToggleHistory: () => void;
  onToggleApprovalRequest: () => void;
  onToggleChecklist: () => void;
  onToggleChatDetails: () => void;
  showApprovalRequestOption?: boolean;
}

export const ChatHeader = ({
  chat,
  isLoading,
  onToggleHistory,
  onToggleApprovalRequest,
  onToggleChecklist,
  onToggleChatDetails,
  showApprovalRequestOption = false,
}: ChatHeaderProps) => {
  const [isApplicantModalOpen, setIsApplicantModalOpen] = React.useState(false);
  const { applicantActivationEnabled } = useFeatureFlags();

  if (isLoading || !chat) {
    return <ChatHeaderSkeleton />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "grey.50",
        gap: 2,
        paddingX: 3,
        paddingRight: 6,
        paddingY: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            variant="body"
            color="#09132C"
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              "&:hover": !!chat.applicant
                ? {
                    cursor: "pointer",
                    textDecoration: "underline",
                  }
                : undefined,
            }}
            onClick={!!chat.applicant ? () => setIsApplicantModalOpen(true) : undefined}
          >
            {chat.applicantName || formatPhoneNumber(chat.endClientNumber)}
          </Typography>

          {applicantActivationEnabled && !!chat.applicant && (
            <>
              {!chat.applicant.active ? (
                <Chip label="Novo contato" size="small" />
              ) : (
                <Chip label="Cliente ativo" size="small" color="secondary" />
              )}
            </>
          )}
        </Box>

        <Typography
          variant="body"
          color="grey.700"
          sx={{
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {formatPhoneNumber(chat.endClientNumber)}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        <Button variant="text" onClick={onToggleHistory} sx={{ fontSize: "16px", textDecoration: "underline" }}>
          Histórico
        </Button>

        <Button variant="text" onClick={onToggleChatDetails} sx={{ fontSize: "16px", textDecoration: "underline" }}>
          Detalhes do chat
        </Button>

        <Button variant="text" onClick={onToggleChecklist} sx={{ fontSize: "16px", textDecoration: "underline" }}>
          Checklist
        </Button>

        {showApprovalRequestOption && onToggleApprovalRequest && (
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                width: 8,
                height: 8,
                borderRadius: "50%",
                backgroundColor: "error.main",
              }}
            />
            <Button
              variant="text"
              onClick={onToggleApprovalRequest}
              sx={{ fontSize: "16px", textDecoration: "underline" }}
            >
              Aprovar
            </Button>
          </Box>
        )}
      </Box>

      <ApplicantModal
        isOpen={isApplicantModalOpen}
        onClose={() => setIsApplicantModalOpen(false)}
        applicantId={chat.applicant?.id}
      />
    </Box>
  );
};

const ChatHeaderSkeleton = () => {
  return (
    <Box
      sx={{
        backgroundColor: "grey.50",
        gap: 2,
        paddingX: 3,
        paddingRight: 6,
        paddingY: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Skeleton variant="text" width={180} />

        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            alignItems: "center",
          }}
        >
          <Skeleton variant="text" width={200} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Skeleton variant="rectangular" width={80} height={32} />
        <Skeleton variant="rectangular" width={80} height={32} />
        <Skeleton variant="circular" width={36} height={36} />
      </Box>
    </Box>
  );
};
