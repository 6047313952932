import React from "react";
import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";
import { ArrowOutwardOutlined, LockOutlined } from "@mui/icons-material";

import { ROUTE_PATHS } from "@/routes/routePaths";

import { calculateDaysToEndPlan } from "@/utils/plans";

import { PlanType } from "@/hooks/credits/types";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { usePermissions } from "@/hooks/usePermissions";
import { useLexZapCases } from "@/hooks/lexZap/useLexZapCases";
import { useCredits } from "@/hooks/credits/useCredits";

import { ChipInRow, Chip, ChipContent } from ".";

export const ActiveCasesChip = ({ onClick }: { onClick?: () => void }) => {
  const { shouldValidateCasesQuota } = useFeatureFlags();
  const { hasManageLegalProceedingsPermission } = usePermissions();
  const { data: credits, isLoading: isLoadingCredits } = useCredits();
  // TODO: fetch only the count of active cases
  const { isLoading: isLoadingCases, totalResults } = useLexZapCases({ status: ["ACTIVE"] });
  const navigate = useNavigate();

  const plan = credits?.companyPlan?.plan;
  const nextBillingDate = credits?.companyPlan?.nextBillingDate;
  const isTestingPlan = plan === PlanType.TRIAL || plan === PlanType.FREEMIUM;
  const activeCasesQuota = credits?.companyPlan?.limitQuotaCases || 0;
  const activeCasesCount = totalResults || 0;
  const isPlanInactive = calculateDaysToEndPlan(nextBillingDate) < 0 && !isTestingPlan;

  function handleClick() {
    if (onClick) {
      return onClick;
    }

    if (hasManageLegalProceedingsPermission) {
      return () => navigate(ROUTE_PATHS.ACTIVE_CASES);
    }

    return undefined;
  }

  const color = activeCasesCount >= activeCasesQuota || isPlanInactive ? "error.main" : "inherit";

  return (
    <Chip
      title="Casos ativos"
      onClick={handleClick()}
      color={color}
      fullWidth
      isLoading={isLoadingCases || isLoadingCredits}
    >
      <ChipContent>
        <ChipInRow>
          <Typography
            variant="h5"
            sx={{
              color: color,
            }}
          >
            {activeCasesCount}
            {shouldValidateCasesQuota && ` de ${activeCasesQuota}`}
          </Typography>
          {hasManageLegalProceedingsPermission ? (
            <ArrowOutwardOutlined
              sx={{
                color: color,
              }}
            />
          ) : (
            <LockOutlined sx={{ color: color }} />
          )}
        </ChipInRow>
      </ChipContent>
    </Chip>
  );
};
