import { logger } from "@/core/logger";
import { PDFDocument } from "pdf-lib";

export async function getPdfPageCount(file: File): Promise<number | null> {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    return pdfDoc.getPageCount();
  } catch (e) {
    logger.error("Error getting pdf page count", { error: e });
    throw e;
  }
}
