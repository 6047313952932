import { Box, Skeleton, TablePagination, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";

import { Card } from "../Card";
import { useCaseFiles } from "@/hooks/lexZap/useCaseFiles";
import { Case } from "@/hooks/lexZap/types";
import { CaseFilesSearch } from "./components/CaseFilesSearch";
import { CaseFile } from "./components/CaseFile";

type CaseFilesProps = {
  caseData?: Case;
};

export const CaseFiles = ({ caseData }: CaseFilesProps) => {
  const [pagination, setPagination] = useState({
    pageSize: 5,
    page: 0,
  });
  const [query, setQuery] = React.useState<string>("");

  const { data: caseFiles, isLoading: isLoadingNewCaseFiles } = useCaseFiles({
    caseId: caseData?.id,
    threadIds: caseData?.threadIds,
    search: query,
  });

  const files = useMemo(() => {
    const startIndex = pagination.page * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    return caseFiles.slice(startIndex, endIndex);
  }, [caseFiles, pagination]);

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeSearch = (value: string) => {
    setPagination((prev) => ({ ...prev, page: 0 }));
    setQuery(value);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1" fontWeight={700} color="text.primary">
          Documentos
        </Typography>
        <CaseFilesSearch onChange={handleChangeSearch} />
      </Box>
      <CaseFilesSkeletonLoading loading={isLoadingNewCaseFiles}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            py: 2,
          }}
        >
          {files?.length ? (
            files.map((item, index) => (
              <CaseFile data={item} key={index} caseId={caseData?.id} threadId={item?.threadId || undefined} />
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                bgcolor: "grey.50",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderColor: "divider",
                mx: -3,
                py: 6,
              }}
            >
              <Typography variant="subtitle1">Nenhum documento vinculado</Typography>
            </Box>
          )}
        </Box>
        {caseFiles.length > 0 && (
          <TablePagination
            onPageChange={handleChangePage}
            component="div"
            count={caseFiles.length}
            page={pagination.page}
            rowsPerPage={pagination.pageSize}
            rowsPerPageOptions={[]}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          />
        )}
      </CaseFilesSkeletonLoading>
    </Card>
  );
};

type CaseFilesSkeletonLoadingProps = {
  loading: boolean;
  children: React.ReactNode;
};

const CaseFilesSkeletonLoading = ({ loading, children }: CaseFilesSkeletonLoadingProps) => {
  if (loading) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, my: 2 }}>
        <Skeleton animation="wave" variant="rectangular" sx={{ borderRadius: 2, height: "20px" }} />
        <Skeleton animation="wave" variant="rectangular" sx={{ borderRadius: 2, height: "20px" }} />
        <Skeleton animation="wave" variant="rectangular" sx={{ borderRadius: 2, height: "20px" }} />
        <Skeleton animation="wave" variant="rectangular" sx={{ borderRadius: 2, height: "20px" }} />
      </Box>
    );
  }
  return <>{children}</>;
};
