import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Case, CaseStep } from "../types";
import { getCaseQueryKey } from "../useLexZapTicket";

export const useEditCase = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["updateCase"],
    mutationFn: async ({
      caseId,
      data,
    }: {
      caseId: string;
      data: {
        name: string;
        applicantId?: string;
        step: CaseStep;
        legalArea?: string;
      };
    }) => await api.updateCase({ caseId, data }),
    onMutate: async ({ caseId, data }) => {
      const previousCase = queryClient.getQueryData<Case>(getCaseQueryKey(caseId));
      queryClient.setQueryData<Case>(getCaseQueryKey(caseId), (old) => {
        if (!old) return old;
        return { ...old, ...data };
      });

      return { previousCase };
    },
    onError: (_error, { caseId }, context) => {
      queryClient.setQueryData<Case>(getCaseQueryKey(caseId), context?.previousCase);
    },
  });
};
