import { useQuery } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { Case, CaseStatus } from "../types";
import { useImpersonation } from "@/hooks/useImpersonation";
import { AdminLexZapService } from "@/services/lexZap";

export const getCasesQueryKey = (status?: string) => ["lexZapCases", status];

interface UseLexZapCasesParams {
  pageSize?: number;
  status?: string[];
  page?: number;
  filter?: string;
  phoneNumber?: string;
}

export const useLexZapCases = ({ pageSize, status, page = 1, filter, phoneNumber }: UseLexZapCasesParams) => {
  const { getLexZapCases } = useApi();
  const { impersonatedUser } = useImpersonation();
  const paginationPayload = { page, pageSize: pageSize ?? 25 };

  type QueryResponse = {
    data: Case[];
    totalResults: number;
    pageSize: number;
    currentPage: number;
  };

  const { data, isLoading, isError, isFetching, refetch } = useQuery<QueryResponse>({
    queryKey: [...getCasesQueryKey(status?.join(",")), page, pageSize, filter, phoneNumber],
    queryFn: async () => {
      if (impersonatedUser) {
        const cases = await AdminLexZapService.getLexZapCases({ companyId: impersonatedUser.companyId });
        return {
          data: cases,
          totalResults: cases.length,
          pageSize: cases.length,
          currentPage: 1,
        };
      }
      const response = await getLexZapCases({
        pagination: paginationPayload,
        status: status as CaseStatus[],
        filter,
        applicantPhoneNumber: phoneNumber,
      });
      return {
        data: response.data,
        totalResults: response.totalResults,
        pageSize: response.pageSize,
        currentPage: response.page,
      };
    },
    placeholderData: (previousData) => previousData,
  });

  return {
    cases: data?.data ?? [],
    isLoading: isLoading || isFetching,
    isError,
    totalResults: data?.totalResults ?? 0,
    pageSize: data?.pageSize ?? pageSize ?? 25,
    currentPage: data?.currentPage ?? page,
    refetch,
  };
};
