import React from "react";
import { Box, Typography } from "@mui/material";
import { BaseStatusViewProps } from "./types";
import { ReviewInfo } from "../../shared/ReviewInfo";
import { JsonViewer } from "../../shared/JsonViewer";

export const EditedView: React.FC<BaseStatusViewProps> = ({ request }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 2,
      }}
    >
      <ReviewInfo request={request}>
        <Box mt={2}>
          <Typography variant="h5" gutterBottom fontWeight={600}>
            Versão Original
          </Typography>
          <JsonViewer data={request.output} />

          <Typography variant="h5" gutterBottom fontWeight={600} sx={{ mt: 3 }}>
            Versão Editada
          </Typography>
          <JsonViewer data={request.output_edited} />
        </Box>
      </ReviewInfo>
    </Box>
  );
};
