import { authenticatedLexterCopilotApi } from "@/core/api";
import { TagDefinition, TicketTag } from "./types";

export const InternalAgentsService = {
  // System endpoints
  getHello: async (): Promise<string> => {
    const response = await authenticatedLexterCopilotApi.get<string>("/hello");
    return response.data;
  },

  tags: {
    get: async (tagId: string): Promise<TagDefinition> => {
      const response = await authenticatedLexterCopilotApi.get<TagDefinition>(`/qa/tags/${tagId}`);
      return response.data;
    },

    getAll: async (): Promise<TagDefinition[]> => {
      const response = await authenticatedLexterCopilotApi.get<TagDefinition[]>(`/qa/tags`);
      return response.data;
    },

    create: async (tag: TagDefinition): Promise<TagDefinition> => {
      const response = await authenticatedLexterCopilotApi.post<TagDefinition>("/qa/tags", {
        ...tag,
        company_id: "lexter",
      });
      return response.data;
    },

    update: async (tagId: string, updateFields: Partial<TagDefinition>): Promise<TagDefinition> => {
      const response = await authenticatedLexterCopilotApi.put<TagDefinition>(`/qa/tags/${tagId}`, {
        update_fields: updateFields,
      });
      return response.data;
    },

    delete: async (tagName: string): Promise<void> => {
      const response = await authenticatedLexterCopilotApi.delete(`/qa/tags/${tagName}`);
      return response.data;
    },
  },

  ticketTags: {
    getTicketTags: async (ticketId: string): Promise<TicketTag[]> => {
      const response = await authenticatedLexterCopilotApi.get<TicketTag[]>(`/qa/tickets/${ticketId}/tags`);
      return response.data;
    },

    getTicketsByTag: async (tagId: string): Promise<TicketTag[]> => {
      const response = await authenticatedLexterCopilotApi.get<TicketTag[]>(`/qa/tickets/tags/${tagId}`);
      return response.data;
    },

    addTagToTicket: async (ticketId: string, tag: TicketTag): Promise<void> => {
      const response = await authenticatedLexterCopilotApi.post(`/qa/tickets/${ticketId}/tags`, tag);
      return response.data;
    },

    removeTagFromTicket: async (ticketId: string, tagId: string): Promise<void> => {
      const response = await authenticatedLexterCopilotApi.delete(`/qa/tickets/${ticketId}/tags/${tagId}`);
      return response.data;
    },
  },
};
