import React from "react";

import { Box, Typography, Collapse, Checkbox } from "@mui/material";
import { DatePicker } from "@/components/DatePicker";
import { AuthContextData } from "@/contexts/AuthContext/types";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { ChatQuickFilters, ChatQuickFiltersCount, AssignedFilter } from "./types";

interface QuickFiltersProps {
  onChange: (filters: ChatQuickFilters) => void;
  filters: ChatQuickFilters;
  defaultFilters: ChatQuickFilters;
  filtersCount: ChatQuickFiltersCount;
  companyTeam: { id: string; name: string }[];
  user: AuthContextData["user"];
}

export function QuickFilters({
  filters,
  onChange,
  defaultFilters,
  filtersCount,
  companyTeam,
  user,
}: QuickFiltersProps) {
  const handleTicketStatusChange = (status: keyof ChatQuickFilters["ticketStatus"]) => {
    onChange({ ...filters, ticketStatus: { ...filters.ticketStatus, [status]: !filters.ticketStatus[status] } });
  };

  const handleResponsibleChange = (member: { id: string; name: string }) => {
    const newResponsibles = filters.responsibles.includes(member.id)
      ? filters.responsibles.filter((id) => id !== member.id)
      : [...filters.responsibles, member.id];

    let newAssigned = AssignedFilter.EMPTY;

    if (newResponsibles.length === 0) {
      newAssigned = AssignedFilter.ALL;
    } else if (newResponsibles.length === 1 && newResponsibles[0] === user?.userId) {
      newAssigned = AssignedFilter.ASSIGNED_TO_ME;
    } else {
      newAssigned = AssignedFilter.EMPTY;
    }

    onChange({
      ...filters,
      responsibles: newResponsibles,
      assigned: newAssigned,
    });
  };

  const handleAssignedChange = (value: AssignedFilter) => {
    if (value === AssignedFilter.ASSIGNED_TO_ME) {
      onChange({ ...filters, assigned: value, responsibles: [user?.userId ?? ""] });
    } else {
      onChange({ ...filters, assigned: value, responsibles: [] });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        flexDirection: "column",
        width: "100%",
        pt: 2,
        pb: 2,
        backgroundColor: "common.white",
        border: "0",
      }}
    >
      <Box sx={{ px: 2, mb: 2, borderBottom: "1px solid", borderColor: "divider" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pb: 2 }}>
          <Typography variant="subtitle2" fontWeight="800">
            FILTROS
          </Typography>
          <Typography
            variant="body1"
            fontWeight="400"
            sx={{
              textDecoration: "underline",
              color: "text.secondary",
              cursor: "pointer",
            }}
            onClick={() => onChange(defaultFilters)}
          >
            Limpar Filtros
          </Typography>
        </Box>
      </Box>

      <Box sx={{ px: 2, mb: 2, borderBottom: "1px solid", borderColor: "divider" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}>
          <AssignmentFilterItem
            label="Todos os atendimentos"
            isSelected={filters.assigned === AssignedFilter.ALL}
            onClick={() => handleAssignedChange(AssignedFilter.ALL)}
          />

          <AssignmentFilterItem
            label="Seus atendimentos"
            isSelected={filters.assigned === AssignedFilter.ASSIGNED_TO_ME}
            onClick={() => handleAssignedChange(AssignedFilter.ASSIGNED_TO_ME)}
            count={filtersCount.responsibles[user?.userId ?? ""] ?? 0}
          />

          <AssignmentFilterItem
            label="Não atribuidos"
            isSelected={filters.assigned === AssignedFilter.NOT_ASSIGNED}
            onClick={() => handleAssignedChange(AssignedFilter.NOT_ASSIGNED)}
          />
        </Box>
      </Box>

      <FilterSection
        title="Etapa Atendimento"
        items={[
          {
            label: "Atendimento IA",
            count: filtersCount.ticketStatus.aiService,
            isSelected: filters.ticketStatus.aiService,
            onChange: () => handleTicketStatusChange("aiService"),
          },
          {
            label: "Triagem Inicial",
            count: filtersCount.ticketStatus.initialTriage,
            isSelected: filters.ticketStatus.initialTriage,
            onChange: () => handleTicketStatusChange("initialTriage"),
          },
          {
            label: "Direcionado Humano",
            count: filtersCount.ticketStatus.redirectedHuman,
            isSelected: filters.ticketStatus.redirectedHuman,
            onChange: () => handleTicketStatusChange("redirectedHuman"),
          },
          {
            label: "Abandonado",
            count: filtersCount.ticketStatus.abandoned,
            isSelected: filters.ticketStatus.abandoned,
            onChange: () => handleTicketStatusChange("abandoned"),
          },
          {
            label: "Atendimento Fechado",
            count: filtersCount.ticketStatus.closed,
            isSelected: filters.ticketStatus.closed,
            onChange: () => handleTicketStatusChange("closed"),
          },
        ]}
      />

      <FilterSection
        title="Status das Respostas"
        items={[
          {
            label: "Respondidas",
            count: filtersCount.answered.answered,
            isSelected: filters.answered.answered,
            onChange: () =>
              onChange({ ...filters, answered: { ...filters.answered, answered: !filters.answered.answered } }),
          },
          {
            label: "Não Respondidas",
            count: filtersCount.answered.unanswered,
            isSelected: filters.answered.unanswered,
            onChange: () =>
              onChange({ ...filters, answered: { ...filters.answered, unanswered: !filters.answered.unanswered } }),
          },
        ]}
      />

      <FilterSection
        title="Responsável"
        items={companyTeam.map((member) => ({
          label: member.name,
          count: filtersCount.responsibles[member.id] ?? "-",
          isSelected: filters.responsibles.includes(member.id),
          onChange: () => handleResponsibleChange(member),
        }))}
      />

      <DateFilterSection filters={filters} onChange={onChange} />
    </Box>
  );
}

interface FilterSectionProps {
  title: string;
  items: Array<{ label: string; count: number | "-"; isSelected: boolean; onChange: () => void }>;
}

function FilterSection({ title, items }: FilterSectionProps) {
  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <Box sx={{ px: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
          mb: 1.5,
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography variant="body1" sx={{ color: "text.secondary", fontWeight: 700, display: "flex" }}>
          {title}
        </Typography>

        <ArrowDropDownIcon
          sx={{
            transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
            width: 32,
            height: 32,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </Box>

      <Collapse in={isOpen}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {items.map((item) => (
            <FilterItem key={item.label} item={item} />
          ))}
        </Box>
      </Collapse>
    </Box>
  );
}

interface FilterItemProps {
  item: {
    label: string;
    count: number | string;
    isSelected: boolean;
    onChange: () => void;
  };
}

function FilterItem({ item }: FilterItemProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        mb: 1,
        cursor: "pointer",
      }}
      onClick={(e) => {
        if (e.target instanceof HTMLInputElement && e.target.type === "checkbox") return;
        item.onChange();
      }}
    >
      <Checkbox size="small" sx={{ padding: 0, pl: 1 }} onChange={item.onChange} checked={item.isSelected} />
      <Box
        sx={{
          width: "200px",
          cursor: "pointer",
        }}
      >
        <Typography variant="body1" sx={{ color: "text.secondary", fontWeight: 500 }}>
          {item.label}
        </Typography>
      </Box>
      <Box
        sx={{
          width: 32,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontWeight: "700", fontSize: "14px" }}>{item.count}</Typography>
      </Box>
    </Box>
  );
}

interface DateFilterSectionProps {
  filters: ChatQuickFilters;
  onChange: (filters: ChatQuickFilters) => void;
}

function DateFilterSection({ filters, onChange }: DateFilterSectionProps) {
  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <Box sx={{ px: 2, mb: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
          mb: 1.5,
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography variant="body1" sx={{ color: "text.secondary", fontWeight: 700, display: "flex" }}>
          Data de criação da conversa
        </Typography>

        <ArrowDropDownIcon
          sx={{
            transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
            width: 32,
            height: 32,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </Box>

      <Collapse in={isOpen}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <DatePicker
            selected={filters.chatCreationDateStart ?? null}
            onChange={(date: number | null) => {
              onChange({
                ...filters,
                chatCreationDateStart: date || undefined,
              });
            }}
            placeholder="Conversas iniciadas a partir de..."
          />

          <DatePicker
            selected={filters.chatCreationDateEnd ?? null}
            onChange={(date: number | null) => {
              onChange({
                ...filters,
                chatCreationDateEnd: date || undefined,
              });
            }}
            placeholder="Conversas iniciadas até..."
          />
        </Box>
      </Collapse>
    </Box>
  );
}

interface AssignmentFilterItemProps {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  count?: number;
}

function AssignmentFilterItem({ label, isSelected, onClick, count }: AssignmentFilterItemProps) {
  return (
    <Box
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
      onClick={onClick}
    >
      <Typography
        variant="body"
        sx={{
          color: "text.secondary",
          fontWeight: isSelected ? 700 : 100,
        }}
      >
        {label}
      </Typography>
      {count !== undefined && (
        <Box
          sx={{
            backgroundColor: "common.coral",
            borderRadius: "50%",
            width: 24,
            height: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2" sx={{ color: "common.white", fontWeight: 700 }}>
            {count}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
