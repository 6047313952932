import { CaseFileItem } from "@/core/CaseFile";
import { CaseFileFilters } from "../../components/filterCaseFilesModal/types";

export const applyCaseFileFilters = ({
  caseFiles,
  searchTerm,
  filters,
}: {
  caseFiles: CaseFileItem[];
  searchTerm: string;
  filters: CaseFileFilters;
}) => {
  let filtered = caseFiles;

  if (searchTerm) {
    filtered = filtered.filter((caseItem) => caseItem.name?.toLowerCase().includes(searchTerm));
  }

  const atLeastOneOrigin =
    filters.origin.platform || filters.origin.whatsapp || filters.origin.record || filters.origin.legalProceeding;

  if (atLeastOneOrigin && !filters.origin.platform) {
    filtered = filtered.filter((item) => !item.isPlatformFile());
  }

  if (atLeastOneOrigin && !filters.origin.whatsapp) {
    filtered = filtered.filter((item) => !item.isWhatsappFile());
  }

  if (atLeastOneOrigin && !filters.origin.record) {
    filtered = filtered.filter((item) => !item.isRecord());
  }

  if (atLeastOneOrigin && !filters.origin.legalProceeding) {
    filtered = filtered.filter((item) => !item.isLegalProceedingAttachedFile());
  }

  const atLeastOneFileType = filters.fileType.audio || filters.fileType.document || filters.fileType.image;

  if (atLeastOneFileType && !filters.fileType.audio) {
    filtered = filtered.filter((item) => item.fileType !== "audio");
  }

  if (atLeastOneFileType && !filters.fileType.document) {
    filtered = filtered
      .filter((item) => item.fileType !== "document")
      ?.filter((item) => item.fileType !== "application")
      ?.filter((item) => item.fileType !== "text");
  }

  if (atLeastOneFileType && !filters.fileType.image) {
    filtered = filtered.filter((item) => item.fileType !== "image");
  }

  return filtered;
};
