import React, { useState } from "react";
import { TagDefinition } from "@/services/internalAgents/types";

interface TagFormErrors {
  tag_name?: string;
  color?: string;
}

export const useTagForm = (initialData?: Partial<TagDefinition>) => {
  const [form, setForm] = useState<Partial<TagDefinition>>(initialData || {});
  const [errors, setErrors] = useState<TagFormErrors>({});

  const validate = React.useCallback((): boolean => {
    const newErrors: TagFormErrors = {};

    if (!form.tag_name?.trim()) {
      newErrors.tag_name = "Nome da tag é obrigatório";
    }

    if (form.color && !/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(form.color)) {
      newErrors.color = "Formato de cor inválido (ex: #FF0000)";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [form]);

  const handleChange = React.useCallback(
    (field: keyof TagDefinition) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm((prev) => ({ ...prev, [field]: e.target.value }));
      // Limpa o erro quando o campo é editado
      if (errors[field as keyof TagFormErrors]) {
        setErrors((prev) => ({ ...prev, [field]: undefined }));
      }
    },
    [errors]
  );

  const resetForm = React.useCallback(() => {
    setForm({});
    setErrors({});
  }, []);

  return {
    form,
    errors,
    validate,
    handleChange,
    resetForm,
    setForm,
  };
};
