export enum LeadStage {
  EM_FECHAMENTO_COMERCIAL = "Em fechamento comercial",
  EM_INSTRUCAO_CASO_ADMINISTRATIVO = "Em instrução do caso administrativo",
  EM_INSTRUCAO_CASO_JUDICIAL = "Em instrução do caso judicial",
  ABANDONADO = "Abandonado",
}

export enum ArchiveReason {
  CLIENT_ABANDONED = "CLIENT_ABANDONED", // Abandonado pelo cliente
  DOES_NOT_MEET_INCOME_CRITERIA = "DOES_NOT_MEET_INCOME_CRITERIA", // Não atende ao critério de renda
  DOES_NOT_MEET_HEALTH_CRITERIA = "DOES_NOT_MEET_HEALTH_CRITERIA", // Não atende ao critério de saúde
  ALREADY_APPLIED_FOR_BENEFIT = "ALREADY_APPLIED_FOR_BENEFIT", // Já deu entrada no benefício e quer tirar dúvidas
  ALREADY_HAS_LAWYER = "ALREADY_HAS_LAWYER", // Já tem advogado
  INQUIRING_ABOUT_OTHER_BENEFITS = "INQUIRING_ABOUT_OTHER_BENEFITS", // Quer falar sobre outros benefícios
  OTHER = "OTHER", // Outros
}

/**
 * Interface para os parâmetros da função syncLeadStageWithCase
 */
export interface SyncParams {
  /** Número do cliente */
  endClientNumber: string;
  /** Status do lead na planilha */
  leadStage: string;
  /** Tipo de razão para arquivamento (opcional, usado apenas quando leadStage é ABANDONADO) */
  archiveType?: ArchiveReason;
  /** Detalhes da razão para arquivamento (opcional, usado apenas quando leadStage é ABANDONADO) */
  archiveDetails?: string;
}

// Mapeamento de razões para texto em português para exibição em dropdowns
export const archiveLabels: Record<ArchiveReason, string> = {
  [ArchiveReason.CLIENT_ABANDONED]: "Abandonado pelo cliente",
  [ArchiveReason.DOES_NOT_MEET_INCOME_CRITERIA]: "Não atende ao critério de renda",
  [ArchiveReason.DOES_NOT_MEET_HEALTH_CRITERIA]: "Não atende ao critério de saúde",
  [ArchiveReason.ALREADY_APPLIED_FOR_BENEFIT]: "Já deu entrada no benefício e quer tirar dúvidas",
  [ArchiveReason.ALREADY_HAS_LAWYER]: "Já tem advogado",
  [ArchiveReason.INQUIRING_ABOUT_OTHER_BENEFITS]: "Quer falar sobre outros benefícios",
  [ArchiveReason.OTHER]: "Outros",
};

// Opções para dropdown
export const archiveOptions = Object.entries(archiveLabels).map(([value, label]) => ({
  value: value as ArchiveReason,
  label,
}));
