import { Box, IconButton, Popover, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useMemo, useState } from "react";
import { debounce } from "lodash";
import {
  SendRounded as SendRoundedIcon,
  PauseCircleOutline as PauseCircleOutlineIcon,
  Delete as DeleteIcon,
  AddBoxOutlined as AddBoxIcon,
} from "@mui/icons-material";
import { useSendLexZapMessage } from "@/hooks/lexZap/useSendLexZapMessage";
import { useLexZapChatInfo } from "@/hooks/lexZap/useLexZapChatInfo";

import { MessageTemplateCharacterCounter } from "@/components/LexZap/MessageTemplateCharacterCounter";
import ChatMicIcon from "@/assets/svgs/chat-mic.svg?react";
import { WebToast } from "@//components/core/Toast";
import { DateTime } from "luxon";
import { useApi } from "@/hooks/useApi";
import { uploadFileToS3 } from "@/contexts/MessagesContext/utils";
import { logger } from "@/core/logger";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import ffmpegCoreUrl from "@ffmpeg/core?url";
import ffmpegWasmUrl from "@ffmpeg/core/wasm?url";
import { FilesSelector } from "../FilesSelector";
import { useLexZapActiveChat } from "@/hooks/lexZap/useLexZapActiveChat";
import { CaseMessageToSendType } from "@/hooks/lexZap/types";
import { buildTemplateMessage } from "@/hooks/lexZap/useSendLexZapMessage/templates";
import { formatChatMessage } from "format-chat-message";
import parseStringToHtml from "html-react-parser";
import EmojiPicker, { Categories, Emoji, EmojiClickData, EmojiStyle } from "emoji-picker-react";

import { limitedTextInput } from "@/utils/lexZap/limitedTextInput";
import { LimitedTextInputTooltip } from "@/components/LexZap/LimitedTextInputTooltip";
interface ChatInputsProps {
  openDocumentSelector: () => void;
  openImageVideoSelector: () => void;
  onPasteFiles: (files: File[]) => void;
}

export const ChatInputs = ({ openDocumentSelector, openImageVideoSelector, onPasteFiles }: ChatInputsProps) => {
  const { activeChat } = useLexZapActiveChat();
  const { templateToUse } = useLexZapChatInfo({ chat: activeChat });
  const formRef = React.useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const api = useApi();

  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const { mutate: sendMessage, isPending: isSendingMessage } = useSendLexZapMessage();
  const [timeInterval, setTimeInterval] = useState<NodeJS.Timeout | null>(null);
  const [counter, setCounter] = useState<number>(0);
  const [inputLength, setInputLength] = useState(0);
  const [hasInput, setHasInput] = useState(false);
  const ffmpegRef = useRef(new FFmpeg());

  const [filesMenuAnchorEl, setFilesMenuAnchorEl] = React.useState<null | Element>(null);

  const openFilesMenu = (event: React.MouseEvent) => {
    setFilesMenuAnchorEl(event.currentTarget);
  };

  const closeFilesMenu = () => {
    setFilesMenuAnchorEl(null);
  };

  const loadFFmpeg = async () => {
    const ffmpeg = ffmpegRef.current;

    await ffmpeg.load({
      coreURL: await toBlobURL(ffmpegCoreUrl, "text/javascript"),
      wasmURL: await toBlobURL(ffmpegWasmUrl, "application/wasm"),
    });
  };

  useEffect(() => {
    if (!ffmpegRef.current.loaded) {
      loadFFmpeg().catch((err) => {
        logger.error(`[loadFFmpeg] error`, err);
      });
    }
  }, []);

  const getDraftKey = () => {
    return `whatsappInputDraft#${activeChat?.endClientNumber}`;
  };

  useEffect(() => {
    const localDraft = sessionStorage.getItem(getDraftKey()) || "";

    if (inputRef.current) {
      inputRef.current.value = localDraft;
      setHasInput(!!localDraft);
      setInputLength(localDraft.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat?.endClientNumber]);

  useEffect(() => {
    if (!hasInput) {
      sessionStorage.removeItem(getDraftKey());
      return;
    }

    const interval = setInterval(() => {
      const currentValue = inputRef.current?.value || "";
      if (currentValue === "") {
        sessionStorage.removeItem(getDraftKey());
      } else {
        sessionStorage.setItem(getDraftKey(), currentValue);
      }
    }, 2000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInput, activeChat?.endClientNumber]);

  const convertWebmToOgg = async (blob: Blob, fileName: string) => {
    const ffmpeg = ffmpegRef.current;
    const fileUrl = URL.createObjectURL(blob);
    try {
      await ffmpeg.writeFile("input.webm", await fetchFile(fileUrl));
      await ffmpeg.exec(["-i", "input.webm", "-c:a", "libopus", fileName]);
      const data = await ffmpeg.readFile(fileName);
      return new Blob([data], { type: "audio/ogg; codecs=opus" });
    } catch (err) {
      logger.error(`[convertWebmToOgg] error`, { error: err });
      return null;
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const currentValue = inputRef.current?.value || "";
    if (currentValue.trim() && activeChat?.endClientNumber) {
      sendMessage({
        applicantPhoneNumber: activeChat.endClientNumber,
        message: templateToUse
          ? buildTemplateMessage({
              templateKey: templateToUse,
              message: currentValue,
              applicantName: activeChat?.applicantName || "",
            })
          : {
              type: CaseMessageToSendType.TEXT,
              body: currentValue,
            },
      });
      if (inputRef.current) {
        inputRef.current.value = "";
        setHasInput(false);
        setInputLength(0);
        sessionStorage.removeItem(getDraftKey());
      }
    }
  };

  const handleSendRecording = async (audio: Blob) => {
    try {
      await sendRecording(audio);
    } catch (err) {
      WebToast.error("Não foi possível enviar o áudio");
      logger.error(`[sendRecording] error`, { error: err });
    }
  };

  const sendRecording = async (audioBlob: Blob) => {
    if (!audioBlob || !activeChat?.endClientNumber) {
      throw new Error("audioBlob e applicantPhoneNumber são obrigatórios");
    }

    const response = await handleUploadRecording(audioBlob);

    if (!response) {
      throw new Error("Erro ao enviar arquivo para o S3");
    }

    const { location, filename, newBlob } = response;

    const localUrl = URL.createObjectURL(newBlob);

    sendMessage({
      applicantPhoneNumber: activeChat.endClientNumber,
      message: {
        type: CaseMessageToSendType.AUDIO,
        body: "",
        media: {
          filename: filename,
          url: localUrl,
          mimeType: "audio/ogg",
          location,
        },
      },
    });
  };

  const handleUploadRecording = async (audioBlob: Blob) => {
    if (!audioBlob || !activeChat?.endClientNumber) return;

    const filename = `${Date.now()}.ogg`;

    try {
      const { signedUrl, location } = await api.signChatBucketUrl({
        applicantPhoneNumber: activeChat.endClientNumber,
        fileName: filename,
      });

      if (!ffmpegRef.current.loaded) {
        await loadFFmpeg();
      }

      const newBlob = await convertWebmToOgg(audioBlob, filename);

      if (!newBlob) {
        throw new Error("Erro ao converter arquivo para ogg");
      }

      await uploadFileToS3(signedUrl, newBlob);

      return { location, filename, newBlob };
    } catch (err) {
      logger.error(`[handleUploadMedia] error`, { error: err });
      return null;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      formRef.current?.requestSubmit();
    }
  };

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        return await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      } catch (err) {
        WebToast.error("É necessário permitir acesso ao microfone");
        return null;
      }
    } else {
      WebToast.error("O navegador não suporta gravação de áudio");
      return null;
    }
  };

  const startRecording = async () => {
    const stream = await getMicrophonePermission();
    if (!stream || !stream.active || isSendingMessage) return;

    setIsRecording(true);

    const recorder = new MediaRecorder(stream);

    const chunks: Blob[] = [];

    recorder.onstart = () => {
      setCounter(0);
    };

    recorder.ondataavailable = (event) => {
      chunks.push(event.data);
    };

    setAudioChunks(chunks);

    recorder.start();
    setMediaRecorder(recorder);
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
      setIsPaused(false);
      setCounter(0);
      clearInterval(timeInterval!);
      mediaRecorder.stop();
    }
  };

  const stopRecordingAndSubmit = async () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
      setIsPaused(false);
      setCounter(0);
      clearInterval(timeInterval!);
      mediaRecorder.stop();
      mediaRecorder.onstop = async () => {
        const newAudioBlob = new Blob(audioChunks, { type: "audio/webm" });
        await handleSendRecording(newAudioBlob);
      };
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.pause();
      setIsPaused(true);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "paused") {
      mediaRecorder.resume();
      setIsPaused(false);
      setIsRecording(true);
    }
  };

  const deleteAudio = () => {
    stopRecording();
  };
  const handleEmojiClick = (emojiData: EmojiClickData) => {
    if (!inputRef.current) return;

    const emoji = emojiData.emoji;

    const input = inputRef.current;
    const startPos = input.selectionStart ?? 0;
    const endPos = input.selectionEnd ?? 0;

    const value = input.value;
    const newValue = value.slice(0, startPos) + emoji + value.slice(endPos);
    input.value = newValue;

    setTimeout(() => {
      if (!inputRef.current) return;

      inputRef.current.selectionStart = inputRef.current.selectionEnd = startPos + emoji.length;
    }, 0);
  };

  const [emojiPickerAnchorEl, setEmojiPickerAnchorEl] = useState<null | Element>(null);

  const handleOpenEmojiPicker = (event: React.MouseEvent) => {
    setEmojiPickerAnchorEl(event.currentTarget);
  };

  const handleCloseEmojiPicker = () => {
    setEmojiPickerAnchorEl(null);
  };

  return (
    <Box
      component="form"
      ref={formRef}
      onSubmit={handleSubmit}
      sx={{
        backgroundColor: "grey.50",
        paddingX: 3,
        paddingY: 2,
        display: "flex",
        alignItems: "center",
        gap: 3,
        paddingRight: 11,
      }}
    >
      <IconButton sx={{ m: -1 }} onClick={handleOpenEmojiPicker}>
        <Emoji unified="1f642" size={24} />
      </IconButton>

      <Popover
        id={!!emojiPickerAnchorEl ? "emoji-picker" : undefined}
        open={!!emojiPickerAnchorEl}
        anchorEl={emojiPickerAnchorEl}
        onClose={handleCloseEmojiPicker}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "transparent",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <EmojiPicker
          open={true}
          onEmojiClick={handleEmojiClick}
          emojiStyle={EmojiStyle.NATIVE}
          searchPlaceholder="Pesquisar..."
          previewConfig={{
            defaultCaption: "Como você está se sentindo?",
          }}
          categories={[
            { category: Categories.SUGGESTED, name: "Recentes" },
            { category: Categories.SMILEYS_PEOPLE, name: "Emoticons" },
            { category: Categories.ANIMALS_NATURE, name: "Animais & Natureza" },
            { category: Categories.FOOD_DRINK, name: "Comida & Bebida" },
            { category: Categories.TRAVEL_PLACES, name: "Viagens" },
            { category: Categories.ACTIVITIES, name: "Atividades" },
            { category: Categories.OBJECTS, name: "Objetos" },
            { category: Categories.SYMBOLS, name: "Símbolos" },
            { category: Categories.FLAGS, name: "Bandeiras" },
          ]}
        />
      </Popover>

      <Tooltip
        disableHoverListener={!templateToUse}
        title="O envio de arquivos será habilitado novamente após uma resposta do seu cliente"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FilesSelector
            open={!!filesMenuAnchorEl}
            anchorEl={filesMenuAnchorEl!}
            onClose={closeFilesMenu}
            openDocumentSelector={() => {
              openDocumentSelector();
              closeFilesMenu();
            }}
            openImageVideoSelector={() => {
              openImageVideoSelector();
              closeFilesMenu();
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{ top: "-28px !important" }}
          />

          <IconButton sx={{ m: -1 }} disabled={!!templateToUse} onClick={openFilesMenu}>
            <AddBoxIcon sx={{ width: "24px", color: "grey.600" }} />
          </IconButton>
        </Box>
      </Tooltip>

      {isRecording ? (
        <AudioInput
          deleteAudio={deleteAudio}
          isRecording={isRecording}
          isPaused={isPaused}
          counter={counter}
          setCounter={setCounter}
          timeInterval={timeInterval}
          setTimeInterval={setTimeInterval}
        />
      ) : (
        <TextInput
          inputRef={inputRef}
          handleKeyDown={handleKeyDown}
          onPasteFiles={onPasteFiles}
          setHasInput={setHasInput}
          setInputLength={setInputLength}
          inputLength={inputLength}
          hasInput={hasInput}
        />
      )}

      {hasInput || !!templateToUse ? (
        <IconButton sx={{ m: -1 }} type="submit">
          <SendRoundedIcon
            sx={{
              width: "24px",
              color: "orange.light.90",
            }}
          />
        </IconButton>
      ) : (
        <AudioButtons
          isRecording={isRecording}
          isPaused={isPaused}
          startRecording={startRecording}
          resumeRecording={resumeRecording}
          pauseRecording={pauseRecording}
          deleteAudio={deleteAudio}
          sendRecording={stopRecordingAndSubmit}
        />
      )}
    </Box>
  );
};

const TextInput = ({
  inputRef,
  handleKeyDown,
  onPasteFiles,
  setHasInput,
  setInputLength,
  inputLength,
  hasInput,
}: {
  inputRef: React.RefObject<HTMLInputElement>;
  handleKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onPasteFiles: (files: File[]) => void;
  setHasInput: (value: boolean) => void;
  setInputLength: (length: number) => void;
  inputLength: number;
  hasInput: boolean;
}) => {
  const { activeChat } = useLexZapActiveChat();
  const { shouldUseTemplate, templateTextWithoutMessage } = useLexZapChatInfo({ chat: activeChat });

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const files = e.clipboardData.files;
    if (files.length > 0) {
      onPasteFiles(Array.from(files));
    }
  };

  const debouncedSetInputLength = useMemo(
    () =>
      debounce((length: number) => {
        setInputLength(length);
      }, 300),
    [setInputLength]
  );

  useEffect(() => {
    return () => {
      debouncedSetInputLength.cancel();
    };
  }, [debouncedSetInputLength]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;

    if (hasInput !== !!value) {
      setHasInput(!!value);
    }

    if (!!shouldUseTemplate) {
      const limitedValue = limitedTextInput(e);
      if (inputRef.current) {
        inputRef.current.value = limitedValue;
        debouncedSetInputLength(limitedValue.length);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        flex: 1,
      }}
    >
      <LimitedTextInputTooltip disabled={!shouldUseTemplate}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            bgcolor: "common.white",
            borderRadius: "24px",
            paddingY: "8.5px",
            paddingX: "14px",
          }}
          onPaste={handlePaste}
        >
          {!!shouldUseTemplate && (
            <Typography variant="multiLineBody" color="text.primary" mb={0.5}>
              {parseStringToHtml(formatChatMessage({ message: templateTextWithoutMessage || "" }))}
            </Typography>
          )}
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "24px",
                padding: 0,
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            maxRows={8}
            autoComplete="off"
            hiddenLabel
            variant="outlined"
            placeholder="Escreva algo"
            size="small"
            multiline
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
          />
        </Box>
      </LimitedTextInputTooltip>

      {!!shouldUseTemplate && <MessageTemplateCharacterCounter inputLength={inputLength} />}
    </Box>
  );
};

const AudioInput = ({
  deleteAudio,
  isRecording,
  isPaused,
  counter,
  setCounter,
  timeInterval,
  setTimeInterval,
}: {
  deleteAudio: () => void;
  isRecording: boolean;
  isPaused: boolean;
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  timeInterval: NodeJS.Timeout | null;
  setTimeInterval: React.Dispatch<React.SetStateAction<NodeJS.Timeout | null>>;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "6px",
        flex: 1,
      }}
    >
      <IconButton onClick={deleteAudio}>
        <DeleteIcon
          style={{
            flexShrink: 0,
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
        />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          bgcolor: "common.white",
          borderRadius: "24px",
          justifyContent: "center",
          justifyItems: "center",
          paddingY: "8.5px",
          paddingX: "14px",
        }}
      >
        {isRecording ? (
          <Timer
            timeInterval={timeInterval}
            setTimeInterval={setTimeInterval}
            isPaused={isPaused}
            counter={counter}
            setCounter={setCounter}
          />
        ) : (
          <Box />
        )}
        <div
          style={{
            width: "100%",
            height: "24px",
          }}
        />
      </Box>
    </Box>
  );
};

const AudioButtons = ({
  isRecording,
  isPaused,
  resumeRecording,
  pauseRecording,
  startRecording,
  sendRecording,
}: {
  isRecording: boolean;
  isPaused: boolean;
  resumeRecording: () => void;
  pauseRecording: () => void;
  startRecording: () => void;
  deleteAudio: () => void;
  sendRecording: () => void;
}) => {
  if (isRecording) {
    return (
      <>
        {isPaused ? (
          <IconButton sx={{ m: -1 }} onClick={resumeRecording}>
            <ChatMicIcon
              style={{
                flexShrink: 0,
                width: "24px",
                height: "24px",
                cursor: "pointer",
              }}
            />
          </IconButton>
        ) : (
          <IconButton sx={{ m: -1 }} onClick={pauseRecording}>
            <PauseCircleOutlineIcon
              style={{
                color: "#ff3b30",
                flexShrink: 0,
                width: "24px",
                height: "24px",
                cursor: "pointer",
              }}
            />
          </IconButton>
        )}

        <IconButton sx={{ m: -1 }} onClick={sendRecording}>
          <SendRoundedIcon
            sx={{
              width: "24px",
              color: "orange.light.90",
            }}
          />
        </IconButton>
      </>
    );
  } else {
    return (
      <IconButton sx={{ m: -1 }} onClick={startRecording}>
        <ChatMicIcon
          style={{
            flexShrink: 0,
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
        />
      </IconButton>
    );
  }
};

const Timer = ({
  counter,
  setCounter,
  isPaused,
  timeInterval,
  setTimeInterval,
}: {
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  isPaused: boolean;
  timeInterval: NodeJS.Timeout | null;
  setTimeInterval: React.Dispatch<React.SetStateAction<NodeJS.Timeout | null>>;
}) => {
  useEffect(() => {
    if (isPaused && timeInterval) {
      clearInterval(timeInterval);
    }
  }, [isPaused, timeInterval]);

  useEffect(() => {
    if (!isPaused) {
      setTimeInterval(
        setInterval(() => {
          setCounter((prev) => prev + 1);
        }, 1000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaused]);

  return (
    <Box>
      <Typography variant="multiLineBody" color="text.primary">
        {DateTime.fromSeconds(counter).toFormat("mm:ss")}
      </Typography>
    </Box>
  );
};
