/* eslint-disable */

const ENV = process.env.ENV;
const IS_DEV = ENV === "development";
const IS_STAGING = ENV === "staging";
const IS_PRODUCTION = ENV === "production";
const WEBSOCKET_URL = process.env.WEBSOCKET_URL;
const API = process.env.API;
const API_HOST = process.env.API_HOST;
const APP_LEXTER_URL = process.env.APP_LEXTER_URL;
const WHATSAPP_SERVER_URL = process.env.WHATSAPP_SERVER_URL;
const LEXTER_COPILOT_API_URL = process.env.LEXTER_COPILOT_API_URL;
const FIREBASE_API_KEY = process.env.FIREBASE_API_KEY;
const FIREBASE_AUTH_DOMAIN = process.env.FIREBASE_AUTH_DOMAIN;
const FIREBASE_DATABASE_URL = process.env.FIREBASE_DATABASE_URL;
const FIREBASE_PROJECT_ID = process.env.FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET = process.env.FIREBASE_STORAGE_BUCKET;
const FIREBASE_MESSAGING_SENDER_ID = process.env.FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_APP_ID = process.env.FIREBASE_APP_ID;
const CURRENT_COMMIT = process.env.CURRENT_COMMIT;
const PUBLIC_DATADOG_CLIENT_ID = process.env.PUBLIC_DATADOG_CLIENT_ID;
const LAUNCH_DARKLY_CLIENT_SIDE_ID = process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID || "";
const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY || "";
const STRIPE_PUBLISHABLE_KEY = process.env.STRIPE_PUBLISHABLE_KEY || "";
const STRIPE_INITIAL_BUTTON_ID = process.env.STRIPE_INITIAL_BUTTON_ID || "";
const STRIPE_BASIC_BUTTON_ID = process.env.STRIPE_BASIC_BUTTON_ID || "";
const STRIPE_INTERMEDIATE_BUTTON_ID = process.env.STRIPE_INTERMEDIATE_BUTTON_ID || "";
const STRIPE_ADVANCED_BUTTON_ID = process.env.STRIPE_ADVANCED_BUTTON_ID || "";
const STRIPE_PROFESSIONAL_BUTTON_ID = process.env.STRIPE_PROFESSIONAL_BUTTON_ID || "";
const DATADOG_APPLICATIN_ID = process.env.DATADOG_APPLICATIN_ID || "";
const DATADOG_CLIENTE_TOKEN_ID = process.env.DATADOG_CLIENTE_TOKEN_ID || "";
const DATADOG_SESSION_SAMPLE_RATE = Number(process.env.DATADOG_SESSION_SAMPLE_RATE) || 0;
const DATADOG_SESSION_REPLAY_SAMPLE_RATE = Number(process.env.DATADOG_SESSION_REPLAY_SAMPLE_RATE) || 0;
const DATADOG_ENV = process.env.DATADOG_ENV || "";
const DATADOG_VERSION = process.env.DATADOG_VERSION || "";

export const env = {
  API,
  API_HOST,
  APP_LEXTER_URL,
  WHATSAPP_SERVER_URL,
  LEXTER_COPILOT_API_URL,
  CURRENT_COMMIT,
  ENV,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  IS_DEV,
  IS_PRODUCTION,
  IS_STAGING,
  LAUNCH_DARKLY_CLIENT_SIDE_ID,
  PUBLIC_DATADOG_CLIENT_ID,
  WEBSOCKET_URL,
  RECAPTCHA_KEY,
  DATADOG_ENV,
  DATADOG_APPLICATIN_ID,
  DATADOG_CLIENTE_TOKEN_ID,
  DATADOG_SESSION_SAMPLE_RATE,
  DATADOG_SESSION_REPLAY_SAMPLE_RATE,
  DATADOG_VERSION,
  STRIPE: {
    PUBLISHABLE_KEY: STRIPE_PUBLISHABLE_KEY,
    BUTTONS: {
      INITIAL: STRIPE_INITIAL_BUTTON_ID,
      BASIC: STRIPE_BASIC_BUTTON_ID,
      INTERMEDIATE: STRIPE_INTERMEDIATE_BUTTON_ID,
      ADVANCED: STRIPE_ADVANCED_BUTTON_ID,
      PROFESSIONAL: STRIPE_PROFESSIONAL_BUTTON_ID,
    },
  },
};
