import { env } from "@/constants/environment";
import { SkillGroupId } from "@/core/skillGroups/types";
import { SkillId } from "@/core/skills/types";
import { removeUndefinedValues } from "@/utils/removeUndefinedValues";
import { createSearchParams, generatePath } from "react-router-dom";

export const ASSISTANT_URL = env.IS_PRODUCTION
  ? "https://assistente.lexter.ai"
  : "https://assistente-staging.lexter.ai";

export const AUTHENTICATED_ROUTES_PREFIX = "/app";

export const EDITOR_ROUTES_PREFIX = `${AUTHENTICATED_ROUTES_PREFIX}/editor`;

export const ROUTE_PATHS = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  SIGNUP_FAILURE: "/signup/failure",
  SIGNUP_SUCCESS: "/signup/success",
  ACTIVATE_ACCOUNT: "/signup/activate",
  ADD_PHONE: "/app/add-phone",

  CLIENT_CONFIG: `${AUTHENTICATED_ROUTES_PREFIX}/client-config`,
  CLIENT_CONFIG_MY_DATA: `${AUTHENTICATED_ROUTES_PREFIX}/client-config/mydata`,
  CLIENT_CONFIG_MY_DATA_WHATSAPP: `${AUTHENTICATED_ROUTES_PREFIX}/client-config/mydata/whatsapp`,
  CLIENT_CONFIG_CHANGE_PASSWORD: `${AUTHENTICATED_ROUTES_PREFIX}/client-config/changepassword`,
  CLIENT_CONFIG_MY_TEAM: `${AUTHENTICATED_ROUTES_PREFIX}/client-config/myteam`,
  PLAN: `${AUTHENTICATED_ROUTES_PREFIX}/client-config/plan`,
  LEGAL_PROCEEDINGS_MONITORING: `${AUTHENTICATED_ROUTES_PREFIX}/client-config/legal-proceedings/monitoring`,
  INTERNAL_PAYMENT: `${AUTHENTICATED_ROUTES_PREFIX}/client-config/payment`,

  DEV_TOOLS: `${AUTHENTICATED_ROUTES_PREFIX}/dev-tools`,

  CHAT: `${EDITOR_ROUTES_PREFIX}/chat`,
  THREAD: `${EDITOR_ROUTES_PREFIX}/chat/:threadId`,
  FEEDBACK: `${EDITOR_ROUTES_PREFIX}/feedback`,
  CASES: `${AUTHENTICATED_ROUTES_PREFIX}/cases`,
  ACTIVE_CASES: `${AUTHENTICATED_ROUTES_PREFIX}/cases/active`,
  ARCHIVED_CASES: `${AUTHENTICATED_ROUTES_PREFIX}/cases/archived`,
  WHATSAPP_ASSISTANT: `${AUTHENTICATED_ROUTES_PREFIX}/whatsapp-assistant`,
  WHATSAPP_ASSISTANT_CHATS: `${AUTHENTICATED_ROUTES_PREFIX}/whatsapp-assistant/chats`,
  WHATSAPP_ASSISTANT_EXPORT_CONTACTS_GUIDE: `${AUTHENTICATED_ROUTES_PREFIX}/whatsapp-assistant/export-contacts-guide`,
  WHATSAPP_ASSISTANT_INTERNAL: `${AUTHENTICATED_ROUTES_PREFIX}/internal-whatsapp-assistant`,
  WHATSAPP_ASSISTANT_CHATS_INTERNAL: `${AUTHENTICATED_ROUTES_PREFIX}/internal-whatsapp-assistant/chats`,
  IMPORT_LEGAL_PROCEEDINGS_BY_OAB: `${AUTHENTICATED_ROUTES_PREFIX}/cases/import-legal-proceedings-by-oab`,

  CREATE_CASE: `${AUTHENTICATED_ROUTES_PREFIX}/case/create`,
  CASE_DETAILS: `${AUTHENTICATED_ROUTES_PREFIX}/cases/:caseId`,
  CASE_DOCUMENT_DETAILS: `${AUTHENTICATED_ROUTES_PREFIX}/cases/:caseId/documents/:documentId`,

  TICKET_DETAILS: `${AUTHENTICATED_ROUTES_PREFIX}/tickets/:ticketId`,

  SESSION_SETTINGS: `${AUTHENTICATED_ROUTES_PREFIX}/session-settings`,
  CREDITS_HISTORY: `${AUTHENTICATED_ROUTES_PREFIX}/credits-history`,
  TERMS_OF_USE: `${AUTHENTICATED_ROUTES_PREFIX}/terms-of-use`,

  PLANS: `${AUTHENTICATED_ROUTES_PREFIX}/plans`,
  HOME: `${AUTHENTICATED_ROUTES_PREFIX}/home`,
  THREAD_HISTORY: `${AUTHENTICATED_ROUTES_PREFIX}/history`,
  OLD_USAGE_HISTORY: `${AUTHENTICATED_ROUTES_PREFIX}/old-history`,
  REFERRAL: `${AUTHENTICATED_ROUTES_PREFIX}/referral`,
  WELCOME: `${AUTHENTICATED_ROUTES_PREFIX}/welcome`,
  ONBOARDING_QUESTIONS: `${AUTHENTICATED_ROUTES_PREFIX}/onboarding-questions`,
  CALL_WITH_SPECIALIST: `${AUTHENTICATED_ROUTES_PREFIX}/call-with-specialist`,

  LEGAL_PROCEEDINGS: `${AUTHENTICATED_ROUTES_PREFIX}/legal-proceedings`,

  WHATSAPP_USERS: `${AUTHENTICATED_ROUTES_PREFIX}/admin/whatsapp-users`,
  WHATSAPP_DLQ_EVENTS: `${AUTHENTICATED_ROUTES_PREFIX}/admin/whatsapp-dlq-events`,
  INTERNAL_AGENTS: `${AUTHENTICATED_ROUTES_PREFIX}/admin/internal-agents`,
  APPROVAL_REQUESTS: `${AUTHENTICATED_ROUTES_PREFIX}/admin/approval-requests`,
  APPLICANT_EVENTS: `${AUTHENTICATED_ROUTES_PREFIX}/admin/applicant-events`,
} as const;

export const EXTERNAL_URLS = {
  PRIVACY_POLICY: "https://lexter.ai/politicadeprivacidade",
  TERMS_OF_USE: "https://lexter.ai/termosdeuso",
  HELP_CENTER_URL: "https://ajuda.lexter.ai/",
  CONTACT: "https://lexter.ai/contato",
  CREDITS_INFO: "https://ajuda.lexter.ai/o-que-sao-e-como-funcionam-os-creditos",
  WHAT_IS_A_CASE: "https://ajuda.lexter.ai/o-que-e-um-caso",
  WHATSAPP_URL: "https://wa.me/5511935032172",
} as const;

export const getCaseDocumentPath = ({ documentId, caseId }: { documentId: string; caseId: string }) => {
  return generatePath(ROUTE_PATHS.CASE_DOCUMENT_DETAILS, {
    documentId: documentId.toString(),
    caseId,
  });
};

export const getCasePath = ({ caseId }: { caseId: string }) => {
  return generatePath(ROUTE_PATHS.CASE_DETAILS, { caseId });
};

export const getSkillPath = ({
  skillId,
  threadId,
  customTitle,
  isOnboarding,
  caseId,
}: {
  skillId: SkillId;
  threadId?: string;
  customTitle?: string;
  isOnboarding?: boolean;
  caseId?: string;
}) => {
  const query = createSearchParams(
    removeUndefinedValues({ skill: skillId, skillTitle: customTitle, isOnboarding, caseId })
  );
  return threadId ? `${generatePath(ROUTE_PATHS.THREAD, { threadId })}?${query}` : `${ROUTE_PATHS.CHAT}?${query}`;
};

export const getSkillGroupPath = ({
  skillGroupId,
  threadId,
  customTitle,
  isOnboarding,
  caseId,
}: {
  skillGroupId: SkillGroupId;
  threadId?: string;
  customTitle?: string;
  isOnboarding?: boolean;
  caseId?: string;
}) => {
  const query = createSearchParams(
    removeUndefinedValues({ skillGroup: skillGroupId, skillTitle: customTitle, isOnboarding, caseId })
  );
  return threadId ? `${generatePath(ROUTE_PATHS.THREAD, { threadId })}?${query}` : `${ROUTE_PATHS.CHAT}?${query}`;
};
