import { useAuthContext } from "@/contexts/AuthContext";
import { useApi } from "@/hooks/useApi";
import { useUserGuiding } from "@/hooks/useUserGuiding";
import { useQuery } from "@tanstack/react-query";
import { HeapService } from "@/services/heap";
import { logger } from "@/core/logger";
import { useCustomerHelpChat } from "@/hooks/customerHelpChat/useCustomerHelpChat";
import { PlanType } from "../types";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useEffect, useRef } from "react";

export const useCredits = () => {
  const api = useApi();
  const userGuiding = useUserGuiding();
  const { user } = useAuthContext();
  const customerHelpChat = useCustomerHelpChat();
  const { customerHelpChatEnabled } = useFeatureFlags();
  const customerHelpChatEnabledRef = useRef(customerHelpChatEnabled);

  useEffect(() => {
    customerHelpChatEnabledRef.current = customerHelpChatEnabled;
  }, [customerHelpChatEnabled]);

  return useQuery({
    queryKey: ["credits"],
    queryFn: async () => {
      const credits = await api.getCredits();
      const planType = credits.companyPlan?.plan.toString();
      const isFreemium = credits?.companyPlan?.plan === PlanType.FREEMIUM;
      const isTrial = credits?.companyPlan?.plan === PlanType.TRIAL;
      const isActivePlan = credits?.companyPlan?.status === "ACTIVE";
      userGuiding.identify(user, { credits: credits.availableCredit, plan: planType });
      HeapService.addUserProperties({ CurrentPlan: planType });
      if (user && customerHelpChatEnabledRef.current) {
        try {
          await customerHelpChat.loadCustomerHelpChat({
            user,
            planType,
            isFreeUser: isFreemium || isTrial,
            isActivePlan,
          });
        } catch (e) {
          logger.debug(`customerHelpChat error: ${JSON.stringify(e)}`);
        }
      }
      return credits;
    },
    enabled: !!user?.userId,
  });
};
