import { Permission, useAuthContext } from "@/contexts/AuthContext";

export const usePermissions = () => {
  const { user, loading } = useAuthContext();

  const hasManagePlansPermission = !user?.permissions || user?.permissions?.includes(Permission.MANAGE_PLANS);
  const hasManageUsersPermission = !user?.permissions || user?.permissions?.includes(Permission.MANAGE_USERS);
  const hasManageLegalProceedingsPermission =
    !user?.permissions || user?.permissions?.includes(Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS);
  const hasCustomerServiceFullAccessPermission =
    !user?.permissions || user?.permissions?.includes(Permission.CUSTOMER_SERVICE_FULL_ACCESS);

  function hasPermission(permissions: Permission | string) {
    return !user?.permissions || user?.permissions?.includes(permissions as Permission);
  }

  return {
    hasManagePlansPermission,
    hasManageUsersPermission,
    hasManageLegalProceedingsPermission,
    hasCustomerServiceFullAccessPermission,
    hasPermission,
    isLoading: loading,
  };
};
