import { Permission } from "@/contexts/AuthContext";

export interface CompanyMember {
  userId: string;
  companyId: string;
  name: string;
  email: string;
  isActive: boolean;
  isAdmin: boolean;
  isOwner: boolean;
  notificationNumber?: string;
  department?: Department;
  activityAreas?: ActivityArea[];
  permissions?: Permission[];
}

export enum Department {
  ADMINISTRATIVE = "ADMINISTRATIVE",
  FINANCIAL = "FINANCIAL",
  LEGAL = "LEGAL",
}

export enum ActivityArea {
  CIVIL = "CIVIL",
  LABOR = "LABOR",
  TAX = "TAX",
  CRIMINAL = "CRIMINAL",
  CAPITAL_MARKETS = "CAPITAL_MARKETS",
  REAL_ESTATE = "REAL_ESTATE",
  DIGITAL_AND_DATA_PROTECTION = "DIGITAL_AND_DATA_PROTECTION",
  CORPORATE_AND_MA = "CORPORATE_AND_MA",
  INTELLECTUAL_PROPERTY = "INTELLECTUAL_PROPERTY",
  INFRASTRUCTURE = "INFRASTRUCTURE",
  PUBLIC_AND_REGULATORY = "PUBLIC_AND_REGULATORY",
  ENVIRONMENTAL = "ENVIRONMENTAL",
  COMPETITIVE = "COMPETITIVE",
  OTHERS = "OTHERS",
}

const activityAreaLabels: Record<ActivityArea, string> = {
  [ActivityArea.CIVIL]: "Cível",
  [ActivityArea.LABOR]: "Trabalhista",
  [ActivityArea.TAX]: "Tributário",
  [ActivityArea.CRIMINAL]: "Criminal",
  [ActivityArea.CAPITAL_MARKETS]: "Mercado de capitais",
  [ActivityArea.REAL_ESTATE]: "Imobiliário",
  [ActivityArea.DIGITAL_AND_DATA_PROTECTION]: "Direito digital e Proteção de dados",
  [ActivityArea.CORPORATE_AND_MA]: "Societário & M&A",
  [ActivityArea.INTELLECTUAL_PROPERTY]: "Propriedade intelectual",
  [ActivityArea.INFRASTRUCTURE]: "Infraestrutura",
  [ActivityArea.PUBLIC_AND_REGULATORY]: "Direito público e regulatório",
  [ActivityArea.ENVIRONMENTAL]: "Ambiental",
  [ActivityArea.COMPETITIVE]: "Concorrencial",
  [ActivityArea.OTHERS]: "Outros",
};

export type ActivityAreaOption = {
  value: ActivityArea;
  label: string;
};

export const activityAreaOptions: ActivityAreaOption[] = Object.entries(activityAreaLabels).map(([value, label]) => ({
  value: value as ActivityArea,
  label,
}));

export function getActivityAreaLabel(value: ActivityArea): string {
  return activityAreaLabels[value];
}
