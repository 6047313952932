import { useInfiniteQuery } from "@tanstack/react-query";
import { CompanyService } from "@/services/company";
import { OrderDirectionUsageHistory, OrderUsageHistoryBy } from "@/hooks/useUsageHistory/types";

const DEFAULT_USAGE_HISTORY_PAGE_SIZE = 10;

export const useUsageHistory = ({
  companyId,
  orderBy,
  orderDirection,
  pageSize = DEFAULT_USAGE_HISTORY_PAGE_SIZE,
  filters,
}: {
  companyId: string;
  orderBy: OrderUsageHistoryBy;
  orderDirection: OrderDirectionUsageHistory;
  pageSize: number;
  filters?: { userId?: string; entityId?: string };
}) => {
  return useInfiniteQuery({
    queryKey: ["usageHistory", { orderBy, orderDirection, pageSize, filters }],
    queryFn: async ({ pageParam }) => {
      return await CompanyService.getCompanyUsageHistory({
        companyId,
        filters,
        options: { orderBy, orderDirection, pagination: { page: pageParam, pageSize } },
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (allPages.flatMap((page) => page.data).length >= lastPage.totalResults) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });
};
