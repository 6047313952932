import { ActionId } from "@/contexts/MessagesContext";
import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCheckCredits = () => {
  const queryClient = useQueryClient();
  const { checkCredits } = useApi();

  return useMutation({
    mutationKey: ["checkCredits"],
    mutationFn: async (actionId: ActionId) => checkCredits({ action: { id: actionId } }),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ["credits"] });
    },
  });
};
