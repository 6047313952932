import { Autocomplete, Box, createFilterOptions, TextField } from "@mui/material";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import React, { ChangeEvent, SyntheticEvent, useMemo } from "react";
import { WebToast } from "@//components/core/Toast";
import { useLexZapCase } from "@/hooks/lexZap/useLexZapCase";
import { useEditCase } from "@/hooks/lexZap/useEditCase";
import { useApplicants } from "@/hooks/applicants/useApplicants";
import { ApplicantModal } from "@/components/Applicants";
import { useLexZapCases } from "@/hooks/lexZap/useLexZapCases";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { CaseStep } from "@/hooks/lexZap/types";

const CREATE_APPLICANT_ID = "__create__";

type BaseApplicant = {
  id: string;
  name: string;
  label: string;
};

type AutocompleteProps = React.ComponentProps<typeof Autocomplete<BaseApplicant>>;

type Form = {
  name: string;
  applicantId?: string;
  step?: CaseStep;
  legalArea: string | undefined;
};

const filter = createFilterOptions<BaseApplicant>();

export function EditCaseModal({ isOpen, onClose, caseId }: { isOpen: boolean; onClose: () => void; caseId: string }) {
  const { caseData, isLoading: isLoadingCaseData } = useLexZapCase({ caseId });
  const { cases } = useLexZapCases({ status: ["ACTIVE"] });
  const { data: applicants, isLoading: isLoadingApplicants } = useApplicants();
  const { mutateAsync: updateCase, isPending: isUpdatingCase } = useEditCase();
  const [clientModalData, setClientModalData] = React.useState<{ name: string }>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<Form>({
    name: caseData?.name ?? "",
    applicantId: caseData?.applicantId,
    step: caseData?.step,
    legalArea: caseData?.legalArea ?? undefined,
  });

  const applicantFormOptions = useMemo(() => {
    return (
      applicants?.map((applicant) => ({
        id: applicant.id,
        name: applicant.name,
        label: applicant.name,
      })) ?? []
    );
  }, [applicants]);

  const applicantFormValue = useMemo(() => {
    return applicantFormOptions?.find((applicant) => applicant.id === form.applicantId) ?? null;
  }, [applicantFormOptions, form.applicantId]);

  React.useEffect(() => {
    if (caseData) {
      setForm({
        name: caseData.name,
        applicantId: caseData.applicantId,
        step: caseData.step,
        legalArea: caseData.legalArea ?? undefined,
      });
    }
  }, [caseData]);

  const handleUpdateCase = async () => {
    if (!caseId || !form.applicantId || !form.step || form.name === "") {
      return;
    }

    try {
      setIsLoading(true);
      await updateCase({ caseId, data: { ...form, step: form.step } });

      setIsLoading(false);

      onClose();
      return WebToast.success("Alteração feita com sucesso");
    } catch (err) {
      setIsLoading(false);
      return WebToast.error("Falha ao alterar o caso");
    }
  };

  const handleChangeForm = <K extends keyof Form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setForm((prevState) => ({
        ...prevState,
        [prop]: e.target.value,
      }));
    };
  };

  const handleChangeSelect = <K extends keyof Form>(prop: K) => {
    const handler: AutocompleteProps["onChange"] = (_e, value) => {
      if (value) {
        if (value.id === CREATE_APPLICANT_ID) {
          setClientModalData({ name: value.name });
        } else {
          setForm((prevState) => ({
            ...prevState,
            [prop]: value.id,
          }));
        }
      }
    };
    return handler;
  };

  const closeEditClientModal = (applicantId?: string) => {
    setClientModalData(undefined);
    if (applicantId) {
      setForm((prevState) => ({
        ...prevState,
        applicantId,
      }));
    }
  };

  const saveButtonDisabled =
    form.name === "" || form.applicantId === null || form.applicantId === CREATE_APPLICANT_ID || isLoading;

  const areaOptions = useMemo(() => {
    if (!cases) return [];
    return Array.from(
      new Set([
        ...cases.filter((c) => c.legalArea).map((c) => c.legalArea),
        "Cível",
        "Trabalhista",
        "Previdenciário",
        "Tributário",
        "Criminal",
      ])
    ).sort();
  }, [cases]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight
        title={"Dados do caso"}
        size={"SMALL"}
        onClose={onClose}
        footer={
          caseData &&
          !isLoading && (
            <ModalFooter
              confirmLabel={"Atualizar"}
              onConfirm={handleUpdateCase}
              cancelLabel="Cancelar"
              onCancel={onClose}
              saveButtonDisabled={saveButtonDisabled}
            />
          )
        }
        open={isOpen}
        sx={{
          "& .MuiTypography-root": {
            fontWeight: 400,
            fontSize: "20px",
          },
          "& .MuiSvgIcon-fontSizeMedium": {
            width: "20px",
            height: "20px",
          },
        }}
      >
        <Loading isLoading={isLoading || isLoadingCaseData || isUpdatingCase || isLoadingApplicants}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              {caseData && (
                <>
                  <TextField
                    placeholder="Nome do caso"
                    onChange={handleChangeForm("name")}
                    value={form.name}
                    label="Nome do caso"
                  />

                  <Autocomplete<BaseApplicant>
                    value={applicantFormValue}
                    onChange={handleChangeSelect("applicantId")}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      filtered.push({
                        id: CREATE_APPLICANT_ID,
                        label: `Adicionar "${params.inputValue || "Novo cliente"}"`,
                        name: params.inputValue,
                      });

                      return filtered;
                    }}
                    options={applicantFormOptions}
                    getOptionLabel={(option) => option.label}
                    selectOnFocus
                    clearOnBlur
                    getOptionKey={(option) => option.id.toString()}
                    handleHomeEndKeys
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                        label="Cliente"
                        placeholder="Selecione o cliente"
                        fullWidth
                      />
                    )}
                    disabled={!!caseData?.applicantId}
                    fullWidth
                  />

                  <Autocomplete
                    options={areaOptions}
                    value={form.legalArea}
                    defaultValue={caseData?.legalArea}
                    freeSolo
                    onChange={(_: SyntheticEvent<Element, Event>, value: string | null | undefined) => {
                      return setForm((prevState) => ({
                        ...prevState,
                        legalArea: value ?? undefined,
                      }));
                    }}
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                        onChange={handleChangeForm("legalArea")}
                        label="Área"
                        placeholder="Selecione a área"
                        variant="outlined"
                      />
                    )}
                  />
                </>
              )}
            </Box>
          </Box>
        </Loading>
      </ModalRight>

      {!!clientModalData && (
        <ApplicantModal isOpen={true} onClose={closeEditClientModal} defaultApplicant={clientModalData} />
      )}
    </Box>
  );
}
