import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";

interface DeleteConfirmationDialogProps {
  open: boolean;
  tagName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  open,
  tagName,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: 400,
        },
      }}
    >
      <DialogTitle sx={{ pb: 2 }}>Excluir Tag</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Tem certeza que deseja excluir a tag &quot;{tagName}&quot;? Esta ação não pode ser desfeita.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 1 }}>
        <Button
          onClick={onCancel}
          sx={{
            textTransform: "none",
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={onConfirm}
          color="error"
          variant="contained"
          sx={{
            textTransform: "none",
          }}
        >
          Excluir
        </Button>
      </DialogActions>
    </Dialog>
  );
};
