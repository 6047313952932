import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Divider,
  CircularProgress,
} from "@mui/material";
import { ApprovalRequest, ProcessSkillOutput } from "@/services/approvalRequests/types";
import { ContextSection } from "./ContextSection";
import { ApprovalOptions } from "./ApprovalOptions";
import { useApprovalManagement } from "@/hooks/useApprovalRequests/useApprovalRequests";

interface ApprovalModalProps {
  open: boolean;
  onClose: () => void;
  request: ApprovalRequest;
  onUpdate: () => void;
}

export const ApprovalModal: React.FC<ApprovalModalProps> = ({ open, onClose, request, onUpdate }) => {
  const { approve, requestReprocessing } = useApprovalManagement();
  const [loading, setLoading] = React.useState(false);

  const handleAction = async (action: Promise<boolean>) => {
    setLoading(true);
    try {
      if (await action) {
        onUpdate();
        onClose();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (outputEdited?: ProcessSkillOutput) => {
    return handleAction(approve(request, outputEdited));
  };

  const handleReprocessing = async (instructions: string) => {
    return handleAction(requestReprocessing(request, instructions));
  };

  return (
    <Dialog
      open={open}
      onClose={loading ? undefined : onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          p: 2,
        },
      }}
    >
      <DialogTitle variant="h6">Detalhes da Solicitação</DialogTitle>

      <DialogContent sx={{ p: 2 }}>
        <Box display="flex" flexDirection="column" gap={3}>
          <ContextSection request={request} />
          <Divider />
          <ApprovalOptions
            request={request}
            loading={loading}
            onApprove={handleApprove}
            onReprocessing={handleReprocessing}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2, gap: 2 }}>
        <Button
          onClick={onClose}
          disabled={loading}
          variant="text"
          startIcon={loading && <CircularProgress size={16} />}
          sx={{
            color: "text.primary",
            "&:hover": {
              textDecoration: "underline",
              backgroundColor: "transparent",
            },
          }}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
