import React from "react";
import { Box, Chip, Typography, styled } from "@mui/material";
import { TagDefinition } from "@/services/internalAgents/types";

interface TagChipProps {
  tag: TagDefinition;
  onClick?: () => void;
  fullWidth?: boolean;
}

const ChipContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
  width: "100%",
}));

export const TagChip: React.FC<TagChipProps> = ({ tag, onClick, fullWidth }) => {
  return (
    <Chip
      onClick={onClick}
      component="div"
      label={
        <ChipContent>
          <Box
            sx={{
              bgcolor: tag.color || "primary.main",
              width: 24,
              height: 24,
              borderRadius: 1,
              border: "1px solid",
              borderColor: "divider",
            }}
          />
          <Typography variant="body1">{tag.tag_name}</Typography>
        </ChipContent>
      }
      sx={{
        "& .MuiChip-label": {
          width: fullWidth ? "100%" : "auto",
          px: 2,
          py: 1,
        },
      }}
    />
  );
};
