import { CaseFileItem } from "@/core/CaseFile";
import { LexZapService } from "@/services/lexZap";
import { saveFileAs } from "@/utils/saveFileAs";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

export const useDownloadWhatsappFile = () => {
  return useMutation({
    mutationKey: ["downloadWhatsappFile"],
    mutationFn: async ({ file }: { file: CaseFileItem }) => {
      return LexZapService.signFileUrl({ fileId: file.id as string });
    },
    onSuccess: async ({ signedUrl }, { file }) => {
      const response = await axios.get(signedUrl, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": file.mimeType,
        },
      });

      const blob = new Blob([response.data], {
        type: file.mimeType!,
      });

      const url = URL.createObjectURL(blob);

      saveFileAs(url, file.name);
    },
  });
};
