import React, { useMemo, useState } from "react";

import { Box, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import { DateTime } from "luxon";
import { SnippetMessageType, SnippetTypes, TicketClosedSnippet, TicketInactiveSnippet } from "@/hooks/lexZap/types";
import { useUpdateTicket } from "@/hooks/lexZap/useUpdateTicket";
import { useCloseTicket } from "@/hooks/lexZap/useCloseTicket";
import { logger } from "@/core/logger";
import { WebToast } from "@/components/core/Toast";
import { useCompanyMember } from "@/hooks/company/useCompanyMember";
import { CloseTicketModal } from "@/pages/WhatsappAssistantPage/components/ChatView/components/ChatList/components/CloseTicketModal";
import { useLexZapCompany } from "@/hooks/lexZap/useLexZapCompany";

interface SnippetMessageProps {
  snippet: SnippetMessageType;
}

export const SnippetMessage = ({ snippet }: SnippetMessageProps) => {
  const [isOpenCloseTicketModal, setIsOpenCloseTicketModal] = useState(false);
  const { mutateAsync: updateTicket } = useUpdateTicket();
  const { mutateAsync: closeTicket, isPending: isClosingTicket } = useCloseTicket();

  const handleSuppressInactiveTicket = async ({ snippet }: { snippet: TicketInactiveSnippet }) => {
    try {
      await updateTicket({
        ticketId: snippet.ticketId,
        applicantPhoneNumber: snippet.applicantPhoneNumber,
        updateFields: { suppressInactiveMessage: true },
      });
      WebToast.success("Atendimento atualizado com sucesso");
    } catch (error) {
      logger.error("Error updating ticket", { error });
      WebToast.error("Erro ao atualizar atendimento");
    }
  };

  const handleCloseTicket = async ({ snippet }: { snippet: TicketInactiveSnippet }) => {
    try {
      await closeTicket({ ticketId: snippet.ticketId, applicantPhoneNumber: snippet.applicantPhoneNumber });
      WebToast.success("Atendimento encerrado com sucesso");
      setIsOpenCloseTicketModal(false);
    } catch (error) {
      setIsOpenCloseTicketModal(false);
      logger.error("Error closing ticket", { error });
      WebToast.error("Erro ao encerrar atendimento");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          borderRadius: "8px",
          backgroundColor: "grey.50",
          width: "90%",
          paddingX: 3,
          paddingY: 2,
          alignItems: "start",
          position: "relative",
          marginBottom: 0,
        }}
      >
        {(() => {
          switch (snippet?.type) {
            case SnippetTypes.TICKET_CLOSED:
              return <TicketClosedMessage snippet={snippet} />;
            case SnippetTypes.TICKET_INACTIVE:
            default:
              return (
                <InactiveTicketMessage
                  handleKeepTicketOpen={() => handleSuppressInactiveTicket({ snippet })}
                  handleCloseTicket={() => handleCloseTicket({ snippet })}
                  isOpenCloseTicketModal={isOpenCloseTicketModal}
                  closeTicketModal={() => setIsOpenCloseTicketModal(false)}
                  openCloseTicketModal={() => setIsOpenCloseTicketModal(true)}
                  isClosingTicket={isClosingTicket}
                />
              );
          }
        })()}
      </Box>
    </Box>
  );
};

const InactiveTicketMessage = ({
  handleKeepTicketOpen,
  handleCloseTicket,
  isOpenCloseTicketModal,
  closeTicketModal,
  openCloseTicketModal,
  isClosingTicket,
}: {
  handleKeepTicketOpen: () => void;
  handleCloseTicket: () => void;
  closeTicketModal: () => void;
  openCloseTicketModal: () => void;
  isOpenCloseTicketModal: boolean;
  isClosingTicket: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 4,
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Typography variant="h6" color="text.primary">
          Essa conversa parece estar inativa há algum tempo
        </Typography>
        <Typography variant="body1" color="text.primary">
          Encerrar o atendimento agora faz com que o próximo contato desse cliente seja atendido pelo nosso
          assistentente de comunicação, e limpa a sua lista de tarefas a fazer.
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(320px, max-content))",
          justifyContent: "space-between",
          alignItems: "end",
          gap: 2,
        }}
      >
        <Typography
          variant="body1"
          color="text.primary"
          sx={{
            width: "100%",
          }}
        >
          Seu cliente não pode ver essa mensagem
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            sx={{
              color: "text.primary",
              borderColor: "text.primary",
              "&:hover": {
                color: "primary.main",
              },
            }}
            onClick={handleKeepTicketOpen}
          >
            Manter aberto
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "common.white",
              borderColor: "secondary.dark",
              bgcolor: "secondary.dark",
            }}
            onClick={openCloseTicketModal}
          >
            Encerrar atendimento
          </Button>
        </Box>
      </Box>

      <CloseTicketModal
        isOpen={isOpenCloseTicketModal}
        onConfirm={handleCloseTicket}
        onCancel={closeTicketModal}
        isLoading={isClosingTicket}
      />
    </Box>
  );
};

const TicketClosedMessage = ({ snippet }: { snippet: TicketClosedSnippet }) => {
  const { data: lexZapCompany } = useLexZapCompany();
  const { data: companyMember } = useCompanyMember({
    memberId: snippet.type === SnippetTypes.TICKET_CLOSED ? snippet.closedBy : "",
  });

  const closedAtDate = DateTime.fromMillis(snippet.closedAt).toFormat("dd/MM/yyyy");
  const closedBy = useMemo(() => {
    if (snippet.closedByAI) {
      return lexZapCompany?.assistantName || "Lia";
    }

    if (snippet.closedBy) {
      return companyMember?.name || snippet.closedBy;
    }

    return "inatividade";
  }, [companyMember, lexZapCompany, snippet.closedBy, snippet.closedByAI]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 4,
      }}
    >
      <Box>
        <Typography variant="h6" color="text.primary">
          Atendimento encerrado
        </Typography>
        <Typography variant="body1" color="text.primary">
          Esse atendimento foi encerrado dia {closedAtDate} por {closedBy}.
          {/* A próxima mensagem do seu
          cliente vai ser atendido pelo assistente Lexter.*/}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "max-content max-content",
        }}
      >
        <Typography
          variant="body1"
          color="text.primary"
          sx={{
            width: "100%",
          }}
        >
          Seu cliente não pode ver essa mensagem
        </Typography>
      </Box>
    </Box>
  );
};
