import React from "react";
import { ContentCopy as ContentCopyIcon, CloseRounded as CloseRoundedIcon } from "@mui/icons-material";
import { Box, Typography, Modal } from "@mui/material";
import { logger } from "@/core/logger";

export const AudioTranscription = ({
  transcription,
  isOpen,
  onClose,
}: {
  transcription: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const handleCopy = async () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(transcription);
        return;
      } catch (err) {
        if (err instanceof Error) {
          logger.error("Falha ao copiar texto.", { error: err });
        }
      }
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minHeight: "90px",
          maxWidth: "480px",
          maxHeight: "80%",
          bgcolor: "common.white",
          borderRadius: "5px",
          outline: "none",

          display: "flex",
        }}
      >
        <Box
          sx={{
            position: "relative",
            padding: 2,

            overflowY: "auto",
            "&::-webkit-scrollbar": { width: "6px" },
            "&::-webkit-scrollbar-track": {
              borderRadius: "10px",
              backgroundColor: "common.white",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              backgroundColor: "text.secondary",
            },
          }}
        >
          <Box
            sx={{
              marginRight: 4,
            }}
          >
            <Typography variant="body" sx={{ whiteSpace: "pre-line" }}>
              {transcription}
            </Typography>
          </Box>
          <Box
            sx={{
              position: "fixed",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              top: 8,
              right: 16,
            }}
          >
            <CloseRoundedIcon onClick={onClose} fontSize="medium" sx={{ cursor: "pointer" }} />
            <ContentCopyIcon onClick={handleCopy} sx={{ cursor: "pointer", color: "common.mediumShade" }} />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
