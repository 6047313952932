import React from "react";
import { Chip } from "@mui/material";
import { APPROVAL_STATUS_LABELS, APPROVAL_STATUS_COLORS } from "@/services/approvalRequests/constants";

interface StatusChipProps {
  status: keyof typeof APPROVAL_STATUS_LABELS;
}

export const StatusChip: React.FC<StatusChipProps> = ({ status }) => (
  <Chip
    label={APPROVAL_STATUS_LABELS[status]}
    size="small"
    sx={{
      minWidth: "90px",
      justifyContent: "center",
      bgcolor: APPROVAL_STATUS_COLORS[status],
      color: "#FFF",
    }}
  />
);
