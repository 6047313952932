import React from "react";
import { PendingView, ApprovedView, EditedView, RequestedChangesView, PendingStatusViewProps } from "./status";

type ApprovalOptionsProps = PendingStatusViewProps;

export const ApprovalOptions: React.FC<ApprovalOptionsProps> = (props) => {
  const { request } = props;

  const statusComponents = {
    PENDING: <PendingView {...props} />,
    APPROVED: <ApprovedView request={request} />,
    EDITED: <EditedView request={request} />,
    REQUESTED_CHANGES: <RequestedChangesView request={request} />,
  };

  return statusComponents[request.status] || null;
};
