import React from "react";

import { Typography } from "@mui/material";
import { ArrowOutwardOutlined, LockOutlined } from "@mui/icons-material";

import { Chip, ChipRow, ChipContent, ChipInRow } from "@/components/Chip/V1";
import { calculateDaysToEndPlan, getPlanDaysLeftDescription } from "@/utils/plans";
import { usePermissions } from "@/hooks/usePermissions";
import { ActiveCasesChip } from "@/components/Chip/V1/ActiveCases";

export const PlanChips = ({
  planName,
  isTestingPlan,
  nextBillingDate,
  usersCount,
  usersQuota,
  handleOpenUsersScreen,
  fullWidth,
  isLoadingPlan,
  isLoadingUsers,
}: {
  planName: string | undefined;
  isTestingPlan: boolean;
  nextBillingDate: string | undefined;
  usersCount: number;
  usersQuota: number;
  handleOpenUsersScreen: () => void;
  fullWidth?: boolean;
  isLoadingPlan: boolean;
  isLoadingUsers: boolean;
}) => {
  const isPlanInactive = calculateDaysToEndPlan(nextBillingDate) < 0 && !isTestingPlan;

  return (
    <ChipRow>
      <PlanChip
        planName={planName}
        showDaysLeft={isTestingPlan}
        nextBillingDate={nextBillingDate}
        fullWidth={fullWidth}
        isPlanInactive={isPlanInactive}
        isLoading={isLoadingPlan}
      />
      {/* This is commented because we don't have the next bill price yet */}
      {/* {!isTestingPlan && nextBillingDate && <NextBillChip nextBillDate={nextBillingDate} price={price} />} */}
      <ActiveCasesChip />
      <UsersChip
        usersCount={usersCount}
        usersQuota={usersQuota}
        onOpenUsersScreen={handleOpenUsersScreen}
        fullWidth={fullWidth}
        isPlanInactive={isPlanInactive}
        isLoading={isLoadingUsers}
      />
    </ChipRow>
  );
};

const PlanChip = ({
  planName,
  showDaysLeft,
  nextBillingDate,
  fullWidth,
  isPlanInactive,
  isLoading,
}: {
  planName?: string;
  showDaysLeft: boolean;
  nextBillingDate: string | undefined;
  fullWidth?: boolean;
  isPlanInactive: boolean;
  isLoading: boolean;
}) => {
  const daysLeft = getPlanDaysLeftDescription(nextBillingDate);

  const daysToEndPlan = calculateDaysToEndPlan(nextBillingDate);
  const color = daysToEndPlan < 0 || isPlanInactive ? "error.main" : "inherit";

  return (
    <Chip title="Plano atual" fullWidth={fullWidth} color={color} isLoading={isLoading}>
      <ChipContent>
        <Typography
          variant="h5"
          fontWeight={400}
          sx={{
            color: color,
          }}
        >
          {planName}
        </Typography>
        {showDaysLeft && (
          <Typography
            sx={{
              color: color,
            }}
            variant="subtitle2"
          >
            {daysLeft}
          </Typography>
        )}
      </ChipContent>
    </Chip>
  );
};

// const NextBillChip = ({ price, nextBillDate }: { price: number; nextBillDate: string }) => {
//   return (
//     <Chip title="Próxima cobrança">
//       <ChipContent>
//         <Stack>
//           <Typography variant="h6" fontWeight={400}>
//             R$ {price},00
//           </Typography>
//           <Typography variant="subtitle2">Vence em: {nextBillDate}</Typography>
//         </Stack>
//       </ChipContent>
//     </Chip>
//   );
// };

const UsersChip = ({
  usersCount,
  usersQuota,
  onOpenUsersScreen,
  fullWidth,
  isPlanInactive,
  isLoading,
}: {
  usersCount: number;
  usersQuota: number;
  onOpenUsersScreen: () => void;
  fullWidth?: boolean;
  isPlanInactive: boolean;
  isLoading: boolean;
}) => {
  const color = isPlanInactive ? "error.main" : "inherit";
  const { hasManageUsersPermission } = usePermissions();

  return (
    <Chip
      title="Qtde. de usuários"
      onClick={hasManageUsersPermission ? onOpenUsersScreen : undefined}
      fullWidth={fullWidth}
      color={color}
      isLoading={isLoading}
    >
      <ChipContent>
        <ChipInRow>
          <Typography variant="h5" sx={{ color: color }}>
            {usersCount} de {usersQuota}
          </Typography>
          {hasManageUsersPermission ? (
            <ArrowOutwardOutlined sx={{ color: color }} />
          ) : (
            <LockOutlined sx={{ color: color }} />
          )}
        </ChipInRow>
      </ChipContent>
    </Chip>
  );
};
