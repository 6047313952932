export const getInitials = (name?: string) => {
  if (!name) return "";

  const trimmedName = name.trim();
  const fullName = trimmedName.split(" ").filter(Boolean);

  if (fullName.length === 0) return "";

  const [firstName, ...rest] = fullName;
  const lastName = rest.length > 0 ? rest[rest.length - 1] : "";

  return `${firstName![0]}${lastName![0] || ""}`;
};
