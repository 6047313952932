import { useCallback, useState } from "react";
import { QuickFilter, DEFAULT_FILTERS, ChatFilters } from "./types";
import { logger } from "@/core/logger";

const STORAGE_KEY_QUICK_FILTERS = "whatsapp-assistant-quick-filters";
const STORAGE_KEY_CHAT_FILTERS = "whatsapp-assistant-chat-filters";

interface StoredFilters {
  quickFilters: QuickFilter[];
  chatFilters: ChatFilters;
}

export const useFilterStorage = () => {
  // Load initial state from localStorage or use defaults
  const loadInitialState = (): StoredFilters => {
    try {
      const storedQuickFilters = localStorage.getItem(STORAGE_KEY_QUICK_FILTERS);
      const storedChatFilters = localStorage.getItem(STORAGE_KEY_CHAT_FILTERS);

      return {
        quickFilters: storedQuickFilters
          ? JSON.parse(storedQuickFilters)
          : DEFAULT_FILTERS.map((f) => ({ ...f, count: 0 })),
        chatFilters: storedChatFilters
          ? JSON.parse(storedChatFilters)
          : {
              responsibles: [],
              excludeResponsibles: [],
              leadStages: [],
              chatModes: [],
              statuses: [],
            },
      };
    } catch (error) {
      logger.error("Error loading filters from localStorage:", { error });
      return {
        quickFilters: DEFAULT_FILTERS.map((f) => ({ ...f, count: 0 })),
        chatFilters: {
          responsibles: [],
          excludeResponsibles: [],
          leadStages: [],
          chatModes: [],
          statuses: [],
        },
      };
    }
  };

  const [storedState, setStoredState] = useState<StoredFilters>(loadInitialState);

  // Save quick filters to localStorage whenever they change
  const saveQuickFilters = useCallback((filters: QuickFilter[]) => {
    try {
      localStorage.setItem(STORAGE_KEY_QUICK_FILTERS, JSON.stringify(filters));
      setStoredState((prev) => ({ ...prev, quickFilters: filters }));
    } catch (error) {
      logger.error("Error saving quick filters to localStorage:", { error });
    }
  }, []);

  // Save chat filters to localStorage whenever they change
  const saveChatFilters = useCallback((filters: ChatFilters) => {
    try {
      localStorage.setItem(STORAGE_KEY_CHAT_FILTERS, JSON.stringify(filters));
      setStoredState((prev) => ({ ...prev, chatFilters: filters }));
    } catch (error) {
      logger.error("Error saving chat filters to localStorage:", { error });
    }
  }, []);

  return {
    storedQuickFilters: storedState.quickFilters,
    storedChatFilters: storedState.chatFilters,
    saveQuickFilters,
    saveChatFilters,
  };
};
