export const formatTimeUnits = (timeDiff: number) => {
  const timeUnits = {
    days: Math.floor(timeDiff / (1000 * 60 * 60 * 24)),
    hours: Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((timeDiff % (1000 * 60)) / 1000),
  };

  const formatTimeUnit = (value: number, unit: string) => {
    if (value === 0) return "";
    return `${value} ${unit}${value > 1 ? "s" : ""} `;
  };

  return Object.entries(timeUnits)
    .map(([unit, value]) => formatTimeUnit(value, unit))
    .filter(Boolean)
    .join("");
};

export const formatDateTime = (date: Date) => {
  return date.toLocaleString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};
