import React from "react";

import { Card } from "../Card";
import { useNotes } from "@/hooks/notes/useNotes";
import { Notes } from "@/components/Notes/notes";

type CaseDetailsNotesProps = {
  caseId?: string;
  ticketId?: string;
  applicantId?: string;
};

export const CaseDetailsNotes = ({ caseId, applicantId, ticketId }: CaseDetailsNotesProps) => {
  const { data: notes, isLoading: isLoadingNotes } = useNotes({ applicantId, caseId, ticketId });

  const isLoading = isLoadingNotes;

  return (
    <Card
      sx={{
        padding: 0,
        gap: 0,
        width: "100%",
      }}
      loadingSize={isLoading ? "200px" : ""}
    >
      <Notes notes={notes} applicantId={applicantId} caseId={caseId} ticketId={ticketId} />
    </Card>
  );
};
