import { useState } from "react";
import { TagDefinition } from "@/services/internalAgents/types";
import { InternalAgentsService } from "@/services/internalAgents";
import { WebToastColoredWithTitle } from "@/components/core/Toast";

export const useTagManagement = () => {
  const [tags, setTags] = useState<TagDefinition[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchTags = async () => {
    setLoading(true);
    try {
      const data = await InternalAgentsService.tags.getAll();
      setTags(data);
    } catch (error) {
      WebToastColoredWithTitle.error(
        "Erro ao buscar tags",
        "Infelizmente não foi possível completar a operação. Tente novamente mais tarde."
      );
    } finally {
      setLoading(false);
    }
  };

  const createTag = async (tag: TagDefinition) => {
    try {
      await InternalAgentsService.tags.create(tag);
      WebToastColoredWithTitle.success("Tag criada com sucesso");
      await fetchTags();
      return true;
    } catch (error) {
      WebToastColoredWithTitle.error(
        "Erro ao criar tag",
        "Infelizmente não foi possível completar a operação. Tente novamente mais tarde."
      );
      return false;
    }
  };

  const updateTag = async (tagId: string, tag: Partial<TagDefinition>) => {
    try {
      await InternalAgentsService.tags.update(tagId, tag);
      WebToastColoredWithTitle.success("Tag atualizada com sucesso");
      await fetchTags();
      return true;
    } catch (error) {
      WebToastColoredWithTitle.error(
        "Erro ao atualizar tag",
        "Infelizmente não foi possível completar a operação. Tente novamente mais tarde."
      );
      return false;
    }
  };

  const deleteTag = async (tagName: string) => {
    try {
      await InternalAgentsService.tags.delete(tagName);
      WebToastColoredWithTitle.success("Tag excluída com sucesso");
      await fetchTags();
      return true;
    } catch (error) {
      WebToastColoredWithTitle.error(
        "Erro ao excluir tag",
        "Infelizmente não foi possível completar a operação. Tente novamente mais tarde."
      );
      return false;
    }
  };

  return {
    tags,
    loading,
    fetchTags,
    createTag,
    updateTag,
    deleteTag,
  };
};
