import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Case, CaseStatus } from "../types";
import { isAxiosError } from "axios";
import { logger } from "@/core/logger";
import { WebToast } from "@/components/core/Toast";
import { getCaseQueryKey } from "../useLexZapCase";

export const useUnarchiveCases = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["unarchiveCases"],
    mutationFn: ({ casesIds }: { casesIds: string[] }) =>
      Promise.all(casesIds.map((caseId) => api.unarchiveCase({ caseId }))),
    onMutate: async ({ casesIds }) => {
      const previousCases = casesIds.map((caseId) => queryClient.getQueryData<Case>(getCaseQueryKey(caseId)));

      casesIds.forEach((caseId) => {
        queryClient.setQueryData<Case>(getCaseQueryKey(caseId), (old) => {
          if (!old) return old;
          return {
            ...old,
            status: CaseStatus.ACTIVE,
            timeline: [
              ...old.timeline,
              {
                status: CaseStatus.ACTIVE,
                createdAt: new Date().toISOString(),
                id: old.timeline.length + 1,
              },
            ],
          };
        });
      });

      return { previousCases };
    },
    onError: (_error, { casesIds }, context) => {
      handleErrorUnarchivingCases(_error);
      context?.previousCases.forEach((previousCase, index) => {
        if (previousCase) {
          queryClient.setQueryData<Case>(getCaseQueryKey(casesIds[index]), previousCase);
        }
      });
    },
  });
};

const handleErrorUnarchivingCases = (error: unknown) => {
  logger.error("handleErrorUnarchivingCases", { error });
  if (isAxiosError(error) && error.response?.status === 402) {
    WebToast.error("Você atingiu o limite máximo de casos");
  } else {
    WebToast.error("Erro ao desarquivar caso");
  }
};
