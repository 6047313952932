import { SxProps, Theme } from "@mui/material";

// Header styles
export const chatHeaderContainerSx: SxProps<Theme> = {
  padding: 2,
  borderBottom: "1px solid",
  borderColor: "common.lightShade",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const headerInfoContainerSx: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: 0.5,
};

export const headerMetadataContainerSx: SxProps<Theme> = {
  display: "flex",
  gap: 1,
  alignItems: "center",
};

// Main layout styles
export const mainContainerSx: SxProps<Theme> = {
  height: "100%",
  display: "grid",
  gridTemplateColumns: "1fr min-content",
  gridTemplateRows: "100%",
};

export const chatContainerSx: SxProps<Theme> = {
  height: "100%",
  width: "100%",
  display: "flex",
  borderRadius: 1,
  overflow: "hidden",
  border: "1px solid",
  borderColor: "common.lightShade",
};

export const chatWrapperSx: SxProps<Theme> = {
  position: "relative",
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
};

// Side panel styles
export const sidePanelCollapseSx: SxProps<Theme> = {
  position: "relative",
};

export const sidePanelContainerSx: SxProps<Theme> = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 2,
};

export const sideBoxSx: SxProps<Theme> = {
  flex: 1,
  borderRadius: 1,
  border: "1px solid",
  borderColor: "common.lightShade",
  overflow: "hidden",
  marginLeft: 2,
  bgcolor: "grey.50",
};
