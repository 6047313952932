import { InfiniteData, QueryClient } from "@tanstack/react-query";
import { CaseMessage } from "@/hooks/lexZap/types";
import { getMessagesQueryKey } from "../..";
import { updateLexZapMessage } from "../updateLexZapMessage";

export const upsertLexZapMessage = ({
  queryClient,
  applicantPhoneNumber,
  where,
  upsertFn,
}: {
  queryClient: QueryClient;
  applicantPhoneNumber: string;
  where: (message: CaseMessage) => boolean;
  upsertFn: (message?: CaseMessage) => CaseMessage;
}) => {
  const queryKey = getMessagesQueryKey({ applicantPhoneNumber });

  let messageUpdated = false;

  updateLexZapMessage({
    queryClient,
    applicantPhoneNumber,
    where,
    updateFn: (msg) => {
      messageUpdated = true;
      return upsertFn(msg);
    },
  });

  if (!messageUpdated) {
    queryClient.setQueryData<InfiniteData<{ messages: CaseMessage[]; pageToken?: string }>>(queryKey, (oldData) => {
      if (!oldData || !oldData.pages.length) {
        return {
          pages: [
            {
              messages: [upsertFn()],
              pageToken: undefined,
            },
          ],
          pageParams: [undefined],
        };
      }

      const firstPage = oldData.pages[0]!;
      const newMessage = upsertFn();
      const updatedPages = [
        {
          ...firstPage,
          messages: [...firstPage.messages, newMessage],
        },
        ...oldData.pages.slice(1),
      ];

      return { ...oldData, pages: updatedPages };
    });
  }
};
