import { LoggedInUserData } from "@/contexts/AuthContext";
import { useCompanyTeam } from "@/hooks/company/useCompanyTeam";
import { Box, Button, ButtonBase, Card, Typography } from "@mui/material";
import React, { useState } from "react";
import { DescriptionOutlined as DescriptionOutlinedIcon } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { CompanyMember } from "@/hooks/company/types";

export type HistoryItemPayload = {
  text?: string;
  client?: string;
  case_breakdown?: string | { text: string };
  theses?: string;
  evidence_text?: string;
  timeliness_text?: string;
  preliminary_objections?: string;
  requests?: string;
  contract_micro_type?: string;
  party_1?: string;
  party_2?: string;
  legal_piece_macro_type?: string;
  legal_piece_micro_type?: string;
  name?: string;
  status?: string;
};

type HistoryItemProps = {
  item: {
    id: string;
    entityType: string;
    entityId: string;
    userId: string;
    actionType: string;
    actionDate: string;
    payload?: {
      repliedMessage?: {
        threadId: string;
        text: string;
        payload?: HistoryItemPayload;
      };
      id?: string;
      name?: string;
      status?: string;
      applicantId?: string;
    };
  };
  currentUser: LoggedInUserData | undefined;
  onOpenThread: (threadId: string) => void;
  onOpenCase: (caseId: string) => void;
};

const formatActionName = (text: string) => {
  return text.replace(/^Criar\s+/i, "");
};

const formatValue = (value: string | { text: string }) => {
  let text = typeof value === "string" ? value : value.text;

  text = text.replace(/^\n+/, "");

  const specialCases: Record<string, string> = {
    peca_inaugural: "Peça inaugural",
    contestacao: "Contestação",
    civel: "Cível",
  };

  if (text in specialCases) {
    return specialCases[text];
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
};

const titleMapping: Record<string, string> = {
  area: "Área jurídica",
  custom_area: "Área jurídica",
  client: "Nome do cliente",
  case_breakdown: "Descrição dos fatos",
  theses: "Argumentos jurídicos",
  evidence_text: "Provas descritas",
  timeliness_text: "Tempestividade e Cabimento",
  preliminary_objections: "Preliminares",
  requests: "Pedidos",
  contract_micro_type: "Tipo de contrato",
  party_1: "Parte contratante",
  party_2: "Parte contratada",
  legal_piece_macro_type: "Momento processual",
  legal_piece_micro_type: "Tipo de peça",
  name: "Nome",
  evidence_documents: "Provas",
};

const ignoredFields = new Set(["text", "id", "threadId", "reference_piece"]);

const getFormattedTitle = (key: string) => {
  return (
    titleMapping[key] ||
    key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  );
};

export const HistoryItem = ({ item, currentUser, onOpenThread, onOpenCase }: HistoryItemProps) => {
  const { data: companyTeam } = useCompanyTeam();
  const user = companyTeam?.find((user) => user.userId === item.userId);
  const formattedDate = new Date(item.actionDate).toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  if (item.entityType === "CASE") {
    return (
      <HistoryItemCase
        user={user}
        formattedDate={formattedDate}
        clientName={item.payload?.name ?? ""}
        item={item}
        onOpenCase={onOpenCase}
      />
    );
  }

  if (item.entityType === "SKILL") {
    const rawActionName = item.payload?.repliedMessage?.payload?.text || item.payload?.repliedMessage?.text || "Ação";
    const actionName = formatActionName(rawActionName);
    const clientName = item.payload?.repliedMessage?.payload?.client;
    const threadId = item.payload?.repliedMessage?.threadId;

    return (
      <HistoryItemSkill
        user={user}
        formattedDate={formattedDate}
        actionName={actionName}
        clientName={clientName}
        threadId={threadId}
        item={item}
        currentUser={currentUser}
        onOpenThread={onOpenThread}
      />
    );
  }

  return null;
};

const BaseStructure = ({
  children,
  user,
  formattedDate,
}: {
  children: React.ReactNode;
  user: CompanyMember | undefined;
  formattedDate: string;
}) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1,
        border: "1px solid #E0E0E0",
        borderRadius: 1,
        p: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        {user && (
          <Typography variant="body1" sx={{ textAlign: "left", width: "100%", color: "grey.500" }}>
            {user.name}
          </Typography>
        )}
        <Typography variant="body1" sx={{ textAlign: "right", width: "100%" }}>
          {formattedDate}
        </Typography>
      </Box>
      {children}
    </Card>
  );
};

const HistoryItemCase = ({
  user,
  formattedDate,
  clientName,
  item,
  onOpenCase,
}: {
  user: CompanyMember | undefined;
  formattedDate: string;
  clientName: string;
  item: HistoryItemProps["item"];
  onOpenCase: (caseId: string) => void;
}) => {
  return (
    <BaseStructure user={user} formattedDate={formattedDate}>
      <Box sx={{ textAlign: "left", width: "100%", display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="body1" component="span">
          Criou um <b>novo caso</b>
          {clientName && (
            <Typography variant="body1" component="span" sx={{ ml: 0.5 }}>
              chamado <b>{clientName}</b>
            </Typography>
          )}
        </Typography>
      </Box>

      {item.payload?.id && (
        <Box
          sx={{
            p: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid #E0E0E0",
            borderRadius: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
            <FolderOutlinedIcon />
            <Typography variant="title" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {clientName}
            </Typography>
          </Box>
          <Button
            sx={{ color: "black", textDecoration: "underline" }}
            onClick={() => onOpenCase(item.payload?.id ?? "")}
          >
            Abrir caso
          </Button>
        </Box>
      )}
    </BaseStructure>
  );
};

const HistoryItemSkill = ({
  user,
  formattedDate,
  actionName,
  clientName,
  threadId,
  item,
  currentUser,
  onOpenThread,
}: {
  user: CompanyMember | undefined;
  formattedDate: string;
  actionName: string;
  clientName?: string;
  threadId?: string;
  item: HistoryItemProps["item"];
  currentUser: LoggedInUserData | undefined;
  onOpenThread: (threadId: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <BaseStructure user={user} formattedDate={formattedDate}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ textAlign: "left", display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="body1" component="span">
              Criou {actionName.toLowerCase().startsWith("outro") ? "um" : "uma"} <b>{actionName}</b>
              {clientName && (
                <Typography variant="body1" component="span" sx={{ ml: 0.5 }}>
                  para o cliente <b>{clientName}</b>
                </Typography>
              )}
            </Typography>
          </Box>
          <ButtonBase onClick={() => setIsOpen(!isOpen)} sx={{ display: "flex", justifyContent: "center" }}>
            <KeyboardArrowDownIcon
              sx={{
                transform: `rotate(${isOpen ? 180 : 0}deg)`,
                transition: "transform 0.3s ease",
              }}
            />
          </ButtonBase>
        </Box>

        {isOpen && item.payload?.repliedMessage?.payload && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
              border: "1px solid #E0E0E0",
              borderRadius: 1,
              p: 2,
              transition: "all 0.3s ease",
            }}
          >
            {Object.entries(item.payload.repliedMessage.payload).map(([key, value]) => {
              if (!value || ignoredFields.has(key)) return null;
              if (key === "evidence_documents" && Array.isArray(value) && value.length === 0) return null;
              return (
                <Box key={key}>
                  {value && (
                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1, fontWeight: 700 }}>
                      {getFormattedTitle(key)}:
                    </Typography>
                  )}
                  {key === "evidence_documents" && Array.isArray(value) ? (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      {value.map((document, index) => (
                        <Typography variant="body" key={index} sx={{ lineHeight: "22px" }}>
                          <span style={{ marginRight: "8px" }}>•</span> {document.file.name}
                        </Typography>
                      ))}
                    </Box>
                  ) : (
                    <Typography variant="body" sx={{ whiteSpace: "pre-wrap" }}>
                      {formatValue(value)}
                    </Typography>
                  )}
                </Box>
              );
            })}
          </Box>
        )}

        <Box
          sx={{
            p: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid #E0E0E0",
            borderRadius: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
            <DescriptionOutlinedIcon />
            <Typography variant="title" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {actionName}
            </Typography>
          </Box>
          <Button
            sx={{ color: "black", textDecoration: "underline" }}
            onClick={() => threadId && onOpenThread(threadId)}
            disabled={currentUser?.userId !== item.userId}
          >
            Abrir
          </Button>
        </Box>
      </Box>
    </BaseStructure>
  );
};
