import { authenticatedCopilotServer } from "@/core/api";
import { logger } from "@/core/logger";
import { Notification } from "./types";

export const NotificationService = {
  getNotifications: async () => {
    try {
      const response = await authenticatedCopilotServer.get<Notification[]>(`/notifications`);
      return response.data;
    } catch (e) {
      logger.error("getNotifications", { error: e });
      throw e;
    }
  },

  getNotificationById: async (id: number) => {
    try {
      const response = await authenticatedCopilotServer.get<Notification>(`/notifications/${id}`);
      return response.data;
    } catch (e) {
      logger.error("getNotificationById", { error: e });
      throw e;
    }
  },

  setNotificationOpen: async (id: number) => {
    try {
      const response = await authenticatedCopilotServer.put(`/notifications/visualize/${id}`);
      return response.data;
    } catch (e) {
      logger.error("setNotificationOpen", { error: e });
      throw e;
    }
  },
};
