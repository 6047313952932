export type ApprovalStatus = "PENDING" | "APPROVED" | "EDITED" | "REQUESTED_CHANGES";

export type ApprovalRequestType =
  | "SEND_WHATSAPP_MESSAGE"
  | "SEND_EMAIL"
  | "SEND_SMS"
  | "SEND_PUSH_NOTIFICATION"
  | "SEND_TELEGRAM_MESSAGE"
  | "SEND_CALENDAR_INVITE"
  | "CREATE_CONTRACT"
  | "SUBMIT_DOCUSIGN_CONTRACT"
  | "UPDATE_CONTRACT"
  | "SCHEDULE_MEETING";

export interface ProcessSkillInput {
  skill_id: string;
  payload: Record<string, unknown>;
  callbackUrl?: string;
  execution_id: string;
  input_type: string;
  header_args: {
    user_id?: string;
    company_id?: string;
    session_id?: string;
    request_id?: string;
    ticket_id?: string;
    platform?: string;
    product?: string;
    device?: string;
    source?: string;
    "x-assistant-name"?: string;
    "x-company-name"?: string;
    [key: string]: string | undefined;
  };
  generated_output?: ProcessSkillOutput;
  change_instructions?: string;
}

export interface ProcessSkillOutput {
  execution_id: string;
  success: boolean;
  billable: boolean;
  payload?: Record<string, unknown>;
  error?: string;
}

export interface ApprovalRequest {
  // Core identifiers
  id: string; // Unique identifier
  company_id: string; // Company identifier
  exec_request_id: string; // Execution request ID

  // Request details
  applicant_phone_number: string; // Phone number of requester
  subagent_name: string; // Name of subagent that generated request
  status: ApprovalStatus; // Request status
  request_type: ApprovalRequestType; // Type of approval request

  // Initial request data
  input_: ProcessSkillInput; // Original input that generated request
  output: ProcessSkillOutput; // Original output to be approved
  callback_url: string; // URL to call after approval

  // Post-review data
  output_edited?: ProcessSkillOutput; // Edited output if status=EDITED
  change_instructions?: string; // Instructions if status=REQUESTED_CHANGES

  // Timestamps and review info
  created_at: Date; // Creation timestamp
  reviewed_at?: Date; // Review timestamp when status changes
  reviewer_id?: string; // ID of reviewer who changed status
}

export interface ApprovalFilters {
  status: ApprovalStatus[];
  dateRange: [Date | null, Date | null];
  subagents: string[];
  searchText: string;
  reviewers: string[];
}

export interface ApprovalRequestPutInput {
  status: "APPROVED" | "EDITED" | "REQUESTED_CHANGES";
  output_edited?: ProcessSkillOutput; // Required if status is EDITED
  change_instructions?: string; // Required if status is REQUESTED_CHANGES
}

// Type guard to validate required fields based on status
export function validateApprovalRequestPutInput(input: ApprovalRequestPutInput): void {
  if (input.status === "EDITED" && !input.output_edited) {
    throw new Error("output_edited is required when status is EDITED");
  }
  if (input.status === "REQUESTED_CHANGES" && !input.change_instructions) {
    throw new Error("change_instructions is required when status is REQUESTED_CHANGES");
  }
}
