import { Permission } from "@/contexts/AuthContext";

export type PermissionGroup = {
  id: string;
  title: string;
  fullAccessTitle?: string;
  description: string;
  permissions: {
    id: Permission;
    title: string;
    description: string;
  }[];
};

export const PERMISSION_GROUPS: PermissionGroup[] = [
  {
    id: "administrative",
    title: "Administrativo",
    fullAccessTitle: "Acesso total",
    description: "Permite o usuário a ter acesso a todas as informações e funcionalidades da plataforma.",
    permissions: [
      {
        id: Permission.MANAGE_USERS,
        title: "Gerenciar usuários",
        description: "Permite o usuário adicionar e remover usuários.",
      },
      {
        id: Permission.MANAGE_PLANS,
        title: "Gerenciar planos",
        description: "Permite o usuário gerenciar informações relacionadas ao plano da conta e pagamentos.",
      },
    ],
  },
  {
    id: "customer_service",
    title: "Atendimento",
    description:
      "Permite os usuários a gerenciarem e interagirem com informações e mensagens com clientes do escritório.",
    permissions: [
      {
        id: Permission.CUSTOMER_SERVICE_FULL_ACCESS,
        title: "Acesso ao atendimento",
        description: "Permite o usuário a ter acesso às informações e funcionalidades do produto de atendimento.",
      },
    ],
  },
  {
    id: "legal_tasks",
    title: "Tarefas e acompanhamentos jurídicos",
    description:
      "Permite os usuários a gerenciarem casos, realizarem tarefas jurídicas e se comunicar com clientes do escritório.",
    permissions: [
      {
        id: Permission.LEGAL_TASKS_MONITORING_FULL_ACCESS,
        title: "Acesso às tarefas",
        description: "Permite o usuário a ter acesso às informações e funcionalidades do produto de atendimento.",
      },
    ],
  },
];
