import { Button } from "@/components/Button";
import { WebToast } from "@/components/core/Toast";
import { Notifications } from "@/components/Notifications";
import { EXTERNAL_URLS, ROUTE_PATHS } from "@/routes/routePaths";
import { LegalProceedingsTable } from "@/components/LegalProceedingsTable";
import { LegalProceedingType } from "@/services/legalProceeding/types";
import { theme } from "@/theme";
import { Close, HelpOutline } from "@mui/icons-material";
import { DialogContent, Typography, Box, AppBar, IconButton, Toolbar, DialogActions, Dialog } from "@mui/material";
import React from "react";
import { LegalProceedingSearchResultDialog } from "../LegalProceedingSearchResultDialog";
import { useLexZapCases } from "@/hooks/lexZap/useLexZapCases";
import { useLegalProceedingToCaseMutation } from "@/hooks/legalProceedings/useLegalProceedingToCaseMutation";
import { useNavigate } from "react-router-dom";

export const LegalProceedingImportingByOABModal = () => {
  const [selectedLegalProceedings, setSelectedLegalProceedings] = React.useState<LegalProceedingType[]>([]);
  const [openResultDialog, setOpenResultDialog] = React.useState(false);
  const { mutateAsync: legalProceedingToCase, isPending: isTransforming } = useLegalProceedingToCaseMutation();
  const [isSuccess, setIsSuccess] = React.useState(false);
  const { refetch: refetchCases } = useLexZapCases({ pageSize: 100, status: ["ACTIVE"] });
  const navigate = useNavigate();

  const handleImportLegalProceedingsToCase = async () => {
    await legalProceedingToCase({
      cnjs: selectedLegalProceedings.map((row) => row.cnj),
    });

    WebToast.success("Processos importados com sucesso");
    setSelectedLegalProceedings([]);
    void refetchCases();
    navigate(ROUTE_PATHS.CASES);
  };

  const handleSuccess = () => {
    setIsSuccess(true);
    setOpenResultDialog(true);
  };

  const handleClose = () => {
    setSelectedLegalProceedings([]);
    navigate(-1);
  };

  const onCloseDialog = () => {
    setOpenResultDialog(false);
  };

  return (
    <Dialog open={true} fullScreen onClose={handleClose}>
      <LegalProceedingSearchResultDialog open={openResultDialog} onClose={onCloseDialog} success={isSuccess} />
      <Header onClose={handleClose} />
      <DialogContent sx={{ bgcolor: "common.dorian", display: "flex", flexDirection: "column", gap: 4 }}>
        <Box
          sx={{
            px: 2,
            py: 2,
            bgcolor: "common.white",
            borderRadius: 2,
            border: "1px solid",
            borderColor: "common.lightShade",
            [theme.breakpoints.up("md")]: {
              mx: 10,
            },
          }}
        >
          <LegalProceedingsTable
            onSelectedLegalProceedingsChange={setSelectedLegalProceedings}
            onSuccess={handleSuccess}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ boxShadow: "0px -1px 3px rgba(0, 0, 0, 0.12)" }}>
        <Footer
          disabled={selectedLegalProceedings.length === 0}
          loading={isTransforming}
          onClose={handleClose}
          onSubmit={handleImportLegalProceedingsToCase}
        />
      </DialogActions>
    </Dialog>
  );
};

const Header = ({ onClose }: { onClose: () => void }) => {
  return (
    <AppBar position="relative" color="transparent">
      <Toolbar>
        <IconButton edge="start" onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant="subtitle1" component="div">
          Importar processos por OAB
        </Typography>
        <Notifications />
      </Toolbar>
    </AppBar>
  );
};

const Footer = ({
  disabled,
  onClose,
  onSubmit,
  loading,
}: {
  disabled: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 1, width: "100%" }}>
      <Button
        onClick={() => window.open(EXTERNAL_URLS.HELP_CENTER_URL, "_blank")}
        startIcon={HelpOutline}
        sx={{
          color: "common.black",
          "&:hover": {
            bgcolor: "common.dorian",
          },
        }}
      >
        Ajuda
      </Button>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Button size="small" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          size="small"
          variant="contained"
          disabled={disabled}
          onClick={onSubmit}
          isLoading={loading}
          loaderStyle={{ width: "200px" }}
        >
          Importar
        </Button>
      </Box>
    </Box>
  );
};
