// React imports
import React, { useState, useEffect, useMemo } from "react";

// Third-party libraries
import { DateTime } from "luxon";
import * as MUI from "@mui/material";
import * as MUIIcons from "@mui/icons-material";

// Core utilities
import { Phone } from "@/core/Phone";
import { logger } from "@/core/logger";
import { WebToastColoredWithTitle } from "@/components/core/Toast";

// Hooks
import { useCompanyTeam } from "@/hooks/company/useCompanyTeam";
import { useChats } from "@/pages/WhatsappAssistantPageV2/hooks/useChats";

// Types
import { ChatMode, ChatStatus } from "@/services/whatsappServer/types/chat";
import { LexZapChat } from "@/hooks/lexZap/types";
import * as TableViewTypes from "@/pages/WhatsappAssistantPageV2/components/TableView/types";
import { DialogModal } from "@/components/DialogModal";
import * as SyncTypes from "@/hooks/tempSyncLeadStage/types";

interface ChatDetailsProps {
  chat: LexZapChat;
  onClose: () => void;
}

export const ChatDetails = ({ chat, onClose }: ChatDetailsProps) => {
  const { data: companyUsers } = useCompanyTeam();
  const { updateChat } = useChats();
  const [isUpdating, setIsUpdating] = useState(false);

  // Local state for editable fields
  const [assignedUserIds, setAssignedUserIds] = useState<string[]>(chat.assignedUserIds || []);
  const [leadStage, setLeadStage] = useState<string>(chat.leadStage || "");
  const [chatMode, setChatMode] = useState<ChatMode>(chat.chatMode || ChatMode.AI);
  const [caseNotes, setCaseNotes] = useState<string>(chat.caseNotes || "");
  const [actionItems, setActionItems] = useState<string>(chat.actionItems || "");

  // Add these states for the abandon dialog
  const [isAbandonDialogOpen, setIsAbandonDialogOpen] = useState(false);
  const [abandonReason, setAbandonReason] = useState<SyncTypes.ArchiveReason | null>(null);
  const [abandonDetails, setAbandonDetails] = useState("");

  // Sync local states when chat changes
  useEffect(() => {
    setAssignedUserIds(chat.assignedUserIds || []);
    setLeadStage(chat.leadStage || "");
    setChatMode(chat.chatMode || ChatMode.AI);
    setCaseNotes(chat.caseNotes || "");
    setActionItems(chat.actionItems || "");
  }, [chat]);

  // Check if any fields have changed
  const hasChanges = useMemo(() => {
    const originalAssignedUserIds = chat.assignedUserIds || [];
    const originalLeadStage = chat.leadStage || "";
    const originalChatMode = chat.chatMode || ChatMode.AI;
    const originalCaseNotes = chat.caseNotes || "";
    const originalActionItems = chat.actionItems || "";

    return (
      JSON.stringify(assignedUserIds) !== JSON.stringify(originalAssignedUserIds) ||
      leadStage !== originalLeadStage ||
      chatMode !== originalChatMode ||
      caseNotes !== originalCaseNotes ||
      actionItems !== originalActionItems
    );
  }, [assignedUserIds, leadStage, chatMode, caseNotes, actionItems, chat]);

  const handleSaveChanges = async () => {
    if (!hasChanges) {
      WebToastColoredWithTitle.error("Nenhuma alteração", "Nenhuma alteração detectada");
      return;
    }

    setIsUpdating(true);
    try {
      // Create an object with only the changed fields
      const changedFields: {
        assignedUserIds?: string[];
        leadStage?: string;
        chatMode?: ChatMode;
        caseNotes?: string;
        actionItems?: string;
      } = {};

      // Compare current values with original values
      if (JSON.stringify(assignedUserIds) !== JSON.stringify(chat.assignedUserIds || [])) {
        changedFields.assignedUserIds = assignedUserIds;
      }

      if (leadStage !== (chat.leadStage || "")) {
        changedFields.leadStage = leadStage;
      }

      if (chatMode !== (chat.chatMode || ChatMode.AI)) {
        changedFields.chatMode = chatMode;
      }

      if (caseNotes !== (chat.caseNotes || "")) {
        changedFields.caseNotes = caseNotes;
      }

      if (actionItems !== (chat.actionItems || "")) {
        changedFields.actionItems = actionItems;
      }

      // Add abandonment details if the lead stage is "Abandonado"
      const abandonmentDetails =
        leadStage === TableViewTypes.LeadStage.ABANDONADO && abandonReason
          ? { archiveType: abandonReason, archiveDetails: abandonDetails }
          : undefined;

      await updateChat(chat.endClientNumber, changedFields, abandonmentDetails);
    } catch (error) {
      logger.error("Erro ao atualizar chat", { error });
    } finally {
      setIsUpdating(false);
    }
  };

  const formatPhoneDisplay = (phoneNumber: string) => {
    const phone = Phone.restore(phoneNumber);
    if (phone.isFailure) {
      return phoneNumber;
    }
    return phone.getValue().toFormatSpaced();
  };

  // Component for rendering the header section
  const renderHeader = () => (
    <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 1, px: 3, pt: 2, pb: 1 }}>
      <MUI.Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <MUI.Typography variant="h6" sx={{ fontWeight: "400", color: "text.secondary" }}>
          Detalhes do Chat
        </MUI.Typography>

        <MUI.IconButton
          onClick={onClose}
          sx={{
            m: -1,
          }}
        >
          <MUIIcons.Close sx={{ width: "24px", height: "24px", color: "text.secondary" }} />
        </MUI.IconButton>
      </MUI.Box>

      <MUI.Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <MUI.Typography variant="body1" sx={{ color: "text.primary", fontWeight: 600 }}>
          {chat.applicantName || formatPhoneDisplay(chat.endClientNumber)}
        </MUI.Typography>

        {chat.status && (
          <MUI.Chip
            label={chat.status === ChatStatus.ACTIVE ? "Ativo" : "Arquivado"}
            color={chat.status === ChatStatus.ACTIVE ? "success" : "default"}
            size="small"
          />
        )}
      </MUI.Box>
    </MUI.Box>
  );

  // Modify the leadStage setter to check for "Abandonado"
  const handleLeadStageChange = (value: string) => {
    if (value === TableViewTypes.LeadStage.ABANDONADO) {
      setIsAbandonDialogOpen(true);
    } else {
      setLeadStage(value);
    }
  };

  // Handle confirmation of abandon reason
  const handleConfirmAbandon = () => {
    setLeadStage(TableViewTypes.LeadStage.ABANDONADO);

    // Add the reason to case notes
    const reasonLabel = abandonReason ? SyncTypes.archiveLabels[abandonReason] : "Não especificado";
    const details = abandonReason === SyncTypes.ArchiveReason.OTHER && abandonDetails ? abandonDetails : "";

    let abandonNote = "[CASO ABANDONADO] Motivo: " + reasonLabel;
    if (details) {
      abandonNote += " - " + details;
    }

    setCaseNotes((prevNotes) => {
      const dateTime = DateTime.now().toFormat("dd/MM/yyyy HH:mm");
      const datePrefix = "[" + dateTime + "] ";
      return prevNotes ? prevNotes + "\n\n" + datePrefix + abandonNote : datePrefix + abandonNote;
    });

    setAbandonReason(abandonReason);
    setAbandonDetails(details);

    setIsAbandonDialogOpen(false);
  };

  // Component for rendering lead stage section
  const renderLeadStageSection = () => (
    <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <MUI.Typography variant="body1" sx={{ color: "text.secondary", fontWeight: 700 }}>
        ETAPA DO FUNIL
      </MUI.Typography>
      <MUI.Select
        value={leadStage}
        onChange={(e) => handleLeadStageChange(e.target.value)}
        sx={{
          width: "100%",
          ...(leadStage !== (chat.leadStage || "") && {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
            },
          }),
        }}
        size="small"
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return (
              <MUI.Typography variant="body2" sx={{ color: "text.secondary" }}>
                Selecione uma etapa
              </MUI.Typography>
            );
          }

          const backgroundColor = TableViewTypes.leadStageColors[selected as TableViewTypes.LeadStage];
          const isDarkBackground = TableViewTypes.darkBackgroundStages.includes(selected as TableViewTypes.LeadStage);

          return (
            <MUI.Chip
              label={selected}
              size="small"
              sx={{
                backgroundColor,
                color: isDarkBackground ? "#ffffff" : "#000000",
                fontWeight: 500,
              }}
            />
          );
        }}
      >
        {Object.values(TableViewTypes.LeadStage).map((stage) => (
          <MUI.MenuItem key={stage} value={stage}>
            {stage}
          </MUI.MenuItem>
        ))}
      </MUI.Select>

      {/* Display abandon reason when lead stage is Abandonado */}
      {leadStage === TableViewTypes.LeadStage.ABANDONADO && (
        <MUI.Box sx={{ mt: 1, display: "flex", flexDirection: "column", gap: 0.5 }}>
          <MUI.Typography variant="body2" sx={{ color: "text.secondary" }}>
            Motivo do abandono: {abandonReason ? SyncTypes.archiveLabels[abandonReason] : "Não especificado"}
          </MUI.Typography>
          {abandonReason === SyncTypes.ArchiveReason.OTHER && abandonDetails && (
            <MUI.Typography variant="body2" sx={{ color: "text.secondary" }}>
              Detalhes: {abandonDetails}
            </MUI.Typography>
          )}
        </MUI.Box>
      )}
    </MUI.Box>
  );

  // Component for rendering assigned users section
  const renderAssignedUsersSection = () => (
    <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <MUI.Typography variant="body1" sx={{ color: "text.secondary", fontWeight: 700 }}>
        RESPONSÁVEIS
      </MUI.Typography>
      <MUI.Select
        multiple
        value={assignedUserIds}
        onChange={(e) => setAssignedUserIds(e.target.value as string[])}
        sx={{
          width: "100%",
          ...(JSON.stringify(assignedUserIds) !== JSON.stringify(chat.assignedUserIds || []) && {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
            },
          }),
        }}
        size="small"
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <MUI.Typography variant="body2" sx={{ color: "text.secondary" }}>
                Nenhum responsável
              </MUI.Typography>
            );
          }

          return (
            <MUI.Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {(selected as string[]).map((userId) => {
                const user = companyUsers?.find((u) => u.userId === userId);
                return <MUI.Chip key={userId} label={user?.name || userId} size="small" sx={{ maxWidth: "100%" }} />;
              })}
            </MUI.Box>
          );
        }}
      >
        {companyUsers
          ?.filter((user) => user.isActive)
          .map((user) => (
            <MUI.MenuItem key={user.userId} value={user.userId}>
              {user.name}
            </MUI.MenuItem>
          ))}
      </MUI.Select>
    </MUI.Box>
  );

  // Component for rendering chat mode section
  const renderChatModeSection = () => (
    <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <MUI.Typography variant="body1" sx={{ color: "text.secondary", fontWeight: 700 }}>
        MODO DE OPERAÇÃO
      </MUI.Typography>
      <MUI.Select
        value={chatMode}
        onChange={(e) => setChatMode(e.target.value as ChatMode)}
        sx={{
          width: "100%",
          ...(chatMode !== (chat.chatMode || ChatMode.AI) && {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
            },
          }),
        }}
        size="small"
      >
        {Object.values(ChatMode).map((mode) => {
          let displayText = "";
          let sx = {};

          switch (mode) {
            case ChatMode.AI:
              displayText = "IA";
              sx = { backgroundColor: "success.main", color: "common.white" };
              break;
            case ChatMode.MANUAL:
              displayText = "Manual";
              sx = { backgroundColor: "error.main", color: "common.white" };
              break;
            case ChatMode.AI_PAUSED_FOR_CURRENT_TICKET:
              displayText = "IA Pausada";
              sx = { backgroundColor: "warning.main", color: "common.white" };
              break;
            default:
              displayText = mode;
              sx = { backgroundColor: "grey.100", color: "grey.900" };
          }

          return (
            <MUI.MenuItem key={mode} value={mode}>
              <MUI.Chip label={displayText} sx={sx} size="small" />
            </MUI.MenuItem>
          );
        })}
      </MUI.Select>
    </MUI.Box>
  );

  // Component for rendering case notes section
  const renderCaseNotesSection = () => (
    <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <MUI.Typography variant="body1" sx={{ color: "text.secondary", fontWeight: 700 }}>
        ANOTAÇÕES DO CASO
      </MUI.Typography>
      <MUI.TextField
        multiline
        rows={4}
        value={caseNotes}
        onChange={(e) => setCaseNotes(e.target.value)}
        placeholder="Adicione anotações sobre este caso..."
        sx={{
          width: "100%",
          ...(caseNotes !== (chat.caseNotes || "") && {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
            },
          }),
        }}
      />
    </MUI.Box>
  );

  // Component for rendering action items section
  const renderActionItemsSection = () => (
    <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <MUI.Typography variant="body1" sx={{ color: "text.secondary", fontWeight: 700 }}>
        ITENS DE AÇÃO
      </MUI.Typography>
      <MUI.TextField
        multiline
        rows={4}
        value={actionItems}
        onChange={(e) => setActionItems(e.target.value)}
        placeholder="Liste os próximos passos a serem tomados..."
        sx={{
          width: "100%",
          ...(actionItems !== (chat.actionItems || "") && {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
            },
          }),
        }}
      />
    </MUI.Box>
  );

  // Component for rendering additional info section
  const renderAdditionalInfoSection = () => (
    <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <MUI.Typography variant="body1" sx={{ color: "text.secondary", fontWeight: 700 }}>
        INFORMAÇÕES ADICIONAIS
      </MUI.Typography>
      <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        <MUI.Typography variant="body2" sx={{ color: "text.secondary" }}>
          Criado em: {DateTime.fromMillis(chat.createdAt).toFormat("dd/MM/yyyy HH:mm")}
        </MUI.Typography>
        {chat.lastMessageDate && (
          <MUI.Typography variant="body2" sx={{ color: "text.secondary" }}>
            Última mensagem: {DateTime.fromMillis(chat.lastMessageDate).toFormat("dd/MM/yyyy HH:mm")}
          </MUI.Typography>
        )}
      </MUI.Box>
    </MUI.Box>
  );

  // Component for rendering the footer with save button
  const renderFooter = () => (
    <MUI.Box
      sx={{
        p: 2,
        borderTop: "1px solid",
        borderColor: "divider",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <MUI.Button
        variant="contained"
        color="primary"
        onClick={handleSaveChanges}
        disabled={isUpdating || !hasChanges}
        sx={{ minWidth: "180px" }}
      >
        {isUpdating ? "Salvando..." : "Salvar alterações"}
      </MUI.Button>
    </MUI.Box>
  );

  return (
    <MUI.Box
      sx={{
        backgroundColor: "common.white",
        height: "100%",
        display: "grid",
        gridTemplateRows: "min-content minmax(0, 1fr) min-content",
      }}
    >
      {renderHeader()}

      <MUI.Box sx={{ overflow: "auto", p: 3 }}>
        <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          {renderLeadStageSection()}
          {renderAssignedUsersSection()}
          {renderChatModeSection()}
          {renderCaseNotesSection()}
          {renderActionItemsSection()}
          {renderAdditionalInfoSection()}
        </MUI.Box>
      </MUI.Box>

      {renderFooter()}

      {/* Add the abandon dialog */}
      <DialogModal
        open={isAbandonDialogOpen}
        onClose={() => setIsAbandonDialogOpen(false)}
        title="Motivo do abandono"
        description={
          <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%", mt: 2 }}>
            <MUI.Autocomplete
              renderInput={(params) => (
                <MUI.TextField
                  {...params}
                  label="Justificativa"
                  placeholder="Selecione a justificativa aqui"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "common.lightShade",
                    },
                  }}
                />
              )}
              options={SyncTypes.archiveOptions}
              value={SyncTypes.archiveOptions.find((option) => option.value === abandonReason) || null}
              disableClearable={!!abandonReason}
              noOptionsText="Nenhuma opção encontrada"
              disablePortal={false}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(_, selectedOption) => {
                setAbandonReason(selectedOption ? selectedOption.value : null);
              }}
              ListboxProps={{
                style: { maxHeight: "200px" },
              }}
            />

            {abandonReason === SyncTypes.ArchiveReason.OTHER && (
              <MUI.TextField
                label="Detalhes"
                placeholder="Escreva a justificativa aqui"
                multiline
                minRows={2}
                maxRows={4}
                fullWidth
                value={abandonDetails}
                onChange={(e) => setAbandonDetails(e.target.value)}
                sx={{
                  mt: 1,
                  "& .MuiOutlinedInput-root": {
                    borderColor: "common.lightShade",
                  },
                }}
                error={abandonReason === SyncTypes.ArchiveReason.OTHER && !abandonDetails}
                helperText={
                  abandonReason === SyncTypes.ArchiveReason.OTHER && !abandonDetails ? "Detalhes obrigatórios" : ""
                }
              />
            )}
          </MUI.Box>
        }
        descriptionTextAlign="left"
        buttonsDirection="row"
        buttons={[
          {
            label: "Cancelar",
            onClick: () => {
              setIsAbandonDialogOpen(false);
              setAbandonReason(null);
              setAbandonDetails("");
            },
            variant: "text",
            sx: {
              color: "text.primary",
              "&:hover": {
                textDecoration: "underline",
              },
            },
          },
          {
            label: "Confirmar",
            onClick: handleConfirmAbandon,
            variant: "contained",
            color: "error",
            disabled: !abandonReason || (abandonReason === SyncTypes.ArchiveReason.OTHER && !abandonDetails),
            sx: {
              "&:hover": {
                backgroundColor: "error.dark",
              },
            },
          },
        ]}
        variant="error"
      />
    </MUI.Box>
  );
};
