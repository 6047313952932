// External imports
import React, { useCallback, useEffect } from "react";
import * as MUI from "@mui/material";
import * as Grid from "@mui/x-data-grid";
import RefreshIcon from "@mui/icons-material/Refresh";

// Internal service/hook imports
import { DialogModal } from "@/components/DialogModal";
import { logger } from "@/core/logger";
import { useCompanyTeam } from "@/hooks/company/useCompanyTeam";
import { ROUTE_PATHS } from "@/routes/routePaths";
import * as SyncTypes from "@/hooks/tempSyncLeadStage/types";
import { useApprovalManagement } from "@/hooks/useApprovalRequests/useApprovalRequests";
import { useChats } from "../../hooks/useChats";

// Local imports
import { ExtendedGridColDef } from "./columnTypes";
import { getColumns } from "./columns";
import { useEditModes } from "./hooks/useEditModes";
import { tableStyles } from "./styles";
import { FlattenedChat, mockDataEnabled } from "./types";
import { QuickFilters } from "./components/QuickFilters";
import { useQuickFilters } from "./components/QuickFilters/useQuickFilters";
import { useTableSettings } from "./hooks/useTableSettings";
import * as Utils from "./utils/whatsapp";

export const TableView = () => {
  const { filters, setFilters, chatFilters, setChatFilters, handleFilterChange, filterRows, calculateFilterCounts } =
    useQuickFilters();
  const { data: companyUsers } = useCompanyTeam();
  const { chats, loading: isLoadingChats, fetchChats } = useChats();
  const { requests, loading: requestsLoading, fetchRequests } = useApprovalManagement();
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [lastRefreshTime, setLastRefreshTime] = React.useState<Date | null>(null);
  const [availableLeadStages, setAvailableLeadStages] = React.useState<string[]>([]);
  const {
    settings,
    isLoaded,
    handleSortModelChange,
    handleFilterModelChange,
    handleColumnVisibilityModelChange,
    handlePaginationModelChange,
    handleDensityChange,
    handleColumnWidthChange,
  } = useTableSettings();
  const [isAbandonDialogOpen, setIsAbandonDialogOpen] = React.useState(false);
  const [abandonReason, setAbandonReason] = React.useState<SyncTypes.ArchiveReason | null>(null);
  const [abandonDetails, setAbandonDetails] = React.useState("");
  const [currentEditingRow, setCurrentEditingRow] = React.useState<string | null>(null);
  const [textEditorOpen, setTextEditorOpen] = React.useState(false);
  const [textEditorField, setTextEditorField] = React.useState<string | null>(null);
  const [textEditorRowId, setTextEditorRowId] = React.useState<string | null>(null);
  const [textEditorValue, setTextEditorValue] = React.useState("");

  useEffect(() => {
    fetchRequests().catch((error) => {
      logger.error("Failed to fetch requests:", error);
    });
  }, [fetchRequests]);

  // Atualizar o timestamp quando a página carrega pela primeira vez
  useEffect(() => {
    if (chats.length > 0 && !lastRefreshTime) {
      setLastRefreshTime(new Date());
    }
  }, [chats, lastRefreshTime]);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await Promise.all([fetchChats(), fetchRequests()]);
    setIsRefreshing(false);
    setLastRefreshTime(new Date());
  };

  const handleOpenChat = useCallback(
    (phoneNumber: string) => {
      const chat = chats.find((chat) => chat.endClientNumber === phoneNumber);
      if (chat) {
        // Create URL with phone number as query parameter
        const chatUrl = `${ROUTE_PATHS.WHATSAPP_ASSISTANT_CHATS_INTERNAL}?phoneNumber=${encodeURIComponent(phoneNumber)}`;

        // Open in new tab
        window.open(chatUrl, "_blank");
      }
    },
    [chats]
  );

  const standardizedChats = React.useMemo(() => {
    if (!mockDataEnabled) {
      return Utils.standardizeChatsData(chats, companyUsers, requests);
    }
    return [];
  }, [chats, requests, companyUsers]);

  const [rows, setRows] = React.useState(standardizedChats);

  React.useEffect(() => {
    setRows(standardizedChats);
  }, [standardizedChats]);

  React.useEffect(() => {
    if (!mockDataEnabled && !isLoadingChats) {
      const counts = calculateFilterCounts(Array.from(rows));
      setFilters((prev) =>
        prev.map((filter) => ({
          ...filter,
          count: counts[filter.key as keyof typeof counts] || 0,
        }))
      );
    }
  }, [rows, isLoadingChats, calculateFilterCounts, setFilters]);

  // Extract unique lead stages from chats
  React.useEffect(() => {
    if (chats.length > 0) {
      const uniqueLeadStages = Utils.extractUniqueLeadStages(chats);
      setAvailableLeadStages(uniqueLeadStages);
    }
  }, [chats]);

  // Define error handler for row updates
  const handleRowUpdateError = (error: Error) => {
    throw new Error(`Failed to update row: ${error.message}`);
  };
  // Define callback for lead stage change to abandoned
  const handleLeadStageChangeToAbandoned = (rowId: string) => {
    setCurrentEditingRow(rowId);
    setIsAbandonDialogOpen(true);
  };

  // Initialize edit mode management
  const {
    // Row mode state management
    rowModesModel,
    setRowModesModel,
    // Row edit event handlers
    handleRowEditStart,
    handleRowEditStop,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    // Row update processing
    processRowUpdate,
    handleConfirmAbandon,
    // Row status tracking
    loadingRows,
    successRows,
    errorRows,
  } = useEditModes(
    [...rows],
    (updatedRows) => setRows(updatedRows as FlattenedChat[]),
    handleRowUpdateError,
    handleLeadStageChangeToAbandoned
  );

  const handleOpenTextEditor = useCallback((id: string, field: string, value: string) => {
    setTextEditorRowId(id);
    setTextEditorField(field);
    setTextEditorValue(value || "");
    setTextEditorOpen(true);
  }, []);

  const handleSaveTextEditor = useCallback(() => {
    if (textEditorRowId && textEditorField) {
      // Find the current row
      const updatedRow = rows.find((row) => row.endClientNumber === textEditorRowId);
      if (updatedRow) {
        // Create updated row with new value
        const newRow = { ...updatedRow, [textEditorField]: textEditorValue };

        // Process the update directly without entering edit mode
        void processRowUpdate(newRow, updatedRow);
      }

      // Close the editor
      setTextEditorOpen(false);
      setTextEditorRowId(null);
      setTextEditorField(null);
      setTextEditorValue("");
    }
  }, [textEditorRowId, textEditorField, textEditorValue, rows, processRowUpdate]);

  const columns = React.useMemo<ExtendedGridColDef[]>(() => {
    return getColumns(
      rowModesModel,
      companyUsers,
      handleEditClick,
      handleSaveClick,
      handleCancelClick,
      loadingRows,
      successRows,
      errorRows,
      handleOpenChat,
      settings.columns.columnWidths,
      handleOpenTextEditor
    );
  }, [
    rowModesModel,
    companyUsers,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    loadingRows,
    successRows,
    errorRows,
    handleOpenChat,
    settings.columns.columnWidths,
    handleOpenTextEditor,
  ]);

  const filteredRows = filterRows(Array.from(rows) as FlattenedChat[]);

  // Add an effect to re-fetch when filters change
  useEffect(() => {
    fetchChats(chatFilters).catch((error) => {
      logger.error("Failed to fetch chats:", error);
    });
  }, [chatFilters, fetchChats]);

  // Modify handleConfirmAbandon to use the hook's function
  const handleConfirmAbandonClick = () => {
    if (!currentEditingRow) return;

    // Use the hook's function
    void handleConfirmAbandon(currentEditingRow, abandonReason, abandonDetails);

    // Reset states
    setIsAbandonDialogOpen(false);
    setAbandonReason(null);
    setAbandonDetails("");
    setCurrentEditingRow(null);
  };
  const handleCellDoubleClick = useCallback(
    (params: Grid.GridCellParams) => {
      if (
        params.field !== "actions" &&
        params.field !== "lastMessageDate" &&
        params.field !== "createdAt" &&
        params.field !== "endClientNumber"
      ) {
        setRowModesModel({
          ...rowModesModel,
          [params.id]: { mode: Grid.GridRowModes.Edit, fieldToFocus: params.field },
        });
      }
    },
    [rowModesModel, setRowModesModel]
  );

  const handleCellEditStop = useCallback(
    (params: Grid.GridCellEditStopParams) => {
      // Don't auto-save on focus out for text fields that need more careful editing
      if (
        params.reason === Grid.GridCellEditStopReasons.cellFocusOut &&
        rowModesModel[params.id]?.mode === Grid.GridRowModes.Edit &&
        params.field !== "caseNotes" &&
        params.field !== "actionItems"
      ) {
        handleSaveClick(params.id)();
      }
    },
    [rowModesModel, handleSaveClick]
  );

  const getRowClassName = useCallback(
    (params: Grid.GridRowParams) => {
      if (successRows.has(params.id)) return "row-success";
      if (errorRows.has(params.id)) return "row-error";
      return "";
    },
    [successRows, errorRows]
  );

  return (
    <MUI.Box sx={tableStyles.wrapper}>
      <MUI.Box sx={tableStyles.filtersContainer}>
        <QuickFilters
          filters={filters}
          onFilterChange={handleFilterChange}
          chatFilters={chatFilters}
          setChatFilters={setChatFilters}
          companyTeam={companyUsers}
          availableLeadStages={availableLeadStages}
        />

        <MUI.Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
          <MUI.Tooltip title="Atualizar dados">
            <MUI.Button
              variant="outlined"
              color="primary"
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
              disabled={isLoadingChats || requestsLoading || isRefreshing}
              sx={tableStyles.refreshButton}
            >
              {isRefreshing ? "Atualizando..." : "Atualizar"}
            </MUI.Button>
          </MUI.Tooltip>

          {lastRefreshTime && (
            <MUI.Typography
              variant="body2"
              sx={{
                mt: 0.5,
                color: "text.secondary",
                fontSize: "0.75rem",
                mr: 1,
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <MUI.Box component="span" sx={{ fontWeight: "medium" }}>
                Última atualização:
              </MUI.Box>
              {new Intl.DateTimeFormat("pt-BR", {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              }).format(lastRefreshTime)}
            </MUI.Typography>
          )}
        </MUI.Box>
      </MUI.Box>

      <Grid.DataGrid
        getRowId={(row) => row.endClientNumber}
        rows={isLoadingChats || requestsLoading ? [] : filteredRows}
        columns={columns}
        loading={isLoadingChats || requestsLoading || isRefreshing || !isLoaded}
        pageSizeOptions={[25, 50, 100]}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onCellDoubleClick={handleCellDoubleClick}
        onCellEditStop={handleCellEditStop}
        slots={{ toolbar: Grid.GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            csvOptions: {
              fileName: "whatsapp-chats",
              delimiter: ";",
              utf8WithBom: true,
              allColumns: true,
            },
            disableFilter: true,
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: settings.pagination.pageSize,
              page: settings.pagination.page,
            },
          },
          sorting: {
            sortModel: settings.sorting,
          },
          columns: {
            columnVisibilityModel: settings.columns.columnVisibilityModel,
          },
          filter: {
            filterModel: settings.filter.filterModel,
          },
          density: settings.density,
        }}
        sortModel={settings.sorting}
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={handleFilterModelChange}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        onPaginationModelChange={handlePaginationModelChange}
        onDensityChange={handleDensityChange}
        onColumnResize={(params) => {
          handleColumnWidthChange(params.colDef.field, params.width);
        }}
        disableRowSelectionOnClick
        isRowSelectable={() => false}
        sx={tableStyles.dataGrid}
        getRowClassName={getRowClassName}
        columnVisibilityModel={settings.columns.columnVisibilityModel}
      />

      {/* Abandonment Dialog */}
      <DialogModal
        open={isAbandonDialogOpen}
        onClose={() => {
          setIsAbandonDialogOpen(false);
          setAbandonReason(null);
          setAbandonDetails("");
          setCurrentEditingRow(null);

          // Cancel the edit mode for the current row
          if (currentEditingRow) {
            setRowModesModel((prevModel) => ({
              ...prevModel,
              [currentEditingRow]: { mode: Grid.GridRowModes.View },
            }));
          }
        }}
        title="Motivo do abandono"
        description={
          <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%", mt: 2 }}>
            <MUI.Autocomplete
              renderInput={(params) => (
                <MUI.TextField
                  {...params}
                  label="Justificativa"
                  placeholder="Selecione a justificativa aqui"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "common.lightShade",
                    },
                  }}
                />
              )}
              options={SyncTypes.archiveOptions}
              value={SyncTypes.archiveOptions.find((option) => option.value === abandonReason) || null}
              disableClearable={!!abandonReason}
              noOptionsText="Nenhuma opção encontrada"
              disablePortal={false}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(_, selectedOption) => {
                setAbandonReason(selectedOption ? selectedOption.value : null);
              }}
              ListboxProps={{
                style: { maxHeight: "200px" },
              }}
            />

            {abandonReason === SyncTypes.ArchiveReason.OTHER && (
              <MUI.TextField
                label="Detalhes"
                placeholder="Escreva a justificativa aqui"
                multiline
                minRows={2}
                maxRows={4}
                fullWidth
                value={abandonDetails}
                onChange={(e) => setAbandonDetails(e.target.value)}
                sx={{
                  mt: 1,
                  "& .MuiOutlinedInput-root": {
                    borderColor: "common.lightShade",
                  },
                }}
                error={abandonReason === SyncTypes.ArchiveReason.OTHER && !abandonDetails}
                helperText={
                  abandonReason === SyncTypes.ArchiveReason.OTHER && !abandonDetails ? "Detalhes obrigatórios" : ""
                }
              />
            )}
          </MUI.Box>
        }
        descriptionTextAlign="left"
        buttonsDirection="row"
        buttons={[
          {
            label: "Cancelar",
            onClick: () => {
              setIsAbandonDialogOpen(false);
              setAbandonReason(null);
              setAbandonDetails("");
              setCurrentEditingRow(null);

              // Cancel the edit mode for the current row
              if (currentEditingRow) {
                setRowModesModel((prevModel) => ({
                  ...prevModel,
                  [currentEditingRow]: { mode: Grid.GridRowModes.View },
                }));
              }
            },
            variant: "text",
            sx: {
              color: "text.primary",
              "&:hover": {
                textDecoration: "underline",
              },
            },
          },
          {
            label: "Confirmar",
            onClick: handleConfirmAbandonClick,
            variant: "contained",
            color: "error",
            disabled: !abandonReason || (abandonReason === SyncTypes.ArchiveReason.OTHER && !abandonDetails),
            sx: {
              "&:hover": {
                backgroundColor: "error.dark",
              },
            },
          },
        ]}
        variant="error"
      />

      {/* Text Editor Dialog */}
      <DialogModal
        open={textEditorOpen}
        onClose={() => {
          setTextEditorOpen(false);
          setTextEditorRowId(null);
          setTextEditorField(null);
          setTextEditorValue("");
        }}
        title={textEditorField === "caseNotes" ? "Editar Observações Gerais" : "Editar Próximos Passos"}
        description={
          <MUI.Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%", mt: 2 }}>
            <MUI.TextField
              label={textEditorField === "caseNotes" ? "Observações" : "Próximos Passos"}
              placeholder={
                textEditorField === "caseNotes" ? "Adicione observações aqui..." : "Adicione próximos passos aqui..."
              }
              multiline
              minRows={6}
              maxRows={12}
              fullWidth
              value={textEditorValue}
              onChange={(e) => setTextEditorValue(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderColor: "common.lightShade",
                },
              }}
              autoFocus
            />
          </MUI.Box>
        }
        descriptionTextAlign="left"
        buttonsDirection="row"
        buttons={[
          {
            label: "Cancelar",
            onClick: () => {
              setTextEditorOpen(false);
              setTextEditorRowId(null);
              setTextEditorField(null);
              setTextEditorValue("");
            },
            variant: "text",
            sx: {
              color: "text.primary",
              "&:hover": {
                textDecoration: "underline",
              },
            },
          },
          {
            label: "Salvar",
            onClick: handleSaveTextEditor,
            variant: "contained",
            color: "primary",
            sx: {
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            },
          },
        ]}
      />
    </MUI.Box>
  );
};
