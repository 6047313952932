import { GridRowsProp } from "@mui/x-data-grid";
import * as LexZapTypes from "@/hooks/lexZap/types";

export enum LeadStage {
  TRIAGEM = "Triagem",
  EM_FECHAMENTO_COMERCIAL = "Em fechamento comercial",
  EM_INSTRUCAO_CASO_ADMINISTRATIVO = "Em instrução do caso administrativo",
  EM_PROCESSO_ADMINISTRATIVO = "Em processo administrativo",
  EM_INSTRUCAO_CASO_JUDICIAL = "Em instrução do caso judicial",
  EM_PROCESSO_JUDICIAL = "Em processo judicial",
  EM_EXECUCAO_JUDICIAL = "Em execução judicial",
  EM_COBRANCA = "Em cobrança",
  ABANDONADO = "Abandonado",
}

export enum SenderType {
  APPLICANT = "applicant",
  ASSISTANT = "assistant",
  LAWYER = "lawyer",
  UNKNOWN = "unknown",
}

export interface FlattenedChat {
  // Required fields from ChatProps
  endClientNumber: string;
  status?: LexZapTypes.ChatStatus;
  createdAt: number;
  chatMode?: LexZapTypes.ChatMode;

  // Optional fields from ChatProps
  applicantName?: string;
  assignedUserIds?: string[];
  leadStage?: string;
  legalMatterType?: string;
  caseNotes?: string;
  actionItems?: string;

  // Flattened fields from lastMessage
  lastMessageDate: number;
  lastMessageText?: string;
  lastMessageSenderType?: SenderType;

  // Additional fields from mock data
  chatStage?: LexZapTypes.ChatStage;

  // Add this field
  hasApprovalRequest: boolean;
}

export const mockDataEnabled = false;

export const mockRows: GridRowsProp = [
  {
    chatMode: "ai_paused_for_current_ticket",
    status: "active",
    applicantName: "5521979151009",
    endClientNumber: "5521979151009",
    createdAt: 1741825012154,
    lastMessageDate: 1742168038269,
    chatStage: LexZapTypes.ChatStage.ABANDONED,
    assignedUserIds: ["603d34158c4ec11478b775a2", "user2"],
    legalMatterType: "Outros",
    caseNotes:
      "Já é beneficiária do BPC e entrou em contato para tirar dúvidas sobre aposentadoria. Não era lead para caso novo.",
    actionItems: "",
    leadStage: "Contato Inicial",
  },
  {
    chatMode: "manual",
    status: "active",
    applicantName: "5521999999999",
    endClientNumber: "5521999999999",
    createdAt: 1741825012154,
    lastMessageDate: 1742168038269,
    chatStage: LexZapTypes.ChatStage.INITIAL_TRIAGE,
    assignedUserIds: ["user3"],
    legalMatterType: "Idoso",
    caseNotes: "Não qualificou para o BPC Idoso",
    actionItems: "",
    leadStage: "Contato Inicial",
  },
  {
    chatMode: "ai_active",
    status: "active",
    applicantName: "5521888888888",
    endClientNumber: "5521888888888",
    createdAt: 1741825012154,
    lastMessageDate: 1742168038269,
    chatStage: LexZapTypes.ChatStage.INITIAL_TRIAGE,
    assignedUserIds: [],
    legalMatterType: "Deficiente",
    caseNotes: "Interessado em BPC Deficiente",
    actionItems: "",
    leadStage: "Contato Inicial",
  },
];

export const leadStageColors: Record<LeadStage, string> = {
  [LeadStage.TRIAGEM]: "#E3F2FD", // Azul muito claro (início)
  [LeadStage.EM_FECHAMENTO_COMERCIAL]: "#90CAF9", // Azul mais intenso
  [LeadStage.EM_INSTRUCAO_CASO_ADMINISTRATIVO]: "#1E88E5", // Azul intenso
  [LeadStage.EM_PROCESSO_ADMINISTRATIVO]: "#2196F3", // Azul mais forte
  [LeadStage.EM_INSTRUCAO_CASO_JUDICIAL]: "#1565C0", // Azul escuro
  [LeadStage.EM_PROCESSO_JUDICIAL]: "#1976D2", // Azul muito intenso
  [LeadStage.EM_EXECUCAO_JUDICIAL]: "#0D47A1", // Azul escuro
  [LeadStage.EM_COBRANCA]: "#7B1FA2", // Roxo
  [LeadStage.ABANDONADO]: "#F44336", // Vermelho (caso ruim)
};

// Cores que precisam de texto branco para melhor contraste
export const darkBackgroundStages: LeadStage[] = [
  LeadStage.EM_INSTRUCAO_CASO_JUDICIAL,
  LeadStage.EM_PROCESSO_JUDICIAL,
  LeadStage.EM_EXECUCAO_JUDICIAL,
  LeadStage.EM_COBRANCA,
  LeadStage.ABANDONADO,
];
