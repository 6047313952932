import { useState, useEffect, useCallback } from "react";
import * as Grid from "@mui/x-data-grid";
import { logger } from "@/core/logger";

// Define the structure of our grid settings
export interface TableSettings {
  pagination: {
    pageSize: number;
    page: number;
  };
  sorting: Grid.GridSortModel;
  columns: {
    columnVisibilityModel: Grid.GridColumnVisibilityModel;
    columnWidths: Record<string, number>;
  };
  filter: {
    filterModel: Grid.GridFilterModel;
  };
  density: Grid.GridDensity;
}

// Default settings to use if nothing is in localStorage
const DEFAULT_SETTINGS: TableSettings = {
  pagination: {
    pageSize: 25,
    page: 0,
  },
  sorting: [{ field: "lastMessageDate", sort: "desc" }],
  columns: {
    columnVisibilityModel: {
      status: false,
      endClientNumber: false,
      lastMessageSenderType: false,
      lastMessageText: false,
      assignedUserNames: false,
    },
    columnWidths: {},
  },
  filter: {
    filterModel: {
      items: [],
      quickFilterExcludeHiddenColumns: false,
    },
  },
  density: "standard",
};

export const useTableSettings = (storageKey: string = "whatsapp-assistant-table-view-settings") => {
  const [settings, setSettings] = useState<TableSettings>(DEFAULT_SETTINGS);
  const [isLoaded, setIsLoaded] = useState(false);

  // Helper function to save to localStorage
  const saveToLocalStorage = useCallback(
    (newSettings: TableSettings, errorMessage: string) => {
      try {
        localStorage.setItem(storageKey, JSON.stringify(newSettings));
        return true;
      } catch (error) {
        logger.error(errorMessage, { error });
        return false;
      }
    },
    [storageKey]
  );

  // Load settings from localStorage on mount
  useEffect(() => {
    try {
      const savedSettings = localStorage.getItem(storageKey);
      if (savedSettings) {
        const parsedSettings = JSON.parse(savedSettings);
        setSettings(parsedSettings);
      }
      setIsLoaded(true);
    } catch (error) {
      logger.error("Failed to load table settings from localStorage:", { error });
      setIsLoaded(true);
    }
  }, [storageKey]);

  // Save settings to localStorage
  const saveSettings = useCallback(
    (newSettings: TableSettings) => {
      if (saveToLocalStorage(newSettings, "Failed to save table settings to localStorage:")) {
        setSettings(newSettings);
      }
    },
    [saveToLocalStorage]
  );

  // Individual handlers for different parts of the state
  const handleSortModelChange = useCallback(
    (sortModel: Grid.GridSortModel) => {
      setSettings((prev) => {
        const newSettings = { ...prev, sorting: sortModel };
        saveToLocalStorage(newSettings, "Failed to save sort settings:");
        return newSettings;
      });
    },
    [saveToLocalStorage]
  );

  const handleFilterModelChange = useCallback(
    (filterModel: Grid.GridFilterModel) => {
      setSettings((prev) => {
        const newSettings = { ...prev, filter: { filterModel } };
        saveToLocalStorage(newSettings, "Failed to save filter settings:");
        return newSettings;
      });
    },
    [saveToLocalStorage]
  );

  const handleColumnVisibilityModelChange = useCallback(
    (columnVisibilityModel: Grid.GridColumnVisibilityModel) => {
      setSettings((prev) => {
        const newSettings = {
          ...prev,
          columns: {
            ...prev.columns,
            columnVisibilityModel,
          },
        };
        saveToLocalStorage(newSettings, "Failed to save column visibility settings:");
        return newSettings;
      });
    },
    [saveToLocalStorage]
  );

  const handlePaginationModelChange = useCallback(
    (paginationModel: Grid.GridPaginationModel) => {
      setSettings((prev) => {
        const newSettings = {
          ...prev,
          pagination: {
            pageSize: paginationModel.pageSize,
            page: paginationModel.page,
          },
        };
        saveToLocalStorage(newSettings, "Failed to save pagination settings:");
        return newSettings;
      });
    },
    [saveToLocalStorage]
  );

  const handleDensityChange = useCallback(
    (density: Grid.GridDensity) => {
      setSettings((prev) => {
        const newSettings = { ...prev, density };
        saveToLocalStorage(newSettings, "Failed to save density settings:");
        return newSettings;
      });
    },
    [saveToLocalStorage]
  );

  // Add a new handler for column width changes
  const handleColumnWidthChange = useCallback(
    (field: string, width: number) => {
      setSettings((prev) => {
        const newSettings = {
          ...prev,
          columns: {
            ...prev.columns,
            columnWidths: {
              ...prev.columns.columnWidths,
              [field]: width,
            },
          },
        };
        saveToLocalStorage(newSettings, "Failed to save column width settings:");
        return newSettings;
      });
    },
    [saveToLocalStorage]
  );

  // Reset settings to default
  const resetSettings = useCallback(() => {
    try {
      localStorage.removeItem(storageKey);
      setSettings(DEFAULT_SETTINGS);
    } catch (error) {
      logger.error("Failed to reset table settings:", { error });
    }
  }, [storageKey]);

  return {
    settings,
    isLoaded,
    handleSortModelChange,
    handleFilterModelChange,
    handleColumnVisibilityModelChange,
    handlePaginationModelChange,
    handleDensityChange,
    handleColumnWidthChange,
    saveSettings,
    resetSettings,
  };
};
