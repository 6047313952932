import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FileType } from "@/hooks/lexZap/types";
import { CaseService } from "@/services/case";
import { getCasePlatformFilesQueryKey } from "@/hooks/case/useCasePlatformFiles";
import { PlatformFile } from "../useCasePlatformFiles/types";

export const useCreateCasePlatformFile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["createCaseFile"],
    mutationFn: async ({
      fileTempId,
      caseId,
      location,
      name,
      size,
      fileType,
      extension,
    }: {
      fileTempId: string;
      location: string;
      caseId: string;
      name: string;
      size: number;
      fileType: FileType;
      extension: string;
    }) => {
      return {
        ...(await CaseService.createCasePlatformFile({ caseId, location, name, size, fileType, extension })),
        caseId,
        fileTempId,
      };
    },
    onSuccess: async (data) => {
      await queryClient.cancelQueries({ queryKey: getCasePlatformFilesQueryKey({ caseId: data.caseId }) });
      const previousCaseFiles = queryClient.getQueryData<string[]>(
        getCasePlatformFilesQueryKey({ caseId: data.caseId })
      );
      queryClient.setQueryData(getCasePlatformFilesQueryKey({ caseId: data.caseId }), (old: PlatformFile[]) =>
        (old || []).map((o) => {
          if (o.id === data.fileTempId) {
            return {
              ...o,
              id: data.id,
              documentsPipelineId: data.documentsPipelineId,
              createdAt: data.createdAt,
            } satisfies PlatformFile;
          }
          return o;
        })
      );
      return { previousCaseFiles };
    },
    onError: (_error, _newCase, context) => {
      queryClient.setQueryData(getCasePlatformFilesQueryKey({ caseId: _newCase.caseId }), context);
    },
  });
};
