import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Message } from "./components/Message";
import { MessageSkeleton } from "./components/Message/skeleton";
import { CaseMessage, TicketStatus } from "@/hooks/lexZap/types";
import { groupMessagesByDay } from "./utils/groupMessagesByDay";
import { useLexZapChatInfo } from "@/hooks/lexZap/useLexZapChatInfo";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { scrollbarStyles } from "@/theme/mixins/scrollbar";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { createTicketInactiveSnippet } from "./utils/createTicketInactiveSnippet";
import { useLexZapChats } from "@/hooks/lexZap/useLexZapChats";
import { useLexZapActiveChat } from "@/hooks/lexZap/useLexZapActiveChat";
import { useLexZapMessages } from "@/hooks/lexZap/useLexZapMessages";
import { dayFromTimestamp } from "../../../../utils/dayFromTimestamp";

const START_INDEX = 10000;

export const ChatMessages = () => {
  const { data: allChats } = useLexZapChats();
  const { activeChat } = useLexZapActiveChat();

  const activeChatIsNewChat = useMemo(() => {
    if (!activeChat) return false;

    const activeChatAlreadyExists = allChats?.some((chat) => chat.endClientNumber === activeChat.endClientNumber);

    return !activeChatAlreadyExists;
  }, [activeChat, allChats]);

  const {
    data: messagesData,
    isFetching: isFetchingMessages,
    isFetchingNextPage,
    fetchNextPage,
  } = useLexZapMessages({
    applicantPhoneNumber: activeChat?.endClientNumber,
    disabled: activeChatIsNewChat,
  });

  const isLoadingFirstFetch = isFetchingMessages && !isFetchingNextPage;

  const { whatsappTicketsEnabled } = useFeatureFlags();
  const { isIdle } = useLexZapChatInfo({ chat: activeChat });
  const messages = useMemo(() => {
    const enhancedMessages = messagesData ? [...messagesData] : [];

    if (
      whatsappTicketsEnabled &&
      isIdle &&
      !activeChat?.lastTicket?.suppressInactiveMessage &&
      activeChat?.lastTicket?.status === TicketStatus.ACTIVE
    ) {
      enhancedMessages.push(
        createTicketInactiveSnippet({
          applicantPhoneNumber: activeChat.endClientNumber,
          ticketId: activeChat.lastTicket.id,
          lastMessageDate: activeChat.lastMessageDate,
        })
      );
    }

    return enhancedMessages;
  }, [messagesData, activeChat, whatsappTicketsEnabled, isIdle]);

  const lastMessage = useMemo(() => messages[messages.length - 1], [messages]);
  const groupedMessages = useMemo(() => groupMessagesByDay(messages), [messages]);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const [atBottom, setAtBottom] = useState(false);

  const { flatList, firstItemIndex } = useMemo(() => {
    const updatedFlatList = groupedMessages.reduce<
      Array<{ type: "header"; date: number } | { type: "message"; message: CaseMessage }>
    >(
      (acc, { date, messages }) => [
        ...acc,
        { type: "header", date },
        ...messages.map((message) => ({ type: "message" as const, message })),
      ],
      []
    );

    return {
      flatList: updatedFlatList,
      firstItemIndex: START_INDEX - updatedFlatList.length,
    };
  }, [groupedMessages]);

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      virtuosoRef.current?.scrollToIndex(START_INDEX - 1);
    }, 0);
  }, []);

  useEffect(() => {
    if (atBottom || lastMessage?.isSending) {
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage?.id]);

  if (isLoadingFirstFetch) {
    return <ChatMessagesSkeleton messages={messages} />;
  }

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          flex: 1,
          "[data-testid='virtuoso-scroller']": {
            ...scrollbarStyles,
          },
        }}
      >
        <Virtuoso
          key={activeChat?.endClientNumber}
          alignToBottom
          ref={virtuosoRef}
          atBottomStateChange={setAtBottom}
          atBottomThreshold={300}
          style={{ height: "100%", width: "100%" }}
          initialTopMostItemIndex={flatList.length - 1}
          firstItemIndex={firstItemIndex}
          increaseViewportBy={{
            top: 400,
            bottom: 0,
          }}
          data={flatList}
          startReached={() => {
            void fetchNextPage();
          }}
          itemContent={(index, item) => {
            if (item === flatList[0] && isFetchingNextPage) {
              return (
                <Box
                  sx={{
                    width: "100%",
                    py: 2,
                    px: 5,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              );
            }

            if (item.type === "header") {
              return (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    pt: index === 0 ? 3 : 2,
                    px: 5,
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "16px",
                      border: "1px solid",
                      borderColor: "common.lightShade",
                      backgroundColor: "common.white",
                      paddingX: 2,
                      paddingY: 1,
                    }}
                  >
                    <Typography
                      variant="body"
                      sx={{
                        whiteSpace: "normal",
                        textTransform: "uppercase",
                      }}
                    >
                      {dayFromTimestamp(item.date)}
                    </Typography>
                  </Box>
                </Box>
              );
            }

            return (
              <Box
                sx={{
                  width: "100%",
                  pt: 2,
                  px: 5,
                  pb: item.message.id === lastMessage?.id ? 2 : 0,
                }}
              >
                <Message message={item.message} />
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
};

interface ChatMessagesSkeletonProps {
  messages: CaseMessage[];
}
const ChatMessagesSkeleton = ({ messages }: ChatMessagesSkeletonProps) => {
  const lastMessage = useMemo(() => messages[messages.length - 1], [messages]);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const SkeletonMessages = useMemo(
    () => Array.from({ length: 20 }, (_, index) => <MessageSkeleton key={index} />),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages]
  );

  const scrollToBottom = useCallback(() => {
    messagesContainerRef.current?.scrollTo({
      top: messagesContainerRef.current.scrollHeight,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage?.id]);

  return (
    <Box
      ref={messagesContainerRef}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        paddingX: 5,
        paddingTop: 3,
        paddingBottom: 2,
        ...scrollbarStyles,
        marginTop: "auto",
      }}
    >
      {SkeletonMessages}
      {messages.map((message, index) => (
        <Message key={index} message={message} />
      ))}
    </Box>
  );
};
