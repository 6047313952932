import { DialogModal } from "@/components/DialogModal";
import { PageLayout } from "@/components/PageLayout";
import { HeaderVariant } from "@/components/PageLayout/Header/constants";
import { useAuthContext } from "@/contexts/AuthContext";
import { ActionProps } from "@/core/actions";
import { useAddFavoriteSkill } from "@/hooks/skills/useAddFavoriteSkill";
import { useDeleteFavoriteSkill } from "@/hooks/skills/useDeleteFavoriteSkill";
import { useApi } from "@/hooks/useApi";
import { Toast } from "@//components/core/Toast";
import {
  alpha,
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  Dialog,
  FormControl,
  FormControlLabel,
  Link,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ActionsList } from "./components/ActionsList";
import { logger } from "@/core/logger";
import { useCredits } from "@/hooks/credits/useCredits";
import { useActions } from "@/hooks/useActions";
import ContractAnalysis from "@/assets/svgs/contract-analysis.svg?react";
import WomanInComputer from "@/assets/images/womanNormalInComputer.jpg";
import { getSkillGroupPath, getSkillPath, ROUTE_PATHS } from "@/routes/routePaths";
import { notShowCredits } from "@/utils/plans";
import { UsageCard } from "@/pages/Home/components/UsageCard";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import {
  AutoAwesomeOutlined,
  CreateOutlined,
  ChevronRight,
  ArrowOutwardOutlined,
  LockOutlined,
} from "@mui/icons-material";
import OtherTasksActions from "./components/OtherTasksActions";
import WriteDocumentsActions from "./components/WriteDocumentsActions";
import { useLocation, useNavigate } from "react-router-dom";
import { ImportSingleOabModal } from "../WhatsappAssistantCases/components/ImportSingleOabModal";
import { CreateCaseModal } from "@/components/Cases/createCaseModal";
import { NewCasesActionButtons } from "../Cases/NewCasesPage/components/NewCasesActionButtons";
import LauraImage from "@/assets/images/Laura.jpg";
import { Chip, ChipContent, ChipInRow, ChipRow } from "@/components/Chip/V1";
import { usePermissions } from "@/hooks/usePermissions";
import { useLexZapGetTickets } from "@/hooks/lexZap/useLexZapGetTickets";
import { useLexZapCases } from "@/hooks/lexZap/useLexZapCases";
import { useNotificationsQuery } from "@/hooks/notifications/useNotificationsQuery";
import { useLexZapCompany } from "@/hooks/lexZap/useLexZapCompany";
import { ActiveCasesChip } from "@/components/Chip/V1/ActiveCases";

const USAGE_CARD_OPEN_STORAGE_KEY = "usageCardOpen";

export const Home = () => {
  const { newAssistentScreensEnabled } = useFeatureFlags();
  const { data: credits } = useCredits();
  const flags = useFeatureFlags();
  const showCredits = !notShowCredits(credits, flags.planScreenV2Enabled);

  const [usageCardOpen, setUsageCardOpen] = useState(() => {
    return sessionStorage.getItem(USAGE_CARD_OPEN_STORAGE_KEY) !== "false";
  });

  const closeUsageCard = () => {
    setUsageCardOpen(false);
    sessionStorage.setItem(USAGE_CARD_OPEN_STORAGE_KEY, "false");
  };

  return (
    <PageLayout
      headerProps={{
        variant: HeaderVariant.WELCOME,
        extraContent: usageCardOpen && showCredits ? <UsageCard onClose={closeUsageCard} /> : undefined,
      }}
      contentSx={{
        width: "100%",
      }}
      sx={{
        ".page-layout-container": {
          p: 0,
        },
      }}
      variant="secondary"
    >
      {newAssistentScreensEnabled ? <NewHomeContent /> : <HomeContent />}
    </PageLayout>
  );
};

const NewHomeContent = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { hasManageLegalProceedingsPermission } = usePermissions();
  const [lockedArea, setLockedArea] = useState("");

  const commonLockedAreaMessage =
    "De acordo com as permissões definidas em seu perfil, você não pode acessar à área de";

  useEffect(() => {
    if (state?.unauthorizedArea) {
      setLockedArea(`${commonLockedAreaMessage} desejo.`);
    }
  }, [state]);

  const handleOpenCases = () => {
    if (hasManageLegalProceedingsPermission) {
      navigate(ROUTE_PATHS.ACTIVE_CASES);
    } else {
      handleOpenCaseLockedArea();
    }
  };

  const handleOpenCaseLockedArea = () => {
    setLockedArea(`${commonLockedAreaMessage} casos.`);
  };

  const handleOpenCustomerServiceLockedArea = () => {
    setLockedArea(`${commonLockedAreaMessage} atendimentos.`);
  };

  const handleOpenOtherTasksLockedArea = () => {
    setLockedArea(`${commonLockedAreaMessage} outras tarefas.`);
  };

  const handleOpenWriteDocumentLockedArea = () => {
    setLockedArea(`${commonLockedAreaMessage} escrever documento.`);
  };

  const handleCloseLockedArea = () => {
    setLockedArea("");

    // Workaround to clear the state
    navigate(ROUTE_PATHS.HOME);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 3, height: "100%", margin: 5 }}>
      <Overview
        lockedArea={lockedArea}
        onCloseLockedArea={handleCloseLockedArea}
        onOpenCases={handleOpenCases}
        onOpenCustomerServiceLockedArea={handleOpenCustomerServiceLockedArea}
        onOpenNewCaseLockedArea={handleOpenCaseLockedArea}
      />
      <LegalAssistantLaura
        onOpenOtherTasksLockedArea={handleOpenOtherTasksLockedArea}
        onOpenWriteDocumentLockedArea={handleOpenWriteDocumentLockedArea}
      />
    </Box>
  );
};

const Overview = ({
  lockedArea,
  onCloseLockedArea,
  onOpenCases,
  onOpenCustomerServiceLockedArea,
  onOpenNewCaseLockedArea,
}: {
  lockedArea: string;
  onCloseLockedArea: () => void;
  onOpenCases: () => void;
  onOpenCustomerServiceLockedArea: () => void;
  onOpenNewCaseLockedArea: () => void;
}) => {
  const { newCaseCreationScreenEnabled } = useFeatureFlags();
  const [isCreateANewCaseOpen, setIsCreateANewCaseOpen] = useState(false);
  const [openImportOAB, setOpenImportOAB] = React.useState(false);
  const handleOpenImportingByOAB = () => {
    setOpenImportOAB(true);
  };

  return (
    <HomeActionTemplate>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4, width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h5">Visão geral</Typography>
          <NewCasesActionButtons
            onOpenNewCaseLockedArea={onOpenNewCaseLockedArea}
            onCreateCase={() => setIsCreateANewCaseOpen(true)}
            shouldRedirectToCases
          />
        </Box>
        <ChipRow>
          <CustomerServicesChip onClickLockedChip={onOpenCustomerServiceLockedArea} />
          <UpdatedCasesChip onClickLockedChip={onOpenCases} />
          <ActiveCasesChip onClick={onOpenCases} />
        </ChipRow>
      </Box>
      {newCaseCreationScreenEnabled ? (
        <CreateNewCase
          open={isCreateANewCaseOpen}
          onClose={() => setIsCreateANewCaseOpen(false)}
          handleOpenImportingByOAB={handleOpenImportingByOAB}
        />
      ) : (
        isCreateANewCaseOpen && <CreateCaseModal onClose={() => setIsCreateANewCaseOpen(false)} />
      )}
      <ImportSingleOabModal
        open={openImportOAB}
        onClose={() => setOpenImportOAB(false)}
        handleOpenImportingByOAB={handleOpenImportingByOAB}
      />
      <Dialog maxWidth="sm" open={!!lockedArea} onClose={onCloseLockedArea} fullWidth>
        <Stack gap={2} padding={3}>
          <Stack gap={1} flexDirection="row" alignItems="center">
            <Avatar src={LauraImage} sx={{ width: "50px", height: "50px" }} />
            <Typography variant="h5">Você não tem acesso a esta área</Typography>
          </Stack>
          <Typography variant="body1">
            {lockedArea}
            <br />
            Em caso de dúvidas, converse com um administrador da conta de seu escritório.
          </Typography>
        </Stack>
      </Dialog>
    </HomeActionTemplate>
  );
};

const CustomerServicesChip = ({ onClickLockedChip }: { onClickLockedChip: () => void }) => {
  const navigate = useNavigate();
  const { hasCustomerServiceFullAccessPermission } = usePermissions();
  const { data: connectionData, isLoading: isLoadingConnectionData } = useLexZapCompany();
  const { data: tickets, isLoading: isLoadingTickets } = useLexZapGetTickets({ status: "ACTIVE" });

  const hasWhatsapp = !!connectionData?.phoneNumber;
  const activeTickets = React.useMemo(() => {
    if (!Array.isArray(tickets)) return 0;
    return tickets.filter((t: { status: string }) => t.status === "ACTIVE").length;
  }, [tickets]);

  const color = "inherit";

  function handleClick() {
    if (hasCustomerServiceFullAccessPermission) {
      navigate(ROUTE_PATHS.WHATSAPP_ASSISTANT);
    } else {
      onClickLockedChip();
    }
  }

  return (
    <Chip
      title="Atendimentos"
      onClick={hasWhatsapp ? handleClick : undefined}
      isLoading={isLoadingConnectionData || isLoadingTickets}
      fullWidth
    >
      <ChipContent>
        <ChipInRow>
          <Typography
            variant="h5"
            sx={{
              color: color,
            }}
          >
            {hasWhatsapp ? activeTickets : "Contrate agora"}
          </Typography>
          {hasWhatsapp && hasCustomerServiceFullAccessPermission ? (
            <ArrowOutwardOutlined
              sx={{
                color: color,
              }}
            />
          ) : (
            <LockOutlined sx={{ color: color }} />
          )}
        </ChipInRow>
      </ChipContent>
    </Chip>
  );
};

const UpdatedCasesChip = ({ onClickLockedChip }: { onClickLockedChip: () => void }) => {
  const navigate = useNavigate();
  const { cases, isLoading: isLoadingCases } = useLexZapCases({ status: ["ACTIVE"], pageSize: 100 });
  const { data: notifications, isLoading: isLoadingNotifications } = useNotificationsQuery();
  const { hasManageLegalProceedingsPermission } = usePermissions();

  const updatedCases = React.useMemo(
    () =>
      cases?.filter((c) =>
        notifications?.some(
          (n) =>
            n.notificationType === "CASE_UPDATED" && !n.visualizedAt && c.id === n.data.caseId && c.status === "ACTIVE"
        )
      ).length,
    [notifications, cases]
  );

  const handleOpenCasesScreen = () => {
    if (hasManageLegalProceedingsPermission) {
      navigate(ROUTE_PATHS.CASES);
    } else {
      return onClickLockedChip();
    }
  };

  const color = "inherit";

  return (
    <Chip
      title="Casos com atualizações"
      onClick={handleOpenCasesScreen}
      isLoading={isLoadingCases || isLoadingNotifications}
      fullWidth
    >
      <ChipContent>
        <ChipInRow>
          <Typography
            variant="h5"
            sx={{
              color: color,
            }}
          >
            {updatedCases}
          </Typography>
          {hasManageLegalProceedingsPermission ? (
            <ArrowOutwardOutlined
              sx={{
                color: color,
              }}
            />
          ) : (
            <LockOutlined sx={{ color: color }} />
          )}
        </ChipInRow>
      </ChipContent>
    </Chip>
  );
};

const CreateNewCase = ({
  open,
  onClose,
  handleOpenImportingByOAB,
}: {
  open: boolean;
  onClose: () => void;
  handleOpenImportingByOAB: () => void;
}) => {
  enum CreateNewCaseOption {
    CREATE_WITH_OAB = "CREATE_WITH_OAB",
    CREATE_WITH_PROCESS_NUMBER = "CREATE_WITH_PROCESS_NUMBER",
    CREATE_MANUALLY = "CREATE_MANUALLY",
  }

  const [selectedOption, setSelectedOption] = useState<CreateNewCaseOption | null>(null);

  const navigate = useNavigate();
  const theme = useTheme();
  // TODO: validar se tbm precisa desabilitar o import por OAB

  // const { user } = useAuthContext();
  // const { data: oabs, isLoading: isLoadingOabs } = useOabs(user!.userId);
  // const { legalProceedingsPageEnabled } = useFeatureFlags();
  // const importByOabDisabled = useMemo(() => {
  //   return (oabs?.data.length !== 0 || isLoadingOabs) && legalProceedingsPageEnabled;
  // }, [oabs, isLoadingOabs, legalProceedingsPageEnabled]);

  const handleContinue = () => {
    if (!selectedOption) return;

    switch (selectedOption) {
      case CreateNewCaseOption.CREATE_WITH_OAB:
        handleOpenImportingByOAB();
        break;
      case CreateNewCaseOption.CREATE_WITH_PROCESS_NUMBER:
        navigate(ROUTE_PATHS.CREATE_CASE, { state: { importType: "process" } });
        break;
      case CreateNewCaseOption.CREATE_MANUALLY:
        navigate(ROUTE_PATHS.CREATE_CASE, { state: { importType: "manual" } });
        break;
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "calc(100% - 32px)",
          maxWidth: "500px",
          bgcolor: "common.white",
          borderRadius: 2,
          boxShadow: theme.shadows[3],
          overflow: "hidden",
          gap: 2,
          p: 3,
        }}
      >
        <Box sx={{ mb: 2, display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Abrir novo caso
          </Typography>
          <Typography variant="subtitle1">Selecione a opção desejada para abrir um novo caso:</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}>
          <FormControl component="fieldset" sx={{ width: "100%" }}>
            <RadioGroup
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value as CreateNewCaseOption)}
            >
              <FormControlLabel
                // disabled={importByOabDisabled}
                value={CreateNewCaseOption.CREATE_WITH_OAB}
                control={<Radio />}
                label={
                  <Box>
                    <Typography variant="body1">Utilizar minha OAB para importar casos</Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value={CreateNewCaseOption.CREATE_WITH_PROCESS_NUMBER}
                control={<Radio />}
                label={
                  <Box>
                    <Typography variant="body1">Utilizar número de processo (padrão CNJ)</Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value={CreateNewCaseOption.CREATE_MANUALLY}
                control={<Radio />}
                label={
                  <Box>
                    <Typography variant="body1">Inserir dados manualmente</Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Button onClick={onClose} variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleContinue} variant="contained" disabled={!selectedOption}>
            Abrir caso
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const LegalAssistantLaura = ({
  onOpenOtherTasksLockedArea,
  onOpenWriteDocumentLockedArea,
}: {
  onOpenOtherTasksLockedArea: () => void;
  onOpenWriteDocumentLockedArea: () => void;
}) => {
  return (
    <HomeActionTemplate>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Avatar src={LauraImage} sx={{ width: "50px", height: "50px" }} />
            <Typography variant="h5">Conte com a ajuda da Laura</Typography>
          </Box>
          <Typography variant="subtitle1">
            Utilize nossa Assistente Jurídica para executar as tarefas necessárias para o seu caso.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <WriteDocument onOpenWriteDocumentLockedArea={onOpenWriteDocumentLockedArea} />
          <OtherTasks onOpenOtherTasksLockedArea={onOpenOtherTasksLockedArea} />
        </Box>
      </Box>
    </HomeActionTemplate>
  );
};

const WriteDocument = ({ onOpenWriteDocumentLockedArea }: { onOpenWriteDocumentLockedArea: () => void }) => {
  const [isWriteDocumentsActionsOpen, setIsWriteDocumentsActionsOpen] = useState(false);
  const { hasManageLegalProceedingsPermission } = usePermissions();

  const handleClick = () => {
    if (hasManageLegalProceedingsPermission) {
      setIsWriteDocumentsActionsOpen(true);
    } else {
      onOpenWriteDocumentLockedArea();
    }
  };

  return (
    <>
      <ButtonBase
        component="div"
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 4,
          p: 3,
          borderRadius: 2,
          border: "1px solid",
          borderColor: "grey.200",
          transition: "background-color 0.3s ease",
          "&:hover": {
            backgroundColor: "grey.200",
          },
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <CreateOutlined />
            <Typography variant="h5">Escrever documento</Typography>
          </Box>
          <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
            Escreva diversos tipos de documentos como peças judiciais, petições, notificações extrajudiciais e contratos
            utilizando Inteligência Artificial.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateRows: "1fr 1fr 1fr",
            height: "100%",
            alignItems: "center",
          }}
        >
          {hasManageLegalProceedingsPermission ? (
            <ChevronRight sx={{ gridRow: "2", justifySelf: "center" }} />
          ) : (
            <LockOutlined sx={{ gridRow: "1" }} />
          )}
        </Box>
      </ButtonBase>
      <WriteDocumentsActions
        isOpen={isWriteDocumentsActionsOpen}
        onClose={() => setIsWriteDocumentsActionsOpen(false)}
      />
    </>
  );
};

const OtherTasks = ({ onOpenOtherTasksLockedArea }: { onOpenOtherTasksLockedArea: () => void }) => {
  const [isOtherTasksActionsOpen, setIsOtherTasksActionsOpen] = useState(false);
  const { hasManageLegalProceedingsPermission } = usePermissions();

  const handleClick = () => {
    if (hasManageLegalProceedingsPermission) {
      setIsOtherTasksActionsOpen(true);
    } else {
      onOpenOtherTasksLockedArea();
    }
  };

  return (
    <>
      <ButtonBase
        component="div"
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 4,
          p: 3,
          borderRadius: 2,
          border: "1px solid",
          borderColor: "grey.200",
          transition: "background-color 0.3s ease",
          "&:hover": {
            backgroundColor: "grey.200",
          },
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <AutoAwesomeOutlined />
            <Typography variant="h5">Outras tarefas</Typography>
          </Box>
          <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
            Busque jurisprudências, tire dúvidas jurídicas, elabore roteiros ou resuma documentos para direcionar
            clientes na abertura de novos casos ou em audiências.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateRows: "1fr 1fr 1fr",
            height: "100%",
            alignItems: "center",
          }}
        >
          {hasManageLegalProceedingsPermission ? (
            <ChevronRight sx={{ gridRow: "2", justifySelf: "center" }} />
          ) : (
            <LockOutlined sx={{ gridRow: "1" }} />
          )}
        </Box>
      </ButtonBase>
      <OtherTasksActions isOpen={isOtherTasksActionsOpen} onClose={() => setIsOtherTasksActionsOpen(false)} />
    </>
  );
};

const HomeActionTemplate = ({ children, onClick }: { children: React.ReactNode; onClick?: () => void }) => {
  return (
    <Card
      sx={{
        p: 4,
        width: "100%",
        maxWidth: "1000px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 4,
      }}
      onClick={onClick}
    >
      {children}
    </Card>
  );
};

const HomeContent = () => {
  const { mutateAsync: deleteFavoriteSkill } = useDeleteFavoriteSkill();
  const { mutateAsync: addFavoriteSkill } = useAddFavoriteSkill();

  const { actions, isLoadingActions } = useActions();

  const comingSoonRef = useRef<{
    handleComingSoon: (action: ActionProps) => void;
  }>();

  const handleComingSoon = (action: ActionProps) => {
    if (comingSoonRef.current) {
      comingSoonRef.current.handleComingSoon(action);
    }
  };

  const toggleFavoriteSkill = useCallback(
    async ({ skillId, isFavorite }: { skillId: string; isFavorite: boolean }) => {
      if (isFavorite) {
        await deleteFavoriteSkill({ skillId });
      } else {
        await addFavoriteSkill({ skillId });
      }
    },
    [addFavoriteSkill, deleteFavoriteSkill]
  );

  return (
    <>
      <ComingSoonModal ref={comingSoonRef} />
      <HomeBanner />
      <Box
        sx={{
          px: 8,
          py: 4,
        }}
      >
        <Typography
          sx={{
            mb: "8px",
          }}
          variant="h5"
        >
          Faça mais com o Assistente Lexter
        </Typography>
        <ActionsList
          handleComingSoon={handleComingSoon}
          actions={actions}
          toggleFavoriteSkill={toggleFavoriteSkill}
          isLoading={isLoadingActions}
        />
      </Box>
    </>
  );
};

const HomeBanner = () => {
  return (
    <Box
      sx={{
        paddingX: 8,
        paddingY: 2,
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
        gridTemplateRows: "repeat(2, 1fr)",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "inline-grid",
          gridColumn: "1 / 5",
          gridRow: "1 / 3",
        }}
      >
        <NewPieceBanner />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, gridColumn: "5 / 7", gridRow: "1 / 3" }}>
        <WhatsappBanner />
        <UseAsWellBanner />
      </Box>
    </Box>
  );
};

const WhatsappBanner = () => {
  const { palette } = useTheme();
  return (
    <Card
      sx={{
        display: "flex",
        flexGrow: 1,
        color: "common.white",
        backgroundImage: `url(${WomanInComputer})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: 1,
          justifyContent: "center",
          padding: 2,
          paddingX: 3,
          backgroundColor: alpha(palette.common.black, 0.75),
        }}
      >
        <Typography variant="h6">Novo assistente para Whatsapp</Typography>
        <Typography variant="subtitle2">
          Conheça uma forma mais leve de trabalhar, deixando a Lexter fazer o primerio atendimento por você.
        </Typography>
        <Link href={ROUTE_PATHS.WHATSAPP_ASSISTANT}>Conhecer o novo assistente</Link>
      </Box>
    </Card>
  );
};

const NewPieceBanner = () => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
        padding: 3,
        gap: 4,
      }}
    >
      <ContractAnalysis style={{ width: "100%", height: "100%", maxHeight: "320px", maxWidth: "320px" }} />
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 2, p: 2 }}
      >
        <Typography variant="h5">Seu escritório e a Lexter podem fazer muito juntos</Typography>
        <Typography variant="subtitle1">
          Um bom começo é escrever uma peça. Abaixo você encontra outras possibilidades.
        </Typography>
        <Tooltip
          title={
            "Escreva diversos tipos de peças judiciais, de iniciais a recursos e aplações. Utilize nossa inteligência artificial e otimize seu processo de escrita."
          }
          arrow
          placement="top"
        >
          <Button variant="contained" href={getSkillGroupPath({ skillGroupId: "create_new_document" })} fullWidth>
            Escrever peça judicial
          </Button>
        </Tooltip>
      </Box>
    </Card>
  );
};

const UseAsWellBanner = () => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 3,
        gap: 2,
      }}
    >
      <Typography variant="h6">Utilize também</Typography>
      <Tooltip
        title={"Tire dúvidas jurídicas de forma rápida e prática utilizando o Assistente Lexter."}
        arrow
        placement="top"
      >
        <Button variant="outlined" href={getSkillPath({ skillId: "legal_questions" })} fullWidth>
          Duvidas jurídicas
        </Button>
      </Tooltip>
      <Tooltip title={"Elabore contratos dos mais diversos tipos com apenas poucos cliques."} arrow placement="top">
        <Button variant="outlined" href={getSkillPath({ skillId: "create_contract" })} fullWidth>
          Elaborar contrato
        </Button>
      </Tooltip>
    </Card>
  );
};

const ComingSoonModal = forwardRef(function ComingSoonModal(_props, ref) {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<ActionProps | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { addActionOfInterest } = useApi();
  const { user } = useAuthContext();

  const onClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleComingSoon(action: ActionProps) {
      setAction(action);
      setOpen(true);
    },
  }));

  const handleActionInterest = async () => {
    try {
      setIsLoading(true);
      if (action && user) {
        await addActionOfInterest({
          userId: user?.userId,
          companyId: user?.companyId,
          actionOfInterest: action?.id.toUpperCase(),
        });
        Toast.success("Interesse cadastrado com sucesso!");
      } else {
        Toast.error("Erro ao cadastrar interesse, tente novamente mais tarde");
      }
    } catch (err) {
      Toast.error("Erro ao cadastrar interesse");
      logger.error("handleActionInterest", { error: err });
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <DialogModal
      open={open}
      title={action?.name}
      isLoading={isLoading}
      heightLoading={160}
      description={
        <Typography variant="body1" sx={{ color: "text.primary" }}>
          A ação de <strong>{action?.name.toUpperCase()}</strong> ainda não está disponível. Cadastre-se abaixo e seja
          informado assim que ela estiver pronta para uso.
        </Typography>
      }
      descriptionTextAlign="left"
      buttons={[
        {
          label: "Avise-me",
          onClick: handleActionInterest,
        },
        {
          label: "Cancelar",
          onClick: onClose,
          variant: "text",
          color: "primary",
          sx: {
            color: "text.primary",
            textDecoration: "underline",
          },
        },
      ]}
    />
  );
});
ComingSoonModal.displayName = "ComingSoonModal";
