import { ChatMode, ChatStage, ChatStatus, LexZapChat, Ticket, TicketStatus, TicketStep } from "@/hooks/lexZap/types";

export const getChatStage = ({ chat, lastTicket }: { chat: LexZapChat; lastTicket?: Ticket }): ChatStage | null => {
  if (chat.status === ChatStatus.ARCHIVED) {
    return ChatStage.ARCHIVED;
  }

  if (chat.chatMode === ChatMode.MANUAL) {
    return ChatStage.INACTIVE_AI;
  }

  if (lastTicket) {
    if (!!lastTicket.createdBy) {
      return ChatStage.HUMAN_INITIATED;
    }

    if (
      lastTicket.step === TicketStep.EXCEEDED_PROGRESS_LIMIT ||
      (lastTicket.status === TicketStatus.CLOSED && lastTicket.closedReasonType)
    ) {
      return ChatStage.ABANDONED;
    }

    if (lastTicket.step === TicketStep.SUMMARY_GENERATED) {
      return ChatStage.INITIAL_TRIAGE;
    }

    if (lastTicket.step === TicketStep.LAWYER_INVOKED_BY_APPLICANT || lastTicket.step === TicketStep.TICKET_FAILED) {
      return ChatStage.REDIRECTED;
    }
  }

  if (chat.chatMode === ChatMode.AI) {
    return ChatStage.AI_SERVICE;
  }

  return null;
};
