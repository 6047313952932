import { authenticatedWhatsappServer } from "@/core/api";
import { StartAIAgentParams } from "./types";
import { ChatFilters, ChatWithLastMessage, ChatsResponse } from "./types/chat";

export const WhatsappServerService = {
  chats: {
    getAll: async (params?: {
      limit?: number;
      lastEvaluatedKey?: Record<string, unknown>;
      filters?: ChatFilters;
    }): Promise<ChatsResponse> => {
      const { limit, lastEvaluatedKey, filters } = params || {};
      const DEFAULT_LIMIT = 100;

      let allChats: ChatWithLastMessage[] = [];
      let lastKey: Record<string, unknown> | undefined = lastEvaluatedKey;
      let hasMoreItems = true;
      let totalCount = 0;

      while (hasMoreItems) {
        const queryParams: Record<string, unknown> = {};

        queryParams.limit = limit || DEFAULT_LIMIT;
        if (lastKey) queryParams.lastEvaluatedKey = JSON.stringify(lastKey);

        // Add filters to query params if they exist
        if (filters) {
          if (filters.assignedUserIds?.length) {
            queryParams.assignedUserIds = filters.assignedUserIds;
          }
          // Adicionar o novo filtro de exclusão
          if (filters.excludeAssignedUserIds?.length) {
            queryParams.excludeAssignedUserIds = filters.excludeAssignedUserIds;
          }
          if (filters.leadStages?.length) {
            queryParams.leadStages = filters.leadStages;
          }
          if (filters.chatModes?.length) {
            queryParams.chatModes = filters.chatModes;
          }
          if (filters.statuses?.length) {
            queryParams.statuses = filters.statuses;
          }
        }

        const apiResponse = await authenticatedWhatsappServer.get<ChatsResponse>(`/external/chats`, {
          params: queryParams,
        });

        allChats = [...allChats, ...apiResponse.data.chats];
        lastKey = apiResponse.data.lastEvaluatedKey;
        totalCount = apiResponse.data.total;

        // If there's no lastEvaluatedKey or we've reached the requested limit, stop fetching
        if (!lastKey || (limit && allChats.length >= limit)) {
          hasMoreItems = false;
        }
      }

      return {
        chats: allChats,
        lastEvaluatedKey: lastKey,
        total: totalCount || allChats.length,
      };
    },

    getChat: async (params: { applicantPhoneNumber: string }): Promise<ChatWithLastMessage> => {
      const { applicantPhoneNumber } = params;
      const response = await authenticatedWhatsappServer.get<{ chat: ChatWithLastMessage }>(
        `/external/chats/byEndClientNumber/${encodeURIComponent(applicantPhoneNumber)}`
      );
      return response.data.chat;
    },

    update: async (params: { endClientNumber: string; updateFields: Partial<ChatWithLastMessage> }): Promise<void> => {
      const { endClientNumber, updateFields } = params;
      await authenticatedWhatsappServer.patch(`/external/chats`, {
        applicantPhoneNumber: endClientNumber,
        updateFields,
      });
    },

    startAIAgent: async ({ applicantPhoneNumber, agentName, agentPayload }: StartAIAgentParams): Promise<void> => {
      await authenticatedWhatsappServer.post(`/external/chats/startAIAgent`, {
        applicantPhoneNumber,
        agentName,
        agentPayload,
      });
    },
  },
};
