import React, { useState } from "react";
import * as MUI from "@mui/material";
import * as MUIIcons from "@mui/icons-material";

import * as types from "./types";
import { ExcludeResponsibleFilter } from "./ExcludeResponsibleFilter";
import { ResponsibleFilter } from "./ResponsibleFilter";
import { LeadStageFilter } from "./LeadStageFilter";
import { ChatModeFilter } from "./ChatModeFilter";
import { StatusFilter } from "./StatusFilter";

export const QuickFilters = ({
  filters,
  onFilterChange,
  chatFilters,
  setChatFilters,
  companyTeam,
  availableLeadStages,
}: types.QuickFiltersProps) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <MUI.Box
      sx={{
        p: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        position: "relative",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        width: "100%",
      }}
    >
      <MUI.Box
        sx={{
          display: "flex",
          gap: 1,
          flexWrap: "wrap",
          alignItems: "center",
          width: "95%",
        }}
      >
        <MUI.Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
          Filtros rápidos:
        </MUI.Typography>
        {filters.map((filter) => (
          <MUI.Chip
            key={filter.key}
            label={
              <MUI.Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <span>{filter.label}</span>
                <span
                  style={{
                    borderLeft: "1px solid",
                    borderColor: filter.enabled ? "rgba(255, 255, 255, 0.3)" : "rgba(0, 0, 0, 0.2)",
                    paddingLeft: "8px",
                    marginLeft: "4px",
                  }}
                >
                  {filter.count}
                </span>
              </MUI.Box>
            }
            onClick={() => onFilterChange(filter.key)}
            color={filter.enabled ? "primary" : "default"}
            variant={filter.enabled ? "filled" : "outlined"}
            size="small"
            sx={{
              borderRadius: "16px",
              "&:hover": {
                backgroundColor: filter.enabled ? "primary.dark" : "action.hover",
              },
            }}
          />
        ))}
      </MUI.Box>

      <MUI.IconButton
        size="small"
        onClick={() => setExpanded(!expanded)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        {expanded ? <MUIIcons.ExpandLess fontSize="small" /> : <MUIIcons.ExpandMore fontSize="small" />}
      </MUI.IconButton>

      <MUI.Collapse in={expanded}>
        <MUI.Box sx={{ mt: 1, pt: 1, borderTop: "1px solid", borderColor: "divider", width: "95%" }}>
          <MUI.Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <ResponsibleFilter
              chatFilters={chatFilters}
              setChatFilters={setChatFilters}
              companyTeam={companyTeam || []}
            />

            <ExcludeResponsibleFilter
              chatFilters={chatFilters}
              setChatFilters={setChatFilters}
              companyTeam={companyTeam || []}
            />

            {availableLeadStages && availableLeadStages.length > 0 && (
              <LeadStageFilter
                chatFilters={chatFilters}
                setChatFilters={setChatFilters}
                availableLeadStages={availableLeadStages}
              />
            )}

            <ChatModeFilter chatFilters={chatFilters} setChatFilters={setChatFilters} />

            <StatusFilter chatFilters={chatFilters} setChatFilters={setChatFilters} />
          </MUI.Box>
        </MUI.Box>
      </MUI.Collapse>
    </MUI.Box>
  );
};
