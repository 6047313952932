import { Box, TextField, Tooltip, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";

import { Note } from "@/services/note/types";
import { Button } from "@/components/Button";
import { EditNote as EditNoteIcon, Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useCreateNote } from "@/hooks/notes/useCreateNote";
import { WebToast } from "@/components/core/Toast";
import { useEditNote } from "@/hooks/notes/useEditNote";
import { logger } from "@/core/logger";
import { useArchiveNote } from "@/hooks/notes/useArchiveNote";
import { DateTime } from "luxon";
import { useCompanyMember } from "@/hooks/company/useCompanyMember";
import { DeleteNoteModal } from "@/components/Notes/deleteNoteModal";

const noteCharactersLimit = 60000;

export const Notes = ({
  notes,
  applicantId,
  caseId,
  ticketId,
}: {
  notes: Note[] | undefined;
  applicantId?: string;
  caseId?: string;
  ticketId?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentEditingNote, setCurrentEditingNote] = useState<Note | null>(null);
  const [isOpenDeleteNoteModal, setIsOpenDeleteNoteModal] = useState(false);
  const [noteContent, setNoteContent] = useState("");
  const { mutateAsync: createNote, isPending: isLoadingCreateNote } = useCreateNote();
  const { mutateAsync: updateNote, isPending: isLoadingUpdateNote } = useEditNote();
  const { mutateAsync: archiveNote, isPending: isLoadingArchiveNote } = useArchiveNote();

  const noteContentLimitError = useMemo(() => {
    return noteContent.length > noteCharactersLimit;
  }, [noteContent]);

  const openEditNote = (note?: Note) => {
    setCurrentEditingNote(note || null);
    setIsEditing(true);
    setNoteContent(note?.content || "");
  };

  const closeEditNote = () => {
    setCurrentEditingNote(null);
    setIsEditing(false);
    setNoteContent("");
  };

  const handleDeleteNote = async () => {
    if (!currentEditingNote?.id) return;
    try {
      await archiveNote({
        noteId: currentEditingNote.id,
        applicantId,
        caseId,
        ticketId,
      });
      handleCloseDeleteNoteModal();
      WebToast.success("Anotação deletada com sucesso");
    } catch (error) {
      handleCloseDeleteNoteModal();
      WebToast.error("Erro ao deletar anotação");
      logger.error("Erro ao deletar anotação", { error });
    }
  };

  const handleOpenDeleteNoteModal = (note: Note) => {
    setCurrentEditingNote(note);
    setIsOpenDeleteNoteModal(true);
  };

  const handleCloseDeleteNoteModal = () => {
    setCurrentEditingNote(null);
    setIsOpenDeleteNoteModal(false);
  };

  const handleSaveNote = async () => {
    try {
      if (currentEditingNote?.id) {
        await updateNote({
          noteId: currentEditingNote.id,
          applicantId,
          caseId,
          ticketId,
          data: { content: noteContent },
        });
      } else {
        await createNote({ data: { content: noteContent, applicantId, caseId, ticketId } });
      }
      closeEditNote();
      WebToast.success("Anotação salva com sucesso");
    } catch (error) {
      WebToast.error("Erro ao salvar anotação");
      logger.error("Erro ao salvar anotação", { error });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingX: 3,
          paddingTop: 3,
          paddingBottom: 2,
          width: "100%",
        }}
      >
        <Typography variant="subtitle1">Anotações</Typography>
      </Box>
      {isEditing ? (
        <EditNoteItem
          noteContent={noteContent}
          handleNoteContentChange={setNoteContent}
          noteContentLimitError={noteContentLimitError}
        />
      ) : notes?.length ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingX: 4,
            gap: 1,
            width: "100%",
          }}
        >
          {notes.map((note) => {
            return (
              <NoteItem
                key={note.id}
                note={note}
                handleOpenEditNote={openEditNote}
                handleOpenDeleteNoteModal={handleOpenDeleteNoteModal}
              />
            );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: "grey.50",
            width: "100%",
            paddingX: 3,
            paddingY: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1">Nenhuma anotação</Typography>
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          paddingX: 3,
          paddingY: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isEditing ? (
          <Box sx={{ display: "grid", gridTemplateColumns: "20% 80%", gap: 2, width: "100%" }}>
            <Button
              sx={{
                width: "100%",
              }}
              variant="outlined"
              color="primary"
              onClick={closeEditNote}
              disabled={isLoadingCreateNote || isLoadingUpdateNote || noteContentLimitError}
            >
              Cancelar
            </Button>
            <Button
              sx={{
                width: "100%",
                fontWeight: 600,
              }}
              variant="contained"
              color="secondary"
              onClick={handleSaveNote}
              disabled={isLoadingCreateNote || isLoadingUpdateNote || noteContentLimitError}
            >
              Salvar
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }} onClick={() => openEditNote()}>
            <EditNoteIcon
              sx={{
                color: "primary.main",
              }}
            />
            <Typography
              sx={{
                fontWeight: 700,
                color: "primary.main",
              }}
              variant="subtitle1"
            >
              Nova anotação
            </Typography>
          </Box>
        )}
      </Box>
      <DeleteNoteModal
        isOpen={isOpenDeleteNoteModal}
        onConfirm={handleDeleteNote}
        onCancel={handleCloseDeleteNoteModal}
        isLoading={isLoadingArchiveNote}
      />
    </Box>
  );
};

const EditNoteItem = ({
  handleNoteContentChange,
  noteContent,
  noteContentLimitError,
}: {
  handleNoteContentChange: (content: string) => void;
  noteContent: string;
  noteContentLimitError: boolean;
}) => {
  return (
    <Box sx={{ width: "100%", paddingX: 3 }}>
      <TextField
        sx={{
          width: "100%",
        }}
        onChange={(e) => handleNoteContentChange(e.target.value)}
        multiline
        rows={2}
        maxRows={4}
        value={noteContent}
        error={noteContentLimitError}
        helperText={noteContentLimitError ? "Deve ter no máximo 60.000 caracteres" : ""}
      />
    </Box>
  );
};

const NoteItem = ({
  note,
  handleOpenEditNote,
  handleOpenDeleteNoteModal,
}: {
  note: Note;
  handleOpenEditNote: (note: Note) => void;
  handleOpenDeleteNoteModal: (note: Note) => void;
}) => {
  const { data: user } = useCompanyMember({ memberId: note.createdByUserId });
  const editNote = () => {
    handleOpenEditNote(note);
  };

  const deleteNote = () => {
    handleOpenDeleteNoteModal(note);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        gap: 1,
      }}
    >
      <Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Typography variant="bold" sx={{ fontSize: "12px", color: "text.secondary" }}>
            {user?.name} - {DateTime.fromJSDate(new Date(note.createdAt)).toFormat("dd/MM/yyyy")}
          </Typography>
        </Box>
        <Tooltip
          title={note.content}
          sx={{
            width: "400px",
          }}
          arrow
          placement="top"
        >
          <Typography
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
              textWrap: "wrap",
              overflow: "hidden",
              width: "100%",
              wordBreak: "break-word",
            }}
            variant="body1"
          >
            {note.content}
          </Typography>
        </Tooltip>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <EditIcon onClick={editNote} sx={{ cursor: "pointer", color: "grey.500" }} />
        <DeleteIcon onClick={deleteNote} sx={{ cursor: "pointer", color: "grey.500" }} />
      </Box>
    </Box>
  );
};
