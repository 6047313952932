import React from "react";
import * as MUI from "@mui/material";
import * as MUIIcons from "@mui/icons-material";
import * as types from "./types";

export const ExcludeResponsibleFilter: React.FC<types.ExcludeResponsibleFilterProps> = ({
  chatFilters,
  setChatFilters,
  companyTeam,
}) => {
  // Sort companyTeam alphabetically by name
  const sortedCompanyTeam = [...companyTeam].sort((a, b) =>
    a.name.localeCompare(b.name, "pt-BR", { sensitivity: "base" })
  );

  // Handle adding users to exclude list
  const handleAddExcludeResponsible = (selectedOptions: typeof chatFilters.excludeResponsibles) => {
    setChatFilters((prev) => ({
      ...prev,
      excludeResponsibles: selectedOptions,
    }));
  };

  // Handle removing a user from exclude list
  const handleRemoveExcludeResponsible = (userId: string) => {
    setChatFilters((prev) => ({
      ...prev,
      excludeResponsibles: prev.excludeResponsibles.filter((resp) => resp.userId !== userId),
    }));
  };

  return (
    <MUI.Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, flex: 1 }}>
      <MUI.Typography variant="body2" color="text.secondary" sx={{ minWidth: "90px" }}>
        Excluir:
      </MUI.Typography>

      <MUI.Box sx={{ flexGrow: 1 }}>
        {/* Display selected users as chips */}
        {chatFilters.excludeResponsibles?.length > 0 && (
          <MUI.Box
            sx={{
              display: "flex",
              gap: 1,
              flexWrap: "wrap",
              mb: 1,
            }}
          >
            {chatFilters.excludeResponsibles.map((responsible) => (
              <MUI.Chip
                key={responsible.userId}
                label={responsible.name}
                onDelete={() => handleRemoveExcludeResponsible(responsible.userId)}
                sx={{
                  backgroundColor: "error.light",
                  color: "error.contrastText",
                }}
                deleteIcon={<MUIIcons.CloseOutlined />}
                size="small"
              />
            ))}
          </MUI.Box>
        )}

        {/* User selection autocomplete */}
        <MUI.Autocomplete
          multiple
          options={sortedCompanyTeam.filter(
            (member) => !chatFilters.responsibles?.some((item) => item.userId === member.userId)
          )}
          value={chatFilters.excludeResponsibles}
          noOptionsText="Nenhuma opção encontrada"
          disablePortal
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.userId === value.userId}
          onChange={(_, selectedOptions) => handleAddExcludeResponsible(selectedOptions)}
          renderInput={(params) => (
            <MUI.TextField {...params} placeholder="Selecionar responsáveis para excluir" size="small" />
          )}
          renderTags={() => null}
          sx={{ flexGrow: 1, width: "100%" }}
        />
      </MUI.Box>
    </MUI.Box>
  );
};
