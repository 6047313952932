// External dependencies
import { Box, Button, IconButton, Menu, MenuItem, Popover, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as MuiIcons from "@mui/icons-material";
import { DateTime } from "luxon";
import { debounce } from "lodash";
import EmojiPicker, { Categories, EmojiClickData, EmojiStyle } from "emoji-picker-react";
import parseStringToHtml from "html-react-parser";
import { formatChatMessage } from "format-chat-message";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import ffmpegCoreUrl from "@ffmpeg/core?url";
import ffmpegWasmUrl from "@ffmpeg/core/wasm?url";

// Internal dependencies
import { WebToast } from "@/components/core/Toast";
import { LimitedTextInputTooltip } from "@/components/LexZap/LimitedTextInputTooltip";
import { MessageTemplateCharacterCounter } from "@/components/LexZap/MessageTemplateCharacterCounter";
import { uploadFileToS3 } from "@/contexts/MessagesContext/utils";
import { logger } from "@/core/logger";
import { useApi } from "@/hooks/useApi";
import { CaseMessageToSendType, LexZapChat } from "@/hooks/lexZap/types";
import { useLexZapChatInfo } from "@/hooks/lexZap/useLexZapChatInfo";
import { useSendLexZapMessage } from "@/hooks/lexZap/useSendLexZapMessage";
import { buildTemplateMessage } from "@/hooks/lexZap/useSendLexZapMessage/templates";
import { useStartAIAgent } from "@/hooks/lexZap/useStartAIAgent";
import { SubAgentName } from "@/services/whatsappServer/types/chat";
import { limitedTextInput } from "@/utils/lexZap/limitedTextInput";
import { FilesSelector } from "../FilesSelector";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

interface AgentTask {
  agentName: SubAgentName;
  label: string;
}

interface ChatInputsProps {
  openDocumentSelector: () => void;
  openImageVideoSelector: () => void;
  onPasteFiles: (files: File[]) => void;
  activeChat: LexZapChat | null;
}

export const ChatInputs: React.FC<ChatInputsProps> = ({
  openDocumentSelector,
  openImageVideoSelector,
  onPasteFiles,
  activeChat,
}) => {
  const { templateToUse, templateTextWithoutMessage } = useLexZapChatInfo({ chat: activeChat || undefined });
  const [messageInputValue, setMessageInputValue] = useState("");
  const [taskInputValue, setTaskInputValue] = useState("");
  const [activeTask, setActiveTask] = useState<AgentTask | null>(null);
  const [shortcutsAnchorEl, setShortcutsAnchorEl] = useState<null | HTMLElement>(null);
  const formRef = React.useRef<HTMLFormElement>(null);
  const api = useApi();
  const ffmpegRef = useRef(new FFmpeg());

  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const { mutate: sendMessage, isPending: isSendingMessage } = useSendLexZapMessage();
  const [timeInterval, setTimeInterval] = useState<NodeJS.Timeout | null>(null);
  const [counter, setCounter] = useState<number>(0);
  const { mutateAsync: startAIAgent, isPending: isStartingAIAgent } = useStartAIAgent();

  const [filesMenuAnchorEl, setFilesMenuAnchorEl] = React.useState<null | Element>(null);
  const [emojiPickerAnchorEl, setEmojiPickerAnchorEl] = useState<null | Element>(null);
  const messageInputRef = useRef<HTMLInputElement>(null);
  const taskInputRef = useRef<HTMLInputElement>(null);

  const openFilesMenu = (event: React.MouseEvent) => {
    setFilesMenuAnchorEl(event.currentTarget);
  };

  const closeFilesMenu = () => {
    setFilesMenuAnchorEl(null);
  };

  const getDraftKey = () => {
    return `whatsappInputDraft#${activeChat?.endClientNumber}`;
  };

  const debouncedSetMessageInput = useMemo(
    () =>
      debounce((value: string) => {
        setMessageInputValue(value);
        if (messageInputRef.current) {
          messageInputRef.current.value = value;
        }
      }, 500),
    []
  );

  const debouncedSetTaskInput = useMemo(
    () =>
      debounce((value: string) => {
        setTaskInputValue(value);
        if (taskInputRef.current) {
          taskInputRef.current.value = value;
        }
      }, 500),
    []
  );

  useEffect(() => {
    const localDraft = sessionStorage.getItem(getDraftKey()) || "";
    setMessageInputValue(localDraft);
    if (messageInputRef.current) {
      messageInputRef.current.value = localDraft;
    }
    setTaskInputValue("");
    if (taskInputRef.current) {
      taskInputRef.current.value = "";
    }
    setActiveTask(null);

    if (isRecording && mediaRecorder) {
      stopRecording();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat?.endClientNumber]);

  useEffect(() => {
    if (messageInputValue === "") {
      sessionStorage.removeItem(getDraftKey());
    } else {
      sessionStorage.setItem(getDraftKey(), messageInputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageInputValue]);

  useEffect(() => {
    return () => {
      debouncedSetMessageInput.cancel();
      debouncedSetTaskInput.cancel();
    };
  }, [debouncedSetMessageInput, debouncedSetTaskInput]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isRecording) {
      await stopRecordingAndSubmit();
      return;
    }

    const currentMessageValue = messageInputRef.current?.value || "";

    if (currentMessageValue.trim() && activeChat?.endClientNumber) {
      sendMessage({
        applicantPhoneNumber: activeChat.endClientNumber,
        message: templateToUse
          ? buildTemplateMessage({
              templateKey: templateToUse,
              message: currentMessageValue,
              applicantName: activeChat?.applicantName || "",
            })
          : {
              type: CaseMessageToSendType.TEXT,
              body: currentMessageValue,
            },
      });
      if (messageInputRef.current) {
        debouncedSetMessageInput.cancel();
        messageInputRef.current.value = "";
        setMessageInputValue("");
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      formRef.current?.requestSubmit();
    }
  };

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        return await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      } catch (err) {
        WebToast.error("É necessário permitir acesso ao microfone");
        return null;
      }
    } else {
      WebToast.error("O navegador não suporta gravação de áudio");
      return null;
    }
  };

  const loadFFmpeg = async () => {
    const ffmpeg = ffmpegRef.current;

    await ffmpeg.load({
      coreURL: await toBlobURL(ffmpegCoreUrl, "text/javascript"),
      wasmURL: await toBlobURL(ffmpegWasmUrl, "application/wasm"),
    });
  };

  useEffect(() => {
    if (!ffmpegRef.current.loaded) {
      loadFFmpeg().catch((err) => {
        logger.error(`[loadFFmpeg] error`, err);
      });
    }
  }, []);

  const convertWebmToOgg = async (blob: Blob, fileName: string) => {
    const ffmpeg = ffmpegRef.current;
    const fileUrl = URL.createObjectURL(blob);
    try {
      await ffmpeg.writeFile("input.webm", await fetchFile(fileUrl));
      await ffmpeg.exec(["-i", "input.webm", "-c:a", "libopus", fileName]);
      const data = await ffmpeg.readFile(fileName);
      return new Blob([data], { type: "audio/ogg; codecs=opus" });
    } catch (err) {
      logger.error(`[convertWebmToOgg] error`, { error: err });
      return null;
    }
  };

  const handleSendRecording = async (audio: Blob) => {
    try {
      await sendRecording(audio);
    } catch (err) {
      WebToast.error("Não foi possível enviar o áudio");
      logger.error(`[sendRecording] error`, { error: err });
    }
  };

  const sendRecording = async (audioBlob: Blob) => {
    if (!audioBlob || !activeChat?.endClientNumber) {
      throw new Error("audioBlob e applicantPhoneNumber são obrigatórios");
    }

    const response = await handleUploadRecording(audioBlob);

    if (!response) {
      throw new Error("Erro ao enviar arquivo para o S3");
    }

    const { location, filename, newBlob } = response;

    const localUrl = URL.createObjectURL(newBlob);

    sendMessage({
      applicantPhoneNumber: activeChat.endClientNumber,
      message: {
        type: CaseMessageToSendType.AUDIO,
        body: "",
        media: {
          filename: filename,
          url: localUrl,
          mimeType: "audio/ogg",
          location,
        },
      },
    });
  };

  const handleUploadRecording = async (audioBlob: Blob) => {
    if (!audioBlob || !activeChat?.endClientNumber) return;

    const filename = `${Date.now()}.ogg`;

    try {
      const { signedUrl, location } = await api.signChatBucketUrl({
        applicantPhoneNumber: activeChat.endClientNumber,
        fileName: filename,
      });

      if (!ffmpegRef.current.loaded) {
        await loadFFmpeg();
      }

      const newBlob = await convertWebmToOgg(audioBlob, filename);

      if (!newBlob) {
        throw new Error("Erro ao converter arquivo para ogg");
      }

      await uploadFileToS3(signedUrl, newBlob);

      return { location, filename, newBlob };
    } catch (err) {
      logger.error(`[handleUploadMedia] error`, { error: err });
      return null;
    }
  };

  const startRecording = async () => {
    const stream = await getMicrophonePermission();
    if (!stream || !stream.active || isSendingMessage) return;

    setIsRecording(true);
    setIsPaused(false);
    setCounter(0);

    const recorder = new MediaRecorder(stream);

    const chunks: Blob[] = [];

    recorder.onstart = () => {
      setCounter(0);
    };

    recorder.ondataavailable = (event) => {
      chunks.push(event.data);
    };

    setAudioChunks(chunks);

    recorder.start();
    setMediaRecorder(recorder);
  };

  const stopRecordingAndSubmit = async () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
      setIsPaused(false);
      setCounter(0);
      if (timeInterval) {
        clearInterval(timeInterval);
        setTimeInterval(null);
      }
      mediaRecorder.stop();
      mediaRecorder.onstop = async () => {
        const newAudioBlob = new Blob(audioChunks, { type: "audio/webm" });
        await handleSendRecording(newAudioBlob);
      };
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.pause();
      setIsPaused(true);
      if (timeInterval) {
        clearInterval(timeInterval);
        setTimeInterval(null);
      }
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "paused") {
      mediaRecorder.resume();
      setIsPaused(false);
      setIsRecording(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
      setIsPaused(false);
      setCounter(0);
      if (timeInterval) {
        clearInterval(timeInterval);
        setTimeInterval(null);
      }
      mediaRecorder.stop();
    }
  };

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    if (!messageInputRef.current) return;

    const emoji = emojiData.emoji;

    const input = messageInputRef.current;
    const startPos = input.selectionStart ?? 0;
    const endPos = input.selectionEnd ?? 0;

    const value = input.value;
    const newValue = value.slice(0, startPos) + emoji + value.slice(endPos);
    setMessageInputValue(newValue);

    setTimeout(() => {
      if (!messageInputRef.current) return;

      messageInputRef.current.selectionStart = messageInputRef.current.selectionEnd = startPos + emoji.length;
    }, 0);
  };

  const handleShortcutsClick = (event: React.MouseEvent<HTMLElement>) => {
    setShortcutsAnchorEl(event.currentTarget);
  };

  const handleShortcutsClose = () => {
    setShortcutsAnchorEl(null);
  };

  const handleTaskSelect = (task: AgentTask) => {
    setActiveTask(task);
    handleShortcutsClose();
  };

  useEffect(() => {
    if (activeTask?.agentName === SubAgentName.DOCUMENT_REQUESTER && activeChat?.docsToAsk && taskInputRef.current) {
      taskInputRef.current.value = activeChat.docsToAsk;
      setTaskInputValue(activeChat.docsToAsk);
    }
  }, [activeTask, activeChat?.docsToAsk]);

  const handleCloseTask = () => {
    setActiveTask(null);
  };

  const handleOpenEmojiPicker = (event: React.MouseEvent) => {
    setEmojiPickerAnchorEl(event.currentTarget);
  };

  const handleStartAgent = async () => {
    const currentTaskValue = taskInputRef.current?.value || "";
    if (!activeTask || !currentTaskValue.trim() || !activeChat) return;

    const agentPayload =
      activeTask.agentName === SubAgentName.DOCUMENT_REQUESTER ? { docs_to_ask: currentTaskValue } : {};

    await startAIAgent({
      applicantPhoneNumber: activeChat.endClientNumber,
      agentName: activeTask.agentName,
      agentPayload,
    });

    if (taskInputRef.current) {
      taskInputRef.current.value = "";
      setTaskInputValue("");
    }
    setActiveTask(null);
  };

  return (
    <Box
      component="form"
      ref={formRef}
      onSubmit={handleSubmit}
      sx={{
        borderTop: "1px solid",
        borderColor: "divider",
        backgroundColor: "common.white",
        padding: 2,
        paddingRight: "104px",
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {activeTask && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1">{activeTask.label}</Typography>
          <IconButton onClick={handleCloseTask} size="small">
            <MuiIcons.Close />
          </IconButton>
        </Box>
      )}

      <Box sx={{ flex: 1 }}>
        {isRecording ? (
          <AudioInput
            isRecording={isRecording}
            isPaused={isPaused}
            counter={counter}
            onDelete={stopRecording}
            setCounter={setCounter}
            timeInterval={timeInterval}
            setTimeInterval={setTimeInterval}
          />
        ) : activeTask ? (
          <TaskInput
            onChange={(value) => {
              if (taskInputRef.current) {
                taskInputRef.current.value = value;
              }
              debouncedSetTaskInput(value);
            }}
            onKeyDown={handleKeyDown}
            onPasteFiles={onPasteFiles}
            inputRef={taskInputRef}
            placeholder="Digite a instrução para o agente"
          />
        ) : (
          <MessageInput
            onChange={(value) => {
              if (messageInputRef.current) {
                messageInputRef.current.value = value;
              }
              debouncedSetMessageInput(value);
            }}
            onKeyDown={handleKeyDown}
            onPasteFiles={onPasteFiles}
            inputRef={messageInputRef}
            shouldUseTemplate={!!templateToUse}
            templateText={templateTextWithoutMessage || undefined}
            value={messageInputValue}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ActionButtons
          isRecording={isRecording}
          isPaused={isPaused}
          hasActiveTask={!!activeTask}
          hasTemplate={!!templateToUse}
          onStartRecording={startRecording}
          onPauseRecording={pauseRecording}
          onResumeRecording={resumeRecording}
          onOpenFilesMenu={openFilesMenu}
          onOpenEmojiPicker={handleOpenEmojiPicker}
          onOpenShortcutsMenu={handleShortcutsClick}
        />

        <Box>
          {activeTask ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleStartAgent}
              disabled={!taskInputValue.trim() || isStartingAIAgent || !!templateToUse}
            >
              {isStartingAIAgent ? "Iniciando..." : "Iniciar agente"}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              endIcon={<MuiIcons.SendRounded />}
              type="submit"
              disabled={!messageInputValue.trim() && !isRecording}
            >
              Enviar
            </Button>
          )}
        </Box>
      </Box>

      <Popover
        id={!!emojiPickerAnchorEl ? "emoji-picker" : undefined}
        open={!!emojiPickerAnchorEl}
        anchorEl={emojiPickerAnchorEl}
        onClose={() => setEmojiPickerAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "transparent",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <EmojiPicker
          open={true}
          onEmojiClick={handleEmojiClick}
          emojiStyle={EmojiStyle.NATIVE}
          searchPlaceholder="Pesquisar..."
          previewConfig={{
            defaultCaption: "Como você está se sentindo?",
          }}
          categories={[
            { category: Categories.SUGGESTED, name: "Recentes" },
            { category: Categories.SMILEYS_PEOPLE, name: "Emoticons" },
            { category: Categories.ANIMALS_NATURE, name: "Animais & Natureza" },
            { category: Categories.FOOD_DRINK, name: "Comida & Bebida" },
            { category: Categories.TRAVEL_PLACES, name: "Viagens" },
            { category: Categories.ACTIVITIES, name: "Atividades" },
            { category: Categories.OBJECTS, name: "Objetos" },
            { category: Categories.SYMBOLS, name: "Símbolos" },
            { category: Categories.FLAGS, name: "Bandeiras" },
          ]}
        />
      </Popover>

      <Menu
        anchorEl={shortcutsAnchorEl}
        open={Boolean(shortcutsAnchorEl)}
        onClose={handleShortcutsClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            marginBottom: 1,
          },
          "& .MuiModal-backdrop": {
            backgroundColor: "transparent",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <MenuItem
          onClick={() =>
            handleTaskSelect({
              agentName: SubAgentName.DOCUMENT_REQUESTER,
              label: "Agente de documentação",
            })
          }
        >
          Agente de documentação
        </MenuItem>
      </Menu>

      <FilesSelector
        open={!!filesMenuAnchorEl}
        anchorEl={filesMenuAnchorEl!}
        onClose={closeFilesMenu}
        openDocumentSelector={() => {
          openDocumentSelector();
          closeFilesMenu();
        }}
        openImageVideoSelector={() => {
          openImageVideoSelector();
          closeFilesMenu();
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            marginBottom: 1,
          },
        }}
      />
    </Box>
  );
};

const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>, onPasteFiles: (files: File[]) => void) => {
  const files = e.clipboardData.files;
  if (files.length > 0) {
    onPasteFiles(Array.from(files));
  }
};

interface MessageInputProps {
  onChange: (value: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onPasteFiles: (files: File[]) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  shouldUseTemplate?: boolean;
  templateText?: string;
  value: string;
}

const MessageInput: React.FC<MessageInputProps> = ({
  onChange,
  onKeyDown,
  onPasteFiles,
  inputRef,
  shouldUseTemplate,
  templateText,
  value,
}) => {
  const handleLimitedInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(limitedTextInput(e));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "6px", flex: 1 }}>
      <LimitedTextInputTooltip disabled={!shouldUseTemplate}>
        <Box
          sx={{ display: "flex", flexDirection: "column", width: "100%" }}
          onPaste={(e) => handlePaste(e, onPasteFiles)}
        >
          {!!shouldUseTemplate && templateText && (
            <Typography variant="multiLineBody" color="text.primary" mb={0.5}>
              {parseStringToHtml(formatChatMessage({ message: templateText }))}
            </Typography>
          )}
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: 1,
                padding: 1,
                minHeight: "48px",
                "& fieldset": {
                  border: "1px solid",
                  borderColor: "divider",
                },
                "&:hover fieldset": {
                  borderColor: "divider",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "divider",
                },
              },
            }}
            maxRows={4}
            minRows={2}
            autoComplete="off"
            hiddenLabel
            variant="outlined"
            placeholder="Escreva algo"
            size="small"
            multiline
            onChange={(e) => {
              if (!!shouldUseTemplate) {
                return handleLimitedInput(e);
              }
              onChange(e.target.value);
            }}
            onKeyDown={onKeyDown}
            inputRef={inputRef}
          />
        </Box>
      </LimitedTextInputTooltip>

      {!!shouldUseTemplate && <MessageTemplateCharacterCounter inputLength={value.length} />}
    </Box>
  );
};

interface TaskInputProps {
  onChange: (value: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onPasteFiles: (files: File[]) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  placeholder?: string;
}

const TaskInput: React.FC<TaskInputProps> = ({ onChange, onKeyDown, onPasteFiles, inputRef, placeholder }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "6px", flex: 1 }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
        onPaste={(e) => handlePaste(e, onPasteFiles)}
      >
        <TextField
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: 1,
              padding: 1,
              minHeight: "48px",
              "& fieldset": {
                border: "1px solid",
                borderColor: "divider",
              },
              "&:hover fieldset": {
                borderColor: "divider",
              },
              "&.Mui-focused fieldset": {
                borderColor: "divider",
              },
            },
          }}
          maxRows={4}
          minRows={2}
          autoComplete="off"
          hiddenLabel
          variant="outlined"
          placeholder={placeholder}
          size="small"
          multiline
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={onKeyDown}
          inputRef={inputRef}
        />
      </Box>
    </Box>
  );
};

interface AudioInputProps {
  isRecording: boolean;
  isPaused: boolean;
  counter: number;
  onDelete: () => void;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  timeInterval: NodeJS.Timeout | null;
  setTimeInterval: React.Dispatch<React.SetStateAction<NodeJS.Timeout | null>>;
}

const AudioInput: React.FC<AudioInputProps> = ({
  isRecording,
  isPaused,
  counter,
  onDelete,
  setCounter,
  timeInterval,
  setTimeInterval,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: "6px", flex: 1 }}>
      <IconButton onClick={onDelete}>
        <MuiIcons.Delete sx={{ width: "24px", height: "24px", cursor: "pointer" }} />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          bgcolor: "common.white",
          borderRadius: "24px",
          justifyContent: "space-between",
          alignItems: "center",
          paddingY: "8.5px",
          paddingX: "14px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {isRecording && (
            <Timer
              timeInterval={timeInterval}
              setTimeInterval={setTimeInterval}
              isPaused={isPaused}
              counter={counter}
              setCounter={setCounter}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

interface ActionButtonsProps {
  isRecording: boolean;
  isPaused: boolean;
  hasActiveTask: boolean;
  hasTemplate: boolean;
  onStartRecording: () => void;
  onPauseRecording: () => void;
  onResumeRecording: () => void;
  onOpenFilesMenu: (event: React.MouseEvent) => void;
  onOpenEmojiPicker: (event: React.MouseEvent) => void;
  onOpenShortcutsMenu: (event: React.MouseEvent<HTMLElement>) => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  isRecording,
  isPaused,
  hasActiveTask,
  hasTemplate,
  onStartRecording,
  onPauseRecording,
  onResumeRecording,
  onOpenFilesMenu,
  onOpenEmojiPicker,
  onOpenShortcutsMenu,
}) => {
  const getIconColor = (isDisabled: boolean) => (isDisabled ? "grey.400" : "grey.600");
  const { startWhatsappAgentEnabled } = useFeatureFlags();

  const buttonRules = {
    microphone: {
      isDisabled: hasActiveTask || hasTemplate,
      getTooltipMessage: () => {
        if (hasActiveTask) return "O microfone ficará disponível novamente quando a task for fechada";
        if (hasTemplate) return "O microfone ficará disponível novamente quando o cliente enviar uma mensagem";
        return "";
      },
    },
    attachment: {
      isDisabled: hasTemplate,
      getTooltipMessage: () => {
        if (hasTemplate) return "Os anexos ficarão disponíveis novamente quando o cliente enviar uma mensagem";
        return "";
      },
    },
    emoji: {
      isDisabled: isRecording || hasActiveTask,
      getTooltipMessage: () => {
        if (isRecording) return "Os emojis ficarão disponíveis novamente quando a gravação for finalizada";
        if (hasActiveTask) return "Os emojis ficarão disponíveis novamente quando a task for fechada";
        return "";
      },
    },
    shortcuts: {
      isDisabled: hasTemplate || isRecording || !startWhatsappAgentEnabled,
      getTooltipMessage: () => {
        if (!startWhatsappAgentEnabled) return "Os atalhos ficarão disponíveis quando a funcionalidade for liberada";
        if (isRecording) return "Os atalhos ficarão disponíveis novamente quando a gravação for finalizada";
        if (hasTemplate) return "Os atalhos ficarão disponíveis novamente quando o cliente enviar uma mensagem";
        return "";
      },
    },
  };

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {isRecording ? (
        isPaused ? (
          <IconButton onClick={onResumeRecording}>
            <MuiIcons.Mic sx={{ width: "24px", color: "grey.600" }} />
          </IconButton>
        ) : (
          <IconButton onClick={onPauseRecording}>
            <MuiIcons.PauseCircleOutline
              style={{
                color: "#ff3b30",
                flexShrink: 0,
                width: "24px",
                height: "24px",
                cursor: "pointer",
              }}
            />
          </IconButton>
        )
      ) : (
        <Tooltip title={buttonRules.microphone.getTooltipMessage()} placement="top" arrow>
          <span>
            <IconButton onClick={onStartRecording} disabled={buttonRules.microphone.isDisabled}>
              <MuiIcons.Mic sx={{ width: "24px", color: getIconColor(buttonRules.microphone.isDisabled) }} />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <Tooltip title={buttonRules.attachment.getTooltipMessage()} placement="top" arrow>
        <span>
          <IconButton disabled={buttonRules.attachment.isDisabled} onClick={onOpenFilesMenu}>
            <MuiIcons.AttachFile sx={{ width: "24px", color: getIconColor(buttonRules.attachment.isDisabled) }} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={buttonRules.emoji.getTooltipMessage()} placement="top" arrow>
        <span>
          <IconButton onClick={onOpenEmojiPicker} disabled={buttonRules.emoji.isDisabled}>
            <MuiIcons.EmojiEmotions sx={{ width: "24px", color: getIconColor(buttonRules.emoji.isDisabled) }} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={buttonRules.shortcuts.getTooltipMessage()} placement="top" arrow>
        <span>
          <Button
            startIcon={<MuiIcons.Bolt sx={{ width: "24px", color: getIconColor(buttonRules.shortcuts.isDisabled) }} />}
            onClick={onOpenShortcutsMenu}
            disabled={buttonRules.shortcuts.isDisabled}
            sx={{
              textTransform: "none",
              color: getIconColor(buttonRules.shortcuts.isDisabled),
            }}
          >
            Atalhos
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

interface TimerProps {
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  isPaused: boolean;
  timeInterval: NodeJS.Timeout | null;
  setTimeInterval: React.Dispatch<React.SetStateAction<NodeJS.Timeout | null>>;
}

const Timer: React.FC<TimerProps> = ({ counter, setCounter, isPaused, timeInterval, setTimeInterval }) => {
  useEffect(() => {
    if (isPaused && timeInterval) {
      clearInterval(timeInterval);
      setTimeInterval(null);
    }
  }, [isPaused, timeInterval, setTimeInterval]);

  useEffect(() => {
    if (!isPaused && !timeInterval) {
      const interval = setInterval(() => {
        setCounter((prev) => prev + 1);
      }, 1000);
      setTimeInterval(interval);
    }
    return () => {
      if (timeInterval) {
        clearInterval(timeInterval);
      }
    };
  }, [isPaused, timeInterval, setTimeInterval, setCounter]);

  return (
    <Box>
      <Typography variant="multiLineBody" color="text.primary">
        {DateTime.fromSeconds(counter).toFormat("mm:ss")}
      </Typography>
    </Box>
  );
};
