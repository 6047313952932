import React, { useState } from "react";
import { Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { PendingStatusViewProps } from "./types";

interface WhatsAppAction {
  message: string;
}

const RequestChangesModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: (instructions: string) => void;
  loading: boolean;
}> = ({ open, onClose, onSubmit, loading }) => {
  const [instructions, setInstructions] = useState("");

  const handleSubmit = () => {
    onSubmit(instructions);
    setInstructions("");
  };

  return (
    <Dialog open={open} onClose={loading ? undefined : onClose} maxWidth="md" fullWidth>
      <DialogTitle variant="h6">Solicitar Ajustes</DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Box mt={2}>
          <Typography variant="subtitle2" sx={{ color: "text.secondary", fontWeight: 500, mb: 1 }}>
            Instruções de Alteração
          </Typography>
          <TextField
            fullWidth
            disabled={loading}
            onChange={(e) => setInstructions(e.target.value)}
            placeholder="Ex: Adicione mais detalhes sobre a urgência da consulta e mencione a necessidade do laudo médico"
            multiline
            rows={4}
            maxRows={4}
            value={instructions}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderColor: "common.lightShade",
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, gap: 2 }}>
        <Button
          onClick={onClose}
          disabled={loading}
          variant="text"
          sx={{
            color: "text.primary",
            "&:hover": {
              textDecoration: "underline",
              backgroundColor: "transparent",
            },
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={loading || !instructions}
          variant="contained"
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          Enviar Ajustes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SendWhatsappPendingView: React.FC<PendingStatusViewProps> = ({ request, loading, onApprove, onReprocessing }) => {
  const originalMessage = (request.output?.payload?.action as WhatsAppAction)?.message ?? "";
  const [message, setMessage] = useState(originalMessage);
  const [showRequestChanges, setShowRequestChanges] = useState(false);
  const hasChanges = message !== originalMessage;

  const handleApprove = async () => {
    try {
      if (hasChanges && request.output?.payload?.action) {
        const editedOutput = {
          execution_id: request.output.execution_id,
          success: request.output.success,
          billable: request.output.billable,
          payload: {
            ...request.output.payload,
            action: {
              ...request.output.payload.action,
              message,
            },
          },
        };
        await onApprove(editedOutput);
      } else {
        await onApprove();
      }
    } catch (error) {
      // Error handling is done at a higher level
    }
  };

  const handleRequestChanges = async (instructions: string) => {
    try {
      await onReprocessing(instructions);
      setShowRequestChanges(false);
    } catch (error) {
      // Handle error
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <TextField
        fullWidth
        multiline
        minRows={8}
        maxRows={16}
        label="Mensagem a ser enviada"
        placeholder="Mensagem a ser enviada..."
        disabled={loading}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderColor: hasChanges ? "primary.main" : "common.lightShade",
            "&:hover": {
              borderColor: hasChanges ? "primary.dark" : "common.lightShade",
            },
          },
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
        <Button
          disabled={loading}
          variant="text"
          onClick={() => setShowRequestChanges(true)}
          sx={{
            color: "text.secondary",
            "&:hover": {
              backgroundColor: "grey.100",
            },
          }}
        >
          Solicitar Ajustes
        </Button>
        <Button
          disabled={loading || (!hasChanges && !message)}
          variant={hasChanges ? "contained" : "text"}
          onClick={handleApprove}
          sx={
            hasChanges
              ? {
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                  },
                }
              : {
                  color: "text.primary",
                  "&:hover": {
                    textDecoration: "underline",
                    backgroundColor: "transparent",
                  },
                }
          }
        >
          {hasChanges ? "Enviar com Edições" : "Enviar Mensagem"}
        </Button>
      </Box>

      <RequestChangesModal
        open={showRequestChanges}
        onClose={() => setShowRequestChanges(false)}
        onSubmit={handleRequestChanges}
        loading={loading}
      />
    </Box>
  );
};

export const PendingView: React.FC<PendingStatusViewProps> = (props) => {
  const { request } = props;

  if (request.request_type === "SEND_WHATSAPP_MESSAGE") {
    return <SendWhatsappPendingView {...props} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
      }}
    >
      <Typography
        variant="body2"
        color="error.main"
        sx={{
          textAlign: "center",
        }}
      >
        Tipo de requisição não suportado: {request.request_type}
      </Typography>
    </Box>
  );
};
