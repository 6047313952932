import { Box, Collapse } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { useDebouncedCallback } from "@/hooks/useDebouncedCallback";
import { LexZapChat } from "@/hooks/lexZap/types";
import { useLexZapActiveChat } from "@/hooks/lexZap/useLexZapActiveChat";
import { useApprovalManagement } from "@/hooks/useApprovalRequests/useApprovalRequests";
import { useResizable } from "@/components/EditorPageLayout/useResizable";
import { useSocket } from "@/contexts/WebSocketContext";
import { WhatsappUpdateType } from "@/contexts/WebSocketContext/types/whatsapp.types";
import { useCompanyTeam } from "@/hooks/company/useCompanyTeam";
import { useAuthContext } from "@/contexts/AuthContext";

import { Chat } from "./components/Chat";
import { ChatList } from "./components/ChatList";
import { ChatHistory } from "./components/ChatHistory";
import { ApprovalRequest } from "./components/ApprovalRequest";
import { Checklist } from "./components/Checklist";
import { Resizer } from "@/components/EditorPageLayout/components/Resizer";
import { ChatDetails } from "./components/ChatDetails";
import { AssignedFilter, ChatQuickFilters, ChatQuickFiltersCount } from "./components/QuickFilters/types";
import { QuickFilters } from "./components/QuickFilters";
import { applyChatFilters, calculateChatFiltersCount, calculateChatFilterChanges } from "./utils/chatFilters";

import MessageReceivedSound from "@/assets/sounds/whatsapp-message-received.mp3";
import { scrollbarStyles } from "@/theme/mixins/scrollbar";
import { ChatStatus } from "@/hooks/lexZap/types";

const minChatListWidth = 300;
const minChatWidth = 500;
const minChatHistoryWidth = 200;

const defaultFilters: ChatQuickFilters = {
  ticketStatus: {
    abandoned: true,
    aiService: true,
    redirectedHuman: true,
    initialTriage: true,
    closed: true,
  },
  assigned: AssignedFilter.ALL,
  answered: {
    answered: true,
    unanswered: true,
  },
  responsibles: [],
  chatCreationDateStart: undefined,
  chatCreationDateEnd: undefined,
};

const defaultFiltersCount: ChatQuickFiltersCount = {
  ticketStatus: {
    abandoned: 0,
    aiService: 0,
    redirectedHuman: 0,
    initialTriage: 0,
    closed: 0,
  },
  answered: {
    answered: 0,
    unanswered: 0,
  },
  responsibles: {},
};

const CHATS_FILTERS_LOCAL_STORAGE_KEY = "CHATS_FILTERS_LOCAL_STORAGE_KEY";

export const ChatView = ({ chats, isLoadingChats }: { chats: LexZapChat[] | undefined; isLoadingChats: boolean }) => {
  const { user } = useAuthContext();
  const { data: companyTeamData = [] } = useCompanyTeam();
  const companyTeam = useMemo(
    () =>
      companyTeamData
        .filter((member) => member.isActive)
        .map((m) => ({
          id: m.userId,
          name: m.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [companyTeamData]
  );

  const [tab, setTab] = useState<ChatStatus | string>(ChatStatus.ACTIVE);
  const [searchTerm, setSearchTerm] = useState("");

  const [isChatHistoryOpen, setIsChatHistoryOpen] = useState(false);
  const [isApprovalRequestOpen, setIsApprovalRequestOpen] = useState(false);
  const [isChecklistOpen, setIsChecklistOpen] = useState(false);
  const [isChatDetailsOpen, setIsChatDetailsOpen] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [editedMessages, setEditedMessages] = useState<Record<string, string>>({});
  const { activeChat } = useLexZapActiveChat();
  const { requests, loading: requestsLoading, fetchRequests } = useApprovalManagement();

  const chatApprovalRequests = useMemo(() => {
    if (!activeChat?.endClientNumber) return [];
    return requests.filter((request) => request.applicant_phone_number === activeChat.endClientNumber);
  }, [requests, activeChat?.endClientNumber]);

  const [inUseResponsibleUserIds, setInUseResponsibleUserIds] = useState<string[]>([]);

  useEffect(() => {
    if (chats && chats.length && !inUseResponsibleUserIds.length) {
      const uniqueResponsibles = Array.from(
        new Set(chats.map((chat) => chat.lastTicket?.responsibleUserId).filter((id): id is string => !!id))
      );
      setInUseResponsibleUserIds(uniqueResponsibles);
    }
  }, [chats, inUseResponsibleUserIds]);

  const filteredCompanyTeam = useMemo(
    () => companyTeam.filter((member) => inUseResponsibleUserIds.includes(member.id) || member.id === user?.userId),
    [companyTeam, inUseResponsibleUserIds, user?.userId]
  );

  const [filters, setFilters] = useState<ChatQuickFilters>(() => {
    const savedFiltersString = localStorage.getItem(CHATS_FILTERS_LOCAL_STORAGE_KEY);

    if (!savedFiltersString) {
      return defaultFilters;
    }

    const savedFilters = JSON.parse(savedFiltersString);

    return {
      ...defaultFilters,
      ...savedFilters,
      ticketStatus: {
        ...defaultFilters.ticketStatus,
        ...savedFilters.ticketStatus,
      },
      answered: {
        ...defaultFilters.answered,
        ...savedFilters.answered,
      },
    };
  });

  const handleFiltersChange = (filters: ChatQuickFilters) => {
    setFilters(filters);
    localStorage.setItem(CHATS_FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(filters));
  };

  const filteredChats = useMemo(() => {
    if (!chats) return;

    if (tab === "approvals") {
      const approvalChats = chats.filter((chat) =>
        requests.some((request) => request.applicant_phone_number === chat.endClientNumber)
      );
      return applyChatFilters({
        chats: approvalChats,
        status: ChatStatus.ACTIVE,
        searchTerm,
        filters,
      });
    }

    return applyChatFilters({
      chats,
      status: tab as ChatStatus,
      searchTerm,
      filters,
    });
  }, [chats, searchTerm, tab, filters, requests]);

  const filtersCount: ChatQuickFiltersCount = useMemo(() => {
    if (!filteredChats) return defaultFiltersCount;

    return calculateChatFiltersCount({
      chats: filteredChats,
      companyTeam,
    });
  }, [filteredChats, companyTeam]);

  const filterChanges = useMemo(() => {
    return calculateChatFilterChanges({
      filters,
      defaultFilters,
    });
  }, [filters]);

  useEffect(() => {
    if (chatApprovalRequests.length > 0) {
      setIsApprovalRequestOpen(true);
      setIsChatHistoryOpen(false);
      setIsChecklistOpen(false);
      setIsChatDetailsOpen(false);
    } else {
      setIsApprovalRequestOpen(false);
    }
  }, [chatApprovalRequests]);

  const handleToggleHistory = () => {
    setIsChatHistoryOpen((prev) => {
      const newValue = !prev;
      if (newValue) {
        setIsApprovalRequestOpen(false);
        setIsChecklistOpen(false);
        setIsChatDetailsOpen(false);
      }
      return newValue;
    });
  };

  const handleToggleApprovalRequest = () => {
    setIsApprovalRequestOpen((prev) => {
      const newValue = !prev;
      if (newValue) {
        setIsChatHistoryOpen(false);
        setIsChecklistOpen(false);
        setIsChatDetailsOpen(false);
      }
      return newValue;
    });
  };

  const handleToggleChecklist = () => {
    setIsChecklistOpen((prev) => {
      const newValue = !prev;
      if (newValue) {
        setIsChatHistoryOpen(false);
        setIsApprovalRequestOpen(false);
        setIsChatDetailsOpen(false);
      }
      return newValue;
    });
  };

  const handleToggleChatDetails = () => {
    setIsChatDetailsOpen((prev) => {
      const newValue = !prev;
      if (newValue) {
        setIsChatHistoryOpen(false);
        setIsApprovalRequestOpen(false);
        setIsChecklistOpen(false);
      }
      return newValue;
    });
  };

  const handleToggleFilters = () => {
    setIsFiltersOpen((prev) => {
      if (!prev) {
        setIsApprovalRequestOpen(false);
      }
      return !prev;
    });
  };

  const handleCloseChatHistory = () => {
    setIsChatHistoryOpen(false);
  };

  const handleCloseApprovalRequest = () => {
    setIsApprovalRequestOpen(false);
  };

  const handleCloseChecklist = () => {
    setIsChecklistOpen(false);
  };

  const handleCloseChatDetails = () => {
    setIsChatDetailsOpen(false);
  };

  const handleMessageEdit = (requestId: string, message: string) => {
    setEditedMessages((prev) => ({
      ...prev,
      [requestId]: message,
    }));
  };

  useSocket({
    onWhatsAppUpdate: (update) => {
      if (update.type === WhatsappUpdateType.MESSAGE_RECEIVED && update.shouldPlaySound) {
        const audio = new Audio(MessageReceivedSound);
        void audio.play();
      }
    },
  });

  useEffect(() => {
    void fetchRequests();
  }, [fetchRequests]);

  const [chatHistoryWidth, setChatHistoryWidth] = useState(minChatHistoryWidth);
  const debouncedSetChatHistoryWidth = useDebouncedCallback(setChatHistoryWidth, 1000);
  const chatHistoryWidthRef = useRef<HTMLDivElement>(null);

  const {
    containerRef: mainContainerRef,
    handleMouseDown: handleTicketDetailsResize,
    isResizing: isResizingTicketDetails,
  } = useResizable({
    defaultWidth: 450,
    minWidth: minChatHistoryWidth,
    minOppositeWidth: minChatListWidth + minChatWidth,
    direction: "right",
    onWidthChange: (width) => {
      if (chatHistoryWidthRef.current) {
        chatHistoryWidthRef.current.style.width = `${width}px`;
      }
      debouncedSetChatHistoryWidth(width);
    },
  });

  const [chatListWidth, setChatListWidth] = useState(minChatListWidth);
  const debouncedSetChatListWidth = useDebouncedCallback(setChatListWidth, 1000);
  const {
    containerRef: chatContainerRef,
    handleMouseDown: handleChatListResize,
    isResizing: isResizingChatList,
  } = useResizable({
    defaultWidth: { value: 27, unit: "%" },
    minWidth: minChatListWidth,
    minOppositeWidth: minChatWidth,
    onWidthChange: (width) => {
      if (chatContainerRef.current) {
        chatContainerRef.current.style.gridTemplateColumns = `${width}px minmax(0, 1fr)`;
      }
      debouncedSetChatListWidth(width);
    },
  });

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "min-content 1fr min-content",
        gridTemplateRows: "100%",
      }}
      ref={mainContainerRef}
    >
      <Box
        sx={{
          height: "100%",
          width: isFiltersOpen ? "300px" : "0px",
          transition: "width 300ms linear",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "300px",
            height: "100%",
            display: "flex",
            position: "absolute",
            flexDirection: "column",
            overflowY: "scroll",
            boxSizing: "border-box",
            flex: 1,
            border: "0px",
            borderTop: "1px solid",
            borderColor: "common.lightShade",
            bgcolor: "common.white",
            overflowX: "hidden",
            ...scrollbarStyles,
          }}
        >
          <QuickFilters
            filters={filters}
            onChange={handleFiltersChange}
            defaultFilters={defaultFilters}
            filtersCount={filtersCount}
            companyTeam={filteredCompanyTeam}
            user={user}
          />
        </Box>
      </Box>

      <Box
        ref={chatContainerRef}
        sx={{
          height: "100%",
          width: "100%",
          display: "grid",
          gridTemplateColumns: `${chatListWidth}px minmax(0, 1fr)`,
          gridTemplateRows: "100%",
          borderRadius: 0,
          overflow: "hidden",
          borderLeft: "0px",
          border: "1px solid",
          borderColor: "common.lightShade",
        }}
      >
        <Box sx={{ position: "relative", display: "flex" }}>
          <ChatList
            chats={filteredChats}
            isLoading={isLoadingChats}
            requests={requests}
            requestsLoading={requestsLoading}
            onToggleFilters={handleToggleFilters}
            tab={tab}
            onChangeTab={setTab}
            searchTerm={searchTerm}
            onChangeSearchTerm={setSearchTerm}
            filterChanges={filterChanges}
          />
          <Resizer onMouseDown={handleChatListResize} isResizing={isResizingChatList} />
        </Box>

        <Box sx={{ position: "relative", display: "flex", height: "100%", overflow: "hidden", minWidth: minChatWidth }}>
          <Chat
            isLoadingChats={isLoadingChats}
            onToggleHistory={handleToggleHistory}
            onToggleApprovalRequest={handleToggleApprovalRequest}
            onToggleChecklist={handleToggleChecklist}
            onToggleChatDetails={handleToggleChatDetails}
            requests={chatApprovalRequests}
            activeChat={activeChat || null}
            allChats={chats || []}
          />
        </Box>
      </Box>

      <Collapse
        in={isChatHistoryOpen || isApprovalRequestOpen || isChecklistOpen || isChatDetailsOpen}
        orientation="horizontal"
        easing={{ enter: "linear", exit: "linear" }}
        sx={{ position: "relative" }}
      >
        {(isChatHistoryOpen || isApprovalRequestOpen || isChecklistOpen || isChatDetailsOpen) && (
          <Resizer
            onMouseDown={handleTicketDetailsResize}
            isResizing={isResizingTicketDetails}
            anchor="left"
            sx={{ left: "3px" }}
          />
        )}

        <Box
          ref={chatHistoryWidthRef}
          sx={{
            width: chatHistoryWidth,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {activeChat && isChatHistoryOpen && (
            <Box
              sx={{
                flex: 1,
                borderRadius: 1,
                border: "1px solid",
                borderColor: "common.lightShade",
                overflow: "hidden",
                marginLeft: 2,
              }}
            >
              <ChatHistory chat={activeChat} onClose={handleCloseChatHistory} />
            </Box>
          )}
          {activeChat && isApprovalRequestOpen && (
            <Box
              sx={{
                flex: 1,
                borderRadius: 1,
                border: "1px solid",
                borderColor: "common.lightShade",
                overflow: "hidden",
                marginLeft: 2,
                bgcolor: "grey.50",
              }}
            >
              <ApprovalRequest
                onClose={handleCloseApprovalRequest}
                requests={chatApprovalRequests}
                editedMessages={editedMessages}
                onMessageEdit={handleMessageEdit}
              />
            </Box>
          )}
          {activeChat && isChecklistOpen && (
            <Box
              sx={{
                flex: 1,
                borderRadius: 1,
                border: "1px solid",
                borderColor: "common.lightShade",
                overflow: "hidden",
                marginLeft: 2,
                bgcolor: "grey.50",
              }}
            >
              <Checklist chat={activeChat} onClose={handleCloseChecklist} />
            </Box>
          )}
          {activeChat && isChatDetailsOpen && (
            <Box
              sx={{
                flex: 1,
                borderRadius: 1,
                border: "1px solid",
                borderColor: "common.lightShade",
                overflow: "hidden",
                marginLeft: 2,
                bgcolor: "grey.50",
              }}
            >
              <ChatDetails chat={activeChat} onClose={handleCloseChatDetails} />
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};
