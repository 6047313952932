import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Computer from "@/assets/svgs/computer.svg?react";
import Whatsapp from "@/assets/svgs/WhatsAppOutlined.svg?react";
import LexterLogoIcon from "@/assets/svgs/lexter-logo.svg?react";
import { Button, useTheme } from "@mui/material";
import { useCredits } from "@/hooks/credits/useCredits";
import { PlanType } from "@/hooks/credits/types";

type WarningMobileScreenProps = {
  contactLink: string;
};

export const WarningMobileScreen = ({ contactLink }: WarningMobileScreenProps) => {
  const theme = useTheme();
  const { data: credits } = useCredits();
  const isTrial = credits?.companyPlan?.plan === PlanType.TRIAL;
  return (
    <Box
      sx={{
        overflow: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "grey.50",
        textAlign: "center",
        padding: 3,
        position: "fixed",
        top: 0,
        left: 0,
        color: undefined,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "380px",
          height: "auto",
          margin: "auto",
          gap: 5,
        }}
      >
        <Computer />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Obrigado pelo seu interesse na Lexter!</Typography>
          <Typography
            variant="body1"
            sx={{
              color: "text.secondary",
              fontSize: "16px",
            }}
          >
            Por enquanto, a nossa plataforma <b>não funciona em dispositivos móveis</b>. Para uma melhor experiência,
            utilize um computador ou notebook.
          </Typography>
          {isTrial && (
            <Box
              sx={{
                backgroundColor: "background.default",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 3,
                borderRadius: "1rem",
                gap: 2,
                width: "90%",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  color: "text.primary",
                }}
              >
                Quer falar com um especialista?
              </Typography>
              <Button
                size="large"
                href={contactLink}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 1,
                  gap: 1,
                  width: "100%",
                  backgroundColor: "primary.main",
                  borderRadius: "5px",
                  color: "black",
                }}
              >
                <Whatsapp />
                <Typography variant="body3">Falar agora</Typography>
              </Button>
            </Box>
          )}
          <LexterLogoIcon
            height={53}
            style={{
              color: theme.palette.primary.main,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
