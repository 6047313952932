import { useQueryClient, useInfiniteQuery, InfiniteData } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { CaseMessage, LexZapChat } from "../types";
import { useSocket } from "@/contexts/WebSocketContext";
import { MessageReceivedUpdate, WhatsappUpdateType } from "@/contexts/WebSocketContext/types/whatsapp.types";
import { useImpersonation } from "@/hooks/useImpersonation";
import { AdminLexZapService } from "@/services/lexZap";
import { useLexZapCompany } from "../useLexZapCompany";
import { getChatsQueryKey } from "../useLexZapChats";
import { useMemo } from "react";
import { upsertLexZapMessage } from "./utils/upsertLexZapMessage";
import { LexZapMessagePage } from "@/services/lexZap/types";

export const getMessagesQueryKey = ({ applicantPhoneNumber }: { applicantPhoneNumber?: string }) => [
  "infiniteLexZapMessages",
  applicantPhoneNumber,
];

interface UseLexZapMessagesProps {
  applicantPhoneNumber?: string;
  disabled?: boolean;
}

export const useLexZapMessages = ({ applicantPhoneNumber, disabled }: UseLexZapMessagesProps) => {
  const queryClient = useQueryClient();
  const { getLexZapMessages } = useApi();
  const { impersonatedUser } = useImpersonation();
  const { data: connectionData } = useLexZapCompany();
  const hasWhatsapp = !!connectionData?.phoneNumber;

  useSocket({
    onWhatsAppUpdate: (update) => {
      if (impersonatedUser) {
        return;
      }

      if (update.type === WhatsappUpdateType.MESSAGE_RECEIVED || update.type === WhatsappUpdateType.MESSAGE_CHANGED) {
        const messageApplicantNumber = update.message.applicantPhoneNumber;

        const mergeNewMessage = (oldMessage: CaseMessage, newMessage: CaseMessage): CaseMessage => {
          return {
            ...oldMessage,
            ...newMessage,
            statusUpdateTimestamp: {
              ...oldMessage.statusUpdateTimestamp,
              ...newMessage.statusUpdateTimestamp,
            },
          };
        };

        upsertLexZapMessage({
          queryClient,
          applicantPhoneNumber: messageApplicantNumber,
          where: (msg) => {
            const sameId = msg.id === update.message.id;
            const sameRequestId = !!msg.requestId && (update as MessageReceivedUpdate).requestId === msg.requestId;
            const sameBodyOrFilenameOfSendingMessage =
              update.type === WhatsappUpdateType.MESSAGE_CHANGED &&
              !!msg.isSending &&
              ((!!msg.body && msg.body === update.message.body) ||
                (!!msg.media && msg.media.filename === update.message.media?.filename));

            return sameId || sameRequestId || sameBodyOrFilenameOfSendingMessage;
          },
          upsertFn: (msg) => (msg ? mergeNewMessage(msg, update.message) : update.message),
        });

        queryClient.setQueryData<LexZapChat[]>(getChatsQueryKey(), (oldChats = []) => {
          return oldChats.map((oldChat) =>
            oldChat.endClientNumber === messageApplicantNumber
              ? {
                  ...oldChat,
                  lastMessageDate: Math.max(oldChat.lastMessageDate || 0, update.message.timestamp),
                }
              : oldChat
          );
        });

        queryClient.setQueryData<LexZapChat[]>(getChatsQueryKey(), (oldChats = []) => {
          return oldChats.map((oldChat) =>
            oldChat.endClientNumber === messageApplicantNumber
              ? {
                  ...oldChat,
                  lastMessageDate: Math.max(oldChat.lastMessageDate || 0, update.message.timestamp),
                }
              : oldChat
          );
        });

        const chats = queryClient.getQueryData<LexZapChat[]>(getChatsQueryKey());
        const chat = chats?.find((chat) => chat.endClientNumber === messageApplicantNumber);

        if (!chat?.hasMessagesFetched) {
          void queryClient.invalidateQueries({
            queryKey: getMessagesQueryKey({ applicantPhoneNumber: messageApplicantNumber }),
          });
        }
      }
    },
  });

  const query = useInfiniteQuery<
    LexZapMessagePage,
    Error,
    InfiniteData<LexZapMessagePage>,
    ReturnType<typeof getMessagesQueryKey>,
    string | undefined
  >({
    queryKey: getMessagesQueryKey({ applicantPhoneNumber }),
    queryFn: async ({ pageParam }) => {
      if (!applicantPhoneNumber) {
        return { messages: [], pageToken: undefined };
      }

      let messagesResult: { messages: CaseMessage[]; pageToken?: string };
      const limit = 15;

      if (impersonatedUser) {
        messagesResult = await AdminLexZapService.getLexZapMessages({
          applicantPhoneNumber,
          companyId: impersonatedUser.companyId,
          pageToken: pageParam,
          limit,
        });
      } else {
        messagesResult = await getLexZapMessages({ applicantPhoneNumber, pageToken: pageParam, limit });
      }

      queryClient.setQueryData<LexZapChat[]>(getChatsQueryKey(), (oldChats = []) => {
        return oldChats.map((oldChat) =>
          oldChat.endClientNumber === applicantPhoneNumber ? { ...oldChat, hasMessagesFetched: true } : oldChat
        );
      });

      return messagesResult;
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage.pageToken,
    enabled: !!applicantPhoneNumber && !disabled && (hasWhatsapp || !!impersonatedUser),
    staleTime: Infinity,
  });

  const sortedMessages = useMemo(() => {
    return query.data?.pages.flatMap((page) => page.messages).sort((a, b) => a.timestamp - b.timestamp);
  }, [query.data]);

  return {
    ...query,
    data: sortedMessages,
  };
};
