import React from "react";
import { Box, Chip, Stack, Typography, Select, MenuItem } from "@mui/material";
import { ChatMode, ChatStatus } from "@/services/whatsappServer/types/chat";
import { LeadStage, SenderType, darkBackgroundStages, leadStageColors } from "../types";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { CompanyMember } from "@/hooks/company/types";

export const StatusCell = React.memo(({ value }: { value: ChatStatus }) => (
  <Chip
    label={value === ChatStatus.ACTIVE ? "Ativo" : "Arquivado"}
    color={value === ChatStatus.ACTIVE ? "success" : "default"}
    size="small"
  />
));

export const CreatedAtCell = React.memo(({ value }: { value: string }) => {
  const date = new Date(value);
  return (
    <Stack>
      <Typography variant="body2">
        {date.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {date.toLocaleTimeString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </Typography>
    </Stack>
  );
});

export const LastMessageDateCell = React.memo(({ params }: { params: GridRenderCellParams }) => {
  const date = new Date(params.value);
  const formattedDate = date.toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  const getSenderLabel = (senderType?: SenderType) => {
    switch (senderType) {
      case SenderType.APPLICANT:
        return "[USER]";
      case SenderType.ASSISTANT:
        return "[BOT]";
      case SenderType.LAWYER:
        return "[ADV]";
      default:
        return "[SYS]";
    }
  };

  return (
    <Stack spacing={0.5} title={`${formattedDate}\n${params.row.lastMessageText || ""}`}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body2" color="textSecondary">
          {formattedDate}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" color="textSecondary">
          {getSenderLabel(params.row.lastMessageSenderType)}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {params.row.lastMessageText || ""}
        </Typography>
      </Stack>
    </Stack>
  );
});

export const LeadStageCell = React.memo(({ value }: { value: LeadStage }) => {
  const backgroundColor = leadStageColors[value];
  const isDarkBackground = darkBackgroundStages.includes(value);

  return (
    <Box title={value}>
      <Chip
        label={value}
        size="small"
        sx={{
          backgroundColor,
          color: isDarkBackground ? "#ffffff" : "#000000",
          fontWeight: 500,
        }}
      />
    </Box>
  );
});

export const ChatModeCell = React.memo(({ value }: { value: ChatMode }) => {
  let displayText = "";
  let sx = {};

  switch (value) {
    case ChatMode.AI:
      displayText = "IA";
      sx = { backgroundColor: "success.dark", color: "common.white" };
      break;
    case ChatMode.MANUAL:
      displayText = "Manual";
      sx = { backgroundColor: "error.dark", color: "common.white" };
      break;
    case ChatMode.AI_PAUSED_FOR_CURRENT_TICKET:
      displayText = "IA Pausada";
      sx = { backgroundColor: "error.dark", color: "common.white" };
      break;
    default:
      displayText = value;
      sx = { backgroundColor: "grey.100", color: "grey.900" };
  }
  return <Chip label={displayText} sx={sx} size="small" />;
});

export const AssignedUserIdsCell = React.memo(
  ({ userIds, companyUsers }: { userIds: string[]; companyUsers?: CompanyMember[] }) => {
    const tooltipContent = userIds
      .map((userId: string) => {
        const user = companyUsers?.find((u) => u.userId === userId);
        return user ? user.name : `${userId} (não encontrado)`;
      })
      .join("\n");

    return (
      <Stack direction="row" spacing={1} title={tooltipContent}>
        {userIds.map((userId: string) => {
          const user = companyUsers?.find((u) => u.userId === userId);
          if (!user) {
            return (
              <Typography key={userId} color="error" sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                • {userId} <span title="Usuário não encontrado">⚠️</span>
              </Typography>
            );
          }

          const [firstName, ...lastNames] = user.name.split(" ");
          const lastName = lastNames[lastNames.length - 1];
          return (
            <Typography key={userId}>
              • {firstName} {lastName}
            </Typography>
          );
        })}
      </Stack>
    );
  }
);

export const AssignedUserEditCell = React.memo(
  ({ params, companyUsers }: { params: GridRenderCellParams; companyUsers?: CompanyMember[] }) => {
    const activeUsers = React.useMemo(() => companyUsers?.filter((user) => user.isActive) || [], [companyUsers]);

    return (
      <Select
        multiple
        defaultValue={params.value || []}
        value={params.value || []}
        onChange={(e) =>
          params.api.setEditCellValue(
            {
              id: params.id,
              field: params.field,
              value: e.target.value,
            },
            e
          )
        }
        sx={{ width: "100%" }}
      >
        {activeUsers.map((user) => (
          <MenuItem
            key={user.userId}
            value={user.userId}
            sx={{
              color: "grey.800",
              backgroundColor: "common.white",
              "&:hover": {
                backgroundColor: "grey.50",
                color: "common.black",
              },
              "&.Mui-selected": {
                backgroundColor: "secondary.light",
                color: "primary.main",
                "&:hover": {
                  backgroundColor: "secondary.main",
                  color: "primary.dark",
                },
              },
              "&.Mui-disabled": {
                backgroundColor: "grey.50",
                color: "grey.400",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "secondary.light",
                outline: `2px solid primary.main`,
              },
              transition: "all 0.2s ease",
            }}
          >
            {user.name}
          </MenuItem>
        ))}
      </Select>
    );
  }
);

export const LeadStageEditCell = React.memo(({ params }: { params: GridRenderCellParams }) => (
  <Select
    defaultValue={params.value}
    value={params.value}
    onChange={(e) =>
      params.api.setEditCellValue(
        {
          id: params.id,
          field: params.field,
          value: e.target.value,
        },
        e
      )
    }
  >
    {Object.values(LeadStage).map((stage) => (
      <MenuItem
        key={stage}
        value={stage}
        sx={{
          color: "grey.800",
          backgroundColor: "common.white",
          "&:hover": {
            backgroundColor: "grey.50",
            color: "common.black",
          },
          "&.Mui-selected": {
            backgroundColor: "secondary.light",
            color: "primary.main",
            "&:hover": {
              backgroundColor: "secondary.main",
              color: "primary.dark",
            },
          },
          "&.Mui-disabled": {
            backgroundColor: "grey.50",
            color: "grey.400",
          },
          "&.Mui-focusVisible": {
            backgroundColor: "secondary.light",
            outline: `2px solid primary.main`,
          },
          transition: "all 0.2s ease",
        }}
      >
        {stage}
      </MenuItem>
    ))}
  </Select>
));

StatusCell.displayName = "StatusCell";
CreatedAtCell.displayName = "CreatedAtCell";
LastMessageDateCell.displayName = "LastMessageDateCell";
LeadStageCell.displayName = "LeadStageCell";
ChatModeCell.displayName = "ChatModeCell";
AssignedUserIdsCell.displayName = "AssignedUserIdsCell";
AssignedUserEditCell.displayName = "AssignedUserEditCell";
LeadStageEditCell.displayName = "LeadStageEditCell";
