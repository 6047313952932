import { ChatStage, TicketStatus } from "@/hooks/lexZap/types";

import { ChatStatus, LexZapChat } from "@/hooks/lexZap/types";
import { AnsweredFilter, ChatFilters, ActiveApplicantFilter } from "../../components/FilterModal/types";

const normalizeString = (str: string) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

export const applyChatFilters = ({
  chats,
  status,
  searchTerm,
  filters,
}: {
  chats: LexZapChat[];
  status: ChatStatus;
  searchTerm: string;
  filters: ChatFilters;
}) => {
  let filtered = chats;

  if (status === ChatStatus.ARCHIVED) {
    filtered = filtered.filter((item) => {
      return item.status === ChatStatus.ARCHIVED || item.chatStage === ChatStage.ARCHIVED;
    });
  } else {
    filtered = filtered.filter((item) => {
      return item.status === ChatStatus.ACTIVE && item.chatStage !== ChatStage.ARCHIVED;
    });
  }

  if (searchTerm) {
    const normalizedSearchTerm = normalizeString(searchTerm);
    filtered = filtered.filter(
      (item) =>
        normalizeString(item.applicantName || "").includes(normalizedSearchTerm) ||
        item.endClientNumber.includes(normalizedSearchTerm)
    );
  }

  if (!filters.ticketStatus.open.abandoned) {
    filtered = filtered.filter((item) => item.chatStage !== ChatStage.ABANDONED);
  }

  if (!filters.ticketStatus.open.AIService) {
    filtered = filtered.filter((item) => item.chatStage !== ChatStage.AI_SERVICE);
  }

  if (!filters.ticketStatus.open.redirectedHuman) {
    filtered = filtered.filter((item) => item.chatStage !== ChatStage.REDIRECTED);
  }

  if (!filters.ticketStatus.open.initialTriage) {
    filtered = filtered.filter((item) => item.chatStage !== ChatStage.INITIAL_TRIAGE);
  }

  if (!filters.ticketStatus.closed) {
    filtered = filtered.filter((item) => item.lastTicket?.status !== TicketStatus.CLOSED);
  }

  if (filters.activeApplicant === ActiveApplicantFilter.ACTIVE) {
    filtered = filtered.filter((item) => !!item.applicant?.active);
  }

  if (filters.activeApplicant === ActiveApplicantFilter.INACTIVE) {
    filtered = filtered.filter((item) => !item.applicant?.active);
  }

  if (filters.answered === AnsweredFilter.ANSWERED) {
    filtered = filtered.filter((item) => !item.unansweredMessagesCount);
  }

  if (filters.answered === AnsweredFilter.UNANSWERED) {
    filtered = filtered.filter((item) => !!item.unansweredMessagesCount);
  }

  // TODO: Removido temporariamente
  // if (filters.caseStatus === CaseStatusFilter.ACTIVE) {
  //   filtered = filtered.filter((item) => item.cases?.some((chatCase) => chatCase.status === "ACTIVE"));
  // }

  // if (filters.caseStatus === CaseStatusFilter.INACTIVE) {
  //   filtered = filtered.filter((item) => item.lastCase?.status === "INACTIVE");
  // }

  if (filters.responsibles.length) {
    const responsibleIds = filters.responsibles.map((item) => item.userId);
    filtered = filtered.filter(
      (item) => item.lastTicket?.responsibleUserId && responsibleIds.includes(item.lastTicket.responsibleUserId)
    );
  }

  if (filters.areas.length) {
    const activityAreas = filters.areas.map((item) => item.value);
    filtered = filtered.filter(
      (item) =>
        item.lastTicket?.status === TicketStatus.ACTIVE &&
        item.lastTicket?.activityAreas?.some((area) => activityAreas.includes(area))
    );
  }

  if (filters.chatCreationDateStart) {
    filtered = filtered.filter((item) => {
      const chatCreationDate = item.createdAt;
      return chatCreationDate >= (filters.chatCreationDateStart || 0);
    });
  }

  if (filters.chatCreationDateEnd) {
    filtered = filtered.filter((item) => {
      const chatCreationDate = item.createdAt;
      const endDate = filters.chatCreationDateEnd
        ? new Date(filters.chatCreationDateEnd).setHours(23, 59, 59, 999)
        : Infinity;

      return chatCreationDate <= endDate;
    });
  }

  return filtered;
};
