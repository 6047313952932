import { Box, CircularProgress, Typography, Collapse, IconButton } from "@mui/material";
import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface CompanyInfoProps {
  companyName: string;
  companyId: string;
  companyEmail?: string;
  whatsappNumber?: string;
  isExpanded: boolean;
  onToggle: () => void;
  isFetching?: boolean;
}

export const CompanyInfo = ({
  companyName,
  companyId,
  companyEmail,
  whatsappNumber,
  isExpanded,
  onToggle,
  isFetching,
}: CompanyInfoProps) => {
  const collapsedInfo = (
    <>
      {companyName} | ID: {companyId}
      {companyEmail && ` | Email: ${companyEmail}`}
      {whatsappNumber && ` | Whatsapp: ${whatsappNumber}`}
    </>
  );

  const expandedInfo = (
    <Typography variant="body" color="text.primary" sx={{ fontSize: "16px" }}>
      ID: {companyId}
      {companyEmail && (
        <>
          <br />
          Email: {companyEmail}
        </>
      )}
      {whatsappNumber && (
        <>
          <br />
          Whatsapp: {whatsappNumber}
        </>
      )}
    </Typography>
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: 2,
        paddingX: 4,
        paddingY: isExpanded ? 2 : 1,
        backgroundColor: "info.light",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography
            variant={isExpanded ? "pageTitle" : "body"}
            sx={!isExpanded ? { fontSize: "14px", fontWeight: "bold" } : undefined}
          >
            {isExpanded ? companyName : collapsedInfo}
          </Typography>
          <IconButton onClick={onToggle} size="small">
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
        <Collapse in={isExpanded}>{expandedInfo}</Collapse>
      </Box>

      {isFetching && <CircularProgress size={20} sx={{ marginLeft: 1, color: "text.primary" }} thickness={5} />}
    </Box>
  );
};
