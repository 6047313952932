import { Box, TextField, Autocomplete } from "@mui/material";
import React from "react";
import { ApprovalFilters as ApprovalFiltersType, ApprovalStatus } from "@/services/approvalRequests/types";
import { APPROVAL_STATUS_LABELS } from "@/services/approvalRequests/constants";
import { useApprovalFilters } from "../../hooks/useApprovalFilters";

interface ApprovalFiltersProps {
  filters: {
    status: ApprovalStatus[];
    dateRange: [Date | null, Date | null];
    subagents: string[];
    searchText: string;
    reviewers: string[];
  };
  onFilterChange: (filters: ApprovalFiltersType) => void;
}

export const ApprovalFilters: React.FC<ApprovalFiltersProps> = ({ filters, onFilterChange }) => {
  const {
    handleSearchTextChange,
    handleStatusChange,
    handleDateRangeChange,
    handleSubagentsChange,
    handleReviewersChange,
  } = useApprovalFilters(filters, onFilterChange);

  const formatDateForInput = (date: Date | null) => {
    return date ? date.toISOString().split("T")[0] : "";
  };

  return (
    <Box
      display="flex"
      gap={2}
      mb={3}
      flexWrap="wrap"
      sx={{
        "& .MuiOutlinedInput-root": {
          borderColor: "common.lightShade",
        },
      }}
    >
      <Autocomplete
        multiple
        disableCloseOnSelect
        options={Object.entries(APPROVAL_STATUS_LABELS).map(([value]) => value as ApprovalStatus)}
        value={filters.status || ["PENDING"]}
        onChange={(_, newValue) => handleStatusChange(newValue)}
        getOptionLabel={(option) => APPROVAL_STATUS_LABELS[option]}
        renderInput={(params) => <TextField {...params} label="Status" size="small" variant="outlined" />}
        size="small"
        sx={{ minWidth: 250 }}
      />

      <TextField
        type="date"
        label="Data inicial"
        value={formatDateForInput(filters.dateRange[0])}
        onChange={(e) => {
          const newDate = e.target.value ? new Date(e.target.value) : null;
          handleDateRangeChange([newDate, filters.dateRange[1]]);
        }}
        size="small"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />

      <TextField
        type="date"
        label="Data final"
        value={formatDateForInput(filters.dateRange[1])}
        onChange={(e) => {
          const newDate = e.target.value ? new Date(e.target.value) : null;
          handleDateRangeChange([filters.dateRange[0], newDate]);
        }}
        size="small"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />

      <Autocomplete
        multiple
        options={[]}
        value={filters.subagents}
        onChange={(_, newValue) => handleSubagentsChange(newValue)}
        renderInput={(params) => <TextField {...params} label="Subagentes" size="small" variant="outlined" />}
        size="small"
        sx={{ minWidth: 200 }}
      />

      <Autocomplete
        multiple
        options={["user-123", "user-456", "user-789"]} // This should come from a service/API
        value={filters.reviewers}
        onChange={(_, newValue) => handleReviewersChange(newValue)}
        renderInput={(params) => <TextField {...params} label="Revisores" size="small" variant="outlined" />}
        size="small"
        sx={{ minWidth: 200 }}
      />

      <TextField
        label="Buscar"
        value={filters.searchText}
        onChange={(e) => handleSearchTextChange(e.target.value)}
        size="small"
        variant="outlined"
        sx={{ minWidth: 200 }}
      />
    </Box>
  );
};
