import React from "react";
import { Card, CardContent, Typography, Box, Tooltip } from "@mui/material";
import { ApprovalRequest } from "@/services/approvalRequests/types";
import { useState } from "react";
import { ApprovalModal } from "../ApprovalModal";
import { StatusChip } from "../shared/StatusChip";
import { formatDateTime } from "../../utils/formatters";
import { ROUTE_PATHS } from "@/routes/routePaths";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

interface ApprovalCardProps {
  request: ApprovalRequest;
  onUpdate: () => void;
}

export const ApprovalCard: React.FC<ApprovalCardProps> = ({ request, onUpdate }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Tooltip
        title={<pre style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(request.output, null, 2)}</pre>}
        placement="top"
        PopperProps={{
          sx: {
            maxWidth: "none",
            "& .MuiTooltip-tooltip": {
              maxWidth: (theme) => theme.breakpoints.values.md,
              width: "fit-content",
            },
          },
        }}
      >
        <Card
          sx={{
            cursor: "pointer",
            width: "100%",
            "&:hover": {
              bgcolor: "action.hover",
            },
          }}
          onClick={() => setModalOpen(true)}
        >
          <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
            <Box
              display="grid"
              gridTemplateColumns="100px 120px 180px 1fr 160px 180px 48px"
              gap={3}
              alignItems="center"
              minHeight="48px"
            >
              <Typography variant="body2" color="text.secondary">
                {request.id}
              </Typography>

              <Box>
                <StatusChip status={request.status} />
              </Box>

              <Box
                component="a"
                href={`${ROUTE_PATHS.WHATSAPP_ASSISTANT_CHATS_INTERNAL}?phoneNumber=${request.applicant_phone_number}`}
                onClick={(e) => e.stopPropagation()}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  textDecoration: "none",
                  color: "text.secondary",
                  "&:hover": {
                    color: "primary.main",
                    "& .MuiTypography-root": {
                      color: "primary.main",
                    },
                  },
                }}
              >
                <WhatsAppIcon sx={{ fontSize: 16 }} />
                <Typography
                  variant="body2"
                  sx={{
                    transition: "color 0.2s ease",
                  }}
                >
                  {request.applicant_phone_number}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="subtitle2" noWrap>
                  {request.request_type}
                </Typography>
                {request.change_instructions && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      mt: 0.5,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {request.change_instructions}
                  </Typography>
                )}
              </Box>

              <Typography variant="body2" color="text.secondary" noWrap>
                {formatDateTime(request.created_at)}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                {!request.reviewed_at ? (
                  <Typography variant="body2" color="text.secondary">
                    Não revisado
                  </Typography>
                ) : (
                  <>
                    <Typography variant="body2" color="text.secondary">
                      Revisado em: {formatDateTime(request.reviewed_at)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Por: {request.reviewer_id}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Tooltip>

      <ApprovalModal open={modalOpen} onClose={() => setModalOpen(false)} request={request} onUpdate={onUpdate} />
    </>
  );
};
