import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { BaseStatusViewProps } from "./types";
import { ReviewInfo } from "../../shared/ReviewInfo";

export const RequestedChangesView: React.FC<BaseStatusViewProps> = ({ request }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 2,
      }}
    >
      <ReviewInfo request={request}>
        <Box mt={2}>
          <Typography variant="h5" gutterBottom fontWeight={600}>
            Instruções de Alteração
          </Typography>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              bgcolor: "secondary.light",
              border: "1px solid",
              borderColor: "secondary.main",
              borderRadius: 1,
            }}
          >
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                whiteSpace: "pre-wrap",
                lineHeight: 1.6,
              }}
            >
              {request.change_instructions}
            </Typography>
          </Paper>
        </Box>
      </ReviewInfo>
    </Box>
  );
};
