import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Case } from "../types";
import { CaseService } from "@/services/case";
import { getCaseQueryKey } from "../useLexZapTicket";

export const useSignCaseContract = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["signCaseContract"],
    mutationFn: ({ caseId }: { caseId: string }) => CaseService.signCaseContract(caseId),
    onMutate: async ({ caseId }) => {
      const previousCase = queryClient.getQueryData<Case>(getCaseQueryKey(caseId));
      queryClient.setQueryData<Case>(getCaseQueryKey(caseId), (old) => {
        if (!old) return old;
        return { ...old, contractSignedAt: new Date().toISOString() };
      });
      return { previousCase };
    },
    onError: (_error, { caseId }, context) => {
      queryClient.setQueryData<Case>(getCaseQueryKey(caseId), context?.previousCase);
    },
  });
};
