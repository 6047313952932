import { PageLayout } from "@/components/PageLayout";
import { HeaderVariant } from "@/components/PageLayout/Header/constants";
import { usePaginatedThreads } from "@/hooks/threads/usePaginatedThreads";
import { Box, Button, ButtonBase, Card, CircularProgress, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { Loading } from "@/components/Loading";
import { useNavigate } from "react-router-dom";
import { generatePath } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { useRecords } from "@/hooks/records/useRecords";
import { ThreadRecordWithThreadId } from "@/hooks/records/types";
import { Paginated } from "@/core/api/types";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Thread } from "@/services/thread/types";
import { useLexZapCase } from "@/hooks/lexZap/useLexZapCase";
import { LoggedInUserData, useAuthContext } from "@/contexts/AuthContext";
import { useCompanyTeam } from "@/hooks/company/useCompanyTeam";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

const MAX_SCROLL_POSITION = 56;
// TODO: hoje estamos listando apenas a ações do usuário que geram documento, fica pendente conseguir listar
// as ações que não geram documentos, ex: dúvida jurídica.
export const UsageHistory = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { newUsageHistoryV2 } = useFeatureFlags();
  const {
    data: paginatedThreads,
    isLoading: isLoadingThreads,
    fetchNextPage,
    hasNextPage,
  } = usePaginatedThreads({
    order: "UPDATED_AT",
    pageSize: 20,
  });
  const threadIds = useMemo(
    () => paginatedThreads?.pages.flatMap((page) => page.data.map((thread) => thread.id)) ?? [],
    [paginatedThreads]
  );

  const { data: records, isLoading: isLoadingRecords } = useRecords({
    order: "CREATED_AT",
    threadId: threadIds.length > 0 ? threadIds : undefined,
  });

  const allRecords = useMemo(() => {
    if (!records) return [];

    const extractedRecords = Array.isArray(records)
      ? records.filter((r): r is Paginated<ThreadRecordWithThreadId> => r !== undefined).flatMap((r) => r.data)
      : (records.data ?? []);

    return extractedRecords
      .filter((record) => record.threadId)
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }, [records]);

  const filteredRecords = newUsageHistoryV2
    ? allRecords.filter((record) => {
        const createdAt = new Date(record.createdAt);
        const cutoffDate = new Date("2025-02-12T23:59:59");
        return createdAt <= cutoffDate;
      })
    : allRecords;

  const isLoading = isLoadingThreads || isLoadingRecords;
  const hasFullList = !hasNextPage;

  const openThread = (threadId: string) => {
    navigate(generatePath(ROUTE_PATHS.THREAD, { threadId }));
  };

  const threads = useMemo(() => paginatedThreads?.pages.flatMap((page) => page.data) ?? [], [paginatedThreads]);

  return (
    <PageLayout
      headerProps={{
        variant: HeaderVariant.GO_BACK,
      }}
      contentSx={{
        width: "100%",
      }}
      sx={{
        ".page-layout-container": {
          p: 0,
        },
      }}
      variant="secondary"
      onScroll={(e) => {
        const target = e.target as HTMLDivElement;
        const scrollPosition = target.scrollHeight - target.offsetHeight - target.scrollTop;
        if (scrollPosition < MAX_SCROLL_POSITION) {
          void fetchNextPage();
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Typography
          sx={{
            ml: 6,
            mt: 6,
            mb: 4,
          }}
          variant="h5"
        >
          Histórico de uso {newUsageHistoryV2 && "- legado"}
        </Typography>

        <Box
          id="UsageHistoryList"
          sx={{
            flex: 1,
            overflowY: "auto",
            px: 6,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading isLoading={isLoading}>
            <Box sx={{ width: "45%", display: "flex", flexDirection: "column", gap: 2 }}>
              {filteredRecords?.length ? (
                filteredRecords.map((record, index) => {
                  const thread = threads.find((t) => t.id === record.threadId);
                  return (
                    <Box key={index} sx={{ width: "100%" }}>
                      <ThreadHistoryItem record={record} thread={thread} openThread={openThread} currentUser={user} />
                    </Box>
                  );
                })
              ) : (
                <Box sx={{ minHeight: 200, p: 2.5 }} display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="multiLineBody" sx={{ textAlign: "center" }}>
                    Você ainda não possui sessões salvas. Realize o primeiro uso do Assistente Lexter para criar sua
                    primeira sessão.
                  </Typography>
                </Box>
              )}
            </Box>
          </Loading>

          {!isLoading && !hasFullList && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "48px !important",
                mt: 2,
              }}
            >
              <CircularProgress size={24} />
            </Box>
          )}

          {!isLoading && hasFullList && filteredRecords.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "48px !important",
                mt: 2,
              }}
            >
              <Typography variant="body2">Fim da lista</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </PageLayout>
  );
};

const ThreadHistoryItem = ({
  record,
  thread,
  openThread,
  currentUser,
}: {
  record: ThreadRecordWithThreadId;
  thread?: Thread;
  openThread: (threadId: string) => void;
  currentUser: LoggedInUserData | undefined;
}) => {
  const formattedDate = new Date(record.createdAt).toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  const { caseData } = useLexZapCase({ caseId: thread?.caseId ?? "" });
  const { data: companyTeam } = useCompanyTeam();
  const user = companyTeam?.find((user) => user.userId === record.userId);

  return (
    <Card
      sx={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1,
        border: "1px solid #E0E0E0",
        borderRadius: 1,
        p: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        {user && (
          <Typography variant="body1" sx={{ textAlign: "left", width: "100%", color: "grey.500" }}>
            {user.name}
          </Typography>
        )}
        <Typography variant="body1" sx={{ textAlign: "right", width: "100%" }}>
          {formattedDate}
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ textAlign: "left", width: "100%" }}>
        <Box component="span">
          Criou {record.name.toLowerCase().startsWith("contrato") ? "um" : "uma"} <b>{record.name}</b>
        </Box>
        {caseData && (
          <Box component="span" sx={{ ml: 0.5 }}>
            dentro do caso <b>{caseData.name}</b>
          </Box>
        )}
      </Typography>
      <ButtonBase
        sx={{
          p: 1,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid #E0E0E0",
          borderRadius: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
          <DescriptionOutlinedIcon />
          <Typography variant="title">{record.name}</Typography>
        </Box>
        <Button
          sx={{ color: "black", textDecoration: "underline" }}
          onClick={() => openThread(record.threadId)}
          disabled={currentUser?.userId !== record.userId}
        >
          Abrir
        </Button>
      </ButtonBase>
    </Card>
  );
};
