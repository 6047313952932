import { authenticatedCopilotServer } from "@/core/api";
import { logger } from "@/core/logger";
import { CaseService } from "@/services/case";
import { LeadStage, ArchiveReason, SyncParams } from "./types";

export const LeadStageSyncService = {
  /**
   * Obtém o caso mais recente associado a um número de cliente
   * @param endClientNumber Número do cliente
   * @returns O ID do caso mais recente ou undefined se não encontrado
   */
  getLatestCaseId: async (endClientNumber: string): Promise<string | undefined> => {
    try {
      // Usar o serviço de API diretamente em vez do hook
      const response = await authenticatedCopilotServer.get(`/cases`, {
        params: {
          applicantPhoneNumber: endClientNumber,
        },
        headers: {
          "x-pagination-page": "1",
          "x-pagination-page-size": "10",
        },
      });

      const cases = response.data;

      if (!cases || cases.length === 0) {
        logger.warn(`Não foi possível encontrar um caso para o cliente ${endClientNumber}`);
        return undefined;
      }

      // Ordenar casos por data de criação (mais recente primeiro)
      const sortedCases = [...cases].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      // Retornar o ID do caso mais recente
      return sortedCases.length > 0 ? sortedCases[0].id : undefined;
    } catch (error) {
      logger.error("Erro ao obter o caso mais recente:", { error });
      throw error;
    }
  },

  /**
   * Sincroniza o status do lead na planilha com as ações correspondentes no caso
   * @param params Parâmetros para sincronização do status do lead
   */
  syncLeadStageWithCase: async (params: SyncParams): Promise<void> => {
    const {
      endClientNumber,
      leadStage,
      archiveType = ArchiveReason.CLIENT_ABANDONED,
      archiveDetails = "Caso abandonado via atualização de status na planilha",
    } = params;

    try {
      // Obter o ID do caso mais recente
      const caseId = await LeadStageSyncService.getLatestCaseId(endClientNumber);

      if (!caseId) {
        logger.warn(`Não foi possível sincronizar o status do lead para ${endClientNumber}: caso não encontrado`);
        return;
      }

      // Executar a ação correspondente ao status do lead
      switch (leadStage) {
        case LeadStage.EM_FECHAMENTO_COMERCIAL:
          // Ativar caso (equivalente a useUnarchiveCase)
          await authenticatedCopilotServer.post(`/cases/${caseId}/unarchive`);
          logger.info(`Caso ${caseId} ativado com sucesso`);
          break;

        case LeadStage.EM_INSTRUCAO_CASO_ADMINISTRATIVO:
        case LeadStage.EM_INSTRUCAO_CASO_JUDICIAL:
          // Fechar contrato (equivalente a useSignCaseContract)
          await CaseService.signCaseContract(caseId);
          logger.info(`Contrato do caso ${caseId} assinado com sucesso`);
          break;

        case LeadStage.ABANDONADO:
          // Abandonar caso (equivalente a useArchiveCase)
          await authenticatedCopilotServer.post(`/cases/${caseId}/archive`, {
            reasonType: archiveType,
            reasonDetails: archiveDetails,
          });
          logger.info(`Caso ${caseId} arquivado com sucesso`);
          break;

        default:
          // Apenas atualizar o leadStage sem executar ações adicionais
          logger.info(`Status do lead atualizado para ${leadStage} sem ações adicionais`);
          break;
      }
    } catch (error) {
      logger.error("Erro ao sincronizar status do lead:", { error });
      throw error;
    }
  },
};
