import { Box, Collapse } from "@mui/material";
import React, { useRef, useState } from "react";
import { LexZapChat } from "@/hooks/lexZap/types";
import { ChatList } from "./components/ChatList";
import { Chat } from "./components/Chat";
import { useSocket } from "@/contexts/WebSocketContext";
import { WhatsappUpdateType } from "@/contexts/WebSocketContext/types/whatsapp.types";
import MessageReceivedSound from "@/assets/sounds/whatsapp-message-received.mp3";
import { useLexZapActiveChat } from "@/hooks/lexZap/useLexZapActiveChat";
import { useResizable } from "@/components/EditorPageLayout/useResizable";
import { Resizer } from "@/components/EditorPageLayout/components/Resizer";
import { useDebouncedCallback } from "@/hooks/useDebouncedCallback";
import { ChatHistory } from "./components/ChatHistory";

const minChatListWidth = 300;
const minChatWidth = 500;
const minChatHistoryWidth = 200;

export const ChatView = ({ chats, isLoadingChats }: { chats: LexZapChat[] | undefined; isLoadingChats: boolean }) => {
  const [isChatHistoryOpen, setIsChatHistoryOpen] = useState(false);
  const { activeChat } = useLexZapActiveChat();

  const isChatHistoryModalOpen = isChatHistoryOpen && !!activeChat?.lastTicket;

  const handleToggleHistory = () => {
    setIsChatHistoryOpen((prev) => !prev);
  };

  const handleCloseChatHistory = () => {
    setIsChatHistoryOpen(false);
  };

  useSocket({
    onWhatsAppUpdate: (update) => {
      if (update.type === WhatsappUpdateType.MESSAGE_RECEIVED && update.shouldPlaySound) {
        const audio = new Audio(MessageReceivedSound);
        void audio.play();
      }
    },
  });

  const [chatHistoryWidth, setChatHistoryWidth] = useState(minChatHistoryWidth);
  const debouncedSetChatHistoryWidth = useDebouncedCallback(setChatHistoryWidth, 1000);
  const chatHistoryWidthRef = useRef<HTMLDivElement>(null);

  const {
    containerRef: mainContainerRef,
    handleMouseDown: handleTicketDetailsResize,
    isResizing: isResizingTicketDetails,
  } = useResizable({
    defaultWidth: 330,
    minWidth: minChatHistoryWidth,
    minOppositeWidth: minChatListWidth + minChatWidth,
    direction: "right",
    onWidthChange: (width) => {
      if (chatHistoryWidthRef.current) {
        chatHistoryWidthRef.current.style.width = `${width}px`;
      }
      debouncedSetChatHistoryWidth(width);
    },
  });

  const [chatListWidth, setChatListWidth] = useState(minChatListWidth);
  const debouncedSetChatListWidth = useDebouncedCallback(setChatListWidth, 1000);
  const {
    containerRef: chatContainerRef,
    handleMouseDown: handleChatListResize,
    isResizing: isResizingChatList,
  } = useResizable({
    defaultWidth: { value: 30, unit: "%" },
    minWidth: minChatListWidth,
    minOppositeWidth: minChatWidth,
    onWidthChange: (width) => {
      if (chatContainerRef.current) {
        chatContainerRef.current.style.gridTemplateColumns = `${width}px minmax(0, 1fr)`;
      }
      debouncedSetChatListWidth(width);
    },
  });

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr min-content",
        gridTemplateRows: "100%",
      }}
      ref={mainContainerRef}
    >
      <Box
        ref={chatContainerRef}
        sx={{
          height: "100%",
          width: "100%",
          display: "grid",
          gridTemplateColumns: `${chatListWidth}px minmax(0, 1fr)`,
          gridTemplateRows: "100%",
          borderRadius: 1,
          overflow: "hidden",
          border: "1px solid",
          borderColor: "common.lightShade",
        }}
      >
        <Box sx={{ position: "relative", display: "flex" }}>
          <ChatList chats={chats} isLoading={isLoadingChats} />
          <Resizer onMouseDown={handleChatListResize} isResizing={isResizingChatList} />
        </Box>

        <Box
          sx={{
            position: "relative",
            display: "flex",
            height: "100%",
            overflow: "hidden",
            minWidth: minChatWidth,
          }}
        >
          <Chat isLoadingChats={isLoadingChats} onToggleHistory={handleToggleHistory} />
        </Box>
      </Box>

      <Collapse
        in={isChatHistoryModalOpen}
        orientation="horizontal"
        easing={{
          enter: "linear",
          exit: "linear",
        }}
        sx={{
          position: "relative",
        }}
      >
        {isChatHistoryModalOpen && (
          <Resizer
            onMouseDown={handleTicketDetailsResize}
            isResizing={isResizingTicketDetails}
            anchor="left"
            sx={{
              left: "3px",
            }}
          />
        )}

        <Box
          ref={chatHistoryWidthRef}
          sx={{
            width: chatHistoryWidth,
            height: "100%",
            borderRadius: 1,
            border: "1px solid",
            borderColor: "common.lightShade",
            overflow: "hidden",
            marginLeft: 2,
          }}
        >
          {activeChat && <ChatHistory chat={activeChat} onClose={handleCloseChatHistory} />}
        </Box>
      </Collapse>
    </Box>
  );
};
