import { Badge, Box, IconButton, SxProps } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { FilterList as FilterListIcon, Search as SearchIcon } from "@mui/icons-material";

interface CaseFilesHeaderOptionsProps {
  onChange: (value: string) => void;
  openFilterCaseFilesModal: () => void;
  totalCaseFileFiltersChanges: number;
  sx?: SxProps;
}
export const CaseFilesHeaderOptions = ({
  openFilterCaseFilesModal,
  totalCaseFileFiltersChanges,
  sx,
  onChange,
}: CaseFilesHeaderOptionsProps) => {
  const [isOpenSearch, setIsOpenSearch] = useState(false);

  return (
    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
      {isOpenSearch ? (
        <TextField
          sx={{
            "& label.Mui-focused": {
              color: "common.mediumShade",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "common.mediumShade",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "common.lightShade",
              },
              "&:hover fieldset": {
                borderColor: "common.mediumShade",
              },
              "&:focus-visible": {
                borderColor: "common.mediumShade",
              },
              "&.Mui-focused fieldset": {
                borderColor: "common.mediumShade",
              },
            },
            borderRadius: "5px",
            width: "300px",
            ...sx,
          }}
          placeholder="Buscar arquivo"
          variant="outlined"
          onChange={(e) => {
            onChange(e.target.value);
          }}
          size="small"
        />
      ) : (
        <IconButton
          onClick={() => setIsOpenSearch(true)}
          sx={{
            height: "50px",
            width: "50px",
          }}
        >
          <SearchIcon
            sx={{
              color: "common.coral",
            }}
          />
        </IconButton>
      )}
      <Badge badgeContent={totalCaseFileFiltersChanges} color="primary">
        <IconButton
          onClick={openFilterCaseFilesModal}
          sx={{
            m: -1,
            height: "50px",
            width: "50px",
          }}
        >
          <FilterListIcon
            sx={{
              color: "common.coral",
            }}
          />
        </IconButton>
      </Badge>
    </Box>
  );
};
