import { authenticatedCopilotServer } from "@/core/api";
import { ActivityArea, CompanyMember } from "@/hooks/company/types";
import { Paginated, PaginationPayload } from "@/core/api/types";
import { getPaginationHeaders } from "@/core/api/utils/getPaginationHeaders";
import { CompanyUsageHistory } from "@/services/company/types";

export const CompanyService = {
  async getTeam() {
    const response = await authenticatedCopilotServer.get<CompanyMember[]>(`/company/myTeam`);
    return response.data;
  },

  async createTeamMember(data: {
    email: string;
    department: string;
    activityAreas?: ActivityArea[];
  }): Promise<{ id: string }> {
    const response = await authenticatedCopilotServer.post<{ id: string }>(`/company/myTeam`, data);
    return response.data;
  },

  async getTeamMember(id: string) {
    const response = await authenticatedCopilotServer.get<CompanyMember>(`/company/myTeam/${id}`);
    return response.data;
  },

  async updateTeamMember({
    memberId,
    data,
  }: {
    memberId: string;
    data: { notificationNumber: string; department: string; activityAreas?: ActivityArea[] };
  }) {
    const response = await authenticatedCopilotServer.post<CompanyMember>(`/company/myTeam/${memberId}`, data);
    return response.data;
  },

  async deleteTeamMember({ memberId }: { memberId: string }) {
    const response = await authenticatedCopilotServer.delete(`/company/myTeam/${memberId}`);
    return response.data;
  },

  async resendTeamMemberInvitation({ memberId }: { memberId: string }) {
    const response = await authenticatedCopilotServer.post(`/company/myTeam/${memberId}/resendInvitation`, {});
    return response.data;
  },

  async getCompanyUsageHistory({
    companyId,
    filters,
    options,
  }: {
    companyId: string;
    filters?: {
      userId?: string;
      entityId?: string;
    };
    options?: {
      orderBy?: string;
      orderDirection?: string;
      pagination?: PaginationPayload;
    };
  }) {
    const response = await authenticatedCopilotServer.get(`/company/${companyId}/usageHistory`, {
      headers: {
        ...getPaginationHeaders(options?.pagination),
      },
      params: { ...filters, orderBy: options?.orderBy, orderDirection: options?.orderDirection },
    });
    return {
      data: response.data,
      totalResults: response.headers["x-pagination-total-results"],
      pageSize: options?.pagination?.pageSize ?? response.data.length,
      page: options?.pagination?.page ?? 1,
    } as Paginated<CompanyUsageHistory>;
  },
};
