import { Box, MenuList, SxProps } from "@mui/material";
import { CardWithoutBackdrop } from "@/components/ConfigMenu";
import React, { useState } from "react";
import HelpIcon from "@/assets/svgs/help.svg?react";
// import WhatsAppIcon from "@/assets/svgs/whatsapp.svg?react";
import BookIcon from "@/assets/svgs/book.svg?react";
// import { isBetweenTheseHours, isWeekend } from "@/utils/betweenHours";
import { HelpMenuItem, HelpMenuItemProps } from "./HelpMenuItem";
import { EXTERNAL_URLS } from "@/routes/routePaths";

export const Help = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = () => {
    if (!menuOpen) {
      setMenuOpen(true);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "2.5rem",
        height: "2.5rem",
        borderRadius: "100%",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(236, 241, 244, 0.25)",
        },
        backgroundColor: menuOpen ? "rgba(236, 241, 244, 0.25)" : "",
      }}
      onClick={handleClick}
    >
      <HelpIcon
        height={22}
        style={{
          cursor: "pointer",
        }}
      />

      <HelpMenu
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        sx={{
          bottom: 0,
          left: "calc(100% + 18px)",
          backgroundColor: "common.dorian",
        }}
      />
    </Box>
  );
};

interface HelpMenuProps {
  open: boolean;
  onClose: () => void;
  sx?: SxProps;
}

const HelpMenu = ({ sx: sxProp, open, onClose }: HelpMenuProps) => {
  const sx: SxProps = {
    position: "absolute",
    m: 0,
    width: 225,
    boxShadow: "0px 20px 40px 0px rgba(74, 74, 104, 0.25)",
    ...sxProp,
  };

  const beforeItemAction = () => {
    onClose();
  };

  const allMenuItems: ({ hidden?: boolean } & HelpMenuItemProps)[] = [
    {
      label: "Central de Ajuda",
      icon: BookIcon,
      onClick: () => window.open(EXTERNAL_URLS.HELP_CENTER_URL, "_blank"),
    },
    // {
    //   label: "WhatsApp",
    //   icon: WhatsAppIcon,
    //   onClick: () => window.open(EXTERNAL_URLS.WHATSAPP_URL, "_blank"),
    //   // hidden: !isBetweenTheseHours(9, 17) || isWeekend(), // TODO: remover quando religar o whatsapp (CloudHumans será 24/7)
    // },
  ];
  const visibleMenuItems = allMenuItems.filter((item) => !item.hidden);

  if (!open) return null;

  return (
    <CardWithoutBackdrop onClose={onClose} sx={sx}>
      <>
        <MenuList
          sx={{
            py: 0,
            borderColor: "common.lightShade",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px",
            li: {
              borderBottom: "1px solid common.lightShade",
              "&:last-child": {
                borderBottom: "none",
              },
            },
          }}
        >
          {visibleMenuItems.map(({ onClick: itemAction, ...item }, index) => (
            <HelpMenuItem
              key={index}
              onClick={() => {
                beforeItemAction();
                itemAction();
              }}
              {...item}
            />
          ))}
        </MenuList>
      </>
    </CardWithoutBackdrop>
  );
};
