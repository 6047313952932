import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { TagWithMetadata } from "@/services/internalAgents/types";
import { useTagManagement } from "./hooks/useTagManagement";
import { TagList } from "./TagList";
import { TagForm } from "./TagForm";
import { DeleteConfirmationDialog } from "./DeleteConfirmationDialog";
import { useNavigate } from "react-router-dom";

export const Tags = () => {
  const navigate = useNavigate();
  const { tags, loading, fetchTags, createTag, updateTag, deleteTag } = useTagManagement();
  const [modalOpen, setModalOpen] = useState(false);
  const [editingTag, setEditingTag] = useState<TagWithMetadata | null>(null);
  const [form, setForm] = useState<Partial<TagWithMetadata>>({});
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    open: boolean;
    tagName: string;
  }>({ open: false, tagName: "" });

  useEffect(() => {
    void fetchTags();
  }, []);

  const handleSubmit = async () => {
    const success = editingTag ? await updateTag(editingTag.tag_id, form) : await createTag(form as TagWithMetadata);

    if (success) {
      setModalOpen(false);
      setForm({});
      setEditingTag(null);
    }
  };

  const handleEdit = (tag: TagWithMetadata) => {
    setEditingTag(tag);
    setForm(tag);
    setModalOpen(true);
  };

  const handleDeleteClick = (tagName: string) => {
    setDeleteConfirmation({ open: true, tagName });
  };

  const handleDeleteConfirm = async () => {
    await deleteTag(deleteConfirmation.tagName);
    setDeleteConfirmation({ open: false, tagName: "" });
  };

  const handleTagSelect = (tag: TagWithMetadata) => {
    navigate(`/admin/internal-agents/tags/${tag.tag_id}`);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
          justifyContent: "space-between",
          mb: 4,
        }}
      >
        <Box>
          <Typography variant="h6">Ticket Tags</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            O sistema de Tags permite categorizar e analisar tickets para melhorar o tracking e identificar problemas
            recorrentes.
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setEditingTag(null);
            setForm({});
            setModalOpen(true);
          }}
        >
          Adicionar Tag
        </Button>
      </Box>

      <TagList
        tags={tags}
        loading={loading}
        onEdit={handleEdit}
        onDelete={handleDeleteClick}
        onSelect={handleTagSelect}
      />

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>{editingTag ? "Editar Tag" : "Criar Tag"}</DialogTitle>
        <TagForm initialData={editingTag || undefined} onChange={setForm} />
        <DialogActions>
          <Button onClick={() => setModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleSubmit} variant="contained">
            {editingTag ? "Atualizar" : "Criar"}
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteConfirmationDialog
        open={deleteConfirmation.open}
        tagName={deleteConfirmation.tagName}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setDeleteConfirmation({ open: false, tagName: "" })}
      />
    </Box>
  );
};
