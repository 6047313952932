import React from "react";
import * as MUI from "@mui/material";
import * as MUIIcons from "@mui/icons-material";
import { ChatStatus } from "@/services/whatsappServer/types/chat";
import * as types from "./types";

export const StatusFilter: React.FC<types.StatusFilterProps> = ({ chatFilters, setChatFilters }) => {
  // Define available statuses
  const availableStatuses = Object.values(ChatStatus);

  // Map statuses to more user-friendly display names
  const getStatusDisplayName = (status: string) => {
    switch (status) {
      case ChatStatus.ACTIVE:
        return "Ativo";
      case ChatStatus.ARCHIVED:
        return "Arquivado";
      default:
        return status;
    }
  };

  // Handle removing a status
  const handleRemoveStatus = (status: ChatStatus) => {
    setChatFilters((prev) => ({
      ...prev,
      statuses: prev.statuses?.filter((item) => item !== status),
    }));
  };

  // Handle adding statuses
  const handleAddStatuses = (selectedOptions: ChatStatus[]) => {
    setChatFilters((prev) => ({
      ...prev,
      statuses: selectedOptions,
    }));
  };

  return (
    <MUI.Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, flex: 1 }}>
      <MUI.Typography variant="body2" color="text.secondary" sx={{ minWidth: "90px" }}>
        Status:
      </MUI.Typography>

      <MUI.Box sx={{ flexGrow: 1 }}>
        {/* Display selected statuses as chips */}
        {chatFilters.statuses?.length > 0 && (
          <MUI.Box
            sx={{
              display: "flex",
              gap: 1,
              flexWrap: "wrap",
              mb: 1,
            }}
          >
            {chatFilters.statuses.map((status) => (
              <MUI.Chip
                key={status}
                label={getStatusDisplayName(status)}
                onDelete={() => handleRemoveStatus(status)}
                sx={{
                  backgroundColor: "secondary.main",
                  color: "text.primary",
                }}
                deleteIcon={<MUIIcons.CloseOutlined />}
                size="small"
              />
            ))}
          </MUI.Box>
        )}

        {/* Status selection autocomplete */}
        <MUI.Autocomplete
          multiple
          options={availableStatuses}
          value={chatFilters.statuses}
          noOptionsText="Nenhuma opção encontrada"
          disablePortal
          getOptionLabel={(option) => getStatusDisplayName(option)}
          onChange={(_, selectedOptions) => handleAddStatuses(selectedOptions)}
          renderInput={(params) => <MUI.TextField {...params} placeholder="Selecionar status" size="small" />}
          renderTags={() => null}
          sx={{ flexGrow: 1, width: "100%" }}
        />
      </MUI.Box>
    </MUI.Box>
  );
};
