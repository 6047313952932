import React, { useMemo, useState } from "react";

import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { Button } from "@/components/Button";

import { Check as CheckIcon, Undo as UndoIcon } from "@mui/icons-material";

import { Case, CaseStatus, TicketStatus } from "@/hooks/lexZap/types";

import { Card } from "../Card";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import { DateTime } from "luxon";
import { dateTimeFromString } from "@/utils/dates/dateTimeFromString";
import { useLexZapTicket } from "@/hooks/lexZap/useLexZapTicket";
import { useArchiveCases } from "@/hooks/lexZap/useArchiveCases";
import { useUnarchiveCases } from "@/hooks/lexZap/useUnarchiveCases";
import { useSignCaseContract } from "@/hooks/lexZap/useSignCaseContract";
import { useUnsignCaseContract } from "@/hooks/lexZap/useUnsignCaseContract";
import { WebToastColoredWithTitle } from "@/components/core/Toast";
import { ReasonForCaseArchive } from "@/services/case/types";
import { DialogModal } from "@/components/DialogModal";
import { useCloseTicket } from "@/hooks/lexZap/useCloseTicket";

interface ITimeLineItem {
  title: string;
  date: DateTime;
  type?: "CONTRACT_SIGNED" | "ABANDONED";
  abandonJustificative?: {
    reasonType?: ReasonForCaseArchive | null;
    reasonDetails?: string | null;
  };
}

const labelReasonForCaseArchive = {
  [ReasonForCaseArchive.CLIENT_ABANDONED]: "Abandonado pelo cliente",
  [ReasonForCaseArchive.OUT_OF_INTEREST]: "Fora do interesse",
  [ReasonForCaseArchive.NOT_QUALIFIED_LEAD]: "Lead não qualificado",
  [ReasonForCaseArchive.DOES_NOT_MEET_MINIMUM_REQUIREMENTS]: "Não atende aos requisitos mínimos",
  [ReasonForCaseArchive.DOES_NOT_MEET_INCOME_CRITERIA]: "Não atende ao critério de renda",
  [ReasonForCaseArchive.DOES_NOT_MEET_HEALTH_CRITERIA]: "Não atende ao critério de saúde",
  [ReasonForCaseArchive.ALREADY_APPLIED_FOR_BENEFIT]: "Já deu entrada no benefício e quer tirar dúvidas",
  [ReasonForCaseArchive.ALREADY_HAS_LAWYER]: "Já tem advogado",
  [ReasonForCaseArchive.INQUIRING_ABOUT_OTHER_BENEFITS]: "Quer falar sobre outros benefícios",
  [ReasonForCaseArchive.OTHER]: "Outros",
};

interface ReasonForAbandonSelectOption {
  value: ReasonForCaseArchive;
  label: string;
}

const reasonForAbandonSelectOptions: ReasonForAbandonSelectOption[] = Object.entries(labelReasonForCaseArchive).map(
  ([value, label]) => ({
    value: value as ReasonForCaseArchive,
    label,
  })
);

interface CaseSituationProps {
  caseData?: Case;
  ticketId?: string;
  onCreateCase?: () => void;
  loadingCaseCreation?: boolean;
}

export const CaseSituation = ({ caseData, ticketId, onCreateCase, loadingCaseCreation }: CaseSituationProps) => {
  const { ticket } = useLexZapTicket({ ticketId: ticketId ?? caseData?.ticketId });
  const [reasonForAbandonSelectedOption, setReasonForAbandonSelectedOption] = useState<ReasonForAbandonSelectOption>();
  const [abandonJustificativeText, setAbandonJustificativeText] = useState<string>("");
  const [isAbandonJustificativeOpen, setIsAbandonJustificativeOpen] = useState(false);
  const [modalJustificativeDetails, setModalJustificativeDetails] = useState<{
    reasonType: ReasonForCaseArchive;
    reasonDetails?: string | null;
  }>();
  const { mutateAsync: archiveCases, isPending: isArchivingCase } = useArchiveCases();
  const { mutateAsync: unarchiveCases, isPending: isUnarchivingCase } = useUnarchiveCases();
  const { mutateAsync: closeTicket, isPending: isClosingTicket } = useCloseTicket();
  const { mutateAsync: signCaseContract, isPending: isSigningCaseContract } = useSignCaseContract();
  const { mutateAsync: unsignCaseContract, isPending: isUnsigningCaseContract } = useUnsignCaseContract();

  const timelineItems: ITimeLineItem[] = useMemo(() => {
    const items: ITimeLineItem[] = [];

    if (ticket) {
      items.push({
        title: "Primeiro contato",
        date: DateTime.fromMillis(ticket.createdAt),
      });
    }

    if (!caseData && ticket?.closedAt) {
      items.push({
        title: "Caso abandonado",
        date: DateTime.fromMillis(ticket.closedAt),
        type: "ABANDONED",
        abandonJustificative: {
          reasonType: Object.values(ReasonForCaseArchive).includes(ticket.closedReasonType as ReasonForCaseArchive)
            ? (ticket.closedReasonType as ReasonForCaseArchive)
            : undefined,
          reasonDetails: ticket.closedReasonDetails,
        },
      });
    }

    caseData?.timeline.forEach((caseTimeline) => {
      switch (caseTimeline.status) {
        case CaseStatus.ARCHIVED: {
          items.push({
            title: "Caso abandonado",
            date: dateTimeFromString(caseTimeline.createdAt),
            type: "ABANDONED",
            abandonJustificative: {
              reasonType: Object.values(ReasonForCaseArchive).includes(caseTimeline.reasonType as ReasonForCaseArchive)
                ? (caseTimeline.reasonType as ReasonForCaseArchive)
                : undefined,
              reasonDetails: caseTimeline.reasonDetails,
            },
          });
          break;
        }
        case CaseStatus.ACTIVE: {
          items.push({
            title: "Caso ativo",
            date: dateTimeFromString(caseTimeline.createdAt),
          });
          break;
        }
      }
    });

    if (caseData?.contractSignedAt) {
      items.push({
        title: "Contrato fechado",
        date: dateTimeFromString(caseData.contractSignedAt),
        type: "CONTRACT_SIGNED",
      });
    }

    return items.sort((a, b) => a.date.diff(b.date).toMillis());
  }, [ticket, caseData]);

  const handleArchiveCase = async () => {
    try {
      if (caseData) {
        await archiveCases({
          casesIds: [caseData.id],
          reasonType: reasonForAbandonSelectedOption?.value,
          reasonDetails:
            reasonForAbandonSelectedOption?.value === ReasonForCaseArchive.OTHER ? abandonJustificativeText : undefined,
        });
      } else if (ticket) {
        await closeTicket({
          ticketId: ticket.id,
          applicantPhoneNumber: ticket.applicantPhoneNumber,
          reasonType: reasonForAbandonSelectedOption?.value,
          reasonDetails:
            reasonForAbandonSelectedOption?.value === ReasonForCaseArchive.OTHER ? abandonJustificativeText : undefined,
        });
      }

      WebToastColoredWithTitle.success("Caso arquivado com sucesso");
      closeJustificative();
    } catch {
      WebToastColoredWithTitle.error(
        "Erro ao arquivar caso",
        "Infelizmente não foi possível completar a operação. Tente novamente mais tarde."
      );
    }
  };

  const handleUnarchiveCase = async () => {
    if (!caseData) {
      return;
    }

    try {
      await unarchiveCases({ casesIds: [caseData.id] });
      WebToastColoredWithTitle.success("Caso desarquivado com sucesso");
    } catch {
      WebToastColoredWithTitle.error(
        "Erro ao desarquivar caso",
        "Infelizmente não foi possível completar a operação. Tente novamente mais tarde."
      );
    }
  };

  const handleSignCaseContract = async () => {
    if (!caseData) {
      return;
    }

    try {
      await signCaseContract({ caseId: caseData.id });
      WebToastColoredWithTitle.success("Contrato fechado com sucesso");
    } catch {
      WebToastColoredWithTitle.error(
        "Erro ao fechar contrato",
        "Infelizmente não foi possível completar a operação. Tente novamente mais tarde."
      );
    }
  };

  const handleUnsignCaseContract = async () => {
    if (!caseData) {
      return;
    }

    try {
      await unsignCaseContract({ caseId: caseData.id });
      WebToastColoredWithTitle.success("Contrato desfeito com sucesso");
    } catch {
      WebToastColoredWithTitle.error(
        "Erro ao desfeitar contrato",
        "Infelizmente não foi possível completar a operação. Tente novamente mais tarde."
      );
    }
  };

  const closeJustificative = () => {
    setIsAbandonJustificativeOpen(false);
    setReasonForAbandonSelectedOption(undefined);
    setAbandonJustificativeText("");
  };

  const pendingRequests =
    isArchivingCase ||
    isUnarchivingCase ||
    isSigningCaseContract ||
    isUnsigningCaseContract ||
    isClosingTicket ||
    loadingCaseCreation;

  return (
    <>
      <Card>
        <Typography variant="h6" fontWeight={700}>
          Situação do caso
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {timelineItems.map((item, index) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineConnector sx={{ bgcolor: index === 0 ? "transparent" : undefined }} />
                  <TimelineDot color="primary">
                    <CheckIcon
                      sx={{
                        fontSize: 20,
                      }}
                    />
                  </TimelineDot>
                  <TimelineConnector sx={{ bgcolor: index === timelineItems.length - 1 ? "transparent" : undefined }} />
                </TimelineSeparator>
                <TimelineContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 0.5 }}>
                  <Typography variant="body1" fontWeight={700}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2">{item.date.toFormat("dd/MM/yyyy - HH:mm")}</Typography>
                  {item.type === "CONTRACT_SIGNED" && (
                    <Button
                      variant="text"
                      onClick={handleUnsignCaseContract}
                      startIcon={UndoIcon}
                      sx={{
                        width: "fit-content",
                        color: "text.primary",
                      }}
                      disabled={pendingRequests}
                    >
                      Reverter
                    </Button>
                  )}
                  {item.type === "ABANDONED" && !!item.abandonJustificative?.reasonType && (
                    <Button
                      variant="text"
                      onClick={() =>
                        setModalJustificativeDetails({
                          reasonType: item.abandonJustificative!.reasonType!,
                          reasonDetails: item.abandonJustificative!.reasonDetails,
                        })
                      }
                      sx={{
                        width: "fit-content",
                        textDecoration: "underline",
                      }}
                    >
                      Ver justificativa
                    </Button>
                  )}
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>

          {isAbandonJustificativeOpen ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: 2,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "16px",
                }}
              >
                Abandonar caso
              </Typography>

              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Justificativa"
                    placeholder="Selecione a justificativa aqui"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                options={reasonForAbandonSelectOptions}
                value={reasonForAbandonSelectedOption}
                disableClearable
                noOptionsText="Nenhuma opção encontrada"
                disablePortal
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(_, selectedOption) => {
                  setReasonForAbandonSelectedOption(selectedOption);
                }}
                sx={{
                  "& .MuiChip-root": {
                    visibility: "hidden",
                    display: "none",
                    color: "red",
                  },
                  "& .MuiChip-label": {
                    display: "none",
                    visibility: "hidden",
                    color: "red",
                  },
                }}
              />

              {reasonForAbandonSelectedOption?.value === ReasonForCaseArchive.OTHER && (
                <TextField
                  label="Justificativa"
                  placeholder="Escreva a justificativa aqui"
                  multiline
                  minRows={2}
                  maxRows={4}
                  fullWidth
                  value={abandonJustificativeText}
                  onChange={(e) => setAbandonJustificativeText(e.target.value)}
                />
              )}

              <Box sx={{ display: "flex", gap: 2 }}>
                <Button variant="text" fullWidth onClick={closeJustificative} disabled={pendingRequests}>
                  Cancelar
                </Button>
                <Button
                  disabled={pendingRequests}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleArchiveCase}
                >
                  Salvar
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              {caseData ? (
                <>
                  {caseData.status !== CaseStatus.ARCHIVED ? (
                    <Button
                      variant="text"
                      fullWidth
                      onClick={() => setIsAbandonJustificativeOpen(true)}
                      disabled={pendingRequests}
                    >
                      Abandonar
                    </Button>
                  ) : (
                    <Button disabled={pendingRequests} variant="text" fullWidth onClick={handleUnarchiveCase}>
                      Reativar caso
                    </Button>
                  )}
                  {caseData.status === CaseStatus.INACTIVE && (
                    <Button
                      disabled={pendingRequests}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={handleUnarchiveCase}
                    >
                      Ativar caso
                    </Button>
                  )}
                  {caseData.status === CaseStatus.ACTIVE && !caseData.contractSignedAt && (
                    <Button
                      disabled={pendingRequests}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={handleSignCaseContract}
                    >
                      Fechar contrato
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {!!ticket && (
                    <>
                      {ticket.status !== TicketStatus.CLOSED && (
                        <Button
                          variant="text"
                          fullWidth
                          onClick={() => setIsAbandonJustificativeOpen(true)}
                          disabled={pendingRequests}
                        >
                          Abandonar
                        </Button>
                      )}

                      <Button
                        disabled={pendingRequests}
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={onCreateCase}
                      >
                        Ativar caso
                      </Button>
                    </>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      </Card>

      <DialogModal
        open={!!modalJustificativeDetails}
        onClose={() => setModalJustificativeDetails(undefined)}
        title={"Justificativa de abandono"}
        description={
          <>
            {!!modalJustificativeDetails && (
              <>
                <Typography variant="body1">
                  {modalJustificativeDetails!.reasonType === ReasonForCaseArchive.OTHER
                    ? modalJustificativeDetails?.reasonDetails
                    : labelReasonForCaseArchive[modalJustificativeDetails!.reasonType]}
                </Typography>
              </>
            )}
          </>
        }
        descriptionTextAlign="left"
        buttonsDirection="row"
        buttons={[
          {
            label: "Fechar",
            onClick: () => setModalJustificativeDetails(undefined),
            variant: "text",
          },
        ]}
        variant="error"
      />
    </>
  );
};
