import { PageLayout } from "@/components/PageLayout";
import { Typography } from "@mui/material";
import React from "react";
import { Tags } from "./components/Tags";

export const InternalAgentsPage: React.FC = () => {
  return (
    <PageLayout
      contentProps={{
        fullWidth: true,
      }}
      sx={{
        "& .page-layout-container": {
          padding: 5,
        },
      }}
      variant="secondary"
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        Agentes Internos
      </Typography>

      <Typography variant="body1" sx={{ mb: 4, color: "text.secondary" }}>
        Esta página reúne ferramentas dedicadas à otimização dos nossos agentes internos. O sistema de TicketTags é a
        primeira dessas ferramentas, permitindo uma melhor organização e análise dos tickets.
      </Typography>

      <Tags />
    </PageLayout>
  );
};
