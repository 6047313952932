import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Case } from "../types";
import { CaseService } from "@/services/case";
import { getCaseQueryKey } from "../useLexZapCase";

export const useUnsignCaseContract = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["unsignCaseContract"],
    mutationFn: ({ caseId }: { caseId: string }) => CaseService.unsignCaseContract(caseId),
    onMutate: async ({ caseId }) => {
      const previousCase = queryClient.getQueryData<Case>(getCaseQueryKey(caseId));
      queryClient.setQueryData<Case>(getCaseQueryKey(caseId), (old) => {
        if (!old) return old;
        return { ...old, contractSignedAt: null };
      });

      return { previousCase };
    },
    onError: (_error, { caseId }, context) => {
      queryClient.setQueryData<Case>(getCaseQueryKey(caseId), context?.previousCase);
    },
  });
};
