export enum ReasonForCaseArchive {
  CLIENT_ABANDONED = "CLIENT_ABANDONED", // Abandonado pelo cliente
  OUT_OF_INTEREST = "OUT_OF_INTEREST", // Fora do interesse
  NOT_QUALIFIED_LEAD = "NOT_QUALIFIED_LEAD", // Lead não qualificado
  DOES_NOT_MEET_MINIMUM_REQUIREMENTS = "DOES_NOT_MEET_MINIMUM_REQUIREMENTS", // Não atende aos requisitos mínimos
  DOES_NOT_MEET_INCOME_CRITERIA = "DOES_NOT_MEET_INCOME_CRITERIA", // Não atende ao critério de renda
  DOES_NOT_MEET_HEALTH_CRITERIA = "DOES_NOT_MEET_HEALTH_CRITERIA", // Não atende ao critério de saúde
  ALREADY_APPLIED_FOR_BENEFIT = "ALREADY_APPLIED_FOR_BENEFIT", // Já deu entrada no benefício e quer tirar dúvidas
  ALREADY_HAS_LAWYER = "ALREADY_HAS_LAWYER", // Já tem advogado
  INQUIRING_ABOUT_OTHER_BENEFITS = "INQUIRING_ABOUT_OTHER_BENEFITS", // Quer falar sobre outros benefícios
  OTHER = "OTHER", // Outros
}
