import React, { useMemo } from "react";

import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { Close as CloseIcon, ArrowOutwardOutlined } from "@mui/icons-material";

import { PlanChips } from "@/pages/Plans/Plan/PlanChips";
import { usePlanChips } from "@/hooks/usePlanChips";
import { calculateDaysToEndPlan } from "@/utils/plans";

interface UsagePaywallModalProps {
  open: boolean;
  onClose: () => void;
  onOpenBilling: () => void;
}

export const UsagePaywallModal = ({ open, onClose, onOpenBilling }: UsagePaywallModalProps) => {
  const {
    planName,
    isTestingPlan,
    nextBillingDate,
    activeCasesQuota,
    usersQuota,
    activeCasesCount,
    usersCount,
    handleOpenUsersScreen,
    isLoadingCredits,
    isLoadingUsers,
  } = usePlanChips();

  const paywallMessage = useMemo(() => {
    const daysToEndPlan = calculateDaysToEndPlan(nextBillingDate);

    if (isTestingPlan && daysToEndPlan < 0) {
      return "Seu período de testes terminou";
    } else if (activeCasesCount >= activeCasesQuota) {
      return "Você atingiu o limite de uso";
    } else if (!isTestingPlan && daysToEndPlan < 0) {
      return "Seu plano está inativo";
    }
    return "Você atingiu o limite de uso";
  }, [isTestingPlan, activeCasesCount, activeCasesQuota, nextBillingDate]);

  return (
    <Modal open={open} onClose={onClose}>
      <Stack
        sx={{
          width: "600px",
          height: "528px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "common.white",
          borderRadius: 1,
          overflow: "hidden",
          p: 4,
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{paywallMessage}</Typography>
          <CloseIcon onClick={onClose} sx={{ color: "grey.500", cursor: "pointer" }} />
        </Box>
        <PlanChips
          planName={planName}
          isTestingPlan={isTestingPlan}
          nextBillingDate={nextBillingDate}
          usersCount={usersCount}
          usersQuota={usersQuota}
          handleOpenUsersScreen={handleOpenUsersScreen}
          isLoadingPlan={isLoadingCredits}
          isLoadingUsers={isLoadingUsers}
        />
        <Typography variant="body1">
          Para continuar utilizando a Plataforma Lexter e otimizando as tarefas do seu dia a dia com inteligência
          artificial, clique no botão abaixo e contrate um dos planos disponíveis.
        </Typography>
        <Stack direction="row" gap={2} justifyContent="flex-end">
          <Button variant="text" onClick={onClose}>
            Fechar
          </Button>
          <Button variant="contained" onClick={onOpenBilling} endIcon={<ArrowOutwardOutlined />}>
            Ver planos disponíveis
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
