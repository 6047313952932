import { useState } from "react";
import { GridRowId, GridRowModes, GridRowModesModel, GridEventListener, GridRowModel } from "@mui/x-data-grid";
import { useChats } from "../../../hooks/useChats";
import * as SyncTypes from "@/hooks/tempSyncLeadStage/types";
import * as TableViewTypes from "@/pages/WhatsappAssistantPageV2/components/TableView/types";

export const useEditModes = (
  rows: GridRowModel[],
  setRows: (rows: GridRowModel[]) => void,
  onError?: (error: Error) => void,
  onLeadStageChangeToAbandoned?: (rowId: string) => void
) => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [loadingRows, setLoadingRows] = useState<Set<GridRowId>>(new Set());
  const [successRows, setSuccessRows] = useState<Set<GridRowId>>(new Set());
  const [errorRows, setErrorRows] = useState<Set<GridRowId>>(new Set());
  const { updateChat } = useChats();

  // Add a new state to track pending abandonment rows
  const [pendingAbandonments, setPendingAbandonments] = useState<Record<string, boolean>>({});

  const handleRowEditStart: GridEventListener<"rowEditStart"> = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_params, event) => {
    // Prevent all automatic edit stops (focus out, escape, enter, tab)
    // Edit mode will only end through explicit Save/Cancel button clicks
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    const row = rows.find((r) => r.endClientNumber === id);
    const rowWithInitialValues = {
      ...row,
      _initialValue: { ...row },
    };
    setRows(rows.map((r) => (r.endClientNumber === id ? rowWithInitialValues : r)));
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setLoadingRows((prev) => new Set(prev).add(id));
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
  };

  // Separate function to detect abandonment changes
  const isChangingToAbandoned = (newRow: GridRowModel, oldRow: GridRowModel) => {
    return (
      newRow.leadStage === TableViewTypes.LeadStage.ABANDONADO &&
      oldRow.leadStage !== TableViewTypes.LeadStage.ABANDONADO
    );
  };

  const processRowUpdate = async (
    newRow: GridRowModel,
    oldRow: GridRowModel,
    abandonmentDetails?: {
      archiveType?: SyncTypes.ArchiveReason;
      archiveDetails?: string;
    }
  ) => {
    const rowId = newRow.endClientNumber;
    try {
      setLoadingRows((prev) => new Set(prev).add(rowId));

      // Check if this is an abandonment change
      const abandonmentChange = isChangingToAbandoned(newRow, oldRow);

      // If this is a new abandonment without details and not already pending
      if (abandonmentChange && !abandonmentDetails && !pendingAbandonments[rowId]) {
        // Mark as pending and trigger dialog
        setPendingAbandonments((prev) => ({ ...prev, [rowId]: true }));

        // Remove from loading state
        setLoadingRows((prev) => {
          const next = new Set(prev);
          next.delete(rowId);
          return next;
        });

        // Trigger the abandonment dialog
        if (onLeadStageChangeToAbandoned) {
          setTimeout(() => {
            onLeadStageChangeToAbandoned(rowId);
          }, 0);
        }

        // Return old row to prevent update until dialog is confirmed
        return oldRow;
      }

      // Optimistically update the UI immediately
      setRows(rows.map((row) => (row.endClientNumber === rowId ? { ...row, ...newRow } : row)));

      // Prepare update object with only changed fields
      const updateFields: Record<string, unknown> = {};

      // Compare each field and only include those that changed
      if (newRow.leadStage !== oldRow.leadStage) {
        updateFields.leadStage = newRow.leadStage;
      }

      if (JSON.stringify(newRow.assignedUserIds) !== JSON.stringify(oldRow.assignedUserIds)) {
        updateFields.assignedUserIds = newRow.assignedUserIds;
      }

      if (newRow.chatMode !== oldRow.chatMode) {
        updateFields.chatMode = newRow.chatMode;
      }

      if (newRow.caseNotes !== oldRow.caseNotes) {
        updateFields.caseNotes = newRow.caseNotes;
      }

      if (newRow.actionItems !== oldRow.actionItems) {
        updateFields.actionItems = newRow.actionItems;
      }

      // Call the API to update the chat with only the changed values
      await updateChat(rowId, updateFields, abandonmentDetails);

      // After successful save, add to success rows and show feedback
      setSuccessRows((prev) => new Set(prev).add(rowId));
      setTimeout(() => {
        setSuccessRows((prev) => {
          const next = new Set(prev);
          next.delete(rowId);
          return next;
        });
      }, 2000);

      // Clear loading state
      setLoadingRows((prev) => {
        const next = new Set(prev);
        next.delete(rowId);
        return next;
      });

      // Clear from pending abandonments if it was there
      if (pendingAbandonments[rowId]) {
        setPendingAbandonments((prev) => {
          const next = { ...prev };
          delete next[rowId];
          return next;
        });
      }

      return newRow;
    } catch (error) {
      // Revert the optimistic update on error
      setRows(rows.map((row) => (row.endClientNumber === rowId ? { ...row, ...oldRow } : row)));

      // Add to error rows and show feedback for 3 seconds
      setErrorRows((prev) => new Set(prev).add(rowId));
      setTimeout(() => {
        setErrorRows((prev) => {
          const next = new Set(prev);
          next.delete(rowId);
          return next;
        });
      }, 3000);

      setLoadingRows((prev) => {
        const next = new Set(prev);
        next.delete(rowId);
        return next;
      });

      const errorMessage = error instanceof Error ? error.message : "Failed to save changes";
      if (onError) onError(new Error(errorMessage));
      throw new Error(errorMessage); // This will trigger the error handling in the DataGrid
    }
  };

  const handleConfirmAbandon = (
    rowId: string | null,
    abandonReason: SyncTypes.ArchiveReason | null,
    abandonDetails: string
  ) => {
    if (!rowId || !abandonReason) return null;

    // Find the row being edited
    const rowToUpdate = rows.find((row) => row.endClientNumber === rowId);
    if (!rowToUpdate) return null;

    // Create a copy of the row with updated values
    const updatedRow = {
      ...rowToUpdate,
      leadStage: TableViewTypes.LeadStage.ABANDONADO,
    };

    // Get the details
    const details = abandonReason === SyncTypes.ArchiveReason.OTHER && abandonDetails ? abandonDetails : "";

    // Process the row update with abandonment details
    return processRowUpdate(updatedRow, rowToUpdate, {
      archiveType: abandonReason,
      archiveDetails: details,
    });
  };

  return {
    rowModesModel,
    setRowModesModel,
    handleRowEditStart,
    handleRowEditStop,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    processRowUpdate,
    handleConfirmAbandon,
    loadingRows,
    successRows,
    errorRows,
  };
};
