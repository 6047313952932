import { useCallback, useState, useEffect } from "react";

import { WebToastColoredWithTitle } from "@/components/core/Toast";
import { logger } from "@/core/logger";
import { LexZapChat } from "@/hooks/lexZap/types";
import { useApplicants } from "@/hooks/applicants/useApplicants";
import { WhatsappServerService } from "@/services/whatsappServer";
import { transformToLexZapChat } from "./useChats";

export const useChat = (applicantPhoneNumber: string) => {
  const [chat, setChat] = useState<LexZapChat | null>(null);
  const [loading, setLoading] = useState(false);
  const { data: applicants } = useApplicants();

  const fetchChat = useCallback(async () => {
    if (!applicantPhoneNumber) return;

    setLoading(true);
    try {
      const chatData = await WhatsappServerService.chats.getChat({
        applicantPhoneNumber,
      });

      // Find applicant data
      const applicantData = applicants?.find(
        (applicant) =>
          applicant.phoneNumber === applicantPhoneNumber || applicant.phoneNumber?.toString() === applicantPhoneNumber
      );

      // Transform the ChatWithLastMessage to LexZapChat with applicant data
      setChat(transformToLexZapChat(chatData, applicantData));
    } catch (error) {
      logger.error("Failed to fetch chat:", { error });
      WebToastColoredWithTitle.error("Erro", "Não foi possível carregar o chat");
    } finally {
      setLoading(false);
    }
  }, [applicantPhoneNumber, applicants]);

  useEffect(() => {
    void fetchChat();
  }, [fetchChat]);

  return {
    chat,
    loading,
    fetchChat,
  };
};
