import { useEffect } from "react";

import { LoggedInUserData, UpdatePasswordErrors } from "@/contexts/AuthContext/types";
import * as logger from "@/core/logger";
import { SignUpSource } from "@/hooks/useApi/types";
import { FirebaseService } from "@/services/firebase";
import { LoginWithEmailAndPassword, LoginWithGoogle, SignInGoogle } from "./types";
import { env } from "@/constants/environment";
import { Result, left, right } from "@/core/Result";
import { useUserGuiding } from "@/hooks/useUserGuiding";
import { LexterCopilotAuthService } from "@/services/lexterCopilotAuth";
import { LexterApiService } from "@/services/lexterApi";
import { useInterval } from "@/hooks/useInterval";
import { AuthService } from "@/services/auth";
import { useUserQuery } from "@/hooks/user/useUserQuery";

const CHECK_TOKEN_INTERVAL = 1000 * 60 * 2; // 2 minutes

export const useLogin = () => {
  const userGuiding = useUserGuiding();

  const { data: user, isPending: loading } = useUserQuery();

  const redirectToAppDiligenceUsers = async (user: LoggedInUserData) => {
    const companyDetails = await LexterApiService.getCompanyDetailsById(user.companyId);
    if (!companyDetails.products.includes("COPILOT")) {
      window.location.replace(env.APP_LEXTER_URL as string);
    }
  };

  const loginWithEmailAndPassword: LoginWithEmailAndPassword = async (
    email: string,
    password: string,
    recaptchaToken: string
  ) => {
    try {
      logger.debug(`loginWithEmailAndPassword with email ${email}`);
      const userCredential = await FirebaseService.signInWithEmailAndPassword(email.trim(), password);

      if (!userCredential.user?.emailVerified) {
        const { signUpSource } = await LexterCopilotAuthService.getSignupSource({ email, recaptchaToken });

        return {
          success: false,
          emailUnverified: true,
          requiresActivationCode: [
            SignUpSource.WORD_ADD_IN,
            SignUpSource.COPILOT_WEB,
            SignUpSource.COPILOT_WORD,
            SignUpSource.COPILOT_WORD_WEB,
          ].includes(signUpSource!),
        };
      }

      await AuthService.setupFromFirebase();

      return { success: true };
    } catch (e) {
      const error = e as { message?: string; code?: string };
      logger.warn(`Error in loginWithEmailAndPassword ERROR ${error.message}, code ${error.code}, email ${email}`, {
        error,
      });

      const isCredentialsError = ["auth/wrong-password", "auth/invalid-email", "auth/user-not-found"].includes(
        error.code ?? ""
      );

      if (isCredentialsError) {
        return { success: false, invalidCredentials: true };
      }
      return { success: false, invalidCredentials: false };
    }
  };

  const loginWithGoogle: LoginWithGoogle = async () => {
    const signedIn = await signInGoogle();

    if (!signedIn.success || !signedIn.token) {
      return { success: false };
    }

    try {
      await LexterCopilotAuthService.getUserData({ token: signedIn.token });
      await AuthService.setupFromFirebase(); // Just setup the firebase token if everything goes ok getting the user data

      return { success: true };
    } catch (e) {
      const error = e as { response?: { status: number }; message?: string };
      logger.warn(`Error in loginWithGoogle ERROR ${error.message}`, { error });

      if (error.response) {
        return { success: false, needSignUp: { user: signedIn.user } };
      }

      return { success: false };
    }
  };

  const signInGoogle: SignInGoogle = async () => {
    try {
      const result = await FirebaseService.signInWithGoogle();
      const token = await result.user.getIdToken();
      return { success: true, user: result.user, token };
    } catch (error) {
      if (error instanceof Object && "message" in error && "code" in error) {
        logger.warn(`Error in signInGoogle ERROR ${error.message}, code ${error.code}`, { error });
      }

      return { success: false };
    }
  };

  const updatePassword = async (currentPassWord: string, newPassword: string) => {
    try {
      const userEmail = FirebaseService.getCurrentUser()?.email ?? user?.userEmail;

      if (userEmail) {
        const response = await FirebaseService.reauthenticateWithCredential(userEmail, currentPassWord)
          ?.then(() => FirebaseService.updatePassword(newPassword))
          .catch((e) => {
            return Result.fail(e);
          });

        if (response?.isFailure) {
          return left(UpdatePasswordErrors.INVALID_PASSWORD);
        }
        return right(undefined);
      }

      return left(UpdatePasswordErrors.GENERIC);
    } catch (e) {
      if (e instanceof Object && "message" in e) {
        logger.warn(`Error in updatePassword ERROR ${e.message}`, { error: e });
      }
      return left(UpdatePasswordErrors.GENERIC);
    }
  };

  useEffect(() => {
    if (user) {
      userGuiding.identify(user);
      logger.setProfile(user.userId, user.companyId, user.userName, user.userEmail);
      void redirectToAppDiligenceUsers(user);
    }
  }, [user]);

  useInterval(() => AuthService.validateToken(), CHECK_TOKEN_INTERVAL);

  return {
    user,
    loading,
    loginWithEmailAndPassword,
    loginWithGoogle,
    updatePassword,
  };
};
