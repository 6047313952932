import { CaseFilters } from "../../components/FilterCasesModal/types";
export const calculateCaseFiltersChanges = ({
  filters,
  defaultFilters,
}: {
  filters: CaseFilters;
  defaultFilters: CaseFilters;
}) => {
  let changes = 0;

  if (filters.origin.imported !== defaultFilters.origin.imported) {
    changes++;
  }
  if (filters.origin.manual !== defaultFilters.origin.manual) {
    changes++;
  }
  if (filters.origin.assistant !== defaultFilters.origin.assistant) {
    changes++;
  }
  if (filters.boundStatus !== defaultFilters.boundStatus) {
    changes++;
  }

  if (filters.legalAreas.length !== defaultFilters.legalAreas.length) {
    changes++;
  }

  return changes;
};
