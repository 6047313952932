import React, { useEffect, useState } from "react";

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeElementsOptionsMode, loadStripe } from "@stripe/stripe-js";
import { useApi } from "@/hooks/useApi";
import { useAuthContext } from "@/contexts/AuthContext";
import { Box, Link, TextField, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import { EXTERNAL_URLS } from "@/routes/routePaths";
import { Checkbox } from "@/components/Checkbox";
import { CardCvcAndExpiryField } from "./CardCvcAndExpiryField";
import { CardNumberField } from "./CardNumberField";
import { isPhoneNumberValid, PhoneNumberField } from "./PhoneNumberField";
import { CpfOrCpnjField, isCpfCnpjValid } from "./CpfOrCpnjField";
import { usePaymentContext } from "@/contexts/PaymentContext";
import { env } from "@/constants/environment";
import * as logger from "../../../../core/logger";
import { AxiosError } from "axios";

const stripePromise = loadStripe(env.STRIPE.PUBLISHABLE_KEY);

export const CreditCardMethod = () => {
  const { selectedPlan } = usePaymentContext();

  const options: StripeElementsOptionsMode = {
    mode: "subscription",
    amount: selectedPlan.price,
    currency: "brl",
    loader: "auto",
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm />
    </Elements>
  );
};

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { handlePayment } = useApi();
  const { user } = useAuthContext();
  const { selectedPlan } = usePaymentContext();
  const { onLoadingChange, handleSuccessChange, handleErrorChange, couponSelected } = usePaymentContext();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cpfOrCnpj, setCpfOrCnpj] = useState("");
  const [name, setName] = useState("");
  const [termsAndUseCheck, setTermsAndUseCheck] = useState(false);
  const [cardFieldsValid, setCardFieldsValid] = useState<{
    cardNumber: boolean;
    cardCvc: boolean;
    cardExpiry: boolean;
  }>({
    cardNumber: false,
    cardCvc: false,
    cardExpiry: false,
  });

  useEffect(() => {
    onLoadingChange(true);
  }, []);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (cardElement) {
      cardElement.on("change", (event) => {
        setCardFieldsValid((prevState) => ({
          ...prevState,
          cardNumber: event.error ? false : true,
        }));
      });
    }

    const cardCvcElement = elements.getElement(CardCvcElement);
    if (cardCvcElement) {
      cardCvcElement.on("change", (event) => {
        setCardFieldsValid((prevState) => ({
          ...prevState,
          cardCvc: event.error ? false : true,
        }));
      });
    }

    const cardExpiryElement = elements.getElement(CardExpiryElement);
    if (cardExpiryElement) {
      cardExpiryElement.on("change", (event) => {
        setCardFieldsValid((prevState) => ({
          ...prevState,
          cardExpiry: event.error ? false : true,
        }));
      });
    }
  }, [stripe, elements]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    try {
      event.preventDefault();

      if (!stripe || !elements) {
        logger.error(`Stripe or Elements not loaded, stripeLoaded: ${!!stripe}, elementsLoaded: ${!!elements}`);
        return;
      }

      onLoadingChange(
        true,
        <>
          Realizando a contratação de sua assinatura. <br /> Aguarde um momento.
        </>
      );

      const { error: submitError } = await elements.submit();

      if (submitError) {
        logger.error("Error on submitting payment:", { error: submitError });
        handleErrorChange(true);
        return;
      }

      if (!user || !user.userEmail) {
        logger.error("User or userEmail not found, user:", { messageContext: { user } });
        handleErrorChange(true);
        return;
      }

      const res = await handlePayment({
        email: user.userEmail,
        planName: selectedPlan.id,
        companyId: user.companyId,
        phoneNumber,
        cpfOrCnpj,
        couponCode: couponSelected?.code,
      });

      const { token } = await res;

      const cardElement = elements.getElement(CardNumberElement);
      if (!cardElement) {
        logger.error(`Error on getting the cardElement when submitting payment`);
        handleErrorChange(true);
        return;
      }

      const { error } = await stripe.confirmCardPayment(token, {
        payment_method: {
          card: cardElement,
          billing_details: {
            address: {
              country: "BR",
            },
          },
        },
      });

      if (error) {
        logger.error(`Error on confirming card payment, error.code:  ${error.code}`, { error });
        let errorMessage: string | undefined = undefined;
        if (error.code === "card_declined" && error.decline_code) {
          errorMessage = cardDeclinedMessageMapper[error.decline_code];
        } else if (error.code) {
          errorMessage = anotherErrorsMessageMapper[error.code];
        }
        handleErrorChange(true, { errorMessage });
        onLoadingChange(false);
        return;
      }

      handleSuccessChange(true);
    } catch (error) {
      logger.error("Error on handling payment:", { error });
      if (error instanceof AxiosError && error.response?.data?.message === "Invalid coupon for this subscription") {
        return handleErrorChange(true, {
          errorMessage: "Cupom não pode ser aplicado a sua assinatura",
        });
      }
      handleErrorChange(true);
    } finally {
      onLoadingChange(false);
    }
  };

  const validateInputs = () => {
    return (
      isPhoneNumberValid(phoneNumber).isSuccess &&
      isCpfCnpjValid(cpfOrCnpj).isSuccess &&
      termsAndUseCheck &&
      name !== "" &&
      cardFieldsValid.cardCvc &&
      cardFieldsValid.cardExpiry &&
      cardFieldsValid.cardNumber
    );
  };

  const onTermsAndUseChange = () => {
    setTermsAndUseCheck(!termsAndUseCheck);
  };

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ".MuiInputBase-root": {
            marginTop: "0!important",
            paddingLeft: "0px",
          },
        }}
      >
        <Typography mb={1} mt={2} variant="subtitle1">
          Dados de pagamento
        </Typography>
        <CardNumberField />
        <CardCvcAndExpiryField />
        <TextField
          sx={{
            mt: 1,
            fieldset: {
              borderColor: "common.lightShade",
            },
            label: { fontSize: "14.5px" },
          }}
          onChange={onNameChange}
          label="Nome"
        />
        <Typography my={2} mt={3} variant="subtitle1">
          Dados do usuário
        </Typography>
        <CpfOrCpnjField onCpfCpnjChange={setCpfOrCnpj} />
        <PhoneNumberField onPhoneNumberChange={setPhoneNumber} />
        <TermsAndUseCheck onTermsAndUseChange={onTermsAndUseChange} />
        <Button
          disabled={!validateInputs()}
          type="submit"
          sx={{ px: 12, mb: 3, mt: 3, alignSelf: "center", width: "100%" }}
          variant="contained"
        >
          Contratar Plano
        </Button>
      </Box>
    </form>
  );
};

const TermsAndUseCheck = ({ onTermsAndUseChange }: { onTermsAndUseChange: () => void }) => {
  return (
    <Checkbox
      sx={{
        my: 3,
        alignItems: "flex-start",
        width: "100%",
        label: {
          alignItems: "center",
        },
      }}
      onChange={onTermsAndUseChange}
      label={
        <Typography variant="multiLineBody" color="common.shade">
          Estou de acordo com os{" "}
          <Link href={EXTERNAL_URLS.TERMS_OF_USE} target="_blank">
            Termos de Uso
          </Link>{" "}
          e{" "}
          <Link href={EXTERNAL_URLS.PRIVACY_POLICY} target="_blank">
            Política de Privacidade
          </Link>{" "}
          da Lexter.Ai.
        </Typography>
      }
    />
  );
};

const cardDeclinedMessageMapper: Record<string, string> = {
  insufficient_funds: "Saldo insuficiente",
  lost_card: "Cartão perdido",
  stolen_card: "Cartão roubado",
  card_not_supported: "Cartão não suportado, atualmente só aceitamos cartões VISA ou MASTERCARD",
};

const anotherErrorsMessageMapper: Record<string, string> = {
  card_declined: "Cartão recusado",
  expired_card: "Cartão expirado",
  incorrect_cvc: "Código de segurança inválido",
  incorrect_number: "Número do cartão inválido",
  processing_error: "Erro de processamento",
};
