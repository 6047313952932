import React, { useEffect } from "react";
import { Box, TextField, DialogContent, InputAdornment, Popover } from "@mui/material";
import { TagDefinition } from "@/services/internalAgents/types";
import { useTagForm } from "./hooks/useTagForm";

interface TagFormProps {
  initialData?: Partial<TagDefinition>;
  onChange: (form: Partial<TagDefinition>) => void;
}

export const TagForm = ({ initialData, onChange }: TagFormProps) => {
  const { form, errors, handleChange } = useTagForm(initialData);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  useEffect(() => {
    onChange(form);
  }, [form, onChange]);

  const handleColorClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColorClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange("color")(event);
    handleColorClose();
  };

  return (
    <DialogContent>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          pt: 2,
          minWidth: 500,
        }}
      >
        <TextField
          label="Nome"
          value={form.tag_name || ""}
          onChange={handleChange("tag_name")}
          error={!!errors.tag_name}
          helperText={errors.tag_name}
          fullWidth
          required
          size="medium"
        />

        <TextField
          label="Descrição"
          value={form.description || ""}
          onChange={handleChange("description")}
          fullWidth
          multiline
          rows={3}
          size="medium"
        />

        <TextField
          label="Categoria"
          value={form.category || ""}
          onChange={handleChange("category")}
          fullWidth
          size="medium"
        />

        <TextField
          label="Cor"
          value={form.color || ""}
          onChange={handleChange("color")}
          error={!!errors.color}
          helperText={errors.color}
          fullWidth
          size="medium"
          onClick={handleColorClick}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: 1,
                    bgcolor: form.color || "#fff",
                    border: "1px solid #ccc",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleColorClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 2 }}>
            <input
              type="color"
              value={form.color || "#fff"}
              onChange={handleColorChange}
              style={{ width: "200px", height: "40px" }}
            />
          </Box>
        </Popover>
      </Box>
    </DialogContent>
  );
};
