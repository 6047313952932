export const defaultFlagValues = {
  /** Habilita e passa o link do vídeo de introdução */
  tutorialVideoUrl: "",
  /** Habilita o alerta de documento read only */
  warnReadOnlyDocument: false,
  /** Url do WhatsApp para iniciar uma conversa com CS */
  customerServiceWhatsApp: "",
  /** Habilita a funcionalidade de buscar jurisprudência */
  jurisprudenceSearch: false,
  /** Habilita o Indique e Ganhe  */
  membergetmemberEnabled: false,
  /** Habilita novo card da ação sugerida com valores (créditos) */
  newActionCardWithCreditAmountEnabled: false,
  /** Mostra o plano basic pre selecionado na tela de pagamento*/
  preSelectedBasicPlan: false,
  /** Habilita a edição de documentos com formatação (HTML) no editor */
  formattedEditionEnabled: false,
  /** Habilita o chat tawk para todos os usuários */
  tawkForAllUsers: false,
  /** Habilita o reenvio de convite*/
  resendReferralEnabled: false,
  /** Habilita nova tela do Assistente WhatsApp */
  whatsAppAssistantEnabled: false,
  /** Habilita o plano essencial */
  unlimitedPlanEnabled: false,
  /** Habilita a exibição do fluxo de onboarding para novos usuários */
  showOnboarding: false,
  /** Habilita a página de sucesso após criação de peça */
  successPageAfterCreationOfPiece: false,
  /** Habilita a ocultação de campos opcionais de skills */
  hideOptionalSkillFields: false,
  /** Habilita inside sales */
  insideSalesEnabled: false,
  /** Habilita apenas o plano essencial para compra */
  onlyUnlimitedPlanEnabled: true,
  /** Habilita o trial para o plano ilimitado */
  trialForUnlimitedPlanEnabled: false,
  /** Habilita os novos planos profissional e performance na tela de planos*/
  newPlansEnabled: false,
  /** Habilita os novos planos profissional e performance no checkout */
  newPlansInCheckoutEnabled: false,
  /** Habilita a funcionalidade de testes de novas skills */
  skillBetaTester: false,
  /** Links do inside sales */
  insideSalesLinks: {
    speakWithSpecialistNow: "https://meet.google.com/omb-mrzr-qtd",
    schedule: "https://calendly.com/carlos-vieira-lexter/30min",
    whatsapp: "https://wa.me/5582987123045",
  },
  /** Habilita a funcionalidade de busca de skills no skill group */
  skillGroupSearchEnabled: false,
  /** Cupom do plano essencial */
  essentialPlanCoupon: "30lexter",
  /** Habilita o numero de telefone no cadastro com google */
  phoneInSignUpWithGoogleEnabled: false,
  /** Habilita a funcionalidade de proximas etapas no caso */
  caseNextStepsEnabled: false,
  /** Petição Inicial (Sem arquivos de provas) */
  skillInitialPetitionV1Enabled: true,
  /** Petição Inicial (Com arquivos de provas) */
  skillInitialPetitionV2Enabled: false,
  /** Habilita a opção carf na busca de jurisprudência */
  searchPrecedentCarfEnabled: false,
  /** Habilita a página de processos legais */
  legalProceedingsPageEnabled: false,
  /** Habilita a exibição de sugestões de passos no caso */
  caseSuggestionsEnabled: false,
  /** Habilita gestão de atendimento no whatsapp. "Tickets" */
  whatsappTicketsEnabled: true,
  /** Habilita o modo de desenvolvimento */
  devModeEnabled: false,
  /** Habilita a funcionalidade de multiplos usuários */
  multipleUsers: false,
  /** Habilita as novas telas do assistente */
  newAssistentScreensEnabled: false,
  /** Habilita a nova página de casos */
  newCasesPageEnabled: false,
  /** Habilita a criação de caso por CNJ */
  createCaseByCnjEnabled: false,
  /** Habilita a nova tela de criação de caso */
  newCaseCreationScreenEnabled: false,
  /** Habilita a nova tela de planos */
  planScreenV2Enabled: false,
  /** Habilita a validação de quota de casos */
  shouldValidateCasesQuota: false,
  /** Habilita a nova tela de histórico de uso */
  newUsageHistoryScreen: false,
  /** Habilita réplica sem evidências */
  skillCreateReplyV1Enabled: false,
  /** Habilita defesa sem evidências */
  skillCreateDefenseV1Enabled: false,
  /** Habilita outro tipo de peça sem evidências */
  skillOtherTypeOfPieceV1Enabled: false,
  /** Habilita reclamação trabalhista sem evidências */
  skillCreateLaborComplaintV1Enabled: false,
  /** Habilita réplica com evidências */
  skillCreateReplyV2Enabled: false,
  /** Habilita defesa com evidências */
  skillCreateDefenseV2Enabled: false,
  /** Habilita outro tipo de peça com evidências */
  skillOtherTypeOfPieceV2Enabled: false,
  /** Habilita reclamação trabalhista com evidências */
  skillCreateLaborComplaintV2Enabled: false,
  /** Habilita o Cloud Humans */
  cloudHumansChatEnabled:
    "TRIAL,FREEMIUM,INITIAL,BASIC,INTERMEDIARY,ADVANCED,PROFESSIONAL,CUSTOM,UNLIMITED,PROFESSIONAL_WITH_WPP,PERFORMANCE",
  /** Habilita a funcionalidade de upload de arquivos */
  fileSignAndUpload: false,
  /** Habilita a nova tela de histórico de uso */
  newUsageHistoryV2: false,
  /** Habilita etiqueta de ativo no requerente */
  applicantActivationEnabled: false,
  /** Habilita widget de track de funil do caso */
  caseSituationEnabled: false,
  /** Habilita o serviço de aprovação de requisições */
  approvalRequestsEnabled: false,
  /** Habilita o botão para iniciar um agente de whatsapp */
  startWhatsappAgentEnabled: false,
  /** Habilita customer help chat */
  customerHelpChatEnabled: true,
};
