import React from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

interface LongTextCellProps {
  content: string;
  emptyText: string;
  fieldLabel: string;
  fieldName: string;
  id: string;
  onOpenTextEditor?: (id: string, field: string, value: string) => void;
}

export const LongTextCell: React.FC<LongTextCellProps> = ({
  content,
  emptyText,
  fieldLabel,
  fieldName,
  id,
  onOpenTextEditor,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        position: "relative",
        py: 0,
        px: 1,
        overflow: "hidden",
      }}
    >
      <Tooltip
        title={
          <Box sx={{ p: 1, maxWidth: 500 }}>
            <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
              {content || emptyText}
            </Typography>
          </Box>
        }
        arrow
        placement="top-start"
      >
        <Typography
          variant="body2"
          className="long-text-cell"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 5,
            WebkitBoxOrient: "vertical",
            lineHeight: 1.15,
            fontSize: "0.75rem",
            width: "calc(100% - 24px)", // Make room for the edit button
            wordBreak: "break-word",
            whiteSpace: "pre-line",
            color: content ? "text.primary" : "text.secondary",
            fontStyle: content ? "normal" : "italic",
            mt: 0,
            mb: 0,
            pt: 0.25,
          }}
        >
          {content || emptyText}
        </Typography>
      </Tooltip>

      {onOpenTextEditor && (
        <Tooltip title={`Editar ${fieldLabel}`}>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onOpenTextEditor(id, fieldName, content);
            }}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              opacity: 0.6,
              padding: "1px",
              "&:hover": {
                opacity: 1,
                backgroundColor: "action.hover",
              },
            }}
          >
            <EditIcon fontSize="small" sx={{ fontSize: "0.85rem" }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
