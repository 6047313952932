import { useQuery } from "@tanstack/react-query";
import { Case } from "../types";
import { useApi } from "@/hooks/useApi";
export const getCaseQueryKey = (caseId?: string) => ["case", caseId];

interface UseLexZapCaseProps {
  caseId?: string;
}

interface UseLexZapCaseReturn {
  caseData?: Case;
  isLoading: boolean;
  isError: boolean;
}

export const useLexZapCase = ({ caseId }: UseLexZapCaseProps): UseLexZapCaseReturn => {
  const { getLexZapCaseById } = useApi();
  const { data, isLoading, isError } = useQuery<Case>({
    queryKey: getCaseQueryKey(caseId),
    queryFn: () => (caseId ? getLexZapCaseById({ caseId }) : Promise.reject("No caseId provided")),
    enabled: !!caseId,
  });

  if (!caseId) {
    return {
      caseData: undefined,
      isLoading: false,
      isError: false,
    };
  }

  return {
    caseData: data,
    isLoading,
    isError,
  };
};
