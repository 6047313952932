import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Case } from "../types";
import { WebToast } from "@/components/core/Toast";
import { logger } from "@/core/logger";
import { isAxiosError } from "axios";
interface QueryResponse {
  data: Case[];
  totalResults: number;
  pageSize: number;
  currentPage: number;
}

export const useCreateCase = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["createCase"],
    mutationFn: async ({
      name,
      applicantId,
      applicantName,
      applicantPhoneNumber,
      ticketId,
      summary,
      threadId,
    }: {
      name: string;
      applicantId: string | null;
      applicantName: string | null;
      applicantPhoneNumber: string | null;
      ticketId?: string;
      summary?: string;
      threadId?: string;
    }) => {
      return {
        ...(await api.createCase({ name, applicantId: applicantId ?? undefined, ticketId, summary, threadId })),
        applicantName,
        applicantPhoneNumber,
      };
    },
    onSuccess: async (data) => {
      await queryClient.cancelQueries({ queryKey: ["lexZapCases"] });

      queryClient.setQueryData<QueryResponse>(["lexZapCases", "ACTIVE"], (old?: QueryResponse) => {
        if (!old) return { data: [data], totalResults: 1, pageSize: 25, currentPage: 1 };
        return {
          ...old,
          data: [data, ...old.data],
          totalResults: old.totalResults + 1,
        };
      });

      await queryClient.invalidateQueries({
        queryKey: ["lexZapCases"],
        exact: false,
        refetchType: "all",
      });
    },
    onError: (error) => {
      handleErrorCreatingCase(error);
    },
  });
};

const handleErrorCreatingCase = (error: unknown) => {
  logger.error("handleErrorCreatingCase");

  if (isAxiosError(error) && error.status === 409) {
    return WebToast.error("O requerente desse caso já possui outro caso ativo.", 10000);
  }

  return WebToast.error("Erro ao criar o caso");
};
