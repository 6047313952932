export enum AssignedFilter {
  EMPTY = "EMPTY",
  ALL = "ALL",
  ASSIGNED_TO_ME = "ASSIGNED_TO_ME",
  NOT_ASSIGNED = "NOT_ASSIGNED",
}

export interface ChatQuickFilters {
  ticketStatus: {
    abandoned: boolean;
    aiService: boolean;
    redirectedHuman: boolean;
    initialTriage: boolean;
    closed: boolean;
  };
  assigned: AssignedFilter;
  answered: {
    answered: boolean;
    unanswered: boolean;
  };
  responsibles: string[];
  chatCreationDateStart?: number;
  chatCreationDateEnd?: number;
}

export interface ChatQuickFiltersCount {
  ticketStatus: {
    abandoned: number;
    aiService: number;
    redirectedHuman: number;
    initialTriage: number;
    closed: number;
  };
  answered: {
    answered: number;
    unanswered: number;
  };
  responsibles: {
    [key: string]: number;
  };
}
