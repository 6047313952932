import React from "react";
import * as MUI from "@mui/material";
import * as MUIIcons from "@mui/icons-material";
import { ChatMode } from "@/services/whatsappServer/types/chat";
import * as types from "./types";

export const ChatModeFilter: React.FC<types.ChatModeFilterProps> = ({ chatFilters, setChatFilters }) => {
  // Define available chat modes
  const availableChatModes = Object.values(ChatMode);

  // Map chat modes to more user-friendly display names
  const getChatModeDisplayName = (mode: ChatMode) => {
    switch (mode) {
      case ChatMode.AI:
        return "IA";
      case ChatMode.AI_PAUSED_FOR_CURRENT_TICKET:
        return "IA Pausada";
      case ChatMode.MANUAL:
        return "Manual";
      default:
        return mode;
    }
  };

  // Handle removing a chat mode
  const handleRemoveChatMode = (mode: ChatMode) => {
    setChatFilters((prev) => ({
      ...prev,
      chatModes: prev.chatModes?.filter((item) => item !== mode),
    }));
  };

  // Handle adding chat modes
  const handleAddChatModes = (selectedOptions: ChatMode[]) => {
    setChatFilters((prev) => ({
      ...prev,
      chatModes: selectedOptions,
    }));
  };

  return (
    <MUI.Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, flex: 1 }}>
      <MUI.Typography variant="body2" color="text.secondary" sx={{ minWidth: "90px" }}>
        Modo:
      </MUI.Typography>

      <MUI.Box sx={{ flexGrow: 1 }}>
        {/* Display selected chat modes as chips */}
        {chatFilters.chatModes?.length > 0 && (
          <MUI.Box
            sx={{
              display: "flex",
              gap: 1,
              flexWrap: "wrap",
              mb: 1,
            }}
          >
            {chatFilters.chatModes.map((mode) => (
              <MUI.Chip
                key={mode}
                label={getChatModeDisplayName(mode)}
                onDelete={() => handleRemoveChatMode(mode)}
                sx={{
                  backgroundColor: "secondary.main",
                  color: "text.primary",
                }}
                deleteIcon={<MUIIcons.CloseOutlined />}
                size="small"
              />
            ))}
          </MUI.Box>
        )}

        {/* Chat mode selection autocomplete */}
        <MUI.Autocomplete
          multiple
          options={availableChatModes}
          value={chatFilters.chatModes}
          noOptionsText="Nenhuma opção encontrada"
          disablePortal
          getOptionLabel={(option) => getChatModeDisplayName(option)}
          onChange={(_, selectedOptions) => handleAddChatModes(selectedOptions)}
          renderInput={(params) => <MUI.TextField {...params} placeholder="Selecionar modo" size="small" />}
          renderTags={() => null}
          sx={{ flexGrow: 1, width: "100%" }}
        />
      </MUI.Box>
    </MUI.Box>
  );
};
