import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { ApplicantEvent, EventCategory, CreatorType } from "@/services/applicantEvents/types";
import { logger } from "@/core/logger";

interface EventModalProps {
  open: boolean;
  onClose: () => void;
  event: ApplicantEvent | null;
  mode: "view" | "edit" | "create";
  onEdit: () => void;
  onSave: (event: ApplicantEvent, isNew: boolean) => Promise<void>;
}

export const EventModal: React.FC<EventModalProps> = ({ open, onClose, event, mode, onEdit, onSave }) => {
  const [formData, setFormData] = useState<Partial<ApplicantEvent>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (event) {
      setFormData({ ...event });
    } else {
      // Default values for new event
      setFormData({
        companyId: "", // Should be set from context in a real app
        applicantPhoneNumber: "",
        category: EventCategory.STAGE_CHANGE,
        eventName: "",
        details: {},
        createdBy: "", // Should be current user ID in a real app
        creatorType: CreatorType.USER,
      });
    }
    setErrors({});
  }, [event, open]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }> | SelectChangeEvent
  ) => {
    const { name, value } = e.target;
    if (name) {
      setFormData((prev) => ({ ...prev, [name]: value }));

      // Clear error when field is edited
      if (errors[name]) {
        setErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors[name];
          return newErrors;
        });
      }
    }
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};

    if (!formData.companyId) {
      newErrors.companyId = "ID da empresa é obrigatório";
    }

    if (!formData.applicantPhoneNumber) {
      newErrors.applicantPhoneNumber = "Telefone do requerente é obrigatório";
    }

    if (!formData.eventName) {
      newErrors.eventName = "Nome do evento é obrigatório";
    }

    if (!formData.category) {
      newErrors.category = "Categoria é obrigatória";
    }

    if (!formData.createdBy) {
      newErrors.createdBy = "ID do criador é obrigatório";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (mode === "view") return;

    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      // Ensure we have all required fields for a complete ApplicantEvent
      const eventToSave = {
        ...formData,
        eventId: formData.eventId || `temp-${Date.now()}`,
        createdAt: formData.createdAt || Date.now(),
      } as ApplicantEvent;

      await onSave(eventToSave, !event);
      onClose();
    } catch (error) {
      logger.error("Falha ao salvar evento", { messageContext: { formData }, error });
      setErrors((prev) => ({ ...prev, submit: "Falha ao salvar evento" }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const isReadOnly = mode === "view";
  // Campos que não devem ser editáveis no modo de edição
  const isNonEditableField = (fieldName: string) => {
    return (
      mode === "edit" &&
      (fieldName === "applicantPhoneNumber" || fieldName === "companyId" || fieldName === "createdBy")
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {mode === "create" ? "Criar Novo Evento" : mode === "edit" ? "Editar Evento" : "Visualizar Evento"}
      </DialogTitle>

      <DialogContent>
        <Box component="form" sx={{ mt: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Telefone do Requerente"
                name="applicantPhoneNumber"
                value={formData.applicantPhoneNumber || ""}
                onChange={handleChange}
                error={!!errors.applicantPhoneNumber}
                helperText={errors.applicantPhoneNumber}
                disabled={isReadOnly || isNonEditableField("applicantPhoneNumber")}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="ID da Empresa"
                name="companyId"
                value={formData.companyId || ""}
                onChange={handleChange}
                error={!!errors.companyId}
                helperText={errors.companyId}
                disabled={isReadOnly || isNonEditableField("companyId")}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nome do Evento"
                name="eventName"
                value={formData.eventName || ""}
                onChange={handleChange}
                error={!!errors.eventName}
                helperText={errors.eventName}
                disabled={isReadOnly}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.category} disabled={isReadOnly} required>
                <InputLabel>Categoria</InputLabel>
                <Select name="category" value={formData.category || ""} onChange={handleChange} label="Categoria">
                  {Object.values(EventCategory).map((category) => (
                    <MenuItem key={category} value={category}>
                      {category.replace("_", " ").replace(/\b\w/g, (l) => l.toUpperCase())}
                    </MenuItem>
                  ))}
                </Select>
                {errors.category && <FormHelperText>{errors.category}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Criado Por"
                name="createdBy"
                value={formData.createdBy || ""}
                onChange={handleChange}
                error={!!errors.createdBy}
                helperText={errors.createdBy}
                disabled={isReadOnly || isNonEditableField("createdBy")}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={isReadOnly} required>
                <InputLabel>Tipo de Criador</InputLabel>
                <Select
                  name="creatorType"
                  value={formData.creatorType || ""}
                  onChange={handleChange}
                  label="Tipo de Criador"
                >
                  {Object.values(CreatorType).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type === CreatorType.USER
                        ? "Usuário"
                        : type === CreatorType.SYSTEM
                          ? "Sistema"
                          : type === CreatorType.AI_AGENT
                            ? "IA"
                            : type === CreatorType.APPLICANT
                              ? "Requerente"
                              : type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Detalhes do Evento
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Detalhes (JSON)"
                name="details"
                value={formData.details ? JSON.stringify(formData.details, null, 2) : "{}"}
                onChange={(e) => {
                  try {
                    const parsedDetails = JSON.parse(e.target.value);
                    setFormData((prev) => ({ ...prev, details: parsedDetails }));

                    if (errors.details) {
                      setErrors((prev) => {
                        const newErrors = { ...prev };
                        delete newErrors.details;
                        return newErrors;
                      });
                    }
                  } catch (error) {
                    setErrors((prev) => ({ ...prev, details: "Formato JSON inválido" }));
                  }
                }}
                error={!!errors.details}
                helperText={errors.details || "Insira os detalhes do evento em formato JSON"}
                disabled={isReadOnly}
              />
            </Grid>

            {formData.eventId && (
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="ID do Evento" value={formData.eventId} disabled />
              </Grid>
            )}

            {formData.createdAt && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Criado Em"
                  value={new Date(formData.createdAt).toLocaleString("pt-BR")}
                  disabled
                />
              </Grid>
            )}

            {errors.submit && (
              <Grid item xs={12}>
                <Typography color="error">{errors.submit}</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>

        {mode === "view" && (
          <Button onClick={onEdit} color="primary">
            Editar
          </Button>
        )}

        {mode !== "view" && (
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSubmitting}>
            {isSubmitting ? "Salvando..." : "Salvar"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
