import React from "react";
// MUI components
import { Box, CircularProgress, Stack, Tooltip, Typography, SxProps, Theme } from "@mui/material";
// MUI icons
import CancelIcon from "@mui/icons-material/Close";
import ChatIcon from "@mui/icons-material/Chat";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import SaveIcon from "@mui/icons-material/Save";
// MUI data grid
import { GridActionsCellItem, GridRowId, GridRowModes, GridRowModesModel } from "@mui/x-data-grid";
// Types
import { CompanyMember } from "@/hooks/company/types";
import { ChatMode, ChatStatus } from "@/services/whatsappServer/types/chat";
import { ExtendedGridColDef } from "./columnTypes";
import { LeadStage } from "./types";
// Utils
import { Phone } from "@/core/Phone";
import { LongTextCell } from "./components/LongTextCell";
import {
  StatusCell,
  CreatedAtCell,
  LastMessageDateCell,
  LeadStageCell,
  ChatModeCell,
  AssignedUserIdsCell,
  AssignedUserEditCell,
  LeadStageEditCell,
} from "./components/ColumnCells";

// Format phone number for better readability
const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return "";

  const phone = Phone.restore(phoneNumber);
  if (phone.isSuccess) {
    return phone.getValue().toFormatSpaced();
  }

  // Fallback for other formats
  return phoneNumber;
};

// Definindo o estilo base como uma constante
const baseActionStyle: SxProps<Theme> = {
  cursor: "pointer !important",
  pointerEvents: "auto",
  padding: "4px 4px",
} as const;

const styles = {
  baseAction: baseActionStyle,
  successAction: {
    backgroundColor: "success.light",
    borderRadius: "4px",
    padding: "4px 8px",
    "&.MuiButtonBase-root": {
      opacity: 1,
      cursor: "default !important",
    },
  },
  successIcon: {
    color: "success.main",
    fontSize: 22,
    filter: "drop-shadow(0px 0px 3px rgba(76, 175, 80, 0.5))",
  },
  successText: {
    color: "success.main",
    fontWeight: "bold",
  },
  successContainer: {
    display: "flex",
    alignItems: "center",
    gap: 1,
    animation: "fadeIn 0.3s ease-in-out",
    "@keyframes fadeIn": {
      "0%": { opacity: 0, transform: "scale(0.8)" },
      "100%": { opacity: 1, transform: "scale(1)" },
    },
  },
  approvalButton: {
    width: 24,
    height: 24,
    borderRadius: "50%",
    backgroundColor: "warning.main",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFF",
    fontWeight: 600,
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "warning.dark",
    },
  },
  approvalContainer: {
    ...baseActionStyle,
    "&:hover": {
      "& .MuiBox-root": {
        backgroundColor: "warning.dark",
        transform: "scale(1.1)",
        transition: "transform 0.2s ease-in-out",
      },
    },
  },
} as const satisfies Record<string, SxProps<Theme>>;

export const getColumns = (
  rowModesModel: GridRowModesModel,
  companyUsers: CompanyMember[] | undefined,
  onEditClick: (id: GridRowId) => () => void,
  onSaveClick: (id: GridRowId) => () => void,
  onCancelClick: (id: GridRowId) => () => void,
  loadingRows: Set<GridRowId>,
  successRows: Set<GridRowId>,
  errorRows: Set<GridRowId>,
  onOpenChat: (phoneNumber: string) => void,
  columnWidths?: Record<string, number>,
  onOpenTextEditor?: (id: string, field: string, value: string) => void
): ExtendedGridColDef[] => [
  {
    field: "endClientNumber",
    headerName: "Número do Cliente",
    width: columnWidths?.endClientNumber,
    editable: false,
    hideable: true,
    renderCell: (params) => (
      <Tooltip
        title={
          <Box sx={{ p: 1 }}>
            <Typography variant="h6" sx={{ fontSize: "1.3rem" }}>
              {formatPhoneNumber(params.value)}
            </Typography>
          </Box>
        }
        arrow
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack sx={{ flexGrow: 1 }}>
            <Typography variant="body1" sx={{ fontSize: "1rem", fontWeight: 500 }}>
              {formatPhoneNumber(params.value)}
            </Typography>
          </Stack>
        </Stack>
      </Tooltip>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    width: columnWidths?.status,
    editable: true,
    type: "singleSelect",
    valueOptions: Object.values(ChatStatus),
    renderCell: (params) => <StatusCell value={params.value} />,
  },
  {
    field: "applicantName",
    headerName: "Nome",
    width: columnWidths?.applicantName,
    editable: true,
    renderCell: (params) => (
      <Tooltip
        title={
          <Box sx={{ p: 1 }}>
            <Typography variant="h6" sx={{ fontSize: "1.3rem" }}>
              {formatPhoneNumber(params.row.endClientNumber)}
            </Typography>
          </Box>
        }
        arrow
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack sx={{ flexGrow: 1 }}>
            <Typography variant="body1">{params.value ? params.value : "Sem nome cadastrado"}</Typography>
          </Stack>
        </Stack>
      </Tooltip>
    ),
    cellClassName: (params) => {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      return isInEditMode ? "editable-cell" : "";
    },
  },
  {
    field: "createdAt",
    headerName: "Início do atendimento",
    width: columnWidths?.createdAt,
    editable: false,
    renderCell: (params) => <CreatedAtCell value={params.value} />,
    cellClassName: (params) => {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      return isInEditMode ? "non-editable-cell" : "";
    },
  },
  {
    field: "lastMessageSenderType",
    headerName: "Tipo do remetente",
    width: columnWidths?.lastMessageSenderType,
    editable: false,
    hideable: true,
  },
  {
    field: "lastMessageText",
    headerName: "Conteúdo da última mensagem",
    width: columnWidths?.lastMessageText,
    editable: false,
    hideable: true,
  },
  {
    field: "lastMessageDate",
    headerName: "Última mensagem",
    width: columnWidths?.lastMessageDate,
    editable: false,
    renderCell: (params) => <LastMessageDateCell params={params} />,
    cellClassName: (params) => {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      return isInEditMode ? "non-editable-cell" : "";
    },
  },
  {
    field: "assignedUserNames",
    headerName: "Responsáveis (nomes)",
    width: columnWidths?.assignedUserNames,
    editable: false,
    hideable: true,
  },
  {
    field: "assignedUserIds",
    headerName: "Responsáveis",
    width: columnWidths?.assignedUserIds,
    editable: true,
    renderCell: (params) => <AssignedUserIdsCell userIds={params.value || []} companyUsers={companyUsers} />,
    renderEditCell: (params) => {
      return <AssignedUserEditCell params={params} companyUsers={companyUsers} />;
    },
    cellClassName: (params) => {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      return isInEditMode ? "editable-cell" : "";
    },
  },
  {
    field: "leadStage",
    headerName: "Etapa do Funil",
    width: columnWidths?.leadStage,
    editable: true,
    renderCell: (params) => <LeadStageCell value={params.value as LeadStage} />,
    renderEditCell: (params) => <LeadStageEditCell params={params} />,
    cellClassName: (params) => {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      return isInEditMode ? "editable-cell" : "";
    },
  },
  {
    field: "chatMode",
    headerName: "Modo de Operação",
    width: columnWidths?.chatMode,
    editable: true,
    type: "singleSelect",
    valueOptions: Object.values(ChatMode),
    renderCell: (params) => <ChatModeCell value={params.value as ChatMode} />,
  },
  {
    field: "legalMatterType",
    headerName: "Tipo de atendimento",
    width: columnWidths?.legalMatterType,
    editable: true,
    cellClassName: (params) => {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      return isInEditMode ? "editable-cell" : "";
    },
  },
  {
    field: "caseNotes",
    headerName: "Observações Gerais",
    width: columnWidths?.caseNotes || 250,
    editable: true,
    renderCell: (params) => {
      return (
        <LongTextCell
          content={params.value || ""}
          emptyText="Sem observações"
          fieldLabel="Observações Gerais"
          fieldName="caseNotes"
          id={params.id.toString()}
          onOpenTextEditor={onOpenTextEditor}
        />
      );
    },
    cellClassName: (params) => {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      return isInEditMode ? "editable-cell" : "";
    },
  },
  {
    field: "actionItems",
    headerName: "Próximos passos",
    width: columnWidths?.actionItems || 250,
    editable: true,
    renderCell: (params) => {
      return (
        <LongTextCell
          content={params.value || ""}
          emptyText="Sem próximos passos definidos"
          fieldLabel="Próximos passos"
          fieldName="actionItems"
          id={params.id.toString()}
          onOpenTextEditor={onOpenTextEditor}
        />
      );
    },
    cellClassName: (params) => {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      return isInEditMode ? "editable-cell" : "";
    },
  },
  {
    field: "actions",
    type: "actions",
    headerName: "Ações",
    width: columnWidths?.actions,
    cellClassName: "actions",
    align: "left",
    getActions: ({ id, row }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      const isLoading = loadingRows.has(id);
      const isSuccess = successRows.has(id);
      const isError = errorRows.has(id);

      if (isLoading) {
        return [
          <GridActionsCellItem
            key="loading"
            icon={<CircularProgress size={20} />}
            label="Salvando"
            disabled
            sx={styles.baseAction}
          />,
        ];
      }

      if (isSuccess) {
        return [
          <GridActionsCellItem
            key="success"
            icon={
              <Box sx={styles.successContainer}>
                <CheckCircleIcon sx={styles.successIcon} />
                <Typography variant="body2" sx={styles.successText}>
                  Salvo
                </Typography>
              </Box>
            }
            label="Salvo com sucesso"
            disabled
            sx={{ ...styles.baseAction, ...styles.successAction }}
          />,
        ];
      }

      if (isError) {
        return [
          <GridActionsCellItem
            key="error"
            icon={<ErrorIcon />}
            label="Erro ao salvar"
            disabled
            sx={styles.baseAction}
          />,
        ];
      }

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            key="save"
            icon={<SaveIcon />}
            label="Salvar"
            onClick={onSaveClick(id)}
            sx={styles.baseAction}
          />,
          <GridActionsCellItem
            key="cancel"
            icon={<CancelIcon />}
            label="Cancelar"
            onClick={onCancelClick(id)}
            sx={styles.baseAction}
          />,
        ];
      }

      return [
        <GridActionsCellItem
          key="edit"
          icon={<EditIcon />}
          label="Editar"
          onClick={onEditClick(id)}
          sx={styles.baseAction}
        />,
        <GridActionsCellItem
          key="chat"
          icon={<ChatIcon />}
          label="Abrir Chat"
          onClick={() => onOpenChat(row.endClientNumber)}
          sx={styles.baseAction}
        />,
        row.hasApprovalRequest && (
          <GridActionsCellItem
            key="approval"
            icon={<Box sx={styles.approvalContainer}>A</Box>}
            label="Aprovação Pendente"
            title="Existem solicitações de aprovação neste chat. Clique para visualizar."
            onClick={() => onOpenChat(row.endClientNumber)}
          />
        ),
      ].filter(Boolean);
    },
  },
];

export default getColumns;
