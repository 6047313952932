import { getCaseQueryKey } from "@/hooks/lexZap/useLexZapCase";
import { CaseService } from "@/services/case";
import { useQuery } from "@tanstack/react-query";

export const getCaseWhatsappFilesQueryKey = (caseId?: string) => [...getCaseQueryKey(caseId), "whatsappFiles"];

export const useCaseWhatsappFiles = ({ caseId }: { caseId?: string }) => {
  return useQuery({
    queryKey: getCaseWhatsappFilesQueryKey(caseId),
    queryFn: async () => {
      if (!caseId) {
        return [];
      }
      const { data } = await CaseService.getCaseWhatsappFiles(caseId);
      return data;
    },
    enabled: !!caseId,
    staleTime: Infinity,
  });
};
