import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LexZapService } from "@/services/lexZap";
import { Ticket, TicketStatus } from "../types";
import { getTicketsQueryKey } from "../useLexZapGetTickets";
export const useCloseTicket = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["closeTicket"],
    mutationFn: async ({
      ticketId,
      applicantPhoneNumber,
      reasonType,
      reasonDetails,
    }: {
      ticketId: string;
      applicantPhoneNumber: string;
      reasonType?: string;
      reasonDetails?: string;
    }) => await LexZapService.closeTicket({ ticketId, applicantPhoneNumber, reasonType, reasonDetails }),
    onMutate: async ({ ticketId, reasonType, reasonDetails }) => {
      await queryClient.cancelQueries({ queryKey: getTicketsQueryKey() });

      const previousTickets = queryClient.getQueryData(getTicketsQueryKey());

      queryClient.setQueryData(getTicketsQueryKey(), (old: Ticket[] | undefined) => {
        if (!old) return [];
        return old.map((ticket) =>
          ticket.id === ticketId
            ? {
                ...ticket,
                status: TicketStatus.CLOSED,
                closedAt: new Date().getTime(),
                closedReasonType: reasonType,
                closedReasonDetails: reasonDetails,
              }
            : ticket
        );
      });

      return { previousTickets };
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(getTicketsQueryKey(), context?.previousTickets);
    },
  });
};
