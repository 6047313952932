import { authenticatedCopilotServer } from "@/core/api";
import { LexZapDlqEvent, LexZapMessagePage } from "./types";
import { Case, LexZapChat, Ticket } from "@/hooks/lexZap/types";
import { LexZapChannel } from "./types";
import { LexZapUser } from "@/hooks/lexZap/types";
import { ActivityArea, Department } from "@/hooks/company/types";
import { UpdateLexZapChannelParams, UpdateLexZapCompanyParams } from "@/hooks/useApi/types";

export const LexZapService = {
  getDlqEvents: async () => {
    const response = await authenticatedCopilotServer.get<LexZapDlqEvent[]>(`/dlqEvents`);
    return response.data;
  },
  reprocessDlqEvents: async ({ ids }: { ids: string[] }) => {
    await authenticatedCopilotServer.post(`/dlqEvents/reprocess`, { ids });
  },
  deleteDlqEvent: async ({ id }: { id: string }) => {
    await authenticatedCopilotServer.delete(`/dlqEvents/${id}`);
  },
  getMe: async () => {
    const response = await authenticatedCopilotServer.get<LexZapUser>(`whatsapp-assistant/users/me`);
    return response.data;
  },
  updateMe: async (data: { notificationNumber: string; department: Department; activityAreas?: ActivityArea[] }) => {
    await authenticatedCopilotServer.post<LexZapUser>(`whatsapp-assistant/users/me`, data);
  },
  getLexZapChats: async () => {
    const response = await authenticatedCopilotServer.get<LexZapChat[]>(`/whatsapp-assistant/chats`);
    return response.data;
  },
  deactivateAi: async ({ applicantPhoneNumber }: { applicantPhoneNumber: string }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/chats/deactivateAi`, {
      applicantPhoneNumber,
    });
  },
  activateAi: async ({ applicantPhoneNumber }: { applicantPhoneNumber: string }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/chats/activateAi`, {
      applicantPhoneNumber,
    });
  },
  markChatAsAnswered: async ({ applicantPhoneNumber }: { applicantPhoneNumber: string }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/chats/markAsAnswered`, {
      applicantPhoneNumber,
    });
  },
  markChatAsUnanswered: async ({ applicantPhoneNumber }: { applicantPhoneNumber: string }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/chats/markAsUnanswered`, {
      applicantPhoneNumber,
    });
  },
  getTickets: async (params?: { status?: "ACTIVE" | "CLOSED" }) => {
    const response = await authenticatedCopilotServer.get<Ticket[]>(`/whatsapp-assistant/tickets`, {
      params,
    });
    return response.data;
  },
  updateTicket: async ({
    ticketId,
    applicantPhoneNumber,
    updateFields,
  }: {
    ticketId: string;
    applicantPhoneNumber: string;
    updateFields: Partial<Ticket>;
  }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/tickets/${ticketId}`, {
      applicantPhoneNumber,
      updateFields,
    });
  },
  closeTicket: async ({
    ticketId,
    applicantPhoneNumber,
    reasonType,
    reasonDetails,
  }: {
    ticketId: string;
    applicantPhoneNumber: string;
    reasonType?: string;
    reasonDetails?: string;
  }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/tickets/${ticketId}/close`, {
      applicantPhoneNumber,
      reasonType,
      reasonDetails,
    });
  },
  archiveChat: async ({ applicantPhoneNumber }: { applicantPhoneNumber: string }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/chats/archive`, {
      applicantPhoneNumber,
    });
  },
  unarchiveChat: async ({ applicantPhoneNumber }: { applicantPhoneNumber: string }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/chats/unarchive`, {
      applicantPhoneNumber,
    });
  },
  signFileUrl: async ({ fileId }: { fileId: string }) => {
    const response = await authenticatedCopilotServer.get<{ signedUrl: string }>(
      `/whatsapp-assistant/files/${fileId}/signUrl`
    );
    return response.data;
  },
};

export const AdminLexZapService = {
  getLexZapCases: async ({ companyId }: { companyId: string }) => {
    const response = await authenticatedCopilotServer.get<Case[]>(`/admin/cases`, {
      params: {
        companyId,
      },
    });
    return response.data;
  },
  getLexZapMessages: async ({
    companyId,
    applicantPhoneNumber,
    limit,
    pageToken,
  }: {
    companyId: string;
    applicantPhoneNumber: string;
    limit?: number;
    pageToken?: string;
  }) => {
    const response = await authenticatedCopilotServer.get<LexZapMessagePage>(`/admin/cases/chats/messages`, {
      params: { companyId, applicantPhoneNumber, limit, pageToken },
    });
    return response.data;
  },
  getLexZapChats: async ({ companyId }: { companyId: string }) => {
    const response = await authenticatedCopilotServer.get<LexZapChat[]>(`/admin/whatsapp-assistant/chats`, {
      params: { companyId },
    });
    return response.data;
  },
  updateLexZapCompany: async ({ companyId, email, updates }: UpdateLexZapCompanyParams) => {
    await authenticatedCopilotServer.put(`whatsapp-assistant/companies/${companyId}`, {
      email,
      ...updates,
    });
  },
  getLexZapChannels: async () => {
    const response = await authenticatedCopilotServer.get<LexZapChannel[]>("whatsapp-assistant/channels");
    return response.data;
  },
  createLexZapChannel: async ({ channelId }: { channelId: string }) => {
    await authenticatedCopilotServer.post("whatsapp-assistant/channels", {
      channelId,
    });
  },
  updateLexZapChannel: async ({ channelId, updates }: UpdateLexZapChannelParams) => {
    await authenticatedCopilotServer.patch(`whatsapp-assistant/channels/${channelId}`, {
      ...updates,
    });
  },
  deleteLexZapChannel: async ({ channelId }: { channelId: string }) => {
    await authenticatedCopilotServer.delete(`whatsapp-assistant/channels/${channelId}`);
  },
};
