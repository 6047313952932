import React, { useState } from "react";
import { Button, TextField, Autocomplete, Typography, Box, Avatar, CircularProgress } from "@mui/material";
import { useLexZapCases } from "@/hooks/lexZap/useLexZapCases";
import { Case } from "@/hooks/lexZap/types";
import { InsertLink as InsertLinkIcon } from "@mui/icons-material";
import LauraImage from "@/assets/images/Laura.jpg";
import { useAttachThreadToCase } from "@/hooks/threads/useAttachThreadToCase";
import { useThread } from "@/hooks/threads/useThread";
import { getCasePath } from "@/routes/routePaths";
import { useNavigate } from "react-router-dom";
import { logger } from "@/core/logger";
import { WebToast } from "@/components/core/Toast";
import { useDebounce } from "@/hooks/useDebounce";

type CreateAttachCaseProps = {
  onCreateNewCase: (openCreateCaseModal: boolean) => void;
  threadId: string;
  onClose: () => void;
  isCaseCreated: boolean;
  isModal?: boolean;
};

export const CreateOrAttachThread = ({
  onCreateNewCase,
  threadId,
  onClose,
  isCaseCreated,
  isModal,
}: CreateAttachCaseProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { cases, isLoading } = useLexZapCases({
    pageSize: 100,
    status: ["ACTIVE"],
    filter: debouncedSearchTerm,
  });
  const { mutateAsync: attachThreadToCase, isPending: isAttachingThreadToCase } = useAttachThreadToCase();
  const [selectedCase, setSelectedCase] = useState<Case | null>(null);
  const { data: thread } = useThread({ threadId });
  const navigate = useNavigate();

  const handleAttachThreadOnCase = async () => {
    try {
      if (selectedCase) {
        await attachThreadToCase({ threadId, caseId: selectedCase.id });
      }
    } catch (error) {
      logger.error("Erro ao vincular thread a um caso", { error });
      WebToast.error("Erro ao vincular caso");
      onClose();
    }
  };

  const gotToCase = () => {
    if (!thread?.caseId) return;
    navigate(getCasePath({ caseId: thread.caseId }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: isModal ? 0 : 8,
        maxWidth: "612px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: isModal ? 2 : 4,
          borderRadius: 1,
          border: isModal ? "none" : "1px solid #E0E0E0",
        }}
      >
        {thread?.caseId || isCaseCreated ? (
          <SuccessAttachedCase gotToCase={gotToCase} continueEditing={onClose} />
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Avatar src={LauraImage} sx={{ width: "50px", height: "50px" }} />
              <Typography variant="h5">Que tal criar um caso para organizar sua tarefa?</Typography>
            </Box>
            <Typography variant="body1">
              Com a nova estrutura de casos da plataforma Lexter, as tarefas realizadas ficam todas organizadas em um só
              lugar e todo o contexto de suas peças é utilizado para uma melhor performance das tarefas futuras.
            </Typography>

            <>
              <Autocomplete
                id="select-case-modal"
                value={selectedCase}
                onChange={(_, newValue) => setSelectedCase(newValue)}
                options={cases || []}
                getOptionLabel={(option) => `${option.name}`}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
                noOptionsText="Nenhum caso ativo encontrado"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                    label="Selecionar caso existente"
                    placeholder="Selecione o caso desejado"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                fullWidth
                onInputChange={(_, newInputValue) => {
                  setSearchTerm(newInputValue);
                }}
              />
              <Button
                variant="outlined"
                disabled={!selectedCase || isAttachingThreadToCase}
                startIcon={<InsertLinkIcon />}
                onClick={handleAttachThreadOnCase}
              >
                Vincular caso
              </Button>
            </>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 2, mt: 2 }}>
                <Button onClick={onClose} variant="text" disabled={isAttachingThreadToCase}>
                  Não quero criar um caso
                </Button>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
                <Button onClick={() => onCreateNewCase(true)} variant="contained" disabled={isAttachingThreadToCase}>
                  Criar novo caso
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

const SuccessAttachedCase = ({
  gotToCase,
  continueEditing,
}: {
  gotToCase: () => void;
  continueEditing: () => void;
}) => {
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Avatar src={LauraImage} sx={{ width: "50px", height: "50px" }} />
        <Typography variant="h5">Sua peça faz parte de um caso</Typography>
      </Box>
      <Typography variant="body1">
        Com a nova estrutura de casos da plataforma Lexter, sua peça fica organizada dentro de um caso específico onde
        você poderá encontrar todos os materiais e informações dele.
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 2, mt: 2 }}>
          <Button onClick={continueEditing} variant="text">
            Continuar editando
          </Button>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
          <Button onClick={gotToCase} variant="contained">
            Acessar caso
          </Button>
        </Box>
      </Box>
    </>
  );
};
