import React, { useState } from "react";
import { Box, Typography, IconButton, Collapse, Paper } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface JsonViewerProps {
  data: unknown;
  label?: string;
}

export const JsonViewer: React.FC<JsonViewerProps> = ({ data, label }) => {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const renderJsonValue = (value: unknown): string => {
    if (typeof value === "string") return `"${value}"`;
    return JSON.stringify(value);
  };

  const renderJsonTree = (obj: unknown, level = 0) => {
    if (typeof obj !== "object" || obj === null) {
      return <span style={{ color: typeof obj === "string" ? "#a31515" : "#098658" }}>{renderJsonValue(obj)}</span>;
    }

    return Object.entries(obj as Record<string, unknown>).map(([key, value]) => {
      const isObject = typeof value === "object" && value !== null;
      const nodeId = `${level}-${key}`;
      const isExpanded = expanded[nodeId] ?? true;

      return (
        <Box key={key} sx={{ ml: level * 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
            {isObject && (
              <IconButton
                size="small"
                onClick={() => setExpanded((prev) => ({ ...prev, [nodeId]: !isExpanded }))}
                sx={{
                  transform: isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
                  transition: "transform 0.2s",
                }}
              >
                <ExpandMoreIcon fontSize="small" />
              </IconButton>
            )}
            <Typography
              component="span"
              sx={{
                color: "#0451a5",
                fontFamily: "monospace",
                fontSize: "0.875rem",
              }}
            >
              &quot;{key}&quot;:
            </Typography>
            {!isObject && renderJsonTree(value, level + 1)}
          </Box>
          {isObject && (
            <Collapse in={isExpanded}>
              <Box sx={{ my: 0.5 }}>{renderJsonTree(value, level + 1)}</Box>
            </Collapse>
          )}
        </Box>
      );
    });
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 2,
        bgcolor: "#f8f9fa",
        "&:hover": {
          bgcolor: "#f3f4f6",
        },
      }}
    >
      {label && (
        <Typography variant="subtitle2" sx={{ color: "#666", fontWeight: 500 }} gutterBottom>
          {label}
        </Typography>
      )}
      {renderJsonTree(data)}
    </Paper>
  );
};
