import React, { ReactNode } from "react";
import { Box, ButtonBase, Stack, Typography, styled, Skeleton } from "@mui/material";

interface ChipProps {
  title: string;
  children: ReactNode;
  onClick?: () => void;
  fullWidth?: boolean;
  color?: string;
  isLoading?: boolean;
}

export const Chip = ({ title, children, onClick, fullWidth, color, isLoading = false }: ChipProps) => {
  if (isLoading) {
    return <LoadingChip fullWidth={fullWidth} />;
  }

  let Container: typeof ChipContainer | typeof ChipButtonBaseContainer = ChipContainer;

  if (onClick) {
    Container = ChipButtonBaseContainer;
  }

  return (
    <Container onClick={onClick} fullWidth={fullWidth}>
      <Typography
        variant="subtitle1"
        sx={{
          color: color,
        }}
      >
        {title}
      </Typography>
      {children}
    </Container>
  );
};

const ChipContainer = styled(Box)<{ fullWidth?: boolean }>(({ theme, fullWidth }) => ({
  backgroundColor: theme.palette.common.dorian,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: theme.spacing(4),
  padding: theme.spacing(2),
  minWidth: "250px",
  flex: fullWidth ? 1 : "none",
  borderRadius: theme.shape.borderRadius,
}));

const ChipButtonBaseContainer = styled(ButtonBase)<{ fullWidth?: boolean }>(({ theme, fullWidth }) => ({
  backgroundColor: theme.palette.common.dorian,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: theme.spacing(4),
  padding: theme.spacing(2),
  minWidth: "250px",
  flex: fullWidth ? 1 : "none",
  borderRadius: theme.shape.borderRadius,
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));

export const ChipInRow = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

export const ChipRow = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  width: "100%",
  flexWrap: "wrap",
}));

export const ChipContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1),
  width: "100%",
}));

interface LoadingChipProps {
  fullWidth?: boolean;
}

const LoadingChip: React.FC<LoadingChipProps> = ({ fullWidth }) => (
  <ChipContainer fullWidth={fullWidth}>
    <Skeleton
      variant="text"
      width="60%"
      height={24}
      sx={{
        borderRadius: 1,
        transform: "none",
      }}
    />
    <Skeleton
      variant="rounded"
      width="100%"
      height={36}
      sx={{
        borderRadius: 1,
        transform: "none",
      }}
    />
  </ChipContainer>
);
