import React from "react";
import { Box, Chip, CircularProgress, Stack, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { EventCategory, CreatorType } from "@/services/applicantEvents/types";

export const getColumns = (
  rowModesModel: GridRowModesModel,
  onEditClick: (id: GridRowId) => () => void,
  onSaveClick: (id: GridRowId) => () => void,
  onCancelClick: (id: GridRowId) => () => void,
  onDeleteClick: (id: GridRowId) => () => void,
  onViewClick: (id: GridRowId) => void,
  loadingRows: Set<GridRowId>,
  successRows: Set<GridRowId>,
  errorRows: Set<GridRowId>
) => [
  {
    field: "eventId",
    headerName: "ID",
    width: 150,
    editable: false,
  },
  {
    field: "applicantPhoneNumber",
    headerName: "Telefone do Requerente",
    width: 180,
    editable: false,
    renderCell: (params: GridRenderCellParams) => <Typography variant="body2">{params.value}</Typography>,
  },
  {
    field: "companyId",
    headerName: "ID da Empresa",
    width: 150,
    editable: false,
  },
  {
    field: "category",
    headerName: "Categoria",
    width: 150,
    editable: true,
    type: "singleSelect",
    valueOptions: Object.values(EventCategory),
    renderCell: (params: GridRenderCellParams) => {
      let chipColor: "primary" | "success" | "warning" | "info" | "error" | "secondary" | "default" = "default";
      switch (params.value) {
        case EventCategory.STAGE_CHANGE:
          chipColor = "primary";
          break;
        case EventCategory.DOCUMENT:
          chipColor = "success";
          break;
        case EventCategory.CONTRACT:
          chipColor = "warning";
          break;
        case EventCategory.PAYMENT:
          chipColor = "info";
          break;
        case EventCategory.DEADLINE:
          chipColor = "error";
          break;
        case EventCategory.MEETING:
          chipColor = "secondary";
          break;
        default:
          chipColor = "default";
      }
      return <Chip label={params.value} color={chipColor} size="small" />;
    },
  },
  {
    field: "eventName",
    headerName: "Nome do Evento",
    width: 200,
    editable: true,
  },
  {
    field: "createdAt",
    headerName: "Data de Criação",
    width: 180,
    editable: false,
    renderCell: (params: GridRenderCellParams) => {
      const date = new Date(params.value);
      return (
        <Stack>
          <Typography variant="body2">
            {date.toLocaleDateString("pt-BR", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {date.toLocaleTimeString("pt-BR", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Typography>
        </Stack>
      );
    },
  },
  {
    field: "creatorType",
    headerName: "Tipo de Criador",
    width: 150,
    editable: true,
    type: "singleSelect",
    valueOptions: Object.values(CreatorType),
    renderCell: (params: GridRenderCellParams) => {
      let label: string;
      let chipColor: "primary" | "success" | "warning" | "info" | "error" | "secondary" | "default" = "default";

      switch (params.value) {
        case CreatorType.USER:
          label = "Usuário";
          chipColor = "primary";
          break;
        case CreatorType.SYSTEM:
          label = "Sistema";
          chipColor = "info";
          break;
        case CreatorType.AI_AGENT:
          label = "IA";
          chipColor = "success";
          break;
        case CreatorType.APPLICANT:
          label = "Requerente";
          chipColor = "warning";
          break;
        default:
          label = params.value;
          chipColor = "default";
      }

      return <Chip label={label} color={chipColor} size="small" />;
    },
  },
  {
    field: "createdBy",
    headerName: "Criado Por",
    width: 150,
    editable: false,
  },
  {
    field: "actions",
    type: "actions",
    headerName: "Ações",
    width: 150,
    cellClassName: "actions",
    getActions: ({ id }: { id: GridRowId }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      const isLoading = loadingRows.has(id);
      const isSuccess = successRows.has(id);
      const isError = errorRows.has(id);

      const baseActionStyle = {
        cursor: "pointer !important",
        pointerEvents: "auto",
        padding: "4px 4px",
      };

      if (isLoading) {
        return [
          <GridActionsCellItem
            key="loading"
            icon={<CircularProgress size={20} />}
            label="Salvando"
            disabled
            sx={{
              ...baseActionStyle,
              color: "primary.main",
            }}
          />,
        ];
      }

      if (isSuccess) {
        return [
          <GridActionsCellItem
            key="success"
            icon={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  animation: "fadeIn 0.3s ease-in-out",
                  "@keyframes fadeIn": {
                    "0%": { opacity: 0, transform: "scale(0.8)" },
                    "100%": { opacity: 1, transform: "scale(1)" },
                  },
                }}
              >
                <CheckCircleIcon
                  sx={{
                    color: "success.main",
                    fontSize: 22,
                    filter: "drop-shadow(0px 0px 3px rgba(76, 175, 80, 0.5))",
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: "success.main",
                    fontWeight: "bold",
                  }}
                >
                  Salvo
                </Typography>
              </Box>
            }
            label="Salvo com sucesso"
            disabled
            sx={{
              ...baseActionStyle,
              backgroundColor: "success.light",
              borderRadius: "4px",
              padding: "4px 8px",
              "&.MuiButtonBase-root": {
                opacity: 1,
                cursor: "default !important",
              },
            }}
          />,
        ];
      }

      if (isError) {
        return [
          <GridActionsCellItem
            key="error"
            icon={<ErrorIcon />}
            label="Erro ao salvar"
            disabled
            sx={{
              ...baseActionStyle,
              color: "error.main",
              cursor: "default !important",
            }}
          />,
        ];
      }

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            key="save"
            icon={<SaveIcon />}
            label="Salvar"
            onClick={onSaveClick(id)}
            sx={{
              ...baseActionStyle,
              color: "success.main",
              "&:hover": {
                transform: "scale(1.1)",
                transition: "transform 0.2s ease-in-out",
                backgroundColor: "success.light",
              },
            }}
          />,
          <GridActionsCellItem
            key="cancel"
            icon={<CancelIcon />}
            label="Cancelar"
            onClick={onCancelClick(id)}
            sx={{
              ...baseActionStyle,
              color: "text.secondary",
              "&:hover": {
                transform: "scale(1.1)",
                transition: "transform 0.2s ease-in-out",
                backgroundColor: "error.light",
                color: "common.white",
              },
            }}
          />,
        ];
      }

      return [
        <GridActionsCellItem
          key="view"
          icon={<VisibilityIcon />}
          label="Visualizar"
          onClick={() => onViewClick(id)}
          title="Visualizar detalhes do evento"
          sx={{
            ...baseActionStyle,
            "&:hover": {
              transform: "scale(1.1)",
              transition: "transform 0.2s ease-in-out",
              backgroundColor: "info.light",
              color: "common.white",
            },
          }}
        />,
        <GridActionsCellItem
          key="edit"
          icon={<EditIcon />}
          label="Editar"
          onClick={onEditClick(id)}
          title="Editar evento"
          sx={{
            ...baseActionStyle,
            "&:hover": {
              transform: "scale(1.1)",
              transition: "transform 0.2s ease-in-out",
              backgroundColor: "primary.light",
              color: "common.white",
            },
          }}
        />,
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon />}
          label="Excluir"
          onClick={onDeleteClick(id)}
          title="Excluir evento"
          sx={{
            ...baseActionStyle,
            "&:hover": {
              transform: "scale(1.1)",
              transition: "transform 0.2s ease-in-out",
              backgroundColor: "error.light",
              color: "common.white",
            },
          }}
        />,
      ];
    },
  },
];
