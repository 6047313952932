import { NoteService } from "@/services/note";
import { useAuthStore } from "@/stores/useAuthStore";
import { dateTimeFromString } from "@/utils/dates/dateTimeFromString";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

export const getNotesQueryKey = ({ applicantId, caseId, ticketId }: UseNotesParams) => [
  "notes",
  applicantId,
  caseId,
  ticketId,
];

interface UseNotesParams {
  applicantId?: string | null;
  caseId?: string | null;
  ticketId?: string | null;
}

export const useNotes = ({ applicantId, caseId, ticketId }: UseNotesParams) => {
  const { isLoggedIn } = useAuthStore();

  const query = useQuery({
    queryKey: getNotesQueryKey({ applicantId, caseId, ticketId }),
    queryFn: async () => {
      return await NoteService.get({ applicantId, caseId, ticketId, filter: "any" });
    },
    enabled: isLoggedIn && (!!applicantId || !!caseId || !!ticketId),
    staleTime: 0,
  });

  const sortedData = useMemo(() => {
    return query.data?.sort(
      (a, b) => dateTimeFromString(b.createdAt).toMillis() - dateTimeFromString(a.createdAt).toMillis()
    );
  }, [query.data]);

  return {
    ...query,
    data: sortedData,
  };
};
