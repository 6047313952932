import React from "react";
import * as MUI from "@mui/material";
import * as MUIIcons from "@mui/icons-material";
import * as types from "./types";

export const ResponsibleFilter: React.FC<types.ResponsibleFilterProps> = ({
  chatFilters,
  setChatFilters,
  companyTeam,
}) => {
  // Sort companyTeam alphabetically by name
  const sortedCompanyTeam = [...companyTeam].sort((a, b) =>
    a.name.localeCompare(b.name, "pt-BR", { sensitivity: "base" })
  );

  // Handle adding users to responsible list
  const handleAddResponsible = (selectedOptions: typeof chatFilters.responsibles) => {
    setChatFilters((prev) => ({
      ...prev,
      responsibles: selectedOptions,
    }));
  };

  // Handle removing a user from responsible list
  const handleRemoveResponsible = (userId: string) => {
    setChatFilters((prev) => ({
      ...prev,
      responsibles: prev.responsibles.filter((resp) => resp.userId !== userId),
    }));
  };

  return (
    <MUI.Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, flex: 1 }}>
      <MUI.Typography variant="body2" color="text.secondary" sx={{ minWidth: "90px" }}>
        Responsável:
      </MUI.Typography>

      <MUI.Box sx={{ flexGrow: 1 }}>
        {/* Display selected users as chips */}
        {chatFilters.responsibles?.length > 0 && (
          <MUI.Box
            sx={{
              display: "flex",
              gap: 1,
              flexWrap: "wrap",
              mb: 1,
            }}
          >
            {chatFilters.responsibles.map((responsible) => (
              <MUI.Chip
                key={responsible.userId}
                label={responsible.name}
                onDelete={() => handleRemoveResponsible(responsible.userId)}
                sx={{
                  backgroundColor: "secondary.main",
                  color: "text.primary",
                }}
                deleteIcon={<MUIIcons.CloseOutlined />}
                size="small"
              />
            ))}
          </MUI.Box>
        )}

        {/* User selection autocomplete */}
        <MUI.Autocomplete
          multiple
          options={sortedCompanyTeam.filter(
            (member) => !chatFilters.excludeResponsibles?.some((item) => item.userId === member.userId)
          )}
          value={chatFilters.responsibles}
          noOptionsText="Nenhuma opção encontrada"
          disablePortal
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.userId === value.userId}
          onChange={(_, selectedOptions) => handleAddResponsible(selectedOptions)}
          renderInput={(params) => <MUI.TextField {...params} placeholder="Selecionar responsável" size="small" />}
          renderTags={() => null}
          sx={{ flexGrow: 1, width: "100%" }}
        />
      </MUI.Box>
    </MUI.Box>
  );
};
