import { CaseMessage, CaseMessageType, SnippetTypes } from "@/hooks/lexZap/types";

export const createTicketInactiveSnippet = ({
  applicantPhoneNumber,
  ticketId,
  lastMessageDate,
}: {
  applicantPhoneNumber: string;
  ticketId: string;
  lastMessageDate?: number;
}): CaseMessage => {
  return {
    id: "999",
    applicantPhoneNumber,
    body: "",
    timestamp: lastMessageDate ? lastMessageDate + 1 : new Date().getTime(),
    isForwarded: false,
    to: "",
    from: "",
    chatId: "",
    fromLawyer: false,
    fromAssistant: false,
    type: CaseMessageType.SNIPPET,
    snippetInfo: {
      type: SnippetTypes.TICKET_INACTIVE,
      ticketId,
      applicantPhoneNumber,
    },
  };
};
