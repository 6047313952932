import { PageLayout } from "@/components/PageLayout";
import { HeaderVariant } from "@/components/PageLayout/Header/constants";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "@/contexts/AuthContext";
import { useUsageHistory } from "@/hooks/useUsageHistory";
import { Loading } from "@/components/Loading";
import { generatePath } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { HistoryItem } from "./components/HistoryItem";

const MAX_SCROLL_POSITION = 1;

export const UsageHistoryV2 = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const {
    data: usageHistory,
    isLoading: isLoadingUsageHistory,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useUsageHistory({
    companyId: user?.companyId ?? "",
    orderBy: "actionDate",
    orderDirection: "DESC",
    pageSize: 10,
  });

  const isLoading = isLoadingUsageHistory || isFetchingNextPage;
  const hasFullList = !hasNextPage;
  const history = usageHistory?.pages.flatMap((page) => page.data) ?? [];
  const openThread = (threadId: string) => {
    navigate(generatePath(ROUTE_PATHS.THREAD, { threadId }));
  };

  const openCase = (caseId: string) => {
    navigate(generatePath(ROUTE_PATHS.CASE_DETAILS, { caseId }));
  };

  return (
    <PageLayout
      headerProps={{
        variant: HeaderVariant.GO_BACK,
      }}
      contentSx={{
        width: "100%",
      }}
      sx={{
        ".page-layout-container": {
          p: 0,
        },
      }}
      variant="secondary"
      onScroll={(e) => {
        const target = e.target as HTMLDivElement;
        const scrollPosition = target.scrollHeight - target.offsetHeight - target.scrollTop;
        if (scrollPosition < MAX_SCROLL_POSITION) {
          void fetchNextPage();
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          p: 6,
        }}
      >
        <Typography variant="h5" sx={{ mb: 4 }}>
          Histórico de uso
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "45%", mx: "auto" }}>
          <Loading isLoading={isLoadingUsageHistory}>
            {history.length > 0 ? (
              history.map((item) => (
                <HistoryItem
                  key={`${item.id}_${item.entityId}`}
                  item={item}
                  currentUser={user}
                  onOpenThread={(threadId) => openThread(threadId)}
                  onOpenCase={(caseId) => openCase(caseId)}
                />
              ))
            ) : (
              <Box sx={{ minHeight: 200, p: 2.5 }} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="multiLineBody" sx={{ textAlign: "center" }}>
                  Você ainda não possui sessões salvas. Realize o primeiro uso do Assistente Lexter para criar sua
                  primeira sessão.
                </Typography>
              </Box>
            )}
          </Loading>

          {isFetchingNextPage && !hasFullList && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "48px !important",
                mt: 2,
              }}
            >
              <CircularProgress size={24} />
            </Box>
          )}

          {!isLoading && hasFullList && history.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "48px !important",
                mt: 2,
              }}
            >
              <Box display="flex" alignItems="center" gap={1} flexDirection="column">
                <Typography variant="body2">
                  Para acessar as peças e ações que antecedem o dia 13/02, clique no botão abaixo.
                </Typography>
                <Button color="primary" onClick={() => navigate(ROUTE_PATHS.OLD_USAGE_HISTORY)}>
                  Acessar histórico antigo
                </Button>
              </Box>
              {/* <Typography variant="body2">Fim da lista</Typography> */}
            </Box>
          )}
        </Box>
      </Box>
    </PageLayout>
  );
};
