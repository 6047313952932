import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, ToggleButtonGroup, ToggleButton } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import ChatIcon from "@mui/icons-material/Chat";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// Components
import { PageLayout } from "@/components/PageLayout";
import { Button } from "@/components/Button";
import { ApplicantsList } from "@/components/Applicants/ApplicantsList";
import { ChatView } from "./components/ChatView";
import { TableView } from "./components/TableView";
import { CompanyInfo } from "./components/CompanyInfo";

// Hooks
import { useImpersonation } from "@/hooks/useImpersonation";
import { useLexZapChats } from "@/hooks/lexZap/useLexZapChats";
import { useLexZapActiveChat } from "@/hooks/lexZap/useLexZapActiveChat";

// Types
import { LexZapChat } from "@/hooks/lexZap/types";

export const WhatsappAssistantInternalPage = () => {
  const [searchParams] = useSearchParams();
  const phoneNumber = searchParams.get("phoneNumber");

  // Se tiver phoneNumber na URL, inicie com o modo chat
  const [viewMode, setViewMode] = useState<"chat" | "table">(phoneNumber ? "chat" : "table");

  const { data: chatsData, isLoading: isLoadingChats, isFetching: isFetchingChats } = useLexZapChats();
  const { impersonatedUser } = useImpersonation();
  const [isCompanyInfoExpanded, setIsCompanyInfoExpanded] = useState(false);
  const [isApplicantListOpen, setIsApplicantListOpen] = useState(false);
  const { setActiveChat, activeChat } = useLexZapActiveChat();

  const sortedChats = chatsData?.sort((a, b) => (b.lastMessageDate || 0) - (a.lastMessageDate || 0)) || [];

  // Efeito para definir o chat ativo quando a página carrega com phoneNumber
  useEffect(() => {
    if (phoneNumber && !isLoadingChats && chatsData && !activeChat) {
      const chatToActivate = chatsData.find((chat) => chat.endClientNumber === phoneNumber);
      if (chatToActivate) {
        setActiveChat(chatToActivate);
      }
    }
  }, [phoneNumber, chatsData, isLoadingChats, setActiveChat, activeChat]);

  const renderCompanyInfo = () => {
    if (!impersonatedUser) return undefined;

    return (
      <CompanyInfo
        companyName={impersonatedUser.companyName || "Outra empresa"}
        companyId={impersonatedUser.companyId}
        companyEmail={impersonatedUser.companyEmail}
        whatsappNumber={impersonatedUser.whatsappNumber}
        isExpanded={isCompanyInfoExpanded}
        onToggle={() => setIsCompanyInfoExpanded((prev) => !prev)}
        isFetching={isFetchingChats}
      />
    );
  };

  const handleViewChange = (_: React.MouseEvent<HTMLElement>, newView: "chat" | "table") => {
    if (newView !== null) {
      setViewMode(newView);
    }
  };

  const openApplicants = () => {
    setIsApplicantListOpen(true);
  };

  const closeApplicants = () => {
    setIsApplicantListOpen(false);
  };

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
        fullWidth: true,
      }}
      contentSx={{
        height: "100%",
        overflow: "hidden",
        display: "grid",
        gridTemplateRows: "min-content minmax(0, 1fr)",
      }}
      headerProps={{
        extraContent: renderCompanyInfo(),
      }}
      variant="secondary"
    >
      <>
        <Box
          sx={{
            backgroundColor: "background.paper",
            borderBottom: "1px solid",
            borderColor: "divider",
            px: 3,
            py: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
              <Box component="h5" sx={{ typography: "h5", fontWeight: 600, m: 0 }}>
                Atendimento
              </Box>
              <Box component="span" sx={{ typography: "body2", color: "text.secondary" }}>
                Gerencie seus atendimentos
              </Box>
            </Box>

            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleViewChange}
                aria-label="view mode"
                size="small"
                sx={{
                  "& .MuiToggleButton-root": {
                    textTransform: "none",
                    px: 3,
                  },
                }}
              >
                <ToggleButton value="chat" aria-label="chat view">
                  <ChatIcon sx={{ mr: 1 }} />
                  Assistente
                </ToggleButton>
                <ToggleButton value="table" aria-label="table view">
                  <ViewListIcon sx={{ mr: 1 }} />
                  Tabela
                </ToggleButton>
              </ToggleButtonGroup>

              <Button
                variant="contained"
                color="primary"
                onClick={openApplicants}
                endIcon={ChevronLeftIcon}
                sx={{
                  textTransform: "none",
                  fontWeight: 500,
                  px: 2,
                  py: 1,
                  borderRadius: 2,
                  "&:hover": {
                    "& .MuiSvgIcon-root:last-child": {
                      transform: "translateX(-4px)",
                      transition: "transform 0.2s ease-in-out",
                    },
                  },
                }}
              >
                Nova conversa
              </Button>
            </Box>
          </Box>
        </Box>

        {viewMode === "chat" ? (
          <WhatsappAssistantInternalPageV2
            chats={sortedChats}
            isLoadingChats={isLoadingChats}
            isFetchingChats={isFetchingChats}
          />
        ) : (
          <Box sx={{ flex: 1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
            <TableView />
          </Box>
        )}

        <ApplicantsList isOpen={isApplicantListOpen} onClose={closeApplicants} />
      </>
    </PageLayout>
  );
};

interface WhatsappAssistantInternalPageV2Props {
  chats: LexZapChat[];
  isLoadingChats: boolean;
  isFetchingChats: boolean;
}

export const WhatsappAssistantInternalPageV2 = ({ chats, isLoadingChats }: WhatsappAssistantInternalPageV2Props) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "grid",
        gridTemplateRows: "minmax(0, 1fr)",
        overflow: "visible",
      }}
    >
      <ChatView chats={chats} isLoadingChats={isLoadingChats} />
    </Box>
  );
};
