import { useState, useEffect, useCallback } from "react";
import { WebToastColoredWithTitle } from "@/components/core/Toast";
import {
  getApplicantEvents,
  createApplicantEvent,
  updateApplicantEvent,
  deleteApplicantEvent,
} from "@/services/applicantEvents";
import {
  ApplicantEvent,
  CreateApplicantEventParams,
  UpdateApplicantEventParams,
  GetApplicantEventsParams,
  DeleteApplicantEventParams,
} from "@/services/applicantEvents/types";

export const useApplicantEvents = () => {
  const [events, setEvents] = useState<ApplicantEvent[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<Record<string, unknown> | undefined>(undefined);
  const [currentParams, setCurrentParams] = useState<GetApplicantEventsParams>({
    companyId: "", // This should be set from context or props in a real app
    limit: 25,
  });

  const fetchEvents = useCallback(
    async (params?: Partial<GetApplicantEventsParams>) => {
      setLoading(true);
      try {
        const queryParams: GetApplicantEventsParams = {
          ...currentParams,
          ...params,
        };

        // Only update currentParams if this is an explicit fetch with new params
        if (params) {
          setCurrentParams(queryParams);
        }

        const response = await getApplicantEvents(queryParams);
        setEvents(response.items);
        setTotalCount(response.count);
        setLastEvaluatedKey(response.lastEvaluatedKey);
        return response;
      } catch (error) {
        WebToastColoredWithTitle.error(
          "Erro ao buscar eventos",
          "Não foi possível carregar os eventos. Tente novamente mais tarde."
        );
        return null;
      } finally {
        setLoading(false);
      }
    },
    [currentParams]
  );

  const createEvent = async (params: CreateApplicantEventParams): Promise<ApplicantEvent | null> => {
    try {
      const newEvent = await createApplicantEvent(params);
      await fetchEvents(); // Refresh the list
      WebToastColoredWithTitle.success("Evento criado com sucesso");
      return newEvent;
    } catch (error) {
      WebToastColoredWithTitle.error(
        "Erro ao criar evento",
        "Não foi possível criar o evento. Tente novamente mais tarde."
      );
      return null;
    }
  };

  const updateEvent = async (params: UpdateApplicantEventParams): Promise<boolean> => {
    try {
      await updateApplicantEvent(params);
      await fetchEvents(); // Refresh the list
      WebToastColoredWithTitle.success("Evento atualizado com sucesso");
      return true;
    } catch (error) {
      WebToastColoredWithTitle.error(
        "Erro ao atualizar evento",
        "Não foi possível atualizar o evento. Tente novamente mais tarde."
      );
      return false;
    }
  };

  const deleteEvent = async (params: DeleteApplicantEventParams): Promise<boolean> => {
    try {
      await deleteApplicantEvent(params);
      await fetchEvents(); // Refresh the list
      WebToastColoredWithTitle.success("Evento excluído com sucesso");
      return true;
    } catch (error) {
      WebToastColoredWithTitle.error(
        "Erro ao excluir evento",
        "Não foi possível excluir o evento. Tente novamente mais tarde."
      );
      return false;
    }
  };

  const loadNextPage = async () => {
    if (lastEvaluatedKey) {
      await fetchEvents({
        ...currentParams,
        exclusiveStartKey: lastEvaluatedKey,
      });
    }
  };

  // Initial fetch - only run once when the component mounts
  useEffect(() => {
    const initialFetch = async () => {
      await fetchEvents();
    };
    initialFetch();
    // Empty dependency array ensures this only runs once
  }, []);

  return {
    events,
    loading,
    totalCount,
    hasNextPage: !!lastEvaluatedKey,
    fetchEvents,
    createEvent,
    updateEvent,
    deleteEvent,
    loadNextPage,
    currentParams,
  };
};
