import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getCasePlatformFilesQueryKey } from "@/hooks/case/useCasePlatformFiles";
import { PlatformFile } from "../useCasePlatformFiles/types";
import { DocumentsPipelineService } from "@/services/documentspipeline";
import { uploadFileToS3 } from "@/contexts/MessagesContext/utils";
import { FileType } from "@/hooks/lexZap/types";
import { v4 as uuidV4 } from "uuid";

export const useUploadCasePlatformFile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["uploadCasePlatformFile"],
    mutationFn: async ({
      name,
      fileBlob,
    }: {
      caseId: string;
      fileTempId: string;
      name: string;
      fileType: FileType;
      extension: string;
      fileBlob: Blob;
    }) => {
      const { url: signedUrl, location } = await DocumentsPipelineService.signUploadUrl({
        name,
      });

      await uploadFileToS3(signedUrl, fileBlob);

      return { location };
    },
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: getCasePlatformFilesQueryKey({ caseId: data.caseId }) });
      const previousCaseFiles = queryClient.getQueryData<string[]>(
        getCasePlatformFilesQueryKey({ caseId: data.caseId })
      );
      queryClient.setQueryData(getCasePlatformFilesQueryKey({ caseId: data.caseId }), (old: PlatformFile[]) => [
        {
          id: data.fileTempId,
          name: data.name,
          createdAt: new Date().toDateString(),
          fileType: data.fileType,
          extension: data.extension,
          documentsPipelineId: uuidV4(),
        },
        ...(old || []),
      ]);
      return { previousCaseFiles };
    },
    onError: (_error, _newCase, context) => {
      queryClient.setQueryData(getCasePlatformFilesQueryKey({ caseId: _newCase.caseId }), context);
    },
  });
};
