import { CaseFileItem } from "@/core/CaseFile";
import { CaseService } from "@/services/case";
import { saveFileAs } from "@/utils/saveFileAs";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

export const useDownloadCaseFile = () => {
  return useMutation({
    mutationKey: ["downloadCaseFile"],
    mutationFn: async ({ caseId, file }: { caseId: string; file: CaseFileItem }) => {
      let getUrl;
      if (file.isPlatformFile()) {
        const platformFile = await CaseService.getPlatformFile({ platformFileId: file.id as string });
        getUrl = platformFile.location_get_url;
      }

      if (file.isLegalProceedingAttachedFile()) {
        const legalProceedingFile = await CaseService.getCaseFileUrl(caseId, file.id as string);
        getUrl = legalProceedingFile.url;
      }

      const response = await axios.get(getUrl!, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": file.mimeType,
        },
      });

      const blob = new Blob([response.data], {
        type: file.mimeType!,
      });

      return { url: URL.createObjectURL(blob), name: file.name };
    },
    onSuccess: (data) => {
      saveFileAs(data.url, data.name);
    },
  });
};
