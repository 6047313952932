import React from "react";
import { Box } from "@mui/material";
import { PageLayout } from "@/components/PageLayout";
import LoadingRing from "@/assets/svgs/loading-ring.svg?react";

import { usePermissions } from "@/hooks/usePermissions";
import { WhatsappAssistantPage } from "../WhatsappAssistantPage";
import { WhatsappAssistantInternalPage } from "../WhatsappAssistantPageV2";
import { WhatsappAssistantChatPage } from "../WhatsappAssistantPageV2/components/ChatPage";
import { useLexZapCompany } from "@/hooks/lexZap/useLexZapCompany";
import { useImpersonation } from "@/hooks/useImpersonation";
import { WhatsappAssistantLanding } from "../WhatsappAssistantLanding";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

export const WhatsappAssistantLayout = () => {
  const { hasCustomerServiceFullAccessPermission } = usePermissions();
  const { data: connectionData, isLoading: isLoadingConnectionData } = useLexZapCompany();
  const { impersonatedUser } = useImpersonation();

  const hasWhatsappAccess = connectionData?.phoneNumber && hasCustomerServiceFullAccessPermission;

  if (isLoadingConnectionData) {
    return <LoadingWhatsAppAssistant />;
  }

  if (hasWhatsappAccess || impersonatedUser) {
    return <WhatsappAssistantPage />;
  }

  return <WhatsappAssistantLanding />;
};

export const WhatsappAssistantInternalLayout = () => {
  const { approvalRequestsEnabled } = useFeatureFlags();

  if (!approvalRequestsEnabled) {
    return <WhatsappAssistantLanding />;
  }

  return <WhatsappAssistantInternalPage />;
};

export const WhatsappAssistantInternalChatLayout = () => {
  const { approvalRequestsEnabled } = useFeatureFlags();

  if (!approvalRequestsEnabled) {
    return <WhatsappAssistantLanding />;
  }

  return <WhatsappAssistantChatPage />;
};

const LoadingWhatsAppAssistant = () => {
  return (
    <PageLayout
      contentSx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          alignSelf: "center",
        }}
      >
        <LoadingRing width="200px" />
      </Box>
    </PageLayout>
  );
};
