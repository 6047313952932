import { useCallback, useState, useMemo } from "react";

// Core
import { logger } from "@/core/logger";
import { WebToastColoredWithTitle } from "@/components/core/Toast";

// Hooks
import { useApplicants } from "@/hooks/applicants/useApplicants";
import { CaseMessage, LexZapChat } from "@/hooks/lexZap/types";
import { getChatStage } from "@/hooks/lexZap/useLexZapChats/utils/getChatStage";
import { LeadStageSyncService } from "@/hooks/tempSyncLeadStage";

// Services
import { Applicant } from "@/services/applicant/types";
import { WhatsappServerService } from "@/services/whatsappServer";
import { ChatMode, ChatStatus, ChatWithLastMessage } from "@/services/whatsappServer/types/chat";
import { ArchiveReason } from "@/hooks/tempSyncLeadStage/types";

// Local imports
import { ChatFilters } from "../components/TableView/components/QuickFilters/types";

// Helper function to transform ChatWithLastMessage to LexZapChat
export const transformToLexZapChat = (chatData: ChatWithLastMessage, applicantData?: Applicant): LexZapChat => {
  const lastMessageDate = Math.max(
    chatData.lastLawyerMessageDate || 0,
    chatData.lastEndClientMessageDate || 0,
    chatData.lastAgentMessageDate || 0
  );

  return {
    endClientNumber: chatData.endClientNumber,
    status: chatData.status as ChatStatus,
    applicantName: applicantData?.name || chatData.applicantName || chatData.endClientNumber,
    lawyerChat: chatData.lawyerChat,
    lastEndClientMessageDate: chatData.lastEndClientMessageDate,
    lastAgentMessageDate: chatData.lastAgentMessageDate,
    createdAt: chatData.createdAt,
    lastLawyerMessageDate: chatData.lastLawyerMessageDate,
    chatMode: chatData.chatMode as ChatMode,
    unansweredMessagesCount: chatData.unansweredMessagesCount,
    assignedUserIds: chatData.assignedUserIds,
    leadStage: chatData.leadStage,
    legalMatterType: chatData.legalMatterType,
    caseNotes: chatData.caseNotes,
    actionItems: chatData.actionItems,
    lastMessageDate,
    lastMessage: chatData.lastMessage as unknown as CaseMessage,
    applicant: applicantData,
    chatStage: getChatStage({
      chat: {
        endClientNumber: chatData.endClientNumber,
        status: chatData.status,
        chatMode: chatData.chatMode,
        createdAt: chatData.createdAt,
      },
      lastTicket: undefined,
    }),
  };
};

export const useChats = () => {
  const [chats, setChats] = useState<ChatWithLastMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const { data: applicants } = useApplicants();

  const transformedChats = useMemo(() => {
    // Transform chats with applicant data
    return chats.map((chatData) => {
      const applicantData = applicants?.find(
        (applicant) =>
          applicant.phoneNumber === chatData.endClientNumber ||
          applicant.phoneNumber?.toString() === chatData.endClientNumber
      );

      return transformToLexZapChat(chatData, applicantData);
    });
  }, [applicants, chats]);

  const fetchChats = useCallback(async (filters?: ChatFilters) => {
    setLoading(true);
    try {
      const response = await WhatsappServerService.chats.getAll({
        filters: {
          // Convert responsibles array to just userIds
          assignedUserIds: filters?.responsibles?.map((resp) => resp.userId),
          // Add exclude filter for responsibles
          excludeAssignedUserIds: filters?.excludeResponsibles?.map((resp) => resp.userId),
          leadStages: filters?.leadStages,
          chatModes: filters?.chatModes,
          statuses: filters?.statuses,
        },
      });
      setChats(response.chats);
    } catch (error) {
      logger.error("Failed to fetch chats:", { error });
    } finally {
      setLoading(false);
    }
  }, []);

  const updateChat = async (
    endClientNumber: string,
    updateFields: Partial<ChatWithLastMessage>,
    abandonmentDetails?: {
      archiveType?: ArchiveReason;
      archiveDetails?: string;
    }
  ) => {
    try {
      // Convert LexZapChat fields back to ChatWithLastMessage fields for the API
      const { lastMessage, ...apiUpdateFields } = updateFields;

      await WhatsappServerService.chats.update({
        endClientNumber,
        updateFields: apiUpdateFields,
      });

      // Sincronizar o status do lead com o caso, se o leadStage foi atualizado
      if (updateFields.leadStage) {
        try {
          await LeadStageSyncService.syncLeadStageWithCase({
            endClientNumber,
            leadStage: updateFields.leadStage,
            archiveType: abandonmentDetails?.archiveType,
            archiveDetails: abandonmentDetails?.archiveDetails,
          });
          logger.info(`Status do lead sincronizado com sucesso para ${endClientNumber}`);
        } catch (syncError) {
          logger.error("Erro ao sincronizar status do lead com o caso:", { error: syncError });
          // Não interrompe o fluxo principal se a sincronização falhar
        }
      }

      // Fetch the updated chat data
      const chatData = await WhatsappServerService.chats.getChat({
        applicantPhoneNumber: endClientNumber,
      });

      // Find applicant data
      const applicantData = applicants?.find(
        (applicant) =>
          applicant.phoneNumber === endClientNumber || applicant.phoneNumber?.toString() === endClientNumber
      );

      // Transform the updated chat
      const updatedChat = transformToLexZapChat(chatData, applicantData);

      // Update the specific chat in the chats array
      setChats((prevChats) =>
        prevChats.map(
          (chat) => (chat.endClientNumber === endClientNumber ? updatedChat : chat) as unknown as ChatWithLastMessage
        )
      );

      WebToastColoredWithTitle.success("Chat atualizado com sucesso");
      return true;
    } catch (error) {
      WebToastColoredWithTitle.error(
        "Erro ao atualizar chat",
        "Infelizmente não foi possível completar a operação. Tente novamente mais tarde."
      );
      return false;
    }
  };

  return {
    chats: transformedChats,
    loading,
    fetchChats,
    updateChat,
  };
};
