import { Box, Typography } from "@mui/material";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import React, { useMemo, useState } from "react";
import { useApplicants } from "@/hooks/applicants/useApplicants";
import { Phone } from "@/core/Phone";
import { Button } from "@/components/Button";
import { ApplicantModal } from "../ApplicantModal";
import { Search } from "@/pages/WhatsappAssistantPage/components/ChatView/components/ChatList/components/Search";
import { Applicant } from "@/services/applicant/types";
import { useLexZapActiveChat } from "@/hooks/lexZap/useLexZapActiveChat";
import { normalizeString } from "@/utils/normalizeString";
import { ContactsModal } from "../ContactsModal";
import { ModalFooter } from "@/components/Modal/ModalFooter";

export function ApplicantsList({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const { data: applicants, isPending: isLoadingApplicants } = useApplicants();
  const [applicantId, setApplicantId] = useState<string>();
  const [searchTerm, setSearchTerm] = useState("");
  const { setActiveChat } = useLexZapActiveChat();
  const [isOpenNewContact, setIsOpenNewContact] = useState(false);

  const openNewContact = () => {
    setIsOpenNewContact(true);
  };

  const closeNewContact = () => {
    setIsOpenNewContact(false);
  };

  const isLoading = isLoadingApplicants;

  const handleOpenApplicantModal = (applicantId: string) => {
    setApplicantId(applicantId);
  };

  const handleOpenApplicantChat = (applicant: Applicant) => {
    if (!applicant.phoneNumber) return;
    setActiveChat({
      endClientNumber: applicant.phoneNumber,
      applicantName: applicant.name,
      createdAt: Date.now(),
    });
    onClose();
  };

  const handleCloseApplicantModal = () => {
    setApplicantId(undefined);
  };

  const filteredApplicants = useMemo(() => {
    return applicants?.filter((applicant) => normalizeString(applicant.name).includes(normalizeString(searchTerm)));
  }, [applicants, searchTerm]);

  return (
    <>
      <ModalRight
        title={"Nova conversa"}
        size={"SMALL"}
        onClose={onClose}
        open={isOpen}
        subHeader={<Search placeholder="Buscar contato" onChange={(query) => setSearchTerm(query)} />}
        footer={
          <ModalFooter
            confirmLabel="Novo contato"
            onConfirm={openNewContact}
            cancelLabel="Cancelar"
            onCancel={onClose}
          />
        }
      >
        <Loading isLoading={isLoading}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              {filteredApplicants?.map((applicant) => (
                <ApplicantCard
                  key={applicant.id}
                  name={applicant.name}
                  phoneNumber={applicant.phoneNumber}
                  goToProfile={() => handleOpenApplicantModal(applicant.id)}
                  goToChat={() => handleOpenApplicantChat(applicant)}
                />
              ))}
            </Box>
          </Box>
        </Loading>
      </ModalRight>

      <ApplicantModal
        isOpen={!!applicantId}
        onClose={handleCloseApplicantModal}
        goBack={handleCloseApplicantModal}
        applicantId={applicantId}
      />

      <ContactsModal isOpen={isOpenNewContact} onClose={closeNewContact} />
    </>
  );
}

const ApplicantCard = ({
  name,
  phoneNumber,
  goToProfile,
  goToChat,
}: {
  name: string;
  phoneNumber: string | null;
  goToProfile: () => void;
  goToChat: () => void;
}) => {
  const formattedPhoneNumber = getFormattedPhoneNumber(phoneNumber);
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "40% 60%",
        justifyContent: "space-between",
        "&:hover": {
          backgroundColor: "orange.light.30",
        },
        padding: "8px",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          width: "100%",
        }}
      >
        <Typography
          variant="body"
          sx={{
            fontSize: "14px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {name}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "14px" }}>
          {formattedPhoneNumber}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{
            "&:hover": {
              color: "common.black",
              backgroundColor: "primary.main",
            },
          }}
          onClick={goToProfile}
        >
          <Typography variant="body" sx={{ fontWeight: "700" }}>
            Ver perfil
          </Typography>
        </Button>
        <Button
          sx={{
            "&:hover": {
              color: "common.black",
              backgroundColor: "primary.main",
            },
          }}
          onClick={goToChat}
          disabled={!phoneNumber}
        >
          <Typography variant="body" sx={{ fontWeight: "700" }}>
            Conversar
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

const getFormattedPhoneNumber = (phoneNumber: string | null) => {
  if (!phoneNumber) return null;
  return Phone.new(phoneNumber.substring(2)).isSuccess
    ? Phone.new(phoneNumber.substring(2)).getValue().toFormatSpaced()
    : phoneNumber;
};
