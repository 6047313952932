import { Box, Chip, useTheme } from "@mui/material";
import React from "react";

interface MultiSelectChipProps {
  options: MultiSelectChipItem[];
  onMultiSelectChipChange: (options: MultiSelectChipItem[]) => void;
  max?: number;
}

export interface MultiSelectChipItem {
  value: string;
  label: string;
  selected?: boolean;
}

export const MultiSelectChip = ({ options, onMultiSelectChipChange, max }: MultiSelectChipProps) => {
  const theme = useTheme();
  const handleClick = (option: MultiSelectChipItem) => {
    if (!option.selected && options.filter((option) => option.selected).length === max) return;
    const index = options.indexOf(option);
    options[index]!.selected = !option.selected;
    onMultiSelectChipChange(options);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
      }}
    >
      {options.map((option) => {
        return (
          <Chip
            key={options.indexOf(option)}
            sx={{
              bgcolor: option.selected ? "common.shade" : "initial",
              color: option.selected ? "common.white" : "text.primary",
              borderColor: "common.lightShade",
              "&:hover": {
                bgcolor: option.selected ? `${theme.palette.common.shade}!important` : "initial",
                color: option.selected
                  ? `${theme.palette.common.white}!important`
                  : `${theme.palette.common.shade}!important`,
              },
            }}
            variant="outlined"
            label={option.label}
            onClick={() => handleClick(option)}
            size="medium"
          />
        );
      })}
    </Box>
  );
};
