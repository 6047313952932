import { Message } from "./message";
import { Ticket } from "./ticket";
import { z } from "zod";

export interface ChatProps {
  companyId: string;
  endClientNumber: string;
  status: ChatStatus;
  lastEndClientMessageDate?: number;
  lastLawyerMessageDate?: number;
  lastAgentMessageDate?: number;
  createdAt: number;
  applicantName?: string;
  caseSummaryLawyer?: string;
  lastAgentName?: SubAgentName | null;
  lawyerChat?: boolean;
  chatModeChangedAt?: number;
  chatModeChangedByUserId?: string;
  chatMode: ChatMode;
  lastTicket: Ticket;
  archivedStatusChangedAt?: number;
  archivedStatusChangedByUserId?: string;
  conversionId?: string;
  /** -1 significa que o chat foi marcado manualmente como não respondido */
  unansweredMessagesCount?: number;
  assignedUserIds?: string[];
  leadStage?: string; // TODO: Campo deve ser alterado por um enum ou custom field (ex: Contato Inicial, Contrato Assinado)
  legalMatterType?: string; //  TODO: Campo deve ser alterado por um enum ou custom field
  caseNotes?: string; //  TODO: Campo deve ser alterado por uma implementacao de tabela filha depois
  actionItems?: string; //  TODO: Campo deve ser alterado por uma implementacao de servico depois
}

export enum ChatMode {
  AI = "ai",
  AI_PAUSED_FOR_CURRENT_TICKET = "ai_paused_for_current_ticket",
  MANUAL = "manual",
}

export enum ChatStatus {
  ACTIVE = "active",
  ARCHIVED = "archived",
}

export enum SubAgentName {
  CASE_SUMMARY_CLIENT = "Case Summary Client",
  CASE_SUMMARY_LAWYER = "Case Summary Lawyer",
  QUESTION_ASKER = "Question Asker",
  TRIAGE_AGENT = "Triage Agent",
  RETRY_CONTACT = "Retry Contact",
  TICKET_SUMMARY = "Ticket Summary",
  CASE_QUESTION_ANSWER = "Case Question Answer",
  SHOW_CASES = "Show Cases",
  DOCUMENT_REQUESTER = "Ask Docs to Client",
}

export interface ChatWithLastMessage extends ChatProps {
  lastMessage: Message;
}

// Define Zod schemas first
const chatFiltersSchema = z.object({
  assignedUserIds: z.array(z.string()).optional(),
  excludeAssignedUserIds: z.array(z.string()).optional(), // Novo campo para filtro por exclusão
  leadStages: z.array(z.string()).optional(),
  chatModes: z.array(z.nativeEnum(ChatMode)).optional(),
  statuses: z.array(z.nativeEnum(ChatStatus)).optional(),
});

export const getChatSchema = z.object({
  companyId: z.string().min(1, "companyId is required"),
  limit: z.number().optional(),
  lastEvaluatedKey: z.record(z.any()).optional(),
  filters: chatFiltersSchema.optional(),
});

export const getChatResponseSchema = z.object({
  chats: z.array(
    z.custom<ChatWithLastMessage>((val) => val !== null, {
      message: "Chat must be a valid ChatWithLastMessage object",
    })
  ),
  lastEvaluatedKey: z.record(z.any()).optional(),
  total: z.number(),
});

// Derive TypeScript types from Zod schemas
export type ChatFilters = z.infer<typeof chatFiltersSchema>;
export type GetChatsParams = z.infer<typeof getChatSchema>;
export type GetChatResponse = z.infer<typeof getChatResponseSchema>;
export type ChatsResponse = GetChatResponse;

// Simple interface for total count params
export interface GetTotalChatCountParams {
  companyId: string;
  filters?: ChatFilters;
}
