import { PageLayout } from "@/components/PageLayout";
import { Typography, Box } from "@mui/material";
import React from "react";
import { ApprovalList } from "./components/ApprovalList";

export const ApprovalRequestsPage: React.FC = () => {
  return (
    <PageLayout
      contentProps={{
        fullWidth: true,
      }}
      sx={{
        "& .page-layout-container": {
          padding: 3,
        },
      }}
      variant="secondary"
    >
      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          Aprovações Pendentes
        </Typography>

        <Typography variant="body1" color="text.secondary">
          Gerencie solicitações de aprovação. Revise, aprove, edite ou solicite reprocessamento das ações propostas
          pelos agentes.
        </Typography>
      </Box>

      <ApprovalList />
    </PageLayout>
  );
};

export default ApprovalRequestsPage;
