import { authenticatedLexterCopilotApi } from "@/core/api";
import {
  ApprovalRequest,
  ApprovalStatus,
  ApprovalRequestPutInput,
  validateApprovalRequestPutInput,
  ProcessSkillOutput,
} from "./types";
import { AxiosError } from "axios";

const mockApprovalRequests: ApprovalRequest[] = [
  {
    id: "1",
    applicant_phone_number: "5511950206297",
    exec_request_id: "REQ-001",
    subagent_name: "Retry Contact",
    status: "PENDING",
    request_type: "SEND_WHATSAPP_MESSAGE",
    input_: {
      skill_id: "whatsapp_chat",
      payload: {
        chat_history:
          '[{"id":"wamid.HBgNNTUxMTk5NDY5MTk2MxUCABIYFjNFQjA0MTU4MzkyODgwNzA2MUQyMDYA","from":"client","body":"Preciso remarcar a consulta","type":"text","timestamp":1741805355102}]',
        subagent_name: "Retry Contact",
      },
      execution_id: "4dcdbbcb-bf7c-4ae2-9b8b-feaa86925cf2",
      input_type: "ProcessSkillInput",
      header_args: {
        company_id: "company-1",
        session_id: "5511950206297",
        request_id: "4dcdbbcb-bf7c-4ae2-9b8b-feaa86925cf2",
        ticket_id: "TKT-001",
        "x-assistant-name": "Ana",
        "x-company-name": "João Varella Advogados",
      },
    },
    output: {
      execution_id: "4dcdbbcb-bf7c-4ae2-9b8b-feaa86925cf2",
      success: true,
      billable: true,
      payload: {
        subagent_name: "Retry Contact",
        action: {
          id: "SEND_WPP_MSG",
          message:
            "Entendido, Aldin. É muito importante que você consiga essa consulta com o ortopedista o quanto antes, pois o laudo atualizado será essencial para dar andamento ao processo de reativação do benefício. Sei que lidar com dores e com toda essa situação pode ser desafiador, mas quero reforçar que estamos aqui para te ajudar no que for necessário. Assim que você tiver o laudo ou precisar de qualquer orientação, conte comigo e com o escritório João Varella Advogados. Estamos à disposição para te apoiar!",
        },
      },
    },
    callback_url: "/callback/1",
    company_id: "company-1",
    created_at: new Date("2024-01-01T10:00:00Z"),
  },
  {
    id: "2",
    applicant_phone_number: "5521979151009",
    exec_request_id: "REQ-006",
    subagent_name: "Retry Contact",
    status: "REQUESTED_CHANGES",
    request_type: "SEND_WHATSAPP_MESSAGE",
    input_: {
      skill_id: "whatsapp_chat",
      payload: {
        chat_history:
          '[{"id":"wamid.HBgNNTUxMTk5NDY5MTk2MxUCABIYFjNFQjA0MTU4MzkyODgwNzA2MUQyMDYA","from":"client","body":"Preciso remarcar a consulta","type":"text","timestamp":1741805355102}]',
        subagent_name: "Retry Contact",
      },
      execution_id: "9idebbcb-gf7c-4ae2-9b8b-feaa86925cf7",
      input_type: "ProcessSkillInput",
      header_args: {
        company_id: "company-2",
        session_id: "5521979151009",
        request_id: "9idebbcb-gf7c-4ae2-9b8b-feaa86925cf7",
        ticket_id: "TKT-002",
        "x-assistant-name": "Ana",
        "x-company-name": "João Varella Advogados",
      },
    },
    output: {
      execution_id: "9idebbcb-gf7c-4ae2-9b8b-feaa86925cf7",
      success: true,
      billable: true,
      payload: {
        subagent_name: "Retry Contact",
        action: {
          id: "SEND_WPP_MSG",
          message:
            "Entendido. É muito importante que você consiga essa consulta com o ortopedista o quanto antes, pois o laudo atualizado será essencial para dar andamento ao processo de reativação do benefício. Sei que lidar com dores e com toda essa situação pode ser desafiador, mas quero reforçar que estamos aqui para te ajudar no que for necessário. Assim que você tiver o laudo ou precisar de qualquer orientação, conte comigo e com o escritório João Varella Advogados. Estamos à disposição para te apoiar!",
        },
      },
    },
    change_instructions: "Adicione mais detalhes sobre a urgência da consulta e mencione a necessidade do laudo médico",
    callback_url: "/callback/6",
    company_id: "company-2",
    created_at: new Date("2024-01-01T09:00:00Z"),
    reviewed_at: new Date("2024-01-01T09:30:00Z"),
    reviewer_id: "user-321",
  },
  {
    id: "3",
    applicant_phone_number: "5521979151009",
    exec_request_id: "REQ-002",
    subagent_name: "Retry Contact",
    status: "PENDING",
    request_type: "SEND_WHATSAPP_MESSAGE",
    input_: {
      skill_id: "whatsapp_chat",
      payload: {
        chat_history:
          '[{"id":"wamid.HBgNNTUxMTk5NDY5MTk2MxUCABIYFjNFQjA0MTU4MzkyODgwNzA2MUQyMDYA","from":"client","body":"Preciso remarcar a consulta","type":"text","timestamp":1741805355102}]',
        subagent_name: "Retry Contact",
      },
      execution_id: "5edebbcb-cf7c-4ae2-9b8b-feaa86925cf3",
      input_type: "ProcessSkillInput",
      header_args: {
        company_id: "company-2",
        session_id: "5521979151009",
        request_id: "5edebbcb-cf7c-4ae2-9b8b-feaa86925cf3",
        ticket_id: "TKT-003",
        "x-assistant-name": "Ana",
        "x-company-name": "João Varella Advogados",
      },
    },
    output: {
      execution_id: "5edebbcb-cf7c-4ae2-9b8b-feaa86925cf3",
      success: true,
      billable: true,
      payload: {
        subagent_name: "Retry Contact",
        action: {
          id: "SEND_WPP_MSG",
          message:
            "Entendido. É muito importante que você consiga essa consulta com o ortopedista o quanto antes, pois o laudo atualizado será essencial para dar andamento ao processo de reativação do benefício. Sei que lidar com dores e com toda essa situação pode ser desafiador, mas quero reforçar que estamos aqui para te ajudar no que for necessário. Assim que você tiver o laudo ou precisar de qualquer orientação, conte comigo e com o escritório João Varella Advogados. Estamos à disposição para te apoiar!",
        },
      },
    },
    callback_url: "/callback/2",
    company_id: "company-2",
    created_at: new Date("2024-01-02T14:30:00Z"),
  },
  {
    id: "4",
    applicant_phone_number: "5511954730620",
    exec_request_id: "REQ-003",
    subagent_name: "Document Analysis",
    status: "APPROVED",
    request_type: "CREATE_CONTRACT",
    input_: {
      skill_id: "document_analysis",
      payload: {},
      execution_id: "6fdebbcb-df7c-4ae2-9b8b-feaa86925cf4",
      input_type: "document",
      header_args: {
        user_id: "user-4",
        company_id: "company-1",
      },
    },
    output: {
      execution_id: "6fdebbcb-df7c-4ae2-9b8b-feaa86925cf4",
      success: true,
      billable: true,
      payload: {
        subagent_name: "Document Analysis",
        action: {
          id: "ANALYZE_DOCUMENT",
          document_type: "medical_report",
          analysis_result: "Document validated and processed successfully",
        },
      },
    },
    callback_url: "/callback/3",
    company_id: "company-1",
    created_at: new Date("2024-01-03T09:15:00Z"),
    reviewed_at: new Date("2024-01-03T10:30:00Z"),
    reviewer_id: "user-123",
  },
  {
    id: "5",
    applicant_phone_number: "5511954730620",
    exec_request_id: "REQ-004",
    subagent_name: "Schedule Appointment",
    status: "EDITED",
    request_type: "SCHEDULE_MEETING",
    input_: {
      skill_id: "schedule_appointment",
      payload: {},
      execution_id: "7gdebbcb-ef7c-4ae2-9b8b-feaa86925cf5",
      input_type: "appointment",
      header_args: {
        user_id: "user-5",
        company_id: "company-3",
      },
    },
    output: {
      execution_id: "7gdebbcb-ef7c-4ae2-9b8b-feaa86925cf5",
      success: true,
      billable: true,
      payload: {
        subagent_name: "Schedule Appointment",
        action: {
          id: "SCHEDULE_APPOINTMENT",
          appointment_date: "2024-02-15T10:00:00Z",
          doctor: "Dr. Silva",
          specialty: "Orthopedics",
        },
      },
    },
    output_edited: {
      execution_id: "7gdebbcb-ef7c-4ae2-9b8b-feaa86925cf5",
      success: true,
      billable: true,
      payload: {
        subagent_name: "Schedule Appointment",
        action: {
          id: "SCHEDULE_APPOINTMENT",
          appointment_date: "2024-02-16T14:00:00Z",
          doctor: "Dr. Santos",
          specialty: "Orthopedics",
        },
      },
    },
    callback_url: "/callback/4",
    company_id: "company-3",
    created_at: new Date("2024-01-04T16:45:00Z"),
    reviewed_at: new Date("2024-01-04T17:00:00Z"),
    reviewer_id: "user-456",
  },
  {
    id: "6",
    applicant_phone_number: "5511954730620",
    exec_request_id: "REQ-005",
    subagent_name: "Benefits Calculator",
    status: "REQUESTED_CHANGES",
    request_type: "CREATE_CONTRACT",
    input_: {
      skill_id: "benefits_calculator",
      payload: {},
      execution_id: "8hdebbcb-ff7c-4ae2-9b8b-feaa86925cf6",
      input_type: "calculation",
      header_args: {
        user_id: "user-6",
        company_id: "company-2",
      },
    },
    output: {
      execution_id: "8hdebbcb-ff7c-4ae2-9b8b-feaa86925cf6",
      success: true,
      billable: true,
      payload: {
        subagent_name: "Benefits Calculator",
        action: {
          id: "CALCULATE_BENEFITS",
          benefit_type: "disability",
          amount: 2500.0,
          period_months: 12,
        },
      },
    },
    change_instructions:
      "Please recalculate considering the new documentation provided showing longer contribution period",
    callback_url: "/callback/5",
    company_id: "company-2",
    created_at: new Date("2024-01-05T11:20:00Z"),
    reviewed_at: new Date("2024-01-05T13:15:00Z"),
    reviewer_id: "user-789",
  },
];

const USE_MOCK = false; // TODO: Set to false when API is ready

export const ApprovalService = {
  getAll: async (
    statuses?: ApprovalStatus[],
    limit: number = 1000,
    exclusiveStartKey?: string,
    scanIndexForward: boolean = false
  ): Promise<ApprovalRequest[]> => {
    if (USE_MOCK) {
      let filteredData = mockApprovalRequests;
      if (statuses?.length) {
        filteredData = mockApprovalRequests.filter((request) => statuses.includes(request.status));
      }
      return filteredData.slice(0, limit);
    }

    // Use URLSearchParams to properly encode multiple status parameters
    const searchParams = new URLSearchParams();
    searchParams.append("limit", limit.toString());
    searchParams.append("scan_index_forward", scanIndexForward.toString());

    if (statuses?.length) {
      statuses.forEach((status) => {
        searchParams.append("statuses", status);
      });
    }

    if (exclusiveStartKey) {
      searchParams.append("exclusive_start_key", exclusiveStartKey);
    }

    try {
      const response = await authenticatedLexterCopilotApi.get<ApprovalRequest[]>("/approval-requests", {
        params: searchParams,
      });
      return response.data;
    } catch (error) {
      throw new Error("Failed to retrieve approval requests");
    }
  },

  getByPhoneNumber: async (
    phoneNumber: string,
    limit?: number,
    statuses?: ApprovalStatus[],
    exclusiveStartKey?: string,
    scanIndexForward: boolean = false
  ): Promise<ApprovalRequest[]> => {
    if (USE_MOCK) {
      let filteredData = mockApprovalRequests.filter((request) => request.applicant_phone_number === phoneNumber);
      if (statuses?.length) {
        filteredData = filteredData.filter((request) => statuses.includes(request.status));
      }
      return filteredData;
    }

    const searchParams = new URLSearchParams();
    if (limit) {
      searchParams.append("limit", limit.toString());
    }
    if (exclusiveStartKey) {
      searchParams.append("exclusive_start_key", exclusiveStartKey);
    }
    searchParams.append("scan_index_forward", scanIndexForward.toString());

    if (statuses?.length) {
      statuses.forEach((status) => {
        searchParams.append("statuses", status);
      });
    }

    try {
      const response = await authenticatedLexterCopilotApi.get<ApprovalRequest[]>(
        `/approval-requests/phone/${phoneNumber}`,
        {
          params: searchParams,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to retrieve approval requests");
    }
  },

  getById: async (requestId: string, phoneNumber: string): Promise<ApprovalRequest> => {
    if (USE_MOCK) {
      const request = mockApprovalRequests.find((r) => r.id === requestId && r.applicant_phone_number === phoneNumber);
      if (!request) {
        throw new Error("Approval request not found");
      }
      return request;
    }

    try {
      const response = await authenticatedLexterCopilotApi.get<ApprovalRequest>(`/approval-requests/${requestId}`, {
        params: { phone_number: phoneNumber },
      });
      return response.data;
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        throw new Error("Approval request not found");
      }
      throw new Error("Failed to retrieve approval request");
    }
  },

  put: async (requestId: string, phoneNumber: string, input: ApprovalRequestPutInput): Promise<ApprovalRequest> => {
    validateApprovalRequestPutInput(input);

    try {
      const response = await authenticatedLexterCopilotApi.put<ApprovalRequest>(
        `/approval-requests/${requestId}`,
        input,
        {
          params: { phone_number: phoneNumber },
        }
      );
      return response.data;
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        throw new Error("Approval request not found");
      }
      throw new Error("Failed to update approval request");
    }
  },

  approve: async (id: string, phoneNumber: string, outputEdited?: ProcessSkillOutput): Promise<void> => {
    const input: ApprovalRequestPutInput = {
      status: outputEdited ? "EDITED" : "APPROVED",
      ...(outputEdited && {
        output_edited: outputEdited,
      }),
    };

    await ApprovalService.put(id, phoneNumber, input);
  },

  requestReprocessing: async (id: string, phoneNumber: string, instructions: string): Promise<void> => {
    const input: ApprovalRequestPutInput = {
      status: "REQUESTED_CHANGES",
      change_instructions: instructions,
    };

    await ApprovalService.put(id, phoneNumber, input);
  },

  delete: async (id: string, phoneNumber: string): Promise<void> => {
    await authenticatedLexterCopilotApi.delete(`/approval-requests/${id}`, {
      params: { phone_number: phoneNumber },
    });
  },
};
