import { Box, Pagination, Skeleton, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { v4 as uuidV4 } from "uuid";

import React, { useMemo, useState } from "react";

import { Card } from "../Card";
import { useCaseFiles } from "@/hooks/lexZap/useCaseFiles";
import { Case, FileType } from "@/hooks/lexZap/types";
import { CaseFilesHeaderOptions } from "./components/CaseFilesHeaderOptions";
import { Button } from "@/components/Button";
import {
  Add,
  AudioFileOutlined as AudioFileIcon,
  ImageOutlined as ImageFileIcon,
  FilePresentOutlined as FilePresentIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import { FilterCaseFilesModal } from "./components/filterCaseFilesModal";
import { CaseFileFilters } from "./components/filterCaseFilesModal/types";
import { calculateCaseFileFiltersChanges } from "./utils/calculateCaseFileFiltersChanges";
import { useDropzone } from "react-dropzone";
import { WebToast } from "@/components/core/Toast";
import { useCreateCasePlatformFile } from "@/hooks/case/useCreateCasePlatformFile";
import { logger } from "@/core/logger";
import { useDeleteCasePlatformFile } from "@/hooks/case/useDeleteCasePlatformFile";
import { CaseFileItem } from "@/core/CaseFile";
import { useDownloadCaseFile } from "@/hooks/case/useDonwloadCaseFile";
import { useUploadCasePlatformFile } from "@/hooks/case/useUploadCaseFile";
import { applyCaseFileFilters } from "./utils/applyCaseFileFilters";
import { RemoveCaseFileCaseModal } from "./components/RemoveCaseFileModal";
import { getCaseDocumentPath } from "@/routes/routePaths";
import { useNavigate } from "react-router-dom";
import { useDeleteRecord } from "@/hooks/records/useDeleteRecord";
import { DateTime } from "luxon";
import { useAuthContext } from "@/contexts/AuthContext";
import { exportHtmlToDocx } from "@/contexts/WebEditorContext/plugins/exportHtmlToDocx";
import { useApi } from "@/hooks/useApi";

type CaseFilesProps = {
  caseData: Case;
};

const defaultCaseFileFilters: CaseFileFilters = {
  fileType: {
    audio: false,
    document: false,
    image: false,
  },
  origin: {
    platform: false,
    whatsapp: false,
    record: false,
    legalProceeding: false,
  },
};

export const CaseFilesV2 = ({ caseData }: CaseFilesProps) => {
  const [pagination, setPagination] = useState({
    pageSize: 5,
    page: 0,
  });
  const [query, setQuery] = React.useState<string>("");
  const [isFilterCaseFilesModalOpen, setIsFilterCaseFilesModalOpen] = React.useState(false);
  const [caseFileFilters, setCaseFileFilters] = React.useState<CaseFileFilters>(defaultCaseFileFilters);
  const { data: caseFiles, isLoading: isLoadingCaseFiles } = useCaseFiles({
    caseId: caseData?.id,
    threadIds: caseData?.threadIds,
    search: query,
  });
  const { mutateAsync: createCasePlatformFile } = useCreateCasePlatformFile();
  const { mutate: deleteCasePlatformFile } = useDeleteCasePlatformFile();
  const { mutateAsync: uploadCasePlatformFile } = useUploadCasePlatformFile();
  const { mutateAsync: downloadCaseFile } = useDownloadCaseFile();
  const { mutateAsync: deleteRecord } = useDeleteRecord({ threadId: caseData?.chatId });
  const [uploadingProgress, setUploadingProgress] = useState<string[]>([]);
  const [successUploadFiles, setSuccessUploadFiles] = useState<string[]>([]);
  const [removeFileModal, setRemoveFileModal] = useState<CaseFileItem | null>(null);
  const { user } = useAuthContext();

  const navigate = useNavigate();
  const files = useMemo(() => {
    const startIndex = pagination.page * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    const filteredFiles = applyCaseFileFilters({ caseFiles, searchTerm: query, filters: caseFileFilters });
    return filteredFiles.slice(startIndex, endIndex);
  }, [caseFiles, pagination, caseFileFilters, query]);
  const { getDocumentById } = useApi();
  const handleChangeSearch = (value: string) => {
    setPagination((prev) => ({ ...prev, page: 0 }));
    setQuery(value);
  };

  const openFilterCaseFilesModal = () => {
    setIsFilterCaseFilesModalOpen(true);
  };

  const closeFilterCaseFilesModal = () => {
    setIsFilterCaseFilesModalOpen(false);
  };

  const openRemoveFileModal = (file: CaseFileItem) => {
    setRemoveFileModal(file);
  };

  const closeRemoveFileModal = () => {
    setRemoveFileModal(null);
  };

  const openDocument = (file: CaseFileItem) => {
    if (!caseData?.id) return;
    navigate(getCaseDocumentPath({ caseId: caseData.id, documentId: file.id.toString() }));
  };

  const handleDeleteRecord = async (data: CaseFileItem) => {
    if (!caseData?.id || !data.isRecord() || !user) return;

    await deleteRecord({
      id: Number(data.id),
      type: data.recordType!,
      name: data.name,
      createdAt: DateTime.fromMillis(data.createdAt).toISO()!,
      threadId: data.threadId,
      userId: user.userId,
    });
    closeRemoveFileModal();
  };

  const totalCaseFileFiltersChanges = useMemo(() => {
    return calculateCaseFileFiltersChanges({
      filters: caseFileFilters,
      defaultFilters: defaultCaseFileFilters,
    });
  }, [caseFileFilters]);

  const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
    await Promise.allSettled(
      acceptedFiles?.map(async (file) => {
        if (!caseData?.id) return;
        const fileTempId = uuidV4();
        const fileToBlob = async (file: File) =>
          new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
        const blob = await fileToBlob(file);
        try {
          setUploadingProgress((prev) => [...(prev || []), fileTempId]);
          const uploadResult = await uploadCasePlatformFile({
            fileTempId,
            caseId: caseData.id,
            name: file.name,
            fileBlob: blob,
            extension: file.type.split("/")[1] as string,
            fileType: file.type.split("/")[0] as FileType,
          });
          const createdFile = await createCasePlatformFile({
            fileTempId,
            caseId: caseData.id,
            name: file.name,
            size: file.size,
            location: uploadResult.location,
            fileType: file.type.split("/")[0] as FileType,
            extension: file.type.split("/")[1] as string,
          });
          handleSuccessUploadFile(createdFile.id);
        } catch (err) {
          logger.error(`[uploadCasePlatformFile] error`, { error: err });
          WebToast.error("Erro ao enviar o arquivo");
        } finally {
          setUploadingProgress((prev) => prev?.filter((id) => id !== fileTempId));
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop,
    onDropRejected(fileRejections) {
      if (fileRejections.length) {
        WebToast.error(
          fileRejections.length === 1
            ? `1 arquivo que você tentou adicionar não é compatível`
            : `${fileRejections.length} arquivos que você tentou adicionar não são compatíveis`
        );
      }
    },
    accept: {
      "video/3gp": [],
      "video/mp4": [],
      "image/jpeg": [],
      "image/png": [],
      "audio/mpeg": [],
      "audio/mp4": [],
      "audio/aac": [],
      "audio/amr": [],
      "application/pdf": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
      "text/plain": [],
    },
  });

  const handleOpenFileSelector = () => {
    open();
  };

  const handleDownloadFile = async (file: CaseFileItem) => {
    if (file.type === "PLATFORM_FILE") {
      try {
        await downloadCaseFile({ caseId: caseData?.id, file });
      } catch (err) {
        logger.error(`[handleDonwloadFile] error`, { error: err });
        WebToast.error("Erro ao baixar o arquivo");
      }
    }
    const html = await getDocumentById(Number(file.id));
    await exportHtmlToDocx(html.content);
  };

  const handleDeletePlatformFile = (platformFileId: string) => {
    try {
      deleteCasePlatformFile({ caseId: caseData?.id, fileId: platformFileId });
      closeRemoveFileModal();
    } catch (err) {
      closeRemoveFileModal();
      logger.error(`[handleDeletePlatformFile] error`, { error: err });
      WebToast.error("Erro ao deletar o arquivo");
    }
  };

  const handleSuccessUploadFile = (fileId: string) => {
    setSuccessUploadFiles((prev) => [...prev, fileId]);
    setTimeout(() => {
      setSuccessUploadFiles((prev) => prev.filter((id) => id !== fileId));
    }, 5000);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0,
        position: "relative",
      }}
      {...getRootProps()}
    >
      <input
        {...getInputProps()}
        style={{
          position: "absolute",
          opacity: 0,
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
          alignItems: "center",
          height: "54px",
        }}
      >
        <Typography variant="subtitle1" fontWeight={700} color="text.primary">
          Arquivos
        </Typography>
        <CaseFilesHeaderOptions
          onChange={handleChangeSearch}
          openFilterCaseFilesModal={openFilterCaseFilesModal}
          totalCaseFileFiltersChanges={totalCaseFileFiltersChanges}
        />
      </Box>
      <CaseFilesSkeletonLoading loading={isLoadingCaseFiles}>
        <DataGrid
          localeText={{
            ...ptBR.components.MuiDataGrid.defaultProps.localeText,
            noResultsOverlayLabel: "Nenhum arquivo encontrado",
          }}
          rows={files}
          columns={[
            {
              field: "type",
              headerName: "Tipo",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              renderCell: (item) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {successUploadFiles.includes(item.row.id.toString()) ? (
                      <CheckCircleIcon fontSize="small" sx={{ color: "success.main" }} />
                    ) : (
                      (() => {
                        switch (item.row.isPlatformFile() ? item.row.fileType : item.row.mimeType?.split("/")[0]) {
                          case "image":
                            return <ImageFileIcon fontSize="small" sx={{ color: "grey.500" }} />;
                          case "audio":
                            return <AudioFileIcon fontSize="small" sx={{ color: "grey.500" }} />;
                          default:
                            return <FilePresentIcon fontSize="small" sx={{ color: "grey.500" }} />;
                        }
                      })()
                    )}
                  </Box>
                );
              },
            },
            {
              field: "name",
              headerName: "Nome",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
            },
            {
              field: "origin",
              headerName: "Origem",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              renderCell: (file) => {
                return (
                  <Box
                    sx={{
                      display: "grid",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {uploadingProgress?.some((fileId) => fileId === file.row.id) ? (
                      <LinearProgress
                        sx={{
                          height: "16px",
                        }}
                      />
                    ) : (
                      <Typography variant="body1" color="text.secondary">
                        {file.row.isPlatformFile() ? "Upload manual" : "Whatsapp"}
                      </Typography>
                    )}
                  </Box>
                );
              },
            },
            {
              field: "actions",
              type: "actions",
              flex: 1,
              getActions: ({ row: file }) => [
                <GridActionsCellItem
                  sx={{
                    width: "150px",
                  }}
                  key={`${file.id}-view`}
                  label="Visualizar"
                  onClick={() => openDocument(file)}
                  showInMenu
                  disabled={!file.isRecord()}
                />,
                <GridActionsCellItem
                  key={`${file.id}-download`}
                  label="Baixar"
                  onClick={() => handleDownloadFile(file)}
                  showInMenu
                />,
                <GridActionsCellItem
                  key={`${file.id}-remove`}
                  label="Remover"
                  onClick={() => openRemoveFileModal(file)}
                  showInMenu
                />,
              ],
            },
          ]}
          rowCount={caseFiles.length}
          rowSelection={false}
          paginationMode="server"
          loading={isLoadingCaseFiles}
          paginationModel={pagination}
          onPaginationModelChange={(paginationModel) => setPagination(paginationModel)}
          hideFooterPagination={true}
          hideFooter={true}
        />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderTop: "1px solid",
            borderTopColor: "grey.200",
            padding: 2,
          }}
        >
          <Pagination
            count={Math.ceil(caseFiles.length / pagination.pageSize)}
            page={pagination.page + 1}
            showFirstButton
            showLastButton
            onChange={(_, value) => setPagination({ ...pagination, page: value - 1 })}
            siblingCount={0}
            boundaryCount={2}
          />
        </Box>
        <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              border: "none",
              "&:hover": {
                border: "none",
              },
            }}
            startIcon={Add}
            onClick={handleOpenFileSelector}
          >
            Adicionar documento
          </Button>
        </Box>
      </CaseFilesSkeletonLoading>
      <FilterCaseFilesModal
        open={isFilterCaseFilesModalOpen}
        onClose={closeFilterCaseFilesModal}
        caseFileFilters={caseFileFilters}
        setCaseFileFilters={setCaseFileFilters}
      />

      {removeFileModal && (
        <RemoveCaseFileCaseModal
          isOpen={!!removeFileModal}
          onConfirm={
            removeFileModal.type === "PLATFORM_FILE"
              ? () => handleDeletePlatformFile(removeFileModal.id.toString())
              : () => handleDeleteRecord(removeFileModal)
          }
          onCancel={closeRemoveFileModal}
        />
      )}
    </Card>
  );
};

type CaseFilesSkeletonLoadingProps = {
  loading: boolean;
  children: React.ReactNode;
};

const CaseFilesSkeletonLoading = ({ loading, children }: CaseFilesSkeletonLoadingProps) => {
  if (loading) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, my: 2 }}>
        <Skeleton animation="wave" variant="rectangular" sx={{ borderRadius: 2, height: "20px" }} />
        <Skeleton animation="wave" variant="rectangular" sx={{ borderRadius: 2, height: "20px" }} />
        <Skeleton animation="wave" variant="rectangular" sx={{ borderRadius: 2, height: "20px" }} />
        <Skeleton animation="wave" variant="rectangular" sx={{ borderRadius: 2, height: "20px" }} />
      </Box>
    );
  }
  return <>{children}</>;
};
