import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Divider, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

export const CustomAccordion = ({ children, title }: { children: React.ReactNode; title: string }) => (
  <Accordion
    sx={{
      border: "1px solid #E0E0E0",
      borderRadius: "4px !important",
      boxShadow: "none",
      margin: "0 !important",
      "::before": {
        opacity: 0,
      },
    }}
  >
    <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
      <Typography variant="body1" fontSize="16px">
        {title}
      </Typography>
    </AccordionSummary>
    <Divider />
    <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: 1 }}>{children}</AccordionDetails>
  </Accordion>
);

export const PermissionDescription = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="multiLineBody" color="text.secondary">
    {children}
  </Typography>
);

export const PermissionItem = ({
  title,
  description,
  isChecked,
  onChange,
  disabled,
}: {
  title: string;
  description: string;
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}) => (
  <Box>
    <Typography variant="body" fontWeight="700">
      {title}
    </Typography>
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1 }}>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
      <Checkbox
        disabled={disabled}
        checked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
        sx={{ padding: 0 }}
      />
    </Box>
  </Box>
);
