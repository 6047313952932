import React, { useState } from "react";
import { Box, Button, Tooltip, Stack, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridRowModes,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
  GridColDef,
} from "@mui/x-data-grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";

import { useApplicantEvents } from "../../hooks/useApplicantEvents";
import { getColumns } from "./columns";
import { tableStyles } from "./styles";
import { EventModal } from "../EventModal";
import { ApplicantEvent } from "@/services/applicantEvents/types";
import { logger } from "@/core/logger";

export const EventsTable: React.FC = () => {
  // Custom hook for applicant events data and operations
  const { events, loading, fetchEvents, updateEvent, deleteEvent, createEvent } = useApplicantEvents();

  // UI state management
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  // Row status tracking
  const [loadingRows, setLoadingRows] = useState<Set<GridRowId>>(new Set());
  const [successRows, setSuccessRows] = useState<Set<GridRowId>>(new Set());
  const [errorRows, setErrorRows] = useState<Set<GridRowId>>(new Set());

  // Modal state management
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<ApplicantEvent | null>(null);
  const [modalMode, setModalMode] = useState<"view" | "edit" | "create">("view");

  // ===== Data Operations =====

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchEvents();
    setIsRefreshing(false);
  };

  // ===== Row Edit Handlers =====

  const handleRowEditStart = () => {
    // Clear any previous success/error states
    setSuccessRows(new Set());
    setErrorRows(new Set());
  };

  const handleRowEditStop = () => {
    // Optional cleanup
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  // ===== Row Status Management =====

  const updateRowLoadingState = (id: GridRowId, isLoading: boolean) => {
    setLoadingRows((prev) => {
      const newSet = new Set(prev);
      if (isLoading) {
        newSet.add(id);
      } else {
        newSet.delete(id);
      }
      return newSet;
    });
  };

  const setRowSuccess = (id: GridRowId) => {
    setSuccessRows((prev) => new Set(prev).add(id));

    // Clear success state after 2 seconds
    setTimeout(() => {
      setSuccessRows((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    }, 2000);
  };

  const setRowError = (id: GridRowId) => {
    setErrorRows((prev) => new Set(prev).add(id));
  };

  // ===== Event CRUD Operations =====

  const handleDeleteClick = (id: GridRowId) => () => {
    const event = events.find((event: ApplicantEvent) => event.eventId === id);

    if (!event) return;

    const confirmMessage = `Tem certeza que deseja excluir o evento "${event.eventName}"?`;
    if (!window.confirm(confirmMessage)) return;

    // Mark row as loading
    updateRowLoadingState(id, true);

    // Prepare delete parameters
    const deleteParams = {
      companyId: event.companyId,
      applicantPhoneNumber: event.applicantPhoneNumber,
      eventId: event.eventId,
    };

    // Execute delete operation
    deleteEvent(deleteParams)
      .then((success: boolean) => {
        // Remove loading state
        updateRowLoadingState(id, false);

        if (success) {
          setRowSuccess(id);
        } else {
          setRowError(id);
        }
      })
      .catch((error) => {
        logger.error("Failed to delete event", { messageContext: { eventId: id }, error });
        updateRowLoadingState(id, false);
        setRowError(id);
      });
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    updateRowLoadingState(newRow.eventId, true);

    try {
      const success = await updateEvent({
        companyId: newRow.companyId,
        applicantPhoneNumber: newRow.applicantPhoneNumber,
        eventId: newRow.eventId,
        updates: {
          category: newRow.category,
          eventName: newRow.eventName,
          creatorType: newRow.creatorType,
        },
      });

      updateRowLoadingState(newRow.eventId, false);

      if (success) {
        setRowSuccess(newRow.eventId);
        return newRow;
      } else {
        setRowError(newRow.eventId);
        throw new Error("Failed to update row");
      }
    } catch (error) {
      updateRowLoadingState(newRow.eventId, false);
      setRowError(newRow.eventId);
      throw error;
    }
  };

  // ===== Modal Handlers =====

  const handleViewClick = (id: GridRowId) => {
    // Add a small delay to prevent accidental triggers
    setTimeout(() => {
      const event = events.find((e: ApplicantEvent) => e.eventId === id);
      if (event) {
        setSelectedEvent(event);
        setModalMode("view");
        setModalOpen(true);
      }
    }, 50);
  };

  const handleCreateClick = () => {
    setSelectedEvent(null);
    setModalMode("create");
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
  };

  const handleEditInModal = () => {
    setModalMode("edit");
  };

  const handleSaveInModal = async (event: ApplicantEvent, isNew: boolean) => {
    if (isNew) {
      await createEvent({
        companyId: event.companyId,
        applicantPhoneNumber: event.applicantPhoneNumber,
        category: event.category,
        eventName: event.eventName,
        details: event.details,
        createdBy: event.createdBy,
        creatorType: event.creatorType,
      });
    } else {
      await updateEvent({
        companyId: event.companyId,
        applicantPhoneNumber: event.applicantPhoneNumber,
        eventId: event.eventId,
        updates: {
          category: event.category,
          eventName: event.eventName,
          details: event.details,
        },
      });
    }
    handleCloseModal();
  };

  // Generate columns with memoization to prevent unnecessary re-renders
  const columns = React.useMemo(
    () =>
      getColumns(
        rowModesModel,
        handleEditClick,
        handleSaveClick,
        handleCancelClick,
        handleDeleteClick,
        handleViewClick,
        loadingRows,
        successRows,
        errorRows
      ) as GridColDef[],
    [rowModesModel, loadingRows, successRows, errorRows]
  );

  // ===== Render Component =====

  return (
    <Box sx={tableStyles.root}>
      {/* Action Buttons */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleCreateClick} sx={{ mr: 2 }}>
          Novo Evento
        </Button>

        <Tooltip title="Atualizar dados">
          <Button
            variant="outlined"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={handleRefresh}
            disabled={loading || isRefreshing}
          >
            {isRefreshing ? "Atualizando..." : "Atualizar"}
          </Button>
        </Tooltip>
      </Box>

      {/* Data Grid */}
      <DataGrid
        getRowId={(row) => row.eventId}
        rows={events}
        columns={columns}
        loading={loading || isRefreshing}
        pageSizeOptions={[25, 50, 100]}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) => {
          logger.error("Error updating row", { messageContext: { error }, error });
        }}
        slots={{
          toolbar: GridToolbar,
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <Typography>Nenhum evento encontrado. Clique em &quot;Novo Evento&quot; para adicionar.</Typography>
              <Typography variant="body2" color="text.secondary">
                Dica: Clique no botão de edição para modificar um evento.
              </Typography>
            </Stack>
          ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            csvOptions: {
              fileName: "applicant-events",
              delimiter: ";",
              utf8WithBom: true,
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25 },
          },
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: {},
          },
        }}
        disableRowSelectionOnClick
        sx={tableStyles.dataGrid}
        getRowClassName={(params) => {
          if (successRows.has(params.id)) return "row-success";
          if (errorRows.has(params.id)) return "row-error";
          return "";
        }}
        isCellEditable={(params) => {
          return rowModesModel[params.id]?.mode === GridRowModes.Edit;
        }}
        getCellClassName={(params) => {
          const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
          return isInEditMode && params.field !== "actions" ? "editable-cell" : "";
        }}
      />

      {/* Event Modal */}
      <EventModal
        open={modalOpen}
        onClose={handleCloseModal}
        event={selectedEvent}
        mode={modalMode}
        onEdit={handleEditInModal}
        onSave={handleSaveInModal}
      />
    </Box>
  );
};
