import { z } from "zod";

export enum EventCategory {
  STAGE_CHANGE = "stage_change",
  DOCUMENT = "document",
  CONTRACT = "contract",
  PAYMENT = "payment",
  DEADLINE = "deadline",
  MEETING = "meeting",
}

export enum CreatorType {
  USER = "USER", // Created by a human user of the system
  SYSTEM = "SYSTEM", // Created automatically by the system
  AI_AGENT = "AI_AGENT", // Created by an AI assistant or agent
  APPLICANT = "APPLICANT", // Created by an action of the applicant
}

// Define base schemas for reuse
const applicantEventBaseSchema = z.object({
  eventId: z.string(),
  companyId: z.string(),
  applicantPhoneNumber: z.string(),
  category: z.nativeEnum(EventCategory),
  eventName: z.string(),
  details: z.record(z.unknown()),
  createdBy: z.string(),
  creatorType: z.nativeEnum(CreatorType),
  createdAt: z.number(),
});

// Derive the TypeScript interface from the schema
export type ApplicantEvent = z.infer<typeof applicantEventBaseSchema>;

export const paginatedResponseSchema = z.object({
  items: z.array(z.any()),
  lastEvaluatedKey: z.record(z.unknown()).optional(),
});

export type PaginatedResponse<T> = {
  items: T[];
  count: number;
  lastEvaluatedKey?: Record<string, unknown>;
};

// Define the Zod schema first
export const createApplicantEventSchema = z.object({
  companyId: z.string().min(1, "Company ID is required"),
  applicantPhoneNumber: z.string().min(1, "Applicant phone number is required"),
  category: z.nativeEnum(EventCategory, {
    errorMap: () => ({ message: "Invalid event category" }),
  }),
  eventName: z.string().min(1, "Event name is required"),
  details: z.record(z.any()).optional().default({}),
  createdBy: z.string().min(1, "Creator ID is required"),
  creatorType: z.nativeEnum(CreatorType, {
    errorMap: () => ({ message: "Invalid creator type" }),
  }),
});

// Then derive the TypeScript type from the schema
export type CreateApplicantEventParams = z.infer<typeof createApplicantEventSchema>;

// Schema for getting applicant events
export const getApplicantEventsSchema = z.object({
  companyId: z.string().min(1, "Company ID is required"),
  applicantPhoneNumber: z.string().optional(),
  category: z
    .nativeEnum(EventCategory, {
      errorMap: () => ({ message: "Invalid event category" }),
    })
    .optional(),
  eventName: z.string().optional(),
  startTime: z.number().optional(),
  endTime: z.number().optional(),
  limit: z.number().positive().optional(),
  exclusiveStartKey: z.record(z.any()).optional(),
});

export type GetApplicantEventsParams = z.infer<typeof getApplicantEventsSchema>;

// Schema for getting a specific applicant event by ID
export const getApplicantEventByIdSchema = z.object({
  companyId: z.string().min(1, "Company ID is required"),
  applicantPhoneNumber: z.string().min(1, "Applicant phone number is required"),
  eventId: z.string().min(1, "Event ID is required"),
});

export type GetApplicantEventByIdParams = z.infer<typeof getApplicantEventByIdSchema>;

// Schema for getting applicant events by category
export const getApplicantEventsByCategorySchema = z.object({
  companyId: z.string().min(1, "Company ID is required"),
  category: z.nativeEnum(EventCategory, {
    errorMap: () => ({ message: "Invalid event category" }),
  }),
  startTime: z.number().optional(),
  endTime: z.number().optional(),
  limit: z.number().positive().optional(),
  exclusiveStartKey: z.record(z.any()).optional(),
});

export type GetApplicantEventsByCategoryParams = z.infer<typeof getApplicantEventsByCategorySchema>;

// Schema for updating an applicant event
export const updateApplicantEventSchema = z.object({
  companyId: z.string().min(1, "Company ID is required"),
  applicantPhoneNumber: z.string().min(1, "Applicant phone number is required"),
  eventId: z.string().min(1, "Event ID is required"),
  updates: z.record(z.any()).refine((obj) => Object.keys(obj).length >= 1, {
    message: "At least one field must be updated",
  }),
});

export type UpdateApplicantEventParams = z.infer<typeof updateApplicantEventSchema>;

// Schema for deleting an applicant event
export const deleteApplicantEventSchema = z.object({
  companyId: z.string().min(1, "Company ID is required"),
  applicantPhoneNumber: z.string().min(1, "Applicant phone number is required"),
  eventId: z.string().min(1, "Event ID is required"),
});

export type DeleteApplicantEventParams = z.infer<typeof deleteApplicantEventSchema>;
