import { env } from "@/constants/environment";
import { StorageTokenService } from "@/services/storageToken";
import { TokenType } from "@/services/storageToken/types";
import {
  getAuth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  getIdToken,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  projectId: env.FIREBASE_PROJECT_ID,
  storageBucket: env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
  appId: env.FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

export const FirebaseService = {
  signOut() {
    StorageTokenService.removeToken(TokenType.FIREBASE);
    return auth.signOut();
  },
  signInWithEmailAndPassword(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email.trim(), password);
  },
  signInWithCustomToken(token: string) {
    return signInWithCustomToken(auth, token);
  },
  signInWithGoogle() {
    const googleProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleProvider);
  },
  getCurrentUser() {
    return auth.currentUser;
  },
  async getToken() {
    const token = StorageTokenService.getToken(TokenType.FIREBASE);

    if (token) {
      return token;
    }
    return this.refreshToken();
  },
  async refreshToken() {
    const firebaseUser = this.getCurrentUser();
    if (!firebaseUser) throw new Error("Firebase user not found");
    const newToken = await getIdToken(firebaseUser, true);
    StorageTokenService.saveToken(newToken, TokenType.FIREBASE);
    return newToken;
  },
  updatePassword(newPassword: string) {
    const firebaseUser = this.getCurrentUser();
    if (!firebaseUser) throw new Error("Firebase user not found");
    return updatePassword(firebaseUser, newPassword);
  },
  reauthenticateWithCredential(email: string, password: string) {
    const firebaseUser = this.getCurrentUser();
    if (!firebaseUser) throw new Error("Firebase user not found");
    return reauthenticateWithCredential(firebaseUser, EmailAuthProvider.credential(email, password));
  },
};
