import { CaseMessage, CaseMessageType, LexZapChat, SnippetTypes } from "@/hooks/lexZap/types";
import { FlattenedChat, SenderType } from "../types";
import { CompanyMember } from "@/hooks/company/types";

/**
 * Determines the sender type of a message
 */
export const getSenderType = (message: CaseMessage): SenderType => {
  if (message.fromAssistant) {
    return SenderType.ASSISTANT;
  } else if (message.fromLawyer) {
    return SenderType.LAWYER;
  } else if (message.applicantName) {
    return SenderType.APPLICANT;
  }
  return SenderType.UNKNOWN;
};

/**
 * Formats the display text for different message types
 */
export const getMessageDisplayText = (message: CaseMessage): string => {
  switch (message.type) {
    case CaseMessageType.TEXT:
      return message.body;
    case CaseMessageType.AUDIO:
      return message.transcription ? "🎵 Áudio: " + message.transcription : "🎵 Áudio";
    case CaseMessageType.IMAGE:
      return message.body ? "📷 Imagem: " + message.body : "📷 Imagem";
    case CaseMessageType.DOCUMENT:
      return `📄 Documento: ${message.media?.filename || "Sem nome"}`;
    case CaseMessageType.VIDEO:
      return message.body ? "🎥 Vídeo: " + message.body : "🎥 Vídeo";
    case CaseMessageType.STICKER:
      return "🏷️ Sticker";
    case CaseMessageType.CONTACTS:
      return `👥 Contatos: ${message.contacts?.map((c) => c.name).join(", ")}`;
    case CaseMessageType.LOCATION:
      return "📍 Localização";
    case CaseMessageType.SNIPPET:
      if (message.snippetInfo?.type === SnippetTypes.TICKET_CLOSED) {
        let closedByText = "";
        if (message.snippetInfo.closedByAI) {
          closedByText = " pelo assistente";
        } else if (message.snippetInfo.closedBy) {
          closedByText = " por " + message.snippetInfo.closedBy;
        }
        return "📝 Ticket fechado" + closedByText;
      }
      return "📝 Snippet";
    default:
      return message.body || "Mensagem sem conteúdo";
  }
};

/**
 * Checks if a phone number has an approval request
 */
export const hasApprovalRequest = (endClientNumber: string, requests: { applicant_phone_number: string }[]) => {
  return requests.some((request) => request.applicant_phone_number === endClientNumber);
};

/**
 * Standardizes chat data for the table view
 */
export const standardizeChatsData = (
  rawChats: LexZapChat[],
  companyUsers: CompanyMember[] | undefined,
  requests: { applicant_phone_number: string }[]
): FlattenedChat[] => {
  // Criar um Map para cache de usuários para evitar múltiplas buscas
  const userNameMap = new Map<string, string>();

  const getUserName = (userId: string): string => {
    if (!userNameMap.has(userId)) {
      userNameMap.set(userId, companyUsers?.find((user) => user.userId === userId)?.name || "");
    }
    return userNameMap.get(userId) || "";
  };

  // Criar um Set para otimizar a busca de approval requests
  const approvalRequestSet = new Set(requests.map((request) => request.applicant_phone_number));

  return rawChats.map((chat) => ({
    endClientNumber: chat.endClientNumber,
    status: chat.status,
    createdAt: chat.createdAt,
    chatMode: chat.chatMode,
    applicantName: chat.applicant?.name || chat.applicantName || chat.endClientNumber,
    assignedUserIds: chat.assignedUserIds || [],
    assignedUserNames: chat.assignedUserIds?.map(getUserName) || [],
    leadStage: chat.leadStage || "",
    legalMatterType: chat.legalMatterType || "",
    caseNotes: chat.caseNotes || "",
    actionItems: chat.actionItems || "",
    lastMessageDate: chat.lastMessage?.timestamp || 0,
    lastMessageText: chat.lastMessage ? getMessageDisplayText(chat.lastMessage) : "",
    lastMessageSenderType: chat.lastMessage ? getSenderType(chat.lastMessage) : undefined,
    hasApprovalRequest: approvalRequestSet.has(chat.endClientNumber),
  }));
};

/**
 * Extracts unique lead stages from chats
 */
export const extractUniqueLeadStages = (chats: LexZapChat[]): string[] => {
  if (chats.length > 0) {
    return Array.from(new Set(chats.map((chat) => chat.leadStage).filter(Boolean) as string[]));
  }
  return [];
};
