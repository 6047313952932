import { Case, CaseMessage, LexZapChat, Ticket } from "@/hooks/lexZap/types";

export type WhatsappUpdate =
  | MessageReceivedUpdate
  | MessageChangedUpdate
  | CaseUpdate
  | ChatUpdate
  | TicketUpdate
  | ApplicantCreated;

export enum WhatsappUpdateType {
  MESSAGE_RECEIVED = "MESSAGE_RECEIVED",
  MESSAGE_CHANGED = "MESSAGE_CHANGED",
  CASE_UPDATE = "CASE_UPDATE",
  CHAT_UPDATE = "CHAT_UPDATE",
  TICKET_UPDATE = "TICKET_UPDATE",
  APPLICANT_CREATED = "APPLICANT_CREATED",
}

interface WhatsappUpdateBase {
  companyId: string;
  type: WhatsappUpdateType;
}

export interface MessageReceivedUpdate extends WhatsappUpdateBase {
  type: WhatsappUpdateType.MESSAGE_RECEIVED;
  message: CaseMessage;
  shouldPlaySound: boolean;
  requestId?: string;
}

interface MessageChangedUpdate extends WhatsappUpdateBase {
  type: WhatsappUpdateType.MESSAGE_CHANGED;
  message: CaseMessage;
}

interface ChatUpdate extends WhatsappUpdateBase {
  type: WhatsappUpdateType.CHAT_UPDATE;
  applicantPhoneNumber: string;
  updatedFields: Partial<LexZapChat>;
}

interface CaseUpdate extends WhatsappUpdateBase {
  type: WhatsappUpdateType.CASE_UPDATE;
  caseData: Case;
}

interface TicketUpdate extends WhatsappUpdateBase {
  type: WhatsappUpdateType.TICKET_UPDATE;
  applicantPhoneNumber: string;
  ticketId: string;
  updatedFields: Partial<Ticket>;
}

interface ApplicantCreated extends WhatsappUpdateBase {
  type: WhatsappUpdateType.APPLICANT_CREATED;
  applicantId: string;
}
