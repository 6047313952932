import { AxiosError } from "axios";
import { authenticatedWhatsappServer } from "@/core/api";
import * as types from "./types";
import { mockApplicantEvents, USE_MOCK } from "./mockData";

export const createApplicantEvent = async (params: types.CreateApplicantEventParams): Promise<types.ApplicantEvent> => {
  if (USE_MOCK) {
    const newEvent: types.ApplicantEvent = {
      ...params,
      eventId: `event-${Date.now()}`,
      createdAt: Date.now(),
    };
    mockApplicantEvents.push(newEvent);
    return newEvent;
  }

  try {
    const response = await authenticatedWhatsappServer.post<types.ApplicantEvent>("/external/applicant-events", params);
    return response.data;
  } catch (error) {
    throw new Error("Failed to create applicant event");
  }
};

export const getApplicantEvents = async (
  params: types.GetApplicantEventsParams
): Promise<types.PaginatedResponse<types.ApplicantEvent>> => {
  if (USE_MOCK) {
    let filteredEvents = [...mockApplicantEvents];

    if (params.companyId) {
      filteredEvents = filteredEvents.filter((event) => event.companyId === params.companyId);
    }

    if (params.applicantPhoneNumber) {
      filteredEvents = filteredEvents.filter((event) => event.applicantPhoneNumber === params.applicantPhoneNumber);
    }

    if (params.category) {
      filteredEvents = filteredEvents.filter((event) => event.category === params.category);
    }

    if (params.eventName) {
      filteredEvents = filteredEvents.filter((event) => event.eventName.includes(params.eventName!));
    }

    if (params.startTime) {
      filteredEvents = filteredEvents.filter((event) => event.createdAt >= params.startTime!);
    }

    if (params.endTime) {
      filteredEvents = filteredEvents.filter((event) => event.createdAt <= params.endTime!);
    }

    let result = filteredEvents;
    if (params.limit) {
      result = filteredEvents.slice(0, params.limit);
    }

    return {
      items: result,
      count: filteredEvents.length,
      lastEvaluatedKey:
        result.length < filteredEvents.length && result.length > 0
          ? { lastId: result[result.length - 1]?.eventId }
          : undefined,
    };
  }

  try {
    const response = await authenticatedWhatsappServer.get<types.PaginatedResponse<types.ApplicantEvent>>(
      "/external/applicant-events",
      {
        params: params,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to retrieve applicant events");
  }
};

export const getApplicantEventById = async (
  params: types.GetApplicantEventByIdParams
): Promise<types.ApplicantEvent | null> => {
  if (USE_MOCK) {
    const event = mockApplicantEvents.find(
      (e) =>
        e.eventId === params.eventId &&
        e.companyId === params.companyId &&
        e.applicantPhoneNumber === params.applicantPhoneNumber
    );
    return event || null;
  }

  try {
    const response = await authenticatedWhatsappServer.get<types.ApplicantEvent>(
      `/external/applicant-events/${params.applicantPhoneNumber}/${params.eventId}`
    );
    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 404) {
      return null;
    }
    throw new Error("Failed to retrieve applicant event");
  }
};

export const getApplicantEventsByCategory = async (
  params: types.GetApplicantEventsByCategoryParams
): Promise<types.PaginatedResponse<types.ApplicantEvent>> => {
  if (USE_MOCK) {
    let filteredEvents = mockApplicantEvents.filter(
      (event) => event.companyId === params.companyId && event.category === params.category
    );

    if (params.startTime) {
      filteredEvents = filteredEvents.filter((event) => event.createdAt >= params.startTime!);
    }

    if (params.endTime) {
      filteredEvents = filteredEvents.filter((event) => event.createdAt <= params.endTime!);
    }

    let result = filteredEvents;
    if (params.limit) {
      result = filteredEvents.slice(0, params.limit);
    }

    return {
      items: result,
      count: filteredEvents.length,
      lastEvaluatedKey:
        result.length < filteredEvents.length && result.length > 0
          ? { lastId: result[result.length - 1]?.eventId }
          : undefined,
    };
  }

  try {
    const response = await authenticatedWhatsappServer.get<types.PaginatedResponse<types.ApplicantEvent>>(
      `/external/applicant-events/category/${params.category}`,
      {
        params: {
          startTime: params.startTime,
          endTime: params.endTime,
          limit: params.limit,
          exclusiveStartKey: params.exclusiveStartKey,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to retrieve applicant events by category");
  }
};

export const updateApplicantEvent = async (params: types.UpdateApplicantEventParams): Promise<void> => {
  if (USE_MOCK) {
    const eventIndex = mockApplicantEvents.findIndex(
      (e) =>
        e.eventId === params.eventId &&
        e.companyId === params.companyId &&
        e.applicantPhoneNumber === params.applicantPhoneNumber
    );

    if (eventIndex === -1) {
      throw new Error("Applicant event not found");
    }

    // Update the event with type safety
    mockApplicantEvents[eventIndex] = {
      ...mockApplicantEvents[eventIndex],
      ...params.updates,
    } as types.ApplicantEvent;
    return;
  }

  try {
    await authenticatedWhatsappServer.patch(
      `/external/applicant-events/${params.applicantPhoneNumber}/${params.eventId}`,
      params.updates
    );
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 404) {
      throw new Error("Applicant event not found");
    }
    throw new Error("Failed to update applicant event");
  }
};

export const deleteApplicantEvent = async (params: types.DeleteApplicantEventParams): Promise<void> => {
  if (USE_MOCK) {
    const eventIndex = mockApplicantEvents.findIndex(
      (e) =>
        e.eventId === params.eventId &&
        e.companyId === params.companyId &&
        e.applicantPhoneNumber === params.applicantPhoneNumber
    );

    if (eventIndex === -1) {
      throw new Error("Applicant event not found");
    }

    mockApplicantEvents.splice(eventIndex, 1);
    return;
  }

  try {
    await authenticatedWhatsappServer.delete(
      `/external/applicant-events/${params.applicantPhoneNumber}/${params.eventId}`
    );
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 404) {
      throw new Error("Applicant event not found");
    }
    throw new Error("Failed to delete applicant event");
  }
};
