import { useAuthContext } from "@/contexts/AuthContext";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useLexZapCompany } from "@/hooks/lexZap/useLexZapCompany";
import { usePermissions } from "@/hooks/usePermissions";

export interface MenuOption {
  label: string;
  options: {
    label: string;
    path?: string;
    withPlanChip?: boolean;
    openBilling?: boolean;
    replaceRoute?: boolean;
    tooltip?: string;
    hidden?: boolean;
    blockedAction?: boolean;
  }[];
}

export const useMenuOptions = (): { menuOptions: MenuOption[] } => {
  const { user } = useAuthContext();
  const flags = useFeatureFlags();
  const { data: connectionData } = useLexZapCompany();
  const {
    hasManagePlansPermission,
    hasManageUsersPermission,
    hasManageLegalProceedingsPermission,
    hasCustomerServiceFullAccessPermission,
  } = usePermissions();
  const showCredits = !flags.planScreenV2Enabled;
  const hasWhatsAppAssistant = !!connectionData?.phoneNumber;
  const shouldShowMyTeam = hasWhatsAppAssistant || flags.multipleUsers;

  const menuOptions: (MenuOption & { hidden?: boolean })[] = [
    {
      label: "Pessoal",
      options: [
        {
          label: "Meus Dados",
          path:
            hasWhatsAppAssistant && hasCustomerServiceFullAccessPermission
              ? ROUTE_PATHS.CLIENT_CONFIG_MY_DATA_WHATSAPP
              : ROUTE_PATHS.CLIENT_CONFIG_MY_DATA,
          replaceRoute: true,
          tooltip: "Altere seu e-mail de acesso à plataforma",
        },
        { label: "Alterar Senha", path: ROUTE_PATHS.CLIENT_CONFIG_CHANGE_PASSWORD, replaceRoute: true },
      ],
    },
    {
      label: "Organização",
      options: [
        { label: "Plano e cobranças", withPlanChip: true, openBilling: true, blockedAction: !hasManagePlansPermission },
        {
          label: "Gestão de colaboradores",
          path: ROUTE_PATHS.CLIENT_CONFIG_MY_TEAM,
          hidden: !shouldShowMyTeam,
          blockedAction: !hasManageUsersPermission,
        },
        {
          label: "Casos e processos",
          path: ROUTE_PATHS.LEGAL_PROCEEDINGS_MONITORING,
          hidden: !flags.legalProceedingsPageEnabled,
          blockedAction: !hasManageLegalProceedingsPermission,
        },
        ...(showCredits ? [{ label: "Histórico de uso", path: ROUTE_PATHS.CREDITS_HISTORY }] : []),
      ],
    },
    {
      label: "Admin",
      options: [
        { label: "Usuários do Assistente WhatsApp", path: ROUTE_PATHS.WHATSAPP_USERS },
        {
          label: "Eventos Não Processados do WhatsApp",
          path: ROUTE_PATHS.WHATSAPP_DLQ_EVENTS,
        },
        {
          label: "Agentes Internos",
          path: ROUTE_PATHS.INTERNAL_AGENTS,
        },
        {
          label: "Solicitações de Aprovação",
          path: ROUTE_PATHS.APPROVAL_REQUESTS,
        },
        {
          label: "Eventos de Requerentes",
          path: ROUTE_PATHS.APPLICANT_EVENTS,
        },
      ],
      hidden: !user?.isAdmin,
    },
  ];

  return {
    menuOptions: menuOptions.filter((option) => !option.hidden),
  };
};
