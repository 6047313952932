import { logger } from "@/core/logger";
import { LegalProceedingService } from "@/services/legalProceeding";
import { queryClient } from "@/utils/queryClient";
import { useMutation } from "@tanstack/react-query";
import { getCaseLegalProceedingsFilesQueryKey } from "@/hooks/legalProceedings/useCaseLegalProceedingsFiles";
import { getCaseLegalProceedingsQueryKey } from "@/hooks/legalProceedings/useCaseLegalProceedingsQuery";

export const useAttachLegalProceedingToCaseMutation = () => {
  const api = LegalProceedingService;

  return useMutation({
    mutationKey: ["attachLegalProceedingToCase"],
    mutationFn: async ({ cnj, caseId }: { cnj: string; caseId: string }) => {
      return await api.attachLegalProceedingToCase({ cnj, caseId });
    },
    onSuccess: async (_, { caseId }) => {
      await queryClient.invalidateQueries({ queryKey: getCaseLegalProceedingsQueryKey(caseId) });
      await queryClient.invalidateQueries({ queryKey: getCaseLegalProceedingsFilesQueryKey(caseId) });
    },
    onError: (error) => {
      logger.error(`Error attaching legal proceeding to case: ${error.message}`, { error });
    },
  });
};
