import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Case, CaseStatus } from "../types";
import { ReasonForCaseArchive } from "@/services/case/types";
import { getCaseQueryKey } from "../useLexZapCase";

export const useArchiveCases = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["archiveCases"],
    mutationFn: ({
      casesIds,
      reasonType,
      reasonDetails,
    }: {
      casesIds: string[];
      reasonType?: ReasonForCaseArchive;
      reasonDetails?: string;
    }) => Promise.all(casesIds.map((caseId) => api.archiveCase({ caseId, reasonType, reasonDetails }))),
    onMutate: async ({ casesIds, reasonType, reasonDetails }) => {
      const previousCases = casesIds.map((caseId) => queryClient.getQueryData<Case>(getCaseQueryKey(caseId)));

      casesIds.forEach((caseId) => {
        queryClient.setQueryData<Case>(getCaseQueryKey(caseId), (old) => {
          if (!old) return old;
          return {
            ...old,
            status: CaseStatus.ARCHIVED,
            timeline: [
              ...old.timeline,
              {
                status: CaseStatus.ARCHIVED,
                createdAt: new Date().toISOString(),
                id: old.timeline.length + 1,
                reasonType,
                reasonDetails,
              },
            ],
          };
        });
      });

      return { previousCases };
    },
    onError: (_error, { casesIds }, context) => {
      context?.previousCases.forEach((previousCase, index) => {
        if (previousCase) {
          queryClient.setQueryData<Case>(getCaseQueryKey(casesIds[index]), previousCase);
        }
      });
    },
  });
};
