import { LoggedInUserData } from "@/contexts/AuthContext";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";

export const useIdentifyUserForFlags = (user?: LoggedInUserData) => {
  const ldClient = useLDClient();

  useEffect(() => {
    if (user && ldClient) {
      void ldClient.identify({
        kind: "user",
        key: user.userId,
        email: user.userEmail,
        name: user.userName,
        companyId: user.companyId,
      });
    }
  }, [user, ldClient]);
};
