import { getCaseQueryKey } from "@/hooks/lexZap/useLexZapCase";
import { CaseService } from "@/services/case";
import { useQuery } from "@tanstack/react-query";

export const getCaseLegalProceedingsFilesQueryKey = (caseId?: string) => [...getCaseQueryKey(caseId), "files"];

export const useCaseLegalProceedingsFiles = ({ caseId }: { caseId?: string }) => {
  return useQuery({
    queryKey: getCaseLegalProceedingsFilesQueryKey(caseId),
    queryFn: async () => {
      if (!caseId) {
        return [];
      }
      return await CaseService.getCaseLegalProceedingsFiles(caseId);
    },
    enabled: !!caseId,
    retry: false,
    refetchOnWindowFocus: false,
  });
};
