import React, { useEffect, useMemo, useState, useRef } from "react";
import { Box, Collapse } from "@mui/material";
import { useSearchParams } from "react-router-dom";

// Core components
import { PageLayout } from "@/components/PageLayout";
import { Resizer } from "@/components/EditorPageLayout/components/Resizer";

// Hooks
import { useApprovalManagement } from "@/hooks/useApprovalRequests/useApprovalRequests";
import { useResizable } from "@/components/EditorPageLayout/useResizable";
import { useDebouncedCallback } from "@/hooks/useDebouncedCallback";
import { useChat } from "../../hooks/useChat";

// Chat components
import { Chat } from "../ChatView/components/Chat";
import { ChatHistory } from "../ChatView/components/ChatHistory";
import { ApprovalRequest } from "../ChatView/components/ApprovalRequest";
import { Checklist } from "../ChatView/components/Checklist";
import { ChatDetails } from "../ChatView/components/ChatDetails";

// Utils and constants
import * as styles from "./styles";

export const WhatsappAssistantChatPage = () => {
  const [searchParams] = useSearchParams();
  const phoneNumber = searchParams.get("phoneNumber");

  // Fetch chat using useChat hook
  const { chat, loading: isLoadingChat } = useChat(phoneNumber || "");

  // For approval requests
  const { fetchRequestsByPhoneNumber, applicantRequests } = useApprovalManagement();
  const [isApprovalRequestOpen, setIsApprovalRequestOpen] = useState(false);
  const [isChatHistoryOpen, setIsChatHistoryOpen] = useState(false);
  const [isChecklistOpen, setIsChecklistOpen] = useState(false);
  const [isChatDetailsOpen, setIsChatDetailsOpen] = useState(false);
  const [editedMessages, setEditedMessages] = useState<Record<string, string>>({});

  // For resizable panels
  const [chatHistoryWidth, setChatHistoryWidth] = useState(300);
  const debouncedSetChatHistoryWidth = useDebouncedCallback(setChatHistoryWidth, 1000);
  const chatHistoryWidthRef = useRef<HTMLDivElement>(null);

  const {
    containerRef: mainContainerRef,
    handleMouseDown: handlePanelResize,
    isResizing: isResizingPanel,
  } = useResizable({
    defaultWidth: 450,
    minWidth: 200,
    minOppositeWidth: 500,
    direction: "right",
    onWidthChange: (width) => {
      if (chatHistoryWidthRef.current) {
        chatHistoryWidthRef.current.style.width = `${width}px`;
      }
      debouncedSetChatHistoryWidth(width);
    },
  });

  // Fetch approval requests for this phone number
  useEffect(() => {
    if (phoneNumber) {
      void fetchRequestsByPhoneNumber(phoneNumber);
    }
  }, [phoneNumber, fetchRequestsByPhoneNumber]);

  // Get approval requests for this chat from the applicantRequests object
  const currentApprovalRequests = useMemo(() => {
    if (!phoneNumber) return [];
    return applicantRequests[phoneNumber] || [];
  }, [applicantRequests, phoneNumber]);

  // Set initial panel state based on approval requests
  useEffect(() => {
    if (currentApprovalRequests.length > 0) {
      setIsApprovalRequestOpen(true);
      setIsChatDetailsOpen(false);
      setIsChatHistoryOpen(false);
      setIsChecklistOpen(false);
    } else {
      setIsChatDetailsOpen(true);
      setIsApprovalRequestOpen(false);
      setIsChatHistoryOpen(false);
      setIsChecklistOpen(false);
    }
  }, [currentApprovalRequests.length]);

  // Toggle panel handlers
  const createToggleHandler = (
    setter: React.Dispatch<React.SetStateAction<boolean>>,
    otherSetters: Array<React.Dispatch<React.SetStateAction<boolean>>>
  ) => {
    return () => {
      setter((prev) => {
        const newValue = !prev;
        if (newValue) {
          // Close other panels
          otherSetters.forEach((setterFn) => setterFn(false));
        }
        return newValue;
      });
    };
  };

  const handleToggleHistory = createToggleHandler(setIsChatHistoryOpen, [
    setIsApprovalRequestOpen,
    setIsChecklistOpen,
    setIsChatDetailsOpen,
  ]);

  const handleToggleApprovalRequest = createToggleHandler(setIsApprovalRequestOpen, [
    setIsChatHistoryOpen,
    setIsChecklistOpen,
    setIsChatDetailsOpen,
  ]);

  const handleToggleChecklist = createToggleHandler(setIsChecklistOpen, [
    setIsChatHistoryOpen,
    setIsApprovalRequestOpen,
    setIsChatDetailsOpen,
  ]);

  const handleToggleChatDetails = createToggleHandler(setIsChatDetailsOpen, [
    setIsChatHistoryOpen,
    setIsApprovalRequestOpen,
    setIsChecklistOpen,
  ]);

  const handleMessageEdit = (requestId: string, message: string) => {
    setEditedMessages((prev) => ({
      ...prev,
      [requestId]: message,
    }));
  };

  // Handle approval request changes (approve, reject, request changes)
  const handleApprovalRequestChange = () => {
    if (phoneNumber) {
      void fetchRequestsByPhoneNumber(phoneNumber);
    }
  };

  const renderSidePanel = () => {
    if (!chat) return null;

    const isPanelOpen = isChatHistoryOpen || isApprovalRequestOpen || isChecklistOpen || isChatDetailsOpen;

    return (
      <Collapse
        in={isPanelOpen}
        orientation="horizontal"
        easing={{ enter: "linear", exit: "linear" }}
        sx={styles.sidePanelCollapseSx}
      >
        {isPanelOpen && (
          <Resizer onMouseDown={handlePanelResize} isResizing={isResizingPanel} anchor="left" sx={{ left: "3px" }} />
        )}

        <Box
          ref={chatHistoryWidthRef}
          sx={{
            ...styles.sidePanelContainerSx,
            width: chatHistoryWidth,
          }}
        >
          {isChatHistoryOpen && (
            <Box sx={styles.sideBoxSx}>
              <ChatHistory chat={chat} onClose={() => setIsChatHistoryOpen(false)} />
            </Box>
          )}

          {isApprovalRequestOpen && (
            <Box sx={styles.sideBoxSx}>
              <ApprovalRequest
                onClose={() => setIsApprovalRequestOpen(false)}
                requests={currentApprovalRequests}
                editedMessages={editedMessages}
                onMessageEdit={handleMessageEdit}
                onRequestChange={handleApprovalRequestChange}
              />
            </Box>
          )}

          {isChecklistOpen && (
            <Box sx={styles.sideBoxSx}>
              <Checklist chat={chat} onClose={() => setIsChecklistOpen(false)} />
            </Box>
          )}

          {isChatDetailsOpen && (
            <Box sx={styles.sideBoxSx}>
              <ChatDetails chat={chat} onClose={() => setIsChatDetailsOpen(false)} />
            </Box>
          )}
        </Box>
      </Collapse>
    );
  };

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
        fullWidth: true,
      }}
      contentSx={{
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
      variant="secondary"
    >
      <Box sx={styles.mainContainerSx} ref={mainContainerRef}>
        <Box sx={styles.chatContainerSx}>
          <Box sx={styles.chatWrapperSx}>
            <Chat
              activeChat={chat}
              allChats={chat ? [chat] : []}
              isLoadingChats={isLoadingChat}
              onToggleHistory={handleToggleHistory}
              onToggleApprovalRequest={handleToggleApprovalRequest}
              onToggleChecklist={handleToggleChecklist}
              onToggleChatDetails={handleToggleChatDetails}
              requests={currentApprovalRequests}
            />
          </Box>
        </Box>

        {renderSidePanel()}
      </Box>
    </PageLayout>
  );
};

export default WhatsappAssistantChatPage;
