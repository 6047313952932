import React, { useMemo, useState } from "react";
import { Box, Chip, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import { PageLayout } from "@/components/PageLayout";
import { Button } from "@/components/Button";
import { Delete, Edit, SearchRounded as SearchRoundedIcon, MailOutline as MailOutlineIcon } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import { useCompanyTeam } from "@/hooks/company/useCompanyTeam";
import { useAuthContext } from "@/contexts/AuthContext";
import { Phone } from "@/core/Phone";
import { CompanyMember, Department, getActivityAreaLabel } from "@/hooks/company/types";
import { EditMemberModal } from "./components/editMemberModal";
import { NewMemberModal } from "./components/NewMemberModal";
import { DeleteMemberModal } from "./components/deleteMemberModal";
import { ResendEmailModal } from "./components/resendEmailModal";
import { usePaywallContext } from "@/contexts/PaywallContext";
import { usePlanChips } from "@/hooks/usePlanChips";

export const MyTeam = () => {
  const { openUsagePaywall } = usePaywallContext();
  const { isUsersQuotaReached } = usePlanChips();
  const { data, isLoading } = useCompanyTeam();
  const { user } = useAuthContext();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  const [search, setSearch] = useState("");
  const [showResendInviteModal, setShowResendInviteModal] = useState(false);
  const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);
  const [showNewMemberModal, setShowNewMemberModal] = useState(false);
  const [showEditMemberModal, setShowEditMemberModal] = useState(false);
  const companyTeam = useMemo(() => {
    return data?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())) || [];
  }, [search, data]);
  const [selectedMember, setSelectedMember] = useState<CompanyMember | null>(null);

  const openResendInviteModal = (member: CompanyMember) => {
    setShowResendInviteModal(true);
    setSelectedMember(member);
  };

  const closeResendInviteModal = () => {
    setShowResendInviteModal(false);
    setSelectedMember(null);
  };

  const handleResendEmail = () => {
    closeResendInviteModal();
  };

  const openDeleteMemberModal = (member: CompanyMember) => {
    setShowDeleteMemberModal(true);
    setSelectedMember(member);
  };

  const closeDeleteMemberModal = () => {
    setShowDeleteMemberModal(false);
    setSelectedMember(null);
  };

  const handleDeleteMember = () => {
    closeDeleteMemberModal();
  };

  const openNewMemberModal = () => {
    if (isUsersQuotaReached) {
      openUsagePaywall();
    } else {
      setShowNewMemberModal(true);
    }
  };

  const closeNewMemberModal = () => {
    setShowNewMemberModal(false);
  };

  const openEditMemberModal = (member: CompanyMember) => {
    setSelectedMember(member);
    setShowEditMemberModal(true);
  };

  const closeEditMemberModal = () => {
    setShowEditMemberModal(false);
    setSelectedMember(null);
  };

  return (
    <PageLayout
      contentSx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "start",
        paddingX: 2,
        paddingY: 2,
      }}
      contentProps={{
        disablePadding: true,
      }}
      headerProps={{
        extraContent: (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingX: 4,
              gap: 1,
              paddingY: 2,
              backgroundColor: "common.dorian",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "60% 40%",
                gridAutoFlow: "column",
                justifyContent: "start",
                alignItems: "center",
                gap: 1,
                width: "100%",
              }}
            >
              <Box>
                <Typography variant="pageTitle">Gestão de colaboradores</Typography>
                <Typography variant="body1" color="#4A4A68" sx={{ fontSize: "16px" }}>
                  Aqui estão todas as informações sobre os seus colegas de trabalho. Apenas o Administrador pode alterar
                  as informações de outros usuários e convidar colaboradores.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Button variant="contained" onClick={openNewMemberModal}>
                  <Typography>Convidar colaborador</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        ),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "common.white",
        }}
      >
        <Box
          sx={{
            paddingTop: 2,
            paddingLeft: 2,
          }}
        >
          <TextField
            label="Buscar colaborador"
            variant="outlined"
            size="medium"
            placeholder="Pesquisar por nome do colaborador"
            sx={{
              label: {
                fontSize: "14px",
              },
              fontSize: "14px",
              width: "50%",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRoundedIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Box>
        <DataGrid
          sx={{
            width: "100%",
            height: "100%",
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          getRowId={(row) => row.userId}
          loading={isLoading}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          columns={[
            {
              field: "name",
              headerName: "Nome",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
            },
            {
              field: "email",
              headerName: "Email",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
            },
            {
              field: "notificationNumber",
              headerName: "Número do whatsapp",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              renderCell: ({ value }) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {value ? (
                      Phone.new(value.substring(2)).isSuccess ? (
                        <Typography variant="body1" color="text.primary">
                          {Phone.new(value.substring(2)).getValue().toFormatSpaced()}
                        </Typography>
                      ) : (
                        <Typography variant="body1" color="text.secondary">
                          {value}
                        </Typography>
                      )
                    ) : (
                      <Typography variant="body1" color="text.secondary">
                        -
                      </Typography>
                    )}
                  </Box>
                );
              },
            },
            {
              field: "activityAreas",
              headerName: "Área de atuação",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              renderCell: ({ row: member }) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {member.activityAreas?.length ? (
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        variant="body1"
                        color="text.primary"
                      >
                        {member.activityAreas.map((value) => getActivityAreaLabel(value)).join(", ")}
                      </Typography>
                    ) : (
                      <Typography variant="body1" color="text.secondary">
                        -
                      </Typography>
                    )}
                  </Box>
                );
              },
            },
            {
              field: "department",
              headerName: "Setor",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              renderCell: ({ value }) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {value ? (
                    <DepartmentTag department={value} />
                  ) : (
                    <Typography variant="body1" color="text.secondary">
                      -
                    </Typography>
                  )}
                </Box>
              ),
            },
            {
              field: "isActive",
              headerName: "Status",
              flex: 1,
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              renderCell: ({ value }) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontStyle: value ? "normal" : "italic",
                        color: value ? "common.black" : "text.disabled",
                      }}
                    >
                      {value ? "Ativo" : "Convite enviado"}
                    </Typography>
                  </Box>
                );
              },
            },
            {
              field: "actions",
              type: "actions",
              flex: 1,
              getActions: ({ row: member }) => {
                return member.isActive
                  ? [
                      <GridActionsCellItem
                        key={member.userId}
                        icon={<Edit />}
                        label="Editar"
                        onClick={() => openEditMemberModal(member)}
                      />,
                      <GridActionsCellItem
                        key={member.userId}
                        icon={<Delete />}
                        label="Deletar"
                        disabled={member.userId === user?.userId || member.isOwner}
                        onClick={() => openDeleteMemberModal(member)}
                      />,
                    ]
                  : [
                      <GridActionsCellItem
                        key={member.userId}
                        icon={
                          <Tooltip title="Reenviar convite" placement="top">
                            <MailOutlineIcon />
                          </Tooltip>
                        }
                        label=""
                        onClick={() => openResendInviteModal(member)}
                      />,
                      <GridActionsCellItem
                        key={member.userId}
                        icon={<Delete />}
                        label="Deletar"
                        onClick={() => openDeleteMemberModal(member)}
                      />,
                    ];
              },
            },
          ]}
          rows={companyTeam}
          rowSelection={false}
          hideFooterPagination={false}
        />
        {selectedMember && (
          <ResendEmailModal
            isOpen={showResendInviteModal}
            onConfirm={handleResendEmail}
            onCancel={closeResendInviteModal}
            isLoading={false}
            memberId={selectedMember.userId}
            email={selectedMember.email}
          />
        )}

        {selectedMember && (
          <DeleteMemberModal
            isOpen={showDeleteMemberModal}
            onConfirm={handleDeleteMember}
            onCancel={closeDeleteMemberModal}
            memberId={selectedMember.userId}
          />
        )}
      </Box>

      {showEditMemberModal && selectedMember && (
        <EditMemberModal onClose={closeEditMemberModal} memberId={selectedMember.userId} />
      )}

      {showNewMemberModal && <NewMemberModal onClose={closeNewMemberModal} />}
    </PageLayout>
  );
};

const DepartmentTag = ({ department }: { department: Department }) => {
  switch (department) {
    case Department.ADMINISTRATIVE:
      return (
        <Chip
          label="Administrativo"
          sx={{
            backgroundColor: "secondary.main",
          }}
        />
      );
    case Department.FINANCIAL:
      return (
        <Chip
          label="Financeiro"
          sx={{
            backgroundColor: "warning.main",
          }}
        />
      );
    case Department.LEGAL:
      return (
        <Chip
          label="Jurídico"
          sx={{
            backgroundColor: "info.main",
          }}
        />
      );
  }
};
