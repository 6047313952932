import { authenticatedCopilotServer } from "@/core/api";
import { Paginated } from "@/core/api/types";
import { getPaginationHeaders } from "@/core/api/utils/getPaginationHeaders";
import { Applicant, ApplicantPayload } from "@/services/applicant/types";

export const ApplicantService = {
  async getApplicants() {
    const pageSize = 1000;
    let allApplicants: Applicant[] = [];

    const {
      data: { data: firstPageData, totalResults },
    } = await authenticatedCopilotServer.get<Paginated<Applicant>>("/applicants/paginated", {
      headers: {
        ...getPaginationHeaders({ page: 1, pageSize }),
      },
    });

    allApplicants = [...firstPageData];

    const totalPages = Math.ceil(totalResults / pageSize);

    for (let page = 2; page <= totalPages; page++) {
      const {
        data: { data },
      } = await authenticatedCopilotServer.get<Paginated<Applicant>>("/applicants/paginated", {
        headers: {
          ...getPaginationHeaders({ page, pageSize }),
        },
      });

      allApplicants = [...allApplicants, ...data];
    }

    return allApplicants;
  },

  async createApplicant({ data }: { data: ApplicantPayload }) {
    const response = await authenticatedCopilotServer.post<{ id: string }>(`/applicants`, data);
    return response.data;
  },

  async createApplicants({ data }: { data: ApplicantPayload[] }) {
    const response = await authenticatedCopilotServer.post<{ id: string; phoneNumber: string }[]>(
      `/applicants/multiple`,
      data
    );
    return response.data;
  },

  async updateApplicant({ applicantId, data }: { applicantId: string; data: ApplicantPayload }) {
    const response = await authenticatedCopilotServer.post<{ id: string }>(`/applicants/${applicantId}`, data);
    return response.data;
  },

  async getApplicantByPhoneNumber(phoneNumber: string) {
    const response = await authenticatedCopilotServer.get<Applicant[]>(`/applicants?phoneNumber=${phoneNumber}`);
    return response.data[0];
  },

  async getApplicantById(applicantId: string) {
    const response = await authenticatedCopilotServer.get<Applicant>(`/applicants/${applicantId}`);
    return response.data;
  },
};
