import React from "react";
import { CompanyMember } from "@/hooks/company/types";
import { ChatMode, ChatStatus } from "@/services/whatsappServer/types/chat";

export interface QuickFilter {
  key: string;
  label: string;
  enabled: boolean;
  count?: number;
}

export interface QuickFiltersProps {
  filters: QuickFilter[];
  onFilterChange: (key: string) => void;
  chatFilters: ChatFilters;
  setChatFilters: React.Dispatch<React.SetStateAction<ChatFilters>>;
  companyTeam?: CompanyMember[];
  availableLeadStages?: string[];
}

export const DEFAULT_FILTERS: QuickFilter[] = [
  { key: "active", label: "Conversas ativas", enabled: true },
  { key: "archived", label: "Arquivados", enabled: false },
  { key: "approvals", label: "Aprovações pendentes", enabled: false },
  { key: "withResponsible", label: "Atribuidos", enabled: false },
  { key: "withoutResponsible", label: "Não atribuidos", enabled: false },
  { key: "myResponsibility", label: "Seus atendimentos", enabled: false },
];

export interface ChatFilters {
  responsibles: CompanyMember[];
  excludeResponsibles: CompanyMember[]; // Novo campo para filtro por exclusão
  leadStages: string[];
  chatModes: ChatMode[];
  statuses: ChatStatus[];
}

export interface ResponsibleFilterProps {
  chatFilters: ChatFilters;
  setChatFilters: React.Dispatch<React.SetStateAction<ChatFilters>>;
  companyTeam: CompanyMember[];
}

export interface ExcludeResponsibleFilterProps {
  chatFilters: ChatFilters;
  setChatFilters: React.Dispatch<React.SetStateAction<ChatFilters>>;
  companyTeam: CompanyMember[];
}

export interface LeadStageFilterProps {
  chatFilters: ChatFilters;
  setChatFilters: React.Dispatch<React.SetStateAction<ChatFilters>>;
  availableLeadStages?: string[];
}

export interface ChatModeFilterProps {
  chatFilters: ChatFilters;
  setChatFilters: React.Dispatch<React.SetStateAction<ChatFilters>>;
}

export interface StatusFilterProps {
  chatFilters: ChatFilters;
  setChatFilters: React.Dispatch<React.SetStateAction<ChatFilters>>;
}
