import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, TextField, Typography, IconButton } from "@mui/material";
import { Balance, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/Button";
import LexterLogo from "@/assets/svgs/lexter-logo.svg?react";
import { RoundedIcon } from "@/components/RoundedIcon";
import AuthorizeCourtCredentialWithCheckCircle from "@/assets/svgs/authorizeCourtCredentialWithCheckCircle.svg?react";
import { CourtCredentialPayload, CourtCredentialType } from "@/services/legalProceeding/types";
import { logger } from "@/core/logger";
import { WebToast } from "@/components/core/Toast";
import { useAddCourtCredential } from "@/hooks/courtCredentials/useAddCourtCredential";
import { useUpdateCourtCredential } from "@/hooks/courtCredentials/useUpdateCourtCredential";
import { useAuthContext } from "@/contexts/AuthContext";

const authorizeAccessSchema = z.object({
  login: z.string().min(1, "Campo obrigatório"),
  password: z.string().min(1, "Campo obrigatório"),
  twoFactorSecret: z.string().optional(),
});

type AuthorizeAccessSchema = z.infer<typeof authorizeAccessSchema>;

type AuthorizeAccessModalProps = {
  open: boolean;
  onClose: () => void;
  tjToAdd: CourtCredentialType[];
  selectedCourtCredential: CourtCredentialType | null;
  buttonText?: string;
  onSuccess?: () => void;
};

export const AuthorizeAccessModal = ({
  open,
  onClose,
  tjToAdd,
  selectedCourtCredential,
  buttonText,
  onSuccess,
}: AuthorizeAccessModalProps) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const { mutateAsync: addCourtCredential } = useAddCourtCredential();
  const { mutateAsync: updateCourtCredential } = useUpdateCourtCredential();
  const { user } = useAuthContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<AuthorizeAccessSchema>({
    resolver: zodResolver(authorizeAccessSchema),
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleAuthorize = async (data: AuthorizeAccessSchema) => {
    try {
      await onAuthorize({
        username: data.login,
        password: data.password,
        twoFactorSecret: data.twoFactorSecret,
      });

      onSuccess?.();

      reset();
    } catch (error) {
      logger.error("Erro ao autorizar acesso", { error });
    }
  };

  const onAuthorize = async (data: CourtCredentialPayload) => {
    try {
      if (tjToAdd.some((tj) => tj.id === selectedCourtCredential?.id)) {
        await addCourtCredential({
          userId: user?.userId ?? "",
          credential: {
            tjName: selectedCourtCredential?.tj ?? "",
            ...data,
          },
        });
      } else if (selectedCourtCredential) {
        await updateCourtCredential({
          userId: user?.userId ?? "",
          credentialId: selectedCourtCredential.id,
          payload: {
            ...data,
          },
        });
      }

      handleClose();

      WebToast.success("Acesso autorizado com sucesso");
    } catch (error) {
      WebToast.error("Erro ao autorizar acesso");
      throw error;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Typography variant="h6" component="div" fontWeight={400}>
          Autorizar acesso
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            bgcolor: "orange.light.5",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 3,
            gap: 2,
            borderRadius: 2,
            mb: 2,
          }}
        >
          <RoundedIcon icon={<LexterLogo width="24px" />} bgcolor="primary.main" />
          <AuthorizeCourtCredentialWithCheckCircle />
          <RoundedIcon icon={<Balance />} />
        </Box>

        <Typography variant="subtitle1" sx={{ mb: 3 }}>
          Para visualizar o conteúdo completo dos processos em que você está envolvido neste tribunal, informe seus
          dados de acesso do{" "}
          <strong>
            <u>{selectedCourtCredential?.tj || (tjToAdd.length > 0 ? tjToAdd[0]!.tj : "")}</u>
          </strong>
          :
        </Typography>

        <Box onSubmit={handleSubmit(handleAuthorize)} component="form">
          <TextField
            fullWidth
            {...register("login")}
            label="Login"
            variant="outlined"
            error={!!errors.login}
            helperText={errors.login?.message || "Obrigatório"}
            sx={{ mb: 2 }}
            FormHelperTextProps={{
              sx: { fontSize: "12px" },
            }}
            InputLabelProps={{ shrink: undefined }}
          />

          <TextField
            fullWidth
            label="Senha"
            type={showPassword ? "text" : "password"}
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password?.message || "Obrigatório"}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </IconButton>
              ),
            }}
            FormHelperTextProps={{
              sx: { fontSize: "12px" },
            }}
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: undefined }}
          />

          <TextField
            fullWidth
            label="Token de autenticação"
            {...register("twoFactorSecret")}
            helperText="Opcional"
            sx={{ mb: 3 }}
            FormHelperTextProps={{
              sx: { fontSize: "12px" },
            }}
            InputLabelProps={{ shrink: undefined }}
          />

          <Box sx={{ display: "flex", gap: 1 }}>
            <Button size="small" onClick={onClose} sx={{ flex: 1 }}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              isLoading={isSubmitting}
              sx={{ flex: 1 }}
              loaderStyle={{ width: "181px" }}
            >
              {buttonText || "Autorizar acesso"}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
