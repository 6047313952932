import React from "react";

import { Permission } from "@/contexts/AuthContext";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { Navigate } from "react-router-dom";
import { usePermissions } from "@/hooks/usePermissions";

interface PermissionRouteProps {
  possiblePermissions: Permission[];
  element: React.JSX.Element;
  fallback?: React.JSX.Element;
}

/**
 * This component is used to check if the user has the required permissions to access the route.
 * If the user does not have the required permissions, the component will navigate to the fallback route.
 * @param possiblePermissions - The permissions required to access the route.
 * @param element - The component to render if the user has the required permissions.
 * @param fallback - The component to render if the user does not have the required permissions.
 * @returns
 */
export const PermissionedRoute = ({
  // We could add a callback instead to decouple the business logic from the component
  // I made it this way for faster development
  possiblePermissions,
  element,
  fallback = <Navigate to={ROUTE_PATHS.HOME} state={{ unauthorizedArea: true }} />,
}: PermissionRouteProps) => {
  const { hasPermission } = usePermissions();

  return possiblePermissions.some(hasPermission) ? element : fallback;
};
