import React from "react";
import { IconButton, CircularProgress, Box, Typography, Card, CardContent, Grid } from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { TagWithMetadata } from "@/services/internalAgents/types";
import { TagChip } from "./TagChip";
import { useQueries } from "@tanstack/react-query";
import { InternalAgentsService } from "@/services/internalAgents";

interface TagListProps {
  tags: TagWithMetadata[];
  loading: boolean;
  onEdit: (tag: TagWithMetadata) => void;
  onDelete: (tagName: string) => void;
  onSelect: (tag: TagWithMetadata) => void;
}

export const TagList: React.FC<TagListProps> = ({ tags, loading, onEdit, onDelete, onSelect }) => {
  const ticketQueries = useQueries({
    queries: tags.map((tag) => ({
      queryKey: ["tagTickets", tag.tag_id],
      queryFn: () => InternalAgentsService.ticketTags.getTicketsByTag(tag.tag_id),
      staleTime: 5 * 60 * 1000,
    })),
  });

  const renderTagMetadata = React.useCallback(
    (tag: TagWithMetadata, index: number) => {
      const ticketQuery = ticketQueries[index];
      if (!ticketQuery) return null;

      const tickets = ticketQuery.data ?? [];
      const totalTickets = tickets.length;
      const positiveTickets = tickets.filter((ticket) => ticket.value === true).length;
      const positivePercentage = totalTickets > 0 ? (positiveTickets / totalTickets) * 100 : 0;

      return (
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {totalTickets > 0 && (
            <>
              {positiveTickets}/{totalTickets} tickets
              <strong> ({positivePercentage.toFixed(0)}%)</strong>
              {" • "}
            </>
          )}
          {tag.category && `Categoria: ${tag.category}`}
        </Typography>
      );
    },
    [ticketQueries]
  );

  const handleEdit = React.useCallback(
    (tag: TagWithMetadata) => {
      onEdit(tag);
    },
    [onEdit]
  );

  const handleDelete = React.useCallback(
    (tagName: string) => {
      onDelete(tagName);
    },
    [onDelete]
  );

  const handleSelect = React.useCallback(
    (tag: TagWithMetadata) => {
      onSelect(tag);
    },
    [onSelect]
  );

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      {tags.map((tag, index) => (
        <Grid item xs={12} key={tag.tag_id}>
          <Card
            sx={{
              cursor: "pointer",
              "&:hover": { bgcolor: "action.hover" },
            }}
            onClick={() => handleSelect(tag)}
          >
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <TagChip tag={tag} />
                </Box>
                <Box onClick={(e) => e.stopPropagation()}>
                  <IconButton size="small" onClick={() => handleEdit(tag)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton size="small" color="error" onClick={() => handleDelete(tag.tag_name)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>

              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {tag.description}
              </Typography>

              {renderTagMetadata(tag, index)}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
