import { ApprovalFilters, ApprovalStatus } from "@/services/approvalRequests/types";

interface ApprovalFiltersHookProps {
  status: ApprovalStatus[];
  dateRange: [Date | null, Date | null];
  subagents: string[];
  searchText: string;
  reviewers: string[];
}

export const useApprovalFilters = (
  filters: ApprovalFiltersHookProps,
  onFilterChange: (filters: ApprovalFilters) => void
) => {
  const handleSearchTextChange = (text: string) => {
    onFilterChange({ ...filters, searchText: text });
  };

  const handleStatusChange = (status: ApprovalStatus[]) => {
    onFilterChange({ ...filters, status });
  };

  const handleDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    onFilterChange({ ...filters, dateRange });
  };

  const handleSubagentsChange = (subagents: string[]) => {
    onFilterChange({ ...filters, subagents });
  };

  const handleReviewersChange = (reviewers: string[]) => {
    onFilterChange({ ...filters, reviewers });
  };

  return {
    handleSearchTextChange,
    handleStatusChange,
    handleDateRangeChange,
    handleSubagentsChange,
    handleReviewersChange,
  };
};
