import { Box, Typography, useTheme, Theme } from "@mui/material";
import React from "react";
import { ChatMode, ChatStage as EChatStage, LexZapChat } from "@/hooks/lexZap/types";
import liaImage from "@/assets/images/lia-icon-1.png";

interface ChatStageProps {
  chat: Pick<LexZapChat, "chatStage" | "chatMode">;
}

interface StageInfo {
  label: string;
  color: string;
  backgroundColor: string;
  responsibleBackgroundColor: string;
}

const STAGE_INFO_MAP = new Map<EChatStage, (palette: Theme["palette"]) => StageInfo>([
  [
    EChatStage.AI_SERVICE,
    (palette) => ({
      label: "Atendimento IA",
      color: palette.text.primary,
      backgroundColor: palette.common.lightShade!,
      responsibleBackgroundColor: palette.common.lightShade!,
    }),
  ],
  [
    EChatStage.INITIAL_TRIAGE,
    (palette) => ({
      label: "Triagem Inicial",
      color: palette.common.white,
      backgroundColor: palette.success.main,
      responsibleBackgroundColor: palette.success.dark,
    }),
  ],
  [
    EChatStage.REDIRECTED,
    (palette) => ({
      label: "Redirecionado",
      color: palette.common.white,
      backgroundColor: palette.warning.main,
      responsibleBackgroundColor: palette.warning.dark,
    }),
  ],
  [
    EChatStage.ABANDONED,
    (palette) => ({
      label: "Abandonado",
      color: palette.text.primary,
      backgroundColor: palette.info.main,
      responsibleBackgroundColor: palette.info.dark,
    }),
  ],
  [
    EChatStage.INACTIVE_AI,
    (palette) => ({
      label: "IA Inativa",
      color: palette.common.white,
      backgroundColor: palette.grey[800],
      responsibleBackgroundColor: palette.grey[900],
    }),
  ],
  [
    EChatStage.ARCHIVED,
    (palette) => ({
      label: "Arquivada",
      color: palette.common.white,
      backgroundColor: palette.grey[500],
      responsibleBackgroundColor: palette.grey[700],
    }),
  ],
  [
    EChatStage.HUMAN_INITIATED,
    (palette) => ({
      label: "Iniciado Humano",
      color: palette.common.white,
      backgroundColor: palette.warning.main,
      responsibleBackgroundColor: palette.warning.dark,
    }),
  ],
]);

export const ChatStage = ({ chat }: ChatStageProps) => {
  const { palette } = useTheme();

  const stageInfo = chat.chatStage ? (STAGE_INFO_MAP.get(chat.chatStage)?.(palette) ?? null) : null;
  const isLia = chat.chatMode === ChatMode.AI;

  if (!stageInfo) return <Box />;

  return (
    <Box
      sx={{
        backgroundColor: stageInfo.backgroundColor,
        paddingRight: "10px",
        paddingLeft: isLia ? "3px" : "10px",
        height: "24px",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        gap: 0.5,
      }}
    >
      {isLia && <LiaIconStatus />}
      <Typography
        variant="body2"
        color={stageInfo.color}
        sx={{
          fontSize: "12px",
          fontWeight: 500,
        }}
      >
        {stageInfo.label}
      </Typography>
    </Box>
  );
};

const LiaIconStatus = () => (
  <Box
    sx={{
      width: "18px",
      height: "18px",
      borderRadius: "50%",
      backgroundColor: "common.mediumShade",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    }}
  >
    <img src={liaImage} alt="Lia" />
  </Box>
);
