import { useState, useCallback, useEffect, SetStateAction } from "react";
import { QuickFilter, ChatFilters } from "./types";
import { ChatStatus } from "@/services/whatsappServer/types/chat";
import { useAuthContext } from "@/contexts/AuthContext";
import { FlattenedChat } from "../../types";
import { useFilterStorage } from "./useFilterStorage";

export const useQuickFilters = () => {
  const { storedQuickFilters, storedChatFilters, saveQuickFilters, saveChatFilters } = useFilterStorage();
  const [filters, setFilters] = useState<QuickFilter[]>(storedQuickFilters);
  const [chatFilters, setChatFilters] = useState<ChatFilters>(storedChatFilters);
  const { user } = useAuthContext();

  // Save filters to localStorage whenever they change
  useEffect(() => {
    saveQuickFilters(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // Save chat filters to localStorage whenever they change
  useEffect(() => {
    saveChatFilters(chatFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatFilters]);

  const calculateFilterCounts = useCallback(
    <T extends { status?: ChatStatus; hasApprovalRequest?: boolean; assignedUserIds?: string[] }>(rows: T[]) => {
      const counts = {
        active: 0,
        archived: 0,
        approvals: 0,
        withResponsible: 0,
        withoutResponsible: 0,
        myResponsibility: 0,
      };

      rows.forEach((chat) => {
        if (chat.status === ChatStatus.ACTIVE) counts.active++;
        if (chat.status === ChatStatus.ARCHIVED) counts.archived++;
        if (chat.hasApprovalRequest) counts.approvals++;
        if (!chat.assignedUserIds || chat.assignedUserIds.length === 0) counts.withoutResponsible++;
        if (chat.assignedUserIds && chat.assignedUserIds.length > 0) counts.withResponsible++;
        if (chat.assignedUserIds?.includes(user?.userId || "")) counts.myResponsibility++;
      });

      return counts;
    },
    [user?.userId]
  );

  const handleFilterChange = useCallback((filterKey: string) => {
    setFilters((prev) =>
      prev.map((filter) => {
        // If this is the clicked filter, toggle it
        if (filter.key === filterKey) {
          return { ...filter, enabled: !filter.enabled };
        }

        // Handle mutually exclusive filters
        const shouldDisable =
          // If enabling "withoutResponsible", disable "myResponsibility" and "withResponsible"
          (filterKey === "withoutResponsible" &&
            (filter.key === "myResponsibility" || filter.key === "withResponsible")) ||
          // If enabling "withResponsible", disable "withoutResponsible"
          (filterKey === "withResponsible" && filter.key === "withoutResponsible") ||
          // If enabling "myResponsibility", disable "withoutResponsible"
          (filterKey === "myResponsibility" && filter.key === "withoutResponsible") ||
          // If enabling "active", disable "archived"
          (filterKey === "active" && filter.key === "archived") ||
          // If enabling "archived", disable "active"
          (filterKey === "archived" && filter.key === "active");

        return {
          ...filter,
          enabled: shouldDisable ? false : filter.enabled,
        };
      })
    );
  }, []);

  const filterRows = useCallback(
    (rows: FlattenedChat[]) => {
      return rows.filter((chat) => {
        // Apply quick filters
        const quickFiltersPass = filters.every((filter) => {
          if (!filter.enabled) return true;

          switch (filter.key) {
            case "active":
              return chat.status === ChatStatus.ACTIVE;
            case "archived":
              return chat.status === ChatStatus.ARCHIVED;
            case "approvals":
              return chat.hasApprovalRequest;
            case "withoutResponsible":
              return !chat.assignedUserIds || chat.assignedUserIds.length === 0;
            case "withResponsible":
              return chat.assignedUserIds && chat.assignedUserIds.length > 0;
            case "myResponsibility":
              return chat.assignedUserIds?.includes(user?.userId || "");
            default:
              return true;
          }
        });

        // Apply responsible filters
        const responsibleFiltersPass =
          chatFilters.responsibles.length === 0 ||
          (chat.assignedUserIds &&
            chatFilters.responsibles.some((resp) => chat.assignedUserIds?.includes(resp.userId)));

        // Apply lead stage filters
        const leadStageFiltersPass =
          chatFilters.leadStages.length === 0 || (chat.leadStage && chatFilters.leadStages.includes(chat.leadStage));

        // Apply chat mode filters
        const chatModeFiltersPass =
          chatFilters.chatModes.length === 0 || (chat.chatMode && chatFilters.chatModes.includes(chat.chatMode));

        // Apply status filters
        const statusFiltersPass =
          chatFilters.statuses.length === 0 || (chat.status && chatFilters.statuses.includes(chat.status));

        return (
          quickFiltersPass && responsibleFiltersPass && leadStageFiltersPass && chatModeFiltersPass && statusFiltersPass
        );
      });
    },
    [
      filters,
      chatFilters.responsibles,
      chatFilters.leadStages,
      chatFilters.chatModes,
      chatFilters.statuses,
      user?.userId,
    ]
  );

  const updateFilterCounts = useCallback(
    (rows: FlattenedChat[]) => {
      const counts = calculateFilterCounts(rows);
      setFilters((prev) =>
        prev.map((filter) => ({
          ...filter,
          count: counts[filter.key as keyof typeof counts] || 0,
        }))
      );
    },
    [calculateFilterCounts]
  );

  // Custom setter for chatFilters that also handles localStorage persistence
  const setPersistedChatFilters = useCallback((value: SetStateAction<ChatFilters>) => {
    setChatFilters(value);
  }, []);

  return {
    filters,
    setFilters,
    chatFilters,
    setChatFilters: setPersistedChatFilters,
    handleFilterChange,
    filterRows,
    calculateFilterCounts,
    updateFilterCounts,
  };
};
