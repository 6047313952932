import React from "react";
import { ModalRight } from "@/components/Modal/ModalRight";
import { Typography, Box, FormControlLabel, Checkbox } from "@mui/material";
import { Button } from "@/components/Button";
import { CaseFileFilters } from "./types";

interface FilterCaseFilesModalProps {
  open: boolean;
  onClose: () => void;
  caseFileFilters: CaseFileFilters;
  setCaseFileFilters: (newFilters: CaseFileFilters) => void;
}

export const FilterCaseFilesModal = ({
  open,
  onClose,
  caseFileFilters,
  setCaseFileFilters,
}: FilterCaseFilesModalProps) => {
  const [filters, setFilters] = React.useState<CaseFileFilters>(caseFileFilters);

  const handleApplyFilters = () => {
    setCaseFileFilters(filters);
    onClose();
  };

  return (
    <ModalRight
      open={open}
      onClose={onClose}
      title="Filtrar casos"
      size={"SMALL"}
      footer={
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
            }}
            onClick={handleApplyFilters}
          >
            Filtrar
          </Button>
        </Box>
      }
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <FilterSelection title="Tipo" description="Filtre arquivos pelo formato">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: 2,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.fileType.audio}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      fileType: {
                        ...prev.fileType,
                        audio: !prev.fileType.audio,
                      },
                    }))
                  }
                />
              }
              label="Audio"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.fileType.document}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      fileType: {
                        ...prev.fileType,
                        document: !prev.fileType.document,
                      },
                    }))
                  }
                />
              }
              label="Documentos"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.fileType.image}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      fileType: {
                        ...prev.fileType,
                        image: !prev.fileType.image,
                      },
                    }))
                  }
                />
              }
              label="Imagens"
            />
          </Box>
        </FilterSelection>

        <FilterSelection title="Origem" description="Filtre arquivos pela origem">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: 2,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.origin.whatsapp}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      origin: {
                        ...prev.origin,
                        whatsapp: !prev.origin.whatsapp,
                      },
                    }))
                  }
                />
              }
              label="Whatsapp"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.origin.platform}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      origin: {
                        ...prev.origin,
                        platform: !prev.origin.platform,
                      },
                    }))
                  }
                />
              }
              label="Upload manual"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.origin.record}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      origin: {
                        ...prev.origin,
                        record: !prev.origin.record,
                      },
                    }))
                  }
                />
              }
              label="Documentos gerados pela plataforma"
            />
          </Box>
        </FilterSelection>
      </Box>
    </ModalRight>
  );
};

const FilterSelection = ({
  title,
  description,
  children,
}: {
  title: string;
  description: string;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
      {children}
    </Box>
  );
};
