export const APPROVAL_STATUS_LABELS = {
  PENDING: "Pendente",
  APPROVED: "Aprovado",
  EDITED: "Editado",
  REQUESTED_CHANGES: "Solicitado Reprocessamento",
} as const;

export const APPROVAL_STATUS_COLORS = {
  PENDING: "warning.main",
  APPROVED: "success.main",
  EDITED: "info.main",
  REQUESTED_CHANGES: "error.main",
} as const;
