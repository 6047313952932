import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getCompanyTeamQueryKey } from "../useCompanyTeam";
import { ActivityArea, CompanyMember } from "../types";
import { CompanyService } from "@/services/company";
import { Permission } from "@/contexts/AuthContext";

export const useUpdateCompanyMember = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["editCompanyMember"],
    mutationFn: async ({
      memberId,
      data,
    }: {
      memberId: string;
      data: {
        notificationNumber: string;
        department: string;
        activityAreas?: ActivityArea[];
        isAdmin?: boolean;
        permissions?: Permission[];
      };
    }) => await CompanyService.updateTeamMember({ memberId, data }),
    onMutate: async ({ memberId, data }) => {
      await queryClient.cancelQueries({ queryKey: getCompanyTeamQueryKey() });
      const previousCases = queryClient.getQueryData<string[]>(getCompanyTeamQueryKey());
      queryClient.setQueryData(getCompanyTeamQueryKey(), (old: CompanyMember[]) => [
        ...old.map((c) => (c.userId === memberId ? { ...c, ...data } : c)),
      ]);
      return { previousCases };
    },
    onError: (_error, _newCase, context) => {
      queryClient.setQueryData(getCompanyTeamQueryKey(), context?.previousCases);
    },
  });
};
