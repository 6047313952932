import { CaseFileFilters } from "../../components/filterCaseFilesModal/types";

export const calculateCaseFileFiltersChanges = ({
  filters,
  defaultFilters,
}: {
  filters: CaseFileFilters;
  defaultFilters: CaseFileFilters;
}) => {
  let changes = 0;

  if (filters.origin.whatsapp !== defaultFilters.origin.whatsapp) {
    changes++;
  }
  if (filters.origin.platform !== defaultFilters.origin.platform) {
    changes++;
  }

  if (filters.fileType.audio !== defaultFilters.fileType.audio) {
    changes++;
  }
  if (filters.fileType.image !== defaultFilters.fileType.image) {
    changes++;
  }
  if (filters.fileType.document !== defaultFilters.fileType.document) {
    changes++;
  }

  return changes;
};
