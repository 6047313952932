import { ModalRight } from "@/components/Modal/ModalRight";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSkillPath } from "@/routes/routePaths";
import { OtherTasksActionsSkillGroup } from "@/core/skillGroups/implementations/otherTasksActions";
import { NewSkillGroupView } from "@/components/Chat/NewSkillGroupView";
import { Case } from "@/hooks/lexZap/types";
import { removeUndefinedValues } from "@/utils/removeUndefinedValues";
import { mapCaseToSkillFields } from "@/utils/mapCaseToSkillFields";
import { SkillId } from "@/core/skills/types";

type OtherTasksActionsProps = { isOpen: boolean; onClose: () => void; caseData?: Case };

const OtherTasksActions = ({ isOpen, onClose, caseData }: OtherTasksActionsProps) => {
  const navigate = useNavigate();

  const [selectedSkill, setSelectedSkill] = useState<{
    skillId: SkillId;
    customTitle?: string;
    defaultData?: Record<string, string>;
  } | null>(null);

  const handleGoToSkill = async (params: {
    selectedCase?: Case | null;
    skillData?: {
      skillId: SkillId;
      customTitle?: string;
      defaultData?: Record<string, string>;
    };
  }) => {
    const { selectedCase, skillData } = params;
    const skill = selectedSkill || skillData;
    if (!skill) return;

    if (!selectedCase) {
      const url = getSkillPath({
        skillId: skill.skillId,
      });

      navigate(url, {
        state: {
          ...skill.defaultData,
          customTitle: skill.customTitle,
        },
      });

      onClose();
      return;
    }

    const url = getSkillPath({
      skillId: skill.skillId,
      threadId: selectedCase.threadId,
      customTitle: skill.customTitle,
      caseId: selectedCase.id,
    });

    const skillFields = mapCaseToSkillFields({ caseData: selectedCase, skillId: skill.skillId });

    navigate(url, {
      state: removeUndefinedValues(skillFields),
    });

    onClose();
  };

  const handleSkillSelect = async (skillData: {
    skillId: SkillId;
    customTitle?: string;
    defaultData?: Record<string, string>;
  }) => {
    setSelectedSkill(skillData);
    await handleGoToSkill({ selectedCase: caseData, skillData });
  };

  return (
    <>
      <ModalRight title={"Outras tarefas"} open={isOpen} onClose={onClose} size="LARGE">
        <NewSkillGroupView
          skillGroup={OtherTasksActionsSkillGroup}
          onSelectSkill={handleSkillSelect}
          withSearch={false}
        />
      </ModalRight>
    </>
  );
};

export default OtherTasksActions;
