import { isAxiosError } from "axios";

import { LegalProceedingService } from "@/services/legalProceeding";
import { useMutation } from "@tanstack/react-query";
import { LegalProceedingToCasePayload } from "@/services/legalProceeding/types";
import { WebToast } from "@/components/core/Toast";
import { logger } from "@/core/logger";

export const useLegalProceedingToCaseMutation = () => {
  const api = LegalProceedingService;

  return useMutation({
    mutationKey: ["importLegalProceedingToCase"],
    mutationFn: async (payload: LegalProceedingToCasePayload) => await api.transformLegalProceedingsToCase(payload),
    onError: (error) => {
      handleErrorImportingLegalProceedings(error);
    },
  });
};

const handleErrorImportingLegalProceedings = (error: unknown) => {
  logger.error("handleErrorImportingLegalProceedings", { error });
  if (isAxiosError(error) && error.response?.status === 402) {
    WebToast.error("Você atingiu o limite máximo de casos");
  } else {
    WebToast.error("Erro ao importar processos");
  }
};
