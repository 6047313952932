import { useRecords } from "@/hooks/records/useRecords";
import { useMemo } from "react";
import { formatDateToISO } from "@/utils/formatDateToISO";
import { ThreadRecord, ThreadRecordWithThreadId } from "@/hooks/records/types";
import { useCaseLegalProceedingsFiles } from "@/hooks/legalProceedings/useCaseLegalProceedingsFiles";
import { CaseFileItem } from "@/core/CaseFile";
import { Paginated } from "@/core/api/types";
import mime from "mime";
import { FileType } from "../types";
import { useCaseWhatsappFiles } from "../useCaseWhatsappFiles";
import { dateTimeFromString } from "@/utils/dates/dateTimeFromString";
import { DateTime } from "luxon";
import { useCasePlatformFiles } from "@/hooks/case/useCasePlatformFiles";

interface UseLexZapCaseFilesProps {
  caseId?: string;
  search?: string;
  threadIds?: string[];
}
export const useCaseFiles = ({ caseId, search = "", threadIds }: UseLexZapCaseFilesProps) => {
  const {
    data: allRecords,
    isLoading: isLoadingRecords,
    error: errorRecords,
  } = useRecords({
    threadId: threadIds,
    order: "CREATED_AT",
  });

  const {
    data: whatsappFilesData,
    isLoading: isLoadingWhatsappFiles,
    isError: isErrorWhatsappFiles,
  } = useCaseWhatsappFiles({
    caseId,
  });

  const { data: caseLegalProceedingsFiles, isLoading: isLoadingCaseLegalProceedingsFiles } =
    useCaseLegalProceedingsFiles({
      caseId,
    });

  const { data: casePlatformFiles, isLoading: isLoadingCasePlatformFiles } = useCasePlatformFiles({
    caseId,
  });

  const whatsappFiles: CaseFileItem[] = useMemo(() => {
    return whatsappFilesData
      ? whatsappFilesData.map((file) =>
          CaseFileItem.createWhatsappFile({
            name: file.filename,
            createdAt: dateTimeFromString(file.createdAt).toMillis(),
            id: file.id,
            mimeType: file.mimeType,
          })
        )
      : [];
  }, [whatsappFilesData]);

  const records: CaseFileItem[] = useMemo(() => {
    function isRecordWithThreadId(input: ThreadRecord): input is ThreadRecordWithThreadId {
      return Boolean(input.threadId);
    }

    const recordsWithThreadId = Array.isArray(allRecords)
      ? allRecords
          .filter((r): r is Paginated<ThreadRecordWithThreadId> => r !== undefined)
          .flatMap((r) => r.data.filter(isRecordWithThreadId))
      : (allRecords?.data.filter(isRecordWithThreadId) ?? []);

    return recordsWithThreadId.map((record) =>
      CaseFileItem.createRecord({
        name: record.name,
        createdAt: dateTimeFromString(record.createdAt).toMillis(),
        id: record.id,
        recordType: record.type,
        threadId: record.threadId,
        fileType: FileType.DOCUMENT,
      })
    );
  }, [allRecords]);

  const legalProceedingFiles: CaseFileItem[] = useMemo(() => {
    return (
      caseLegalProceedingsFiles?.map((file) =>
        CaseFileItem.createLegalProceedingAttachedFile({
          name: file.name,
          createdAt: dateTimeFromString(file.createdAt).toMillis(),
          id: file.id,
          cnj: file.cnj,
          instance: file.instance,
          integration: file.integration,
          integrationFileId: file.integrationFileId,
          legalProceedingStepId: file.legalProceedingStepId,
          extension: file.extension,
          fileType: mime.getType(file.extension)?.split("/")[0] as FileType,
        })
      ) || []
    );
  }, [caseLegalProceedingsFiles]);

  const platformFiles: CaseFileItem[] = useMemo(() => {
    return (
      casePlatformFiles?.map((file) =>
        CaseFileItem.createPlatformFile({
          name: file.name,
          createdAt: DateTime.fromISO(formatDateToISO(file.createdAt)).toMillis(),
          id: file.id,
          fileType: file.fileType,
          extension: file.extension,
        })
      ) || []
    );
  }, [casePlatformFiles]);

  const caseFiles = useMemo(() => {
    return (
      [...records, ...whatsappFiles, ...legalProceedingFiles, ...platformFiles]
        .sort((a, b) => {
          return b.createdAt - a.createdAt;
        })
        .filter((item) => item.name.toLowerCase().includes(search.toLowerCase())) || []
    );
  }, [records, whatsappFiles, legalProceedingFiles, platformFiles, search]);

  return {
    data: caseFiles,
    isLoading:
      isLoadingRecords || isLoadingWhatsappFiles || isLoadingCaseLegalProceedingsFiles || isLoadingCasePlatformFiles,
    error: errorRecords || isErrorWhatsappFiles,
  };
};
