import { Box, Button, Chip, Skeleton, Typography } from "@mui/material";
import React from "react";
import { LexZapChat } from "@/hooks/lexZap/types";
import { formatPhoneNumber } from "@/utils/lexZap/formatPhoneNumber";
import { ApplicantModal } from "@/components/Applicants";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
interface ChatHeaderProps {
  chat?: LexZapChat;
  isLoading?: boolean;
  onToggleHistory: () => void;
}

export const ChatHeader = ({ chat, isLoading, onToggleHistory }: ChatHeaderProps) => {
  const [isApplicantModalOpen, setIsApplicantModalOpen] = React.useState(false);
  const { applicantActivationEnabled } = useFeatureFlags();

  if (isLoading || !chat) {
    return <ChatHeaderSkeleton />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "grey.50",
        gap: 2,
        paddingX: 3,
        paddingRight: 6,
        paddingY: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            variant="body"
            color="#09132C"
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              "&:hover": !!chat.applicant
                ? {
                    cursor: "pointer",
                    textDecoration: "underline",
                  }
                : undefined,
            }}
            onClick={!!chat.applicant ? () => setIsApplicantModalOpen(true) : undefined}
          >
            {chat.applicantName || formatPhoneNumber(chat.endClientNumber)}
          </Typography>

          {applicantActivationEnabled && !!chat.applicant && (
            <>
              {!chat.applicant.active ? (
                <Chip label="Novo contato" size="small" />
              ) : (
                <Chip label="Cliente ativo" size="small" color="secondary" />
              )}
            </>
          )}
        </Box>

        <Typography
          variant="body"
          color="grey.700"
          sx={{
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {formatPhoneNumber(chat.endClientNumber)}
        </Typography>
      </Box>

      <Button variant="text" onClick={onToggleHistory} sx={{ fontSize: "16px", textDecoration: "underline" }}>
        Histórico
      </Button>

      <ApplicantModal
        isOpen={isApplicantModalOpen}
        onClose={() => setIsApplicantModalOpen(false)}
        applicantId={chat.applicant?.id}
      />
    </Box>
  );
};

const ChatHeaderSkeleton = () => {
  return (
    <Box
      sx={{
        backgroundColor: "grey.50",
        gap: 2,
        paddingX: 3,
        paddingRight: 6,
        paddingY: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Skeleton variant="text" width={180} />

        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            alignItems: "center",
          }}
        >
          <Skeleton variant="text" width={200} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      ></Box>
    </Box>
  );
};
