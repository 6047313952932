import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

interface VerificationCodeInputProps {
  length?: number;
  onChange: (code: string) => void;
  error?: string;
}

export const VerificationCodeInput = ({ length = 6, onChange, error }: VerificationCodeInputProps) => {
  const [code, setCode] = useState<string[]>(new Array(length).fill(""));
  const inputRefs = useMemo(
    () => Array.from({ length }, () => null).map(() => React.createRef<HTMLInputElement>()),
    [length]
  );

  useEffect(() => {
    onChange(code.join(""));
  }, [code, onChange]);

  const handleChange = (index: number, value: string) => {
    const singleValue = value.slice(-1);

    setCode((prev) => {
      const newCode = [...prev];
      newCode[index] = singleValue;
      return newCode;
    });

    if (singleValue !== "" && index < length - 1) {
      inputRefs[index + 1]!.current?.focus();
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && index > 0 && code[index] === "") {
      inputRefs[index - 1]!.current?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");

    if (pasteData.length === length) {
      const newCode = pasteData.split("");
      setCode(newCode);

      inputRefs[length - 1]!.current?.focus();

      onChange(pasteData);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        px: "50px",
      }}
    >
      <Typography variant="body2" align="center" mb={1}>
        Código de confirmação
      </Typography>

      <Box
        sx={{
          display: "grid",
          height: "40px",
          gridTemplateColumns: `repeat(${length}, minmax(0, 1fr))`,
          gap: 0.5,
        }}
      >
        {Array.from({ length }, (_, index) => (
          <TextField
            key={index}
            inputRef={inputRefs[index]}
            type="number"
            inputProps={{
              maxLength: 1,
              style: {
                textAlign: "center",
                padding: 0,
                WebkitAppearance: "textfield",
                MozAppearance: "textfield",
              },
            }}
            value={code[index]}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            error={!!error}
            sx={{
              width: "34px",
              "& .MuiOutlinedInput-root": {
                height: "100%",
              },
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
            }}
          />
        ))}
      </Box>

      {error && (
        <Typography variant="body2" color="error.main" mt={0.5}>
          {error}
        </Typography>
      )}
    </Box>
  );
};
