/* eslint-disable */

import { DeleteOutlined as DeleteOutlinedIcon } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import React, { useEffect, useRef } from "react";
import { v4 as uuidV4 } from "uuid";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { Action, ErrorMessage, Message, useMessagesContext } from "@/contexts/MessagesContext";
import { $getRoot } from "lexical";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

const SUPPORTED_FILE_TYPES = [
  "application/pdf", //pdf
  "image/jpeg", //jpeg/jpg
  "image/png", //png
  "application/msword", //doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //docx
  "font/ttf", //tiff
];

export function Input(props: {
  sendMessage: (message: Message) => unknown;
  context: string;
  clearContext: () => unknown;
  onError: (message: ErrorMessage) => unknown;
  loading: boolean;
  actions: Action[];
}) {
  const { sendMessage, context, clearContext, onError, loading, actions } = props;
  const [files, setFiles] = React.useState<File[]>([]);
  const { inputVisible, setInputVisible } = useMessagesContext();
  const flags = useFeatureFlags();

  /* WARNING: gambiarra
   *
   * Nós tínhamos um bug em que o editor crashava depois de algumas iterações.
   * Para reproduzir basta tirar o `key={inputId}` do Box abaixo e seguir os
   * seguintes passos:
   *
   * 1. Fechar e abrir o chat se necessário, para limpar a conversa.
   * 2. Clicar no botão de 'Escrever petição inicial' ou inputar o prompt
   *    'Criar uma petição inicial.'
   * 3. Depois da resposta, entrar os dados do caso abaixo:
   *   João Paulo, residente na cidade do Rio de Janeiro, ao tentar comprar um
   *   eletrodoméstico, foi informado pelo estabelecimento vendedor que não seria
   *   possivel aceitar o pagamento financiado, em virtude de uma negativação de seu
   *   nome junto aos cadastros restritivos de crédito pelo Banco XYZ, sediado no Rio de
   *   Janeiro. João Paulo ficou surpreso, tendo em vista que nunca contratou com tal
   *   banco.
   *   Diante do ocorrido, João Paulo buscou informações e verificou que a divida, origem
   *   da negativação, era referente a um contrato de empréstimo de R$ 10.000.00 que ele
   *   nunca celebrou, sendo, portanto, fruto de alguma fraude com seu nome. João Paulo
   *   dirigiu-se ao banco, pedindo a imediata exclusão de seu nome do cadastro restritivo
   *   de crédito, o que foi negado pelo Banco XYZ
   *   Diante desse cenário, João Paulo pretende a retirada imediata de seu nome dos
   *   cadastros restritivos de crédito, já que nunca contraiu a divida apontada, além de
   *   indenização por danos morais no equivalente a R$ 30.000,00
   * 4. Depois da resposta copiar e colar o seguinte texto:
   *   Ok. Pode redigir a petição com base nesta estratégia.
   *
   * É importante de fato copiar e color, é no ctrl + v que acontece o problema.
   * (Você vai precisar tirar os asteriscos dos textos de exemplo acima, eu
   * estava copiando e colando do slack).
   *
   * O editor deve crashar com um problema no `append`. Eu investiguei por algum
   * tempo e não consegui entender o motivo do erro acontecer.
   *
   * O que eu percebi no entanto é que tem alguma coisa a ver com a manutenção
   * do estado interno do editor, logo se nós forçamos o editor a ser recriado
   * o problema não acontece. Para fazer isso eu adicionei a key={inputId} na
   * raiz do componente e fiz com que a key mudasse cada vez que enviamos uma
   * mensagem.
   */
  const [inputId, setInputId] = React.useState<string>(uuidV4());

  const onSend = React.useCallback(
    (text: string) => {
      //We should not send empty messages
      if (!text && !files.length) return;

      if (files.length) {
        sendMessage({
          id: uuidV4(),
          type: "FILE",
          direction: "SENT",
          author: "Current User",
          date: DateTime.now(),
          status: "READ",
          text: text,
          context,
          files: files.map((file) => ({
            type: "UPLOADING",
            id: uuidV4(),
            file,
            name: file.name,
          })),
        });

        setFiles([]);
      } else {
        sendMessage({
          id: uuidV4(),
          type: "TEXT",
          direction: "SENT",
          author: "Current User",
          date: DateTime.now(),
          status: "READ",
          text: text,
          context,
        });
      }

      setInputId(uuidV4());
    },
    [sendMessage, context, files]
  );

  const onInputFiles = React.useCallback(
    (files: File[]) => {
      const unsupportedFiles: File[] = [];
      const supportedFiles: File[] = [];

      files.forEach((file) => {
        if (SUPPORTED_FILE_TYPES.includes(file.type)) {
          supportedFiles.push(file);
        } else {
          unsupportedFiles.push(file);
        }
      });

      if (unsupportedFiles.length) {
        unsupportedFiles.forEach((file) => {
          onError({
            id: uuidV4(),
            type: "ERROR",
            direction: "RECEIVED",
            author: "Lexter.ai",
            date: DateTime.now(),
            status: "READ",
            text: `Extensão do arquivo '${file.name}' não suportada.`,
            actions: [],
          });
        });
      }

      setFiles((prev) => [...prev, ...supportedFiles]);
    },
    [setFiles, onError]
  );

  const handleDrop = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const droppedFiles: File[] = [];
      if (event.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        const items = event.dataTransfer.items;
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          // If dropped items aren't files, reject them
          if (item!.kind === "file") {
            const file = item!.getAsFile();
            if (file) {
              droppedFiles.push(file);
            }
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        const files = event.dataTransfer.files;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          droppedFiles.push(file!);
        }
      }

      onInputFiles(droppedFiles);
    },
    [onInputFiles]
  );

  const handleRemoveFile = React.useCallback(
    (file: File) => {
      setFiles((prev) => prev.filter((f) => f !== file));
    },
    [setFiles]
  );

  if (loading && inputVisible) {
    return <LoadingInput />;
  }

  return (
    <Box
      hidden={!inputVisible}
      key={inputId}
      sx={{
        backgroundColor: "background.paper",
        pl: 3,
        pr: 3,
        mt: 2,
        position: "relative",
      }}
    >
      <Box sx={{ height: "24px" }}>
        <IconButton
          aria-label="Esconder campo de input"
          onClick={() => setInputVisible(false)}
          sx={{
            position: "absolute",
            top: -16,
            left: "50%",
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>

      <LexicalComposer
        initialConfig={{
          namespace: "chat-input",
          theme: {
            ltr: "ltr",
            rtl: "rtl",
            placeholder: "editor-placeholder",
            paragraph: "editor-paragraph",
          },
          onError(error) {
            throw error;
          },
          nodes: [],
        }}
      >
        <InputContext context={context} clearContext={clearContext} />

        <Box
          sx={{
            minHeight: 64,
            backgroundColor: "background.default",
            borderRadius: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            pr: 1,
          }}
        >
          <Box sx={{ flexGrow: 1, p: 1 }}>
            <Box className="editor-container">
              <PlainTextPlugin
                contentEditable={<ContentEditable className="editor-input" />}
                placeholder={<Placeholder />}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HistoryPlugin />
              <AutoFocusPlugin />
            </Box>
          </Box>

          <ButtonsPlugin onSend={onSend} onFiles={onInputFiles} actions={actions} />
        </Box>

        <InputFiles files={files} onRemove={handleRemoveFile} />
      </LexicalComposer>
    </Box>
  );
}

function Placeholder() {
  return <div className="editor-placeholder">Digite uma mensagem...</div>;
}

function AutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus();
  }, [editor]);

  return null;
}

function ButtonsPlugin(props: {
  onSend: (text: string) => unknown;
  onFiles: (files: File[]) => unknown;
  actions: Action[];
}) {
  const { actions, onSend, onFiles } = props;

  const [openActionsMenu, setOpenActionsMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [editor] = useLexicalComposerContext();

  const handleCloseActionsMenu = () => {
    setOpenActionsMenu(false);
  };

  const handleClickSendMessage = React.useCallback(() => {
    editor.update(() => {
      const root = $getRoot();
      onSend(root.getTextContent());
      root.clear();
    });
  }, [editor, onSend]);

  const handleAttachment = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files: File[] = [];
      const filesList = event.target.files || [];

      for (let i = 0; i < filesList.length; i++) {
        const file = filesList[i];
        files.push(file!);
      }
      onFiles(files);
    },
    [onFiles]
  );

  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        handleCloseActionsMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <Box
      sx={{
        alignSelf: "flex-end",
        display: "flex",
        mb: 2,
      }}
    >
      <IconButton aria-label="Enviar mensagem" onClick={handleClickSendMessage}>
        <SendRoundedIcon sx={{ transform: "rotate(-45deg)", marginTop: "-4px" }} />
      </IconButton>

      {/* <IconButton aria-label="Anexar arquivo" component="label">
        <AttachmentRoundedIcon sx={{ transform: "rotate(-45deg)" }} />
        <input type="file" hidden multiple onChange={handleAttachment} />
      </IconButton> */}

      <Popper open={openActionsMenu} anchorEl={anchorEl} placement="bottom-start">
        <div ref={menuRef}>
          <ActionsMenu actions={actions} onClose={() => setOpenActionsMenu(false)} />
        </div>
      </Popper>
    </Box>
  );
}

function ActionsMenu(props: { onClose: () => void; actions: Action[] }) {
  const { onClose, actions } = props;

  const availableActions = actions.filter((action: Action) => !action.hidden && !action.disabled);

  return (
    <List sx={{ p: 0, backgroundColor: "background.default", borderRadius: 1, boxShadow: 3 }}>
      {availableActions.map((action: Action, idx: number) => {
        const onClick = (props: any) => {
          action.onClick(props);
          onClose();
        };

        return (
          <span key={idx}>
            <ListItem disableGutters sx={{ p: 0, height: "50px" }}>
              <ListItemButton onClick={onClick} sx={{ p: 0, height: "50px" }}>
                <Typography variant="multiLineBody" color={"text.primary"} sx={{ p: 2 }}>
                  {action.text}
                </Typography>
              </ListItemButton>
            </ListItem>
            {idx === availableActions.length - 1 ? null : <Divider />}
          </span>
        );
      })}
    </List>
  );
}

function InputContext(props: { context: string; clearContext: () => unknown }) {
  const { context, clearContext } = props;

  if (!context?.trim()) return null;

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        mb: 1,
      }}
    >
      <Box
        sx={{
          maxHeight: 150,
          backgroundColor: "background.default",
          p: 1,
          flexGrow: 1,
          borderRadius: 1,
          overflowY: "auto",
        }}
      >
        <Typography variant="multiLineBody" color={"text.disabled"}>
          {context}
        </Typography>
      </Box>

      <IconButton
        aria-label="Limpar contexto"
        onClick={clearContext}
        size="small"
        sx={{
          alignSelf: "flex-start",
        }}
      >
        <Close fontSize="inherit" />
      </IconButton>
    </Box>
  );
}

function InputFiles(props: { files: File[]; onRemove: (file: File) => unknown }) {
  const { files, onRemove } = props;

  if (!files.length) return null;

  return (
    <Stack sx={{ flexGrow: 1, maxHeight: "200px", overflowY: "auto", mt: 1 }}>
      {files.map((file) => (
        <Box
          key={file.name}
          sx={{
            flexGrow: 1,
            backgroundColor: "background.default",
            borderRadius: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mb: 1,
            p: 2,
          }}
        >
          <TextSnippetOutlinedIcon fontSize="small" />

          <Typography variant="body" sx={{ ml: 1 }}>
            {file.name}
          </Typography>

          <IconButton
            aria-label="Remover arquivo"
            size="small"
            sx={{
              alignSelf: "flex-start",
              ml: "auto",
              color: "common.shade",
            }}
            onClick={() => onRemove(file)}
          >
            <DeleteOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Box>
      ))}
    </Stack>
  );
}

function LoadingInput() {
  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        pl: 3,
        pr: 3,
        mt: 2,
      }}
    >
      <Box
        sx={{
          minHeight: 64,
          backgroundColor: "background.default",
          borderRadius: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1, p: 1 }}>
          <Box
            className="editor-container"
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Skeleton variant="rectangular" height={20} sx={{ minWidth: 100, maxWidth: 600, flexGrow: 1, mr: 2 }} />

            <Skeleton variant="circular" height={20} width={20} sx={{ mr: 1 }} />

            <Skeleton variant="circular" height={20} width={20} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
