import { Result } from "./Result";
import { ValueObject } from "./ValueObject";

interface CPFProps {
  value: string;
}

/**
 * Represents CPF
 *
 * CPF is a brazilian registration number for person used to uniquely identify
 * a physical person
 */
export class CPF extends ValueObject<CPFProps> {
  private constructor(props: CPFProps) {
    super(props);
  }

  /**
   * Format the CPF for the internal representation
   * @param cpf valid cpf string
   */
  private static format(cpf: string): string {
    return cpf.replace(/\D/g, "");
  }

  /**
   * Calculate the first verification digit based on the main part of the CPF
   *
   * The algorithm to be applied was obtained in: http://www.macoratti.net/alg_cpf.htm
   *
   * The follow algorithm is used to calculate the first verification digit:
   * 1. Multiply each digit from right to left by crescent numbers from 2 to 10
   * 2. Sum the multiplication results
   * 3. Get the remainder of the integer division by 11
   * 4. If the rest of the division is less than 2, the digit is 0
   * 5. Otherwise, subtract the rest of the division from 11
   * @param inscriptionNumber CPF inscriptionNumber
   */
  private static calculateFirstVerificationDigit(inscriptionNumber: string): number {
    const multiplicationNumbers = [10, 9, 8, 7, 6, 5, 4, 3, 2];
    const cpfNumbersArray = inscriptionNumber.split("").map((n) => parseInt(n, 10));

    const reducedValue = multiplicationNumbers.reduce((acc, currentValue, currentIndex) => {
      const equivalentCPFNumber = cpfNumbersArray[currentIndex];
      if (!equivalentCPFNumber) return acc;
      return acc + currentValue * equivalentCPFNumber;
    }, 0);

    const remainder = reducedValue % 11;

    if (remainder < 2) {
      return 0;
    } else {
      return 11 - remainder;
    }
  }

  /**
   * Calculate the second verification digit based on the main part of the CPF
   *
   * The algorithm to be applied was obtained in: http://www.macoratti.net/alg_cpf.htm
   *
   * The follow algorithm is used to calculate the second verification digit:
   *   1. Add the first verification digit to the CPF main part
   *   2. Multiply each digit from right to left by crescent numbers from 2 to 11
   *   3. Sum the multiplication results
   *   4. Get the remainder of the integer division by 11
   *   5. If the rest of the division is less than 2, the digit is 0
   *   6. Otherwise, subtract the rest of the division from 11
   * @param inscriptionNumber CPF inscriptionNumber
   * @param firstVerificationDigit: number
   */
  private static calculateSecondVerificationDigit(inscriptionNumber: string, firstVerificationDigit: number): number {
    const multiplicationNumbers = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
    const cpfNumbersArray = `${inscriptionNumber}${firstVerificationDigit}`.split("").map((n) => parseInt(n, 10));

    const reducedValue = multiplicationNumbers.reduce((acc, currentValue, currentIndex) => {
      const equivalentCPFNumber = cpfNumbersArray[currentIndex];
      if (!equivalentCPFNumber) return acc;
      return acc + currentValue * equivalentCPFNumber;
    }, 0);

    const remainder = reducedValue % 11;

    if (remainder < 2) {
      return 0;
    } else {
      return 11 - remainder;
    }
  }

  /**
   * Validate a CPF string
   * @param cpf a string that may be a CPF
   */
  private static isValid(cpfRaw: string): boolean {
    try {
      const cpf = cpfRaw.replace(/\D/g, "");

      const hasCorrectLength = /^\d{11}$/.test(cpf);
      if (!hasCorrectLength) {
        return false;
      }

      //Separate the CPF in its components
      const inscriptionNumber = cpf.slice(0, 9);
      const verificationDigits = cpf.slice(9, 11);

      //Pass the CPF through the verification digit algorithm
      const firstVerificationDigit = this.calculateFirstVerificationDigit(inscriptionNumber);

      const secondVerificationDigit = this.calculateSecondVerificationDigit(inscriptionNumber, firstVerificationDigit);

      const calculatedVerificationDigits = `${firstVerificationDigit}${secondVerificationDigit}`;

      return verificationDigits === calculatedVerificationDigits;
    } catch (e) {
      return false;
    }
  }

  /**
   * Create a new CPF instance
   * @param cpf CPF string representation
   */
  static fromString(cpf: string): Result<CPF> {
    if (!CPF.isValid(cpf)) {
      return Result.fail<CPF>("Invalid CPF");
    }
    return Result.ok<CPF>(new CPF({ value: this.format(cpf) }));
  }

  /**
   * Transform current CPF to the persistance format
   */
  toPersistence(): string {
    return this.props.value;
  }

  /**
   * Transform current CPF to a user friendly format
   */
  toString(): string {
    const cpf = this.props.value;
    const p1 = cpf.slice(0, 3);
    const p2 = cpf.slice(3, 6);
    const p3 = cpf.slice(6, 9);
    const p4 = cpf.slice(9, 11);
    return `${p1}.${p2}.${p3}-${p4}`;
  }
}
