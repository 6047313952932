import { zodResolver } from "@hookform/resolvers/zod";
import { Box, TextField, Typography } from "@mui/material";
import { ChevronRight as ChevronRightIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Button } from "@/components/Button";
import { Layout } from "@/pages/Signup/Layout";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { applyPhoneMask } from "@/utils/applyInputMask";
import { useSignUpContext } from "../../context";
import { BasicInfoFormData, basicInfoSchema } from "./types";
import { GoogleButton } from "@/components/GoogleButton";
import { LexterCopilotAuthService } from "@/services/lexterCopilotAuth";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { logger } from "@/core/logger";
import LexterLogoIcon from "@/assets/svgs/lexter-logo.svg?react";

export const BasicInfoForm = () => {
  const { nextStep, setData, data, invitationEmail } = useSignUpContext();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<BasicInfoFormData>({
    resolver: zodResolver(basicInfoSchema),
    defaultValues: data,
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleData = async (data: BasicInfoFormData) => {
    if (invitationEmail) {
      setData(data);
      nextStep();
      return;
    }

    setIsLoading(true);

    try {
      const recaptchaToken = await executeRecaptcha!();
      const { available, requiresActivationCode } = await LexterCopilotAuthService.getEmailAvailability({
        recaptchaToken,
        email: data.email,
      });

      if (requiresActivationCode) {
        return navigate(ROUTE_PATHS.ACTIVATE_ACCOUNT, {
          state: { email: data.email },
        });
      }
      if (!available) {
        setEmailMessage("E-mail já cadastrado.");
        setIsLoading(false);
        return;
      }
    } catch (e) {
      logger.error("BasicInfoForm handleData", { error: e });
      setEmailMessage("Erro ao validar e-mail.");
      setIsLoading(false);
      return;
    }

    setData(data);
    nextStep();
  };

  const setEmailMessage = (message: string) => {
    setError("email", {
      type: "manual",
      message,
    });
    setFocus("email");
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 4,
          overflow: "auto",
        }}
        component="form"
        onSubmit={handleSubmit(handleData)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            gap: 3,
            mb: 2,
          }}
        >
          <Box
            component={LexterLogoIcon}
            height={54}
            sx={{
              color: "primary.main",
            }}
          />
          <Typography variant="body1">Vamos precisar de alguns dados para completar o seu cadastro.</Typography>
        </Box>
        {!invitationEmail && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
            <GoogleButton
              disabled={isLoading}
              setError={() => {
                setError("root", {
                  type: "manual",
                  message: "Erro ao tentar fazer login com o Google. Tente novamente mais tarde.",
                });
              }}
              isSomethingLoading={isLoading}
            >
              Continuar com o Google
            </GoogleButton>
          </Box>
        )}

        <TextField
          label="Nome"
          placeholder="Digite seu nome"
          {...register("name")}
          error={!!errors.name}
          helperText={errors.name?.message}
        />

        {invitationEmail ? (
          <TextField label="E-mail" type="email" value={invitationEmail} disabled={true} />
        ) : (
          <TextField
            label="E-mail"
            placeholder="Digite seu e-mail"
            type="email"
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        )}

        <TextField
          label="Telefone"
          placeholder="Digite seu telefone com DDD"
          {...register("phone")}
          InputProps={{
            onKeyUp: applyPhoneMask,
          }}
          error={!!errors.phone}
          helperText={errors.phone?.message}
          type="tel"
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Button type="submit" variant="contained" fullWidth isLoading={isLoading} endIcon={ChevronRightIcon}>
            Continuar
          </Button>
          <Button
            onClick={() => navigate(ROUTE_PATHS.LOGIN)}
            startIcon={ArrowBackIcon}
            type="button"
            variant="text"
            fullWidth
            sx={{
              color: "common.black",
            }}
          >
            Voltar
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};
