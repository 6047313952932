/* eslint-disable no-console */
import { Box, TextField, Typography } from "@mui/material";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import React, { useState } from "react";
import { WebToast } from "@//components/core/Toast";
import { useCreateApplicant } from "@/hooks/applicants/useCreateApplicant";
import { useCreateApplicants } from "@/hooks/applicants/useCreateApplicants";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { removeUndefinedValues } from "@/utils/removeUndefinedValues";
import { ContactPayload, contactPayloadSchema } from "@/services/applicant/types";
import CollaborationSVG from "@/assets/svgs/collaboration.svg?react";
import { applyPhoneMask } from "@/utils/applyInputMask";
import { Phone } from "@/core/Phone";
import { useLexZapActiveChat } from "@/hooks/lexZap/useLexZapActiveChat";
import { PhoneNumberField } from "@/pages/admin/ManageWhatsappUsers/components/PhoneNumberField";
import vCard from "vcf";
import { useDropzone } from "react-dropzone";
import { logger } from "@/core/logger";
import {
  UploadFile as UploadFileIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { ModalFooter } from "@/components/Modal/ModalFooter";

const defaultFormValue: ContactPayload = {
  name: "",
  phoneNumber: "",
};

interface ContactsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ContactsModal = (props: ContactsModalProps) => {
  if (!props.isOpen) return null;

  return <ContactsModalContent {...props} />;
};

function ContactsModalContent({ isOpen, onClose }: ContactsModalProps) {
  const { mutateAsync: createApplicant, isPending: isCreatingApplicant } = useCreateApplicant();
  const { setActiveChat } = useLexZapActiveChat();

  const [createdApplicant, setCreatedApplicant] = useState<{ name: string; phoneNumber: string }>();
  const { mutateAsync: createApplicants, isPending: isCreatingApplicants } = useCreateApplicants();
  const [isSuccessScreen, setIsSuccessScreen] = React.useState(false);
  const [isHoveringImport, setIsHoveringImport] = React.useState(false);
  const [importError, setImportError] = React.useState<boolean>(false);

  const [importedContacts, setImportedContacts] = React.useState<{
    fileName: string;
    data: ContactPayload[];
  }>({
    fileName: "",
    data: [],
  });

  const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) return;
    setImportError(false);
    const reader = new FileReader();
    const fileToBlob = async (file: File) => new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
    const blob = await fileToBlob(file);
    reader.readAsText(blob);
    reader.onload = () => {
      const buffer = reader.result;
      if (buffer) {
        const contacts = parseVCardToJson(buffer as string);
        setImportedContacts({
          fileName: file.name,
          data: contacts,
        });
      }
    };
  }, []);

  const handleRemove = () => {
    setImportedContacts({
      fileName: "",
      data: [],
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: onDrop,
    accept: {
      "text/vcard": [],
      "text/x-vcard": [],
    },
    onDropRejected() {
      WebToast.error(`O arquivo que você tentou adicionar não é compatível`);
      setImportError(true);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ContactPayload>({
    defaultValues: {
      ...defaultFormValue,
    },
    mode: "onChange",
    resolver: zodResolver(contactPayloadSchema),
  });

  const onSubmit = handleSubmit(async (submittedData) => {
    try {
      const data = removeUndefinedValues(submittedData);

      const phoneNumberWithCountryCode = Phone.new(data.phoneNumber.replace(/[^\d]+/g, ""));

      if (!phoneNumberWithCountryCode.isSuccess) {
        return WebToast.error("Número inválido");
      }

      const applicantPhoneNumber = phoneNumberWithCountryCode.getValue().toPersistence();
      await createApplicant({
        data: {
          ...data,
          phoneNumber: applicantPhoneNumber,
        },
      });
      setCreatedApplicant({
        name: data.name,
        phoneNumber: applicantPhoneNumber,
      });

      setIsSuccessScreen(true);
      return WebToast.success("Cadastro realizado com sucesso");
    } catch (err) {
      return WebToast.error("Falha ao cadastrar contato");
    }
  });

  const handleContactsCreate = async () => {
    if (importedContacts.data.length > 0) {
      try {
        await createApplicants({ data: importedContacts.data });
        setIsSuccessScreen(true);
        return WebToast.success("Cadastros importados com sucesso");
      } catch (err) {
        return WebToast.error("Falha ao importar contatos");
      }
    } else {
      await onSubmit();
    }
  };

  const handleStartConversation = () => {
    if (!createdApplicant) return;

    setActiveChat({
      endClientNumber: createdApplicant.phoneNumber,
      applicantName: createdApplicant.name,
      createdAt: Date.now(),
    });
    onClose();
  };

  const isLoading = isCreatingApplicant || isCreatingApplicants;

  const saveButtonEnabled = isValid || importedContacts.data.length > 0;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight
        title={"Novo contato"}
        size={"SMALL"}
        onClose={onClose}
        goBack={onClose}
        footer={
          !isLoading && (
            <>
              {isSuccessScreen ? (
                importedContacts.data.length ? null : (
                  <ModalFooter
                    confirmLabel={"Iniciar conversa"}
                    onConfirm={handleStartConversation}
                    cancelLabel="Fechar"
                    onCancel={() => onClose()}
                    saveButtonDisabled={false}
                  />
                )
              ) : (
                <ModalFooter
                  confirmLabel={importedContacts.data.length ? "Importar contatos" : "Salvar contato"}
                  onConfirm={handleContactsCreate}
                  cancelLabel="Cancelar"
                  onCancel={() => onClose()}
                  saveButtonDisabled={!saveButtonEnabled}
                />
              )}
            </>
          )
        }
        open={isOpen}
      >
        <Loading isLoading={isLoading}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <Typography variant="body1" fontWeight={500}>
                Você pode enviar uma mensagem proativa para um novo contato por esse número, desde que sua mensagem
                inclua o template pré-aprovado pelo Whatsapp
              </Typography>

              {isSuccessScreen ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <CollaborationSVG width={200} height={200} />

                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    Tudo certo
                  </Typography>

                  <Typography variant="body1" fontWeight={500}>
                    {importedContacts.data.length
                      ? `${importedContacts.data.length} novos contatos foram criados. Você pode iniciar uma conversa pelo chat normalmente.`
                      : "Seu novo contato acaba de ser criado na plataforma Lexter."}
                  </Typography>
                </Box>
              ) : (
                <>
                  <TextField
                    placeholder="Nome"
                    label="Nome"
                    {...register("name")}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />

                  <PhoneNumberField
                    placeholder="(99) 99999-9999"
                    {...register("phoneNumber")}
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber?.message}
                    label="Telefone"
                    type="tel"
                    InputProps={{
                      onKeyUp: applyPhoneMask,
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                      }}
                    >
                      Importe mais de um contato:
                    </Typography>

                    <Typography variant="body1">
                      Você pode exportar seu contatos nesse formato direto da sua conta google, ou do seu telefone;{" "}
                      <Typography
                        variant="body1"
                        sx={{
                          color: "primary.main",
                          textDecoration: "underline",
                          display: "inline",
                          textUnderlineOffset: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window.open(ROUTE_PATHS.WHATSAPP_ASSISTANT_EXPORT_CONTACTS_GUIDE, "_blank");
                        }}
                      >
                        entenda como.
                      </Typography>
                    </Typography>
                    {importedContacts.data.length ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "8px",
                          width: "100%",
                          height: "136px",
                        }}
                      >
                        <UploadFileIcon sx={{ fontSize: "24px", color: "primary.main" }} />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            gap: "4px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              width: "100%",
                              fontSize: "16px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "pre-wrap",
                              MozOrient: "vertical",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              wordBreak: "break-word",
                            }}
                          >
                            {importedContacts.fileName}
                          </Typography>
                          <Typography variant="body1" sx={{ color: "grey.800" }}>
                            {importedContacts.data.length} contatos encontrados
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                          <DeleteIcon
                            onClick={handleRemove}
                            sx={{ cursor: "pointer", fontSize: "24px", color: "grey.400" }}
                          />
                          <CheckCircleIcon sx={{ fontSize: "24px", color: "success.main" }} />
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "136px",
                          width: "100%",
                          overflowX: "hidden",
                          borderRadius: "8px",
                          border: "1px dashed",
                          cursor: "pointer",
                          borderColor: importError ? "error.main" : "text.disabled",
                          backgroundColor: importError ? "rgba(211, 47, 47, 0.04)" : "background.paper",
                        }}
                        onMouseEnter={() => setIsHoveringImport(true)}
                        onMouseLeave={() => setIsHoveringImport(false)}
                        onClick={open}
                        {...getRootProps()}
                      >
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px" }}>
                          <UploadFileIcon
                            sx={{ fontSize: "24px", color: importError ? "error.main" : "primary.main" }}
                          />

                          <Typography
                            variant="body1"
                            sx={{
                              textDecoration: isHoveringImport ? "none" : "underline",
                              color: "primary.main",
                            }}
                          >
                            Clique para buscar
                          </Typography>
                        </Box>

                        <Typography variant="body1">ou solte seu arquivo aqui</Typography>

                        <Typography
                          variant="body1"
                          sx={{
                            color: importError ? "error.main" : "text.disabled",
                            fontWeight: 500,
                          }}
                        >
                          {importError ? "Nenhum contato encontrado" : "VCF"}
                        </Typography>

                        <input
                          {...getInputProps()}
                          maxLength={1}
                          max={1}
                          style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            opacity: 0,
                            zIndex: -1,
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Loading>
      </ModalRight>
    </Box>
  );
}

const parseVCardToJson = (content: string): ContactPayload[] => {
  try {
    const card = vCard.parse(content);
    console.log(card.length);
    const contacts = card.map((c) => {
      let phoneNumber = c.get("tel").valueOf();
      if (Array.isArray(phoneNumber)) {
        for (const phone of phoneNumber) {
          const validPhone = validPhoneNumber(phone.valueOf().toString());
          if (validPhone) {
            phoneNumber = validPhone;
          }
        }
      }
      const validPhone = validPhoneNumber(phoneNumber.toString());
      if (validPhone) {
        return {
          name: c.get("fn").valueOf().toString(),
          phoneNumber: validPhone,
        };
      }
      return undefined;
    });
    return contacts.filter((contact) => contact !== undefined);
  } catch (error) {
    logger.error("Error parsing vCard to JSON", { error });
    return [];
  }
};

const validPhoneNumber = (phoneNumber: string) => {
  let onlyNumbers = phoneNumber.replace(/[^\d]+/g, "");
  if (onlyNumbers.startsWith("55")) {
    onlyNumbers = onlyNumbers.substring(2);
  }
  if (onlyNumbers.startsWith("015")) {
    onlyNumbers = onlyNumbers.substring(3);
  }
  if (onlyNumbers.startsWith("0")) {
    onlyNumbers = onlyNumbers.substring(1);
  }
  const phone = Phone.new(onlyNumbers);
  if (phone.isSuccess) {
    return phone.getValue().toPersistence();
  }
  return undefined;
};
