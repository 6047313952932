import React from "react";
import * as MUI from "@mui/material";
import * as MUIIcons from "@mui/icons-material";
import { LeadStage } from "@/pages/WhatsappAssistantPageV2/components/TableView/types";
import * as types from "./types";

export const LeadStageFilter: React.FC<types.LeadStageFilterProps> = ({
  chatFilters,
  setChatFilters,
  availableLeadStages = [],
}) => {
  // Get official lead stages from the enum
  const officialLeadStages = Object.values(LeadStage);

  // Separate official and unofficial lead stages
  const unofficialLeadStages = availableLeadStages
    .filter((stage) => !officialLeadStages.includes(stage as LeadStage))
    .sort((a, b) => a.localeCompare(b, "pt-BR", { sensitivity: "base" }));

  // Combine stages with official ones first (in enum order), then unofficial ones (alphabetically)
  const orderedLeadStages = [...officialLeadStages, ...unofficialLeadStages];

  // Handle removing a lead stage
  const handleRemoveLeadStage = (stage: string) => {
    setChatFilters((prev) => ({
      ...prev,
      leadStages: prev.leadStages?.filter((item) => item !== stage),
    }));
  };

  // Handle adding lead stages
  const handleAddLeadStages = (selectedOptions: string[]) => {
    setChatFilters((prev) => ({
      ...prev,
      leadStages: selectedOptions,
    }));
  };

  return (
    <MUI.Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, flex: 1 }}>
      <MUI.Typography variant="body2" color="text.secondary" sx={{ minWidth: "90px" }}>
        Funil:
      </MUI.Typography>

      <MUI.Box sx={{ flexGrow: 1 }}>
        {/* Display selected lead stages as chips */}
        {chatFilters.leadStages?.length > 0 && (
          <MUI.Box
            sx={{
              display: "flex",
              gap: 1,
              flexWrap: "wrap",
              mb: 1,
            }}
          >
            {chatFilters.leadStages.map((stage) => (
              <MUI.Chip
                key={stage}
                label={stage}
                onDelete={() => handleRemoveLeadStage(stage)}
                sx={{
                  backgroundColor: "secondary.main",
                  color: "text.primary",
                }}
                deleteIcon={<MUIIcons.CloseOutlined />}
                size="small"
              />
            ))}
          </MUI.Box>
        )}

        {/* Lead stage selection autocomplete */}
        <MUI.Autocomplete
          multiple
          options={orderedLeadStages}
          value={chatFilters.leadStages}
          noOptionsText="Nenhuma opção encontrada"
          disablePortal
          onChange={(_, selectedOptions) => handleAddLeadStages(selectedOptions)}
          renderInput={(params) => <MUI.TextField {...params} placeholder="Selecionar estágio" size="small" />}
          renderTags={() => null}
          sx={{ flexGrow: 1, width: "100%" }}
          renderOption={(props, option) => (
            <li {...props}>
              <MUI.Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  ...(officialLeadStages.includes(option as LeadStage) ? { fontWeight: "bold" } : {}),
                }}
              >
                {option}
                {!officialLeadStages.includes(option as LeadStage) && (
                  <MUI.Typography variant="body2" color="text.secondary" sx={{ ml: "auto" }}>
                    (não oficial)
                  </MUI.Typography>
                )}
              </MUI.Box>
            </li>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
};
