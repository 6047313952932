import { datadogLogs, StatusType } from "@datadog/browser-logs";
import { env } from "@/constants/environment";
import { removeUndefinedValues } from "@/utils/removeUndefinedValues";

export function init() {
  const serviceBaseName = "lexter-copilot-addin-web";

  if (env.PUBLIC_DATADOG_CLIENT_ID) {
    //Documentation: https://docs.datadoghq.com/logs/log_collection/javascript/
    datadogLogs.init({
      clientToken: env.PUBLIC_DATADOG_CLIENT_ID || "",
      site: "datadoghq.com",
      sessionSampleRate: 100,
      service: `${serviceBaseName}`,
      env: env.DATADOG_ENV,
      version: env.DATADOG_VERSION,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: "all",
      forwardReports: "all",
    });

    datadogLogs.logger.setHandler("http");
    datadogLogs.logger.setLevel("debug");
  }
}

export function setProfile(id: string, companyId: string, name: string, email?: string) {
  if (env.PUBLIC_DATADOG_CLIENT_ID) {
    datadogLogs.setUser({
      id,
      name,
      companyId,
      email: email || "-",
    });
  }
}

export function clearProfile() {
  if (env.PUBLIC_DATADOG_CLIENT_ID) {
    datadogLogs.clearUser();
  }
}

export function debug(message: string, { messageContext }: { messageContext?: object } = {}) {
  log(message, messageContext, "debug");
}

export function info(message: string, { messageContext }: { messageContext?: object } = {}) {
  log(message, messageContext, "info");
}

export function warn(message: string, { messageContext, error }: { messageContext?: object; error?: unknown } = {}) {
  log(message, messageContext, "warn", error);
}

export function error(message: string, { messageContext, error }: { messageContext?: object; error?: unknown } = {}) {
  log(message, messageContext, "error", error);
}

function log(message: string, messageContext?: object, status?: StatusType, error?: unknown) {
  if (env.PUBLIC_DATADOG_CLIENT_ID) {
    datadogLogs.logger.log(message, messageContext, status, error instanceof Error ? error : undefined);
  } else {
    const options = removeUndefinedValues({
      messageContext,
      status,
      error: error instanceof Error ? error : undefined,
    });
    switch (status) {
      case "error":
        // eslint-disable-next-line no-console
        console.error(message, options);
        break;
      case "warn":
        // eslint-disable-next-line no-console
        console.warn(message, options);
        break;
      case "debug":
        // eslint-disable-next-line no-console
        console.debug(message, options);
        break;
      case "info":
        // eslint-disable-next-line no-console
        console.info(message, options);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(message, options);
    }
  }
}

export const logger = {
  init,
  setProfile,
  clearProfile,
  debug,
  info,
  warn,
  error,
};
