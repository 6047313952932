export const base64ToFile = (base64: string) => {
  const [data, name] = base64.split(";");
  const [, contentType] = data!.split("data:");
  const [, fileName] = name!.split("name=");
  const base64Bytes = atob(base64.split(",")[1]!);
  const byteNumbers = new Array(base64Bytes.length);
  for (let i = 0; i < base64Bytes.length; i++) {
    byteNumbers[i] = base64Bytes.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new File([byteArray], decodeURIComponent(fileName!), { type: contentType });
};
