import { useMutation } from "@tanstack/react-query";
import { WhatsappServerService } from "@/services/whatsappServer";
import { StartAIAgentParams } from "@/services/whatsappServer/types";

export const useStartAIAgent = () => {
  return useMutation({
    mutationKey: ["startAIAgent"],
    mutationFn: async (params: StartAIAgentParams) => await WhatsappServerService.chats.startAIAgent(params),
  });
};
