import React from "react";

import { Box, Typography } from "@mui/material";

import CasesNotFoundIcon from "@/assets/svgs/cases-not-found.svg?react";
import { LexZapChat } from "@/hooks/lexZap/types";

interface EmptyChatProps {
  chats?: LexZapChat[];
}

export const EmptyChat: React.FC<EmptyChatProps> = ({ chats }) => {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#F9F9F9",
        height: "100%",
        width: "100%",
      }}
    >
      {!chats?.length && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            padding: 2,
            margin: "auto",
            width: "444px",
            maxWidth: "100%",
          }}
        >
          <Box
            sx={{
              paddingX: 10.5,
            }}
          >
            <CasesNotFoundIcon
              style={{
                flexShrink: 0,
                width: "100%",
                cursor: "pointer",
                marginBottom: "48px",
              }}
            />
          </Box>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "700",
              fontSize: "22px",
            }}
          >
            Você não possui conversas ou casos
          </Typography>
          <Typography variant="body" sx={{ whiteSpace: "normal" }}>
            Um novo caso vai aparecer aqui assim que tivermos sua aprovação em algum resumo de contato com cliente
          </Typography>
        </Box>
      )}
    </Box>
  );
};
