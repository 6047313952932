import React, { ChangeEvent, useMemo } from "react";
import { ModalRight } from "@/components/Modal/ModalRight";
import { Loading } from "@/components/Loading";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { WebToast } from "@/components/core/Toast";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { useCreateCompanyMember } from "@/hooks/company/useCreateCompanyMember";
import { CloseOutlined } from "@mui/icons-material";
import { Email } from "@/core/Email";
import { ActivityArea, activityAreaOptions, Department } from "@/hooks/company/types";
import { CustomAccordion, PermissionDescription, PermissionItem } from "../PermissionAccordion";
import { PERMISSION_GROUPS } from "../PermissionAccordion/types";
import { Permission } from "@/contexts/AuthContext";

type Form = {
  email: string;
  isAdmin?: boolean;
  department?: Department;
  activityAreas: { value: ActivityArea; label: string }[];
  permissions: Permission[];
};

export const NewMemberModal = ({ onClose }: { onClose: () => void }) => {
  const [form, setForm] = React.useState<Form>({
    email: "",
    activityAreas: [],
    isAdmin: false,
    permissions: [],
  });
  const { mutateAsync: createCompanyMember, isPending: isLoading } = useCreateCompanyMember();
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(true);

  const handleCreateCompanyMember = async () => {
    if (!form.email || !form.department || !form.permissions.length) {
      return;
    }

    await createCompanyMember({
      email: form.email,
      department: form.department,
      isAdmin: form.isAdmin,
      activityAreas: form.department === Department.LEGAL ? form.activityAreas?.map((area) => area.value) : undefined,
      permissions: form.permissions,
    });

    onClose();
    return WebToast.success("Convite enviado com sucesso");
  };

  const handleChangeForm = <K extends keyof Form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      if (prop === "isAdmin") {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.checked,
        }));
      } else {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.value,
        }));
      }
    };
  };

  const saveButtonDisabled = useMemo(() => {
    return (
      form.email === "" ||
      !isValidEmail ||
      !form.department ||
      (form.department === Department.LEGAL && form.activityAreas?.length === 0) ||
      !form.permissions.length
    );
  }, [form.activityAreas?.length, form.department, form.email, isValidEmail, form.permissions.length]);

  const handlePermissionChange = (permissionId: Permission, checked: boolean) => {
    setForm((prevForm) => {
      const newPermissions = checked
        ? [...prevForm.permissions, permissionId]
        : prevForm.permissions.filter((p) => p !== permissionId);

      return {
        ...prevForm,
        permissions: newPermissions,
      };
    });
  };

  const handleFullAccessChange = (groupId: string, checked: boolean) => {
    const group = PERMISSION_GROUPS.find((g) => g.id === groupId);
    if (!group) return;

    setForm((prevForm) => {
      const setPermissions = new Set(prevForm.permissions);

      if (checked) {
        group.permissions.forEach((permission) => setPermissions.add(permission.id));
      } else {
        group.permissions.forEach((permission) => setPermissions.delete(permission.id));
      }

      return {
        ...prevForm,
        permissions: Array.from(setPermissions.values()),
      };
    });
  };

  const isGroupFullAccess = (groupId: string): boolean => {
    const group = PERMISSION_GROUPS.find((g) => g.id === groupId);
    if (!group) return false;

    return group.permissions.every((permission) => form.permissions.includes(permission.id));
  };

  return (
    <ModalRight
      title={"Novo colaborador"}
      size={"MEDIUM"}
      onClose={onClose}
      footer={
        !isLoading && (
          <ModalFooter
            confirmLabel={"Convidar"}
            onConfirm={handleCreateCompanyMember}
            cancelLabel="Cancelar"
            onCancel={onClose}
            saveButtonDisabled={saveButtonDisabled}
          />
        )
      }
      open={true}
    >
      <Loading isLoading={isLoading}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: "16px",
            marginBottom: "8px",
          }}
        >
          <Typography variant="body2">
            Todos os colaboradores podem criar e visualizar as peças criadas na sua plataforma Lexter
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "16px",
              }}
            >
              Pra onde enviamos o convite?
            </Typography>
            <TextField
              placeholder="E-mail"
              onChange={(e) => {
                handleChangeForm("email")(e as ChangeEvent<HTMLInputElement>);
                setIsValidEmail(e.target.value === "" ? true : Email.fromString(e.target.value).isSuccess);
              }}
              value={form.email}
              label="E-mail"
              error={!isValidEmail}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "16px",
                }}
              >
                Setor
              </Typography>
              <Typography variant="body2">
                Os setores influenciam especialmente no redirecionamento de um atendimento para um colaborador
              </Typography>
            </Box>
            <FormControl>
              <RadioGroup onChange={handleChangeForm("department")}>
                <FormControlLabel value={Department.ADMINISTRATIVE} control={<Radio />} label="Administrativo" />
                <FormControlLabel value={Department.FINANCIAL} control={<Radio />} label="Financeiro" />
                <FormControlLabel value={Department.LEGAL} control={<Radio />} label="Jurídico" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {form.department === Department.LEGAL && (
              <>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    Área de atuação
                  </Typography>
                  <Typography variant="body2">
                    A área de atuação impacta no redirecionamento ou não de um atendimento para você
                  </Typography>
                </Box>
                <Box>
                  <Autocomplete
                    disablePortal
                    options={activityAreaOptions}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <TextField {...params} label="Comece a digitar para buscar" />}
                    multiple
                    value={form.activityAreas}
                    noOptionsText="Nenhuma opção encontrada"
                    sx={{
                      "& .MuiChip-root": {
                        visibility: "hidden",
                        display: "none",
                      },
                      "& .MuiChip-label": {
                        display: "none",
                        visibility: "hidden",
                      },
                    }}
                    onChange={(_, value) => {
                      setForm((prevState) => ({
                        ...prevState,
                        activityAreas: value,
                      }));
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  {form.activityAreas.map((areaOption) => (
                    <Chip
                      key={areaOption.value}
                      label={areaOption.label}
                      onDelete={() => {
                        setForm((prevState) => ({
                          ...prevState,
                          activityAreas: prevState.activityAreas.filter((item) => item.value !== areaOption.value),
                        }));
                      }}
                      sx={{
                        backgroundColor: "primary.main",
                        color: "common.white",
                      }}
                      deleteIcon={<CloseOutlined />}
                    />
                  ))}
                </Box>
              </>
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", paddingBottom: "16px" }}>
            <Typography variant="body1" fontSize="16px">
              Permissões
            </Typography>

            {PERMISSION_GROUPS.map((group) => (
              <CustomAccordion key={group.id} title={group.title}>
                <PermissionDescription>{group.description}</PermissionDescription>

                {group.permissions.length > 1 && (
                  <>
                    <PermissionItem
                      title={group.fullAccessTitle ?? ""}
                      description={group.description}
                      isChecked={isGroupFullAccess(group.id)}
                      onChange={(checked) => handleFullAccessChange(group.id, checked)}
                    />

                    {group.permissions.map((permission) => (
                      <PermissionItem
                        key={permission.id}
                        title={permission.title}
                        description={permission.description}
                        isChecked={form.permissions.includes(permission.id)}
                        onChange={(checked) => handlePermissionChange(permission.id, checked)}
                      />
                    ))}
                  </>
                )}

                {group.permissions.length === 1 && (
                  <PermissionItem
                    title={group.permissions[0]!.title}
                    description={group.permissions[0]!.description}
                    isChecked={form.permissions.includes(group.permissions[0]!.id)}
                    onChange={(checked) => handlePermissionChange(group.permissions[0]!.id, checked)}
                  />
                )}
              </CustomAccordion>
            ))}
          </Box>
        </Box>
      </Loading>
    </ModalRight>
  );
};
