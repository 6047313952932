import React from "react";
import { Badge, Box, IconButton, TextField, Typography } from "@mui/material";
import { FilterList as FilterListIcon } from "@mui/icons-material";
import { useDebounce } from "@/hooks/useDebounce";

export const CustomCasesListToolbar = ({
  disabled,
  onSearch,
  openFilterCasesModal,
  filterChanges,
}: {
  disabled: boolean;
  onSearch: (text: string) => void;
  openFilterCasesModal: () => void;
  filterChanges: number;
}) => {
  const [searchValue, setSearchValue] = React.useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);

  React.useEffect(() => {
    onSearch(debouncedSearchValue);
  }, [debouncedSearchValue, onSearch]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const newValue = event.target.value;
    setSearchValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 2,
        py: 1,
      }}
    >
      <TextField
        sx={{
          flexGrow: 2,
          maxWidth: "500px",
          mt: 1,
        }}
        placeholder="Pesquisar por nome do caso, cliente ou número do processo"
        fullWidth
        value={searchValue}
        variant="outlined"
        label="Pesquisar"
        disabled={disabled}
        onChange={handleSearch}
      />
      <Badge badgeContent={filterChanges} color="primary">
        <IconButton
          onClick={openFilterCasesModal}
          sx={{
            m: -1,
            borderRadius: 0.4,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
            }}
            variant="body"
          >
            Filtrar
          </Typography>
          <FilterListIcon
            sx={{
              color: "common.coral",
              cursor: "pointer",
            }}
          />
        </IconButton>
      </Badge>
    </Box>
  );
};
