import { logger } from "@/core/logger";
import { AuthService } from "@/services/auth";
import { AxiosInstance } from "axios";

const getAuthorizationHeader = async ({ withLoginType = true }: { withLoginType?: boolean } = {}) => {
  try {
    const { loginType, token } = await AuthService.getValidToken();
    if (!token || !loginType) {
      logger.warn("Token or login type not found getting authorization header");
      return undefined;
    }
    const apiToken = withLoginType ? `${loginType}:${token}` : token;
    return `bearer ${apiToken}`;
  } catch (error) {
    logger.error("Error getting authorization header", { error });
    return undefined;
  }
};

export const withAuthorization = ({
  instance,
  withLoginType = true,
  logoutOnUnauthorized = true,
}: {
  instance: AxiosInstance;
  withLoginType?: boolean;
  logoutOnUnauthorized?: boolean;
}) => {
  instance.interceptors.request.use(async (reqConfig) => {
    const hasAuthorizationHeader = reqConfig.headers.getAuthorization();
    if (hasAuthorizationHeader) {
      return reqConfig;
    }
    const authorizationHeader = await getAuthorizationHeader({ withLoginType });
    if (authorizationHeader) {
      reqConfig.headers.setAuthorization(authorizationHeader);
    } else {
      return Promise.reject(new Error("Authorization header not found"));
    }
    return reqConfig;
  });
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401 || error.response?.status === 403) {
        if (originalRequest._token_refreshed) {
          if (logoutOnUnauthorized) {
            await AuthService.logout({ redirectBack: true });
          }
          return Promise.reject(error);
        } else {
          originalRequest._token_refreshed = true; // Mark the request with token refreshed to avoid infinite loops.
          await AuthService.refreshAuthToken();
          return instance(originalRequest); // Rerun the request.
        }
      }
      return Promise.reject(error);
    }
  );
  return instance;
};
