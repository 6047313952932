import { ChatStage, TicketStatus } from "@/hooks/lexZap/types";

import { ChatStatus, LexZapChat } from "@/hooks/lexZap/types";
import { AssignedFilter, ChatQuickFilters, ChatQuickFiltersCount } from "../../components/QuickFilters/types";

const normalizeString = (str: string) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

export const applyChatFilters = ({
  chats,
  status,
  searchTerm,
  filters,
}: {
  chats: LexZapChat[];
  status: ChatStatus;
  searchTerm: string;
  filters: ChatQuickFilters;
}) => {
  let filtered = chats;

  if (status === ChatStatus.ARCHIVED) {
    filtered = filtered.filter((item) => {
      return item.status === ChatStatus.ARCHIVED || item.chatStage === ChatStage.ARCHIVED;
    });
  } else {
    filtered = filtered.filter((item) => {
      return item.status === ChatStatus.ACTIVE && item.chatStage !== ChatStage.ARCHIVED;
    });
  }

  if (searchTerm) {
    const normalizedSearchTerm = normalizeString(searchTerm);
    filtered = filtered.filter(
      (item) =>
        normalizeString(item.applicantName || "").includes(normalizedSearchTerm) ||
        item.endClientNumber.includes(normalizedSearchTerm)
    );
  }

  if (!filters.ticketStatus.abandoned) {
    filtered = filtered.filter((item) => item.chatStage !== ChatStage.ABANDONED);
  }

  if (!filters.ticketStatus.aiService) {
    filtered = filtered.filter((item) => item.chatStage !== ChatStage.AI_SERVICE);
  }

  if (!filters.ticketStatus.redirectedHuman) {
    filtered = filtered.filter((item) => item.chatStage !== ChatStage.REDIRECTED);
  }

  if (!filters.ticketStatus.initialTriage) {
    filtered = filtered.filter((item) => item.chatStage !== ChatStage.INITIAL_TRIAGE);
  }

  if (!filters.ticketStatus.closed) {
    filtered = filtered.filter((item) => item.lastTicket?.status !== TicketStatus.CLOSED);
  }

  if (!filters.answered.answered) {
    filtered = filtered.filter((item) => !!item.unansweredMessagesCount);
  }

  if (!filters.answered.unanswered) {
    filtered = filtered.filter((item) => !item.unansweredMessagesCount);
  }

  if (filters.responsibles.length) {
    filtered = filtered.filter(
      (item) => item.lastTicket?.responsibleUserId && filters.responsibles.includes(item.lastTicket.responsibleUserId)
    );
  } else if (filters.assigned === AssignedFilter.NOT_ASSIGNED) {
    filtered = filtered.filter((item) => !item.lastTicket?.responsibleUserId);
  }
  // Os filtros filters.assigned === AssignedFilter.ALL e filters.assigned === AssignedFilter.ASSIGNED_TO_ME
  // não são aplicados, pois são aplicados através do filters.responsibles!

  if (filters.chatCreationDateStart) {
    filtered = filtered.filter((item) => {
      const chatCreationDate = item.createdAt;
      return chatCreationDate >= (filters.chatCreationDateStart || 0);
    });
  }

  if (filters.chatCreationDateEnd) {
    filtered = filtered.filter((item) => {
      const chatCreationDate = item.createdAt;
      const endDate = filters.chatCreationDateEnd
        ? new Date(filters.chatCreationDateEnd).setHours(23, 59, 59, 999)
        : Infinity;

      return chatCreationDate <= endDate;
    });
  }

  return filtered;
};

export const calculateChatFiltersCount = ({
  chats,
  companyTeam,
}: {
  chats: LexZapChat[];
  companyTeam: { id: string; name: string }[];
}): ChatQuickFiltersCount => {
  const filtersCount: ChatQuickFiltersCount = {
    ticketStatus: {
      abandoned: 0,
      aiService: 0,
      redirectedHuman: 0,
      initialTriage: 0,
      closed: 0,
    },
    answered: {
      answered: 0,
      unanswered: 0,
    },
    responsibles: {
      ...companyTeam.reduce(
        (acc, member) => {
          acc[member.id] = 0;
          return acc;
        },
        {} as Record<string, number>
      ),
    },
  };

  chats.forEach((chat) => {
    if (chat.chatStage === ChatStage.ABANDONED) {
      filtersCount.ticketStatus.abandoned++;
    }

    if (chat.chatStage === ChatStage.AI_SERVICE) {
      filtersCount.ticketStatus.aiService++;
    }

    if (chat.chatStage === ChatStage.REDIRECTED) {
      filtersCount.ticketStatus.redirectedHuman++;
    }

    if (chat.chatStage === ChatStage.INITIAL_TRIAGE) {
      filtersCount.ticketStatus.initialTriage++;
    }

    if (chat.lastTicket?.status === TicketStatus.CLOSED) {
      filtersCount.ticketStatus.closed++;
    }

    if (chat.unansweredMessagesCount) {
      filtersCount.answered.unanswered++;
    }

    if (!chat.unansweredMessagesCount) {
      filtersCount.answered.answered++;
    }

    if (chat.lastTicket?.responsibleUserId) {
      filtersCount.responsibles[chat.lastTicket.responsibleUserId] =
        (filtersCount.responsibles[chat.lastTicket.responsibleUserId] || 0) + 1;
    }
  });

  return filtersCount;
};

export const calculateChatFilterChanges = ({
  filters,
  defaultFilters,
}: {
  filters: ChatQuickFilters;
  defaultFilters: ChatQuickFilters;
}) => {
  let changes = 0;

  if (filters.ticketStatus.abandoned !== defaultFilters.ticketStatus.abandoned) {
    changes++;
  }
  if (filters.ticketStatus.aiService !== defaultFilters.ticketStatus.aiService) {
    changes++;
  }
  if (filters.ticketStatus.redirectedHuman !== defaultFilters.ticketStatus.redirectedHuman) {
    changes++;
  }
  if (filters.ticketStatus.initialTriage !== defaultFilters.ticketStatus.initialTriage) {
    changes++;
  }
  if (filters.ticketStatus.closed !== defaultFilters.ticketStatus.closed) {
    changes++;
  }
  if (filters.assigned !== defaultFilters.assigned && filters.assigned !== AssignedFilter.ASSIGNED_TO_ME) {
    // Tiramos o filtro filters.assigned === AssignedFilter.ASSIGNED_TO_ME, pois ele já relfete através do filters.responsibles
    changes++;
  }
  if (filters.answered.answered !== defaultFilters.answered.answered) {
    changes++;
  }
  if (filters.answered.unanswered !== defaultFilters.answered.unanswered) {
    changes++;
  }
  if (filters.responsibles.length !== defaultFilters.responsibles.length) {
    changes++;
  }
  if (
    filters.chatCreationDateStart !== defaultFilters.chatCreationDateStart ||
    filters.chatCreationDateEnd !== defaultFilters.chatCreationDateEnd
  ) {
    changes++;
  }

  return changes;
};
