import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CaseService } from "@/services/case";
import { getCasePlatformFilesQueryKey } from "@/hooks/case/useCasePlatformFiles";
import { PlatformFile } from "../useCasePlatformFiles/types";

export const useDeleteCasePlatformFile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["deleteCaseFile"],
    mutationFn: async ({ caseId, fileId }: { caseId: string; fileId: string }) => {
      return {
        ...(await CaseService.deleteCasePlatformFile({ caseId, fileId })),
        caseId,
      };
    },
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: getCasePlatformFilesQueryKey({ caseId: data.caseId }) });
      const previousCaseFiles = queryClient.getQueryData<string[]>(
        getCasePlatformFilesQueryKey({ caseId: data.caseId })
      );

      queryClient.setQueryData(getCasePlatformFilesQueryKey({ caseId: data.caseId }), (old: PlatformFile[]) => {
        return (old || [])?.filter((o) => o.id !== data.fileId);
      });

      return { previousCaseFiles };
    },
    onError: (_error, _newCase, context) => {
      queryClient.setQueryData(getCasePlatformFilesQueryKey({ caseId: _newCase.caseId }), context?.previousCaseFiles);
    },
  });
};
