import { PageLayout } from "@/components/PageLayout";
import { Typography } from "@mui/material";
import React from "react";
import { EventsTable } from "./components/EventsTable";

export const ApplicantEventsPage: React.FC = () => {
  return (
    <PageLayout
      contentProps={{
        fullWidth: true,
      }}
      sx={{
        "& .page-layout-container": {
          padding: 5,
        },
      }}
      variant="secondary"
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        Eventos de Requerentes
      </Typography>

      <Typography variant="body1" sx={{ mb: 4, color: "text.secondary" }}>
        Esta página permite gerenciar e visualizar todos os eventos relacionados aos requerentes. Você pode filtrar,
        editar e criar novos eventos conforme necessário.
      </Typography>

      <EventsTable />
    </PageLayout>
  );
};
