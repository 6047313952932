import { ApplicantService } from "@/services/applicant";
import { useAuthStore } from "@/stores/useAuthStore";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSocket } from "@/contexts/WebSocketContext";
import { WhatsappUpdateType } from "@/contexts/WebSocketContext/types/whatsapp.types";
import { Applicant } from "@/services/applicant/types";

export const getApplicantsQueryKey = () => ["applicants"];

export const useApplicants = () => {
  const { isLoggedIn } = useAuthStore();
  const queryClient = useQueryClient();

  useSocket({
    onWhatsAppUpdate: async (update) => {
      if (update.type === WhatsappUpdateType.APPLICANT_CREATED) {
        const applicant = await ApplicantService.getApplicantById(update.applicantId);

        if (applicant) {
          queryClient.setQueryData(getApplicantsQueryKey(), (oldData: Applicant[] | undefined) => {
            if (!oldData) return oldData;

            const applicantIndex = oldData.findIndex((item) => item.id === applicant.id);

            if (applicantIndex !== -1) {
              return [...oldData.slice(0, applicantIndex), applicant, ...oldData.slice(applicantIndex + 1)];
            }

            return [...oldData, applicant];
          });
        }
      }
    },
  });

  return useQuery({
    queryKey: getApplicantsQueryKey(),
    queryFn: async () => {
      const applicants = await ApplicantService.getApplicants();
      return applicants.sort((a, b) => a.name.localeCompare(b.name));
    },
    enabled: isLoggedIn,
    staleTime: Infinity,
  });
};
