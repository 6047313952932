import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Paper, styled, Tooltip, Typography } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { PageLayout } from "@/components/PageLayout";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useCredits } from "@/hooks/credits/useCredits";
import { useOpenBilling } from "@/hooks/useOpenBilling";
import { MenuOption, useMenuOptions } from "./hooks/useMenuOptions";

import { PlanChips } from "@/pages/Plans/Plan/PlanChips";
import { usePlanChips } from "@/hooks/usePlanChips";
import { planTitles } from "@/hooks/credits";
import { PlanType } from "@/hooks/credits/types";
import { getPlanDaysLeftDescription } from "@/utils/plans";

export function ClientConfig() {
  const { menuOptions } = useMenuOptions();
  const {
    planName,
    isTestingPlan,
    nextBillingDate,
    usersQuota,
    usersCount,
    handleOpenUsersScreen,
    isLoadingCredits,
    isLoadingUsers,
  } = usePlanChips();

  return (
    <PageLayout
      contentSx={{
        width: "clamp(816px, 50%, 100%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "left",
          alignSelf: "center",
        }}
      >
        <Typography variant="h3">Configurações</Typography>
      </Box>

      <PlanContainer>
        <Typography variant="body1">Veja abaixo as informações do seu plano atual:</Typography>
        <PlanChipsContainer>
          <PlanChips
            handleOpenUsersScreen={handleOpenUsersScreen}
            planName={planName}
            isTestingPlan={isTestingPlan}
            nextBillingDate={nextBillingDate}
            usersCount={usersCount}
            usersQuota={usersQuota}
            isLoadingPlan={isLoadingCredits}
            isLoadingUsers={isLoadingUsers}
          />
        </PlanChipsContainer>
      </PlanContainer>

      {menuOptions.map((menuOption, index) => {
        return <MenuOptions key={index} label={menuOption.label} options={menuOption.options} />;
      })}

      <Outlet />
    </PageLayout>
  );
}

function MenuOptions({ label, options }: MenuOption) {
  const navigate = useNavigate();
  const { data: credits } = useCredits();
  const openBilling = useOpenBilling();

  const planType = credits?.companyPlan?.plan;
  const nextBillingDate = credits?.companyPlan?.nextBillingDate;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingBottom: "24px",
        alignSelf: "center",
      }}
    >
      <Paper
        sx={{
          p: "20px 20px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "info.main",
          color: "info.contrastText",
          borderRadius: "8px 8px 0 0",
          height: "52px",
        }}
      >
        <Typography variant="subtitle1">{label}</Typography>
      </Paper>
      {options
        .filter((option) => !option.hidden)
        .map((option, index) => {
          const isLastElement = index === options.length - 1;
          return (
            <Tooltip key={index} title={option.tooltip ? option.tooltip : ""} arrow placement="top">
              <Paper
                sx={{
                  p: "20px 20px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  backgroundColor: "common.white",
                  borderRadius: isLastElement ? "0 0 8px 8px" : "0",
                  borderBottom: isLastElement ? "" : "1px solid #E5E5E5",
                  height: "52px",
                  ":hover": {
                    backgroundColor: "orange.light.5",
                  },
                }}
                onClick={() => {
                  if (option.blockedAction) return;

                  if (option.openBilling) return openBilling();

                  if (option.path) {
                    if (option.replaceRoute) {
                      return navigate(option.path, { replace: true });
                    }

                    return navigate(option.path);
                  }
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Box>
                    <Typography
                      sx={{
                        color: "grey.900",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        ...(option.blockedAction && {
                          color: "grey.500",
                          cursor: "not-allowed",
                          pointerEvents: "none",
                        }),
                      }}
                      variant="body1"
                    >
                      {option.label}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {option.withPlanChip && !!planType && (
                        <PlanArea
                          planType={planType}
                          nextBillingDate={nextBillingDate}
                          blockedAction={!!option.blockedAction}
                        />
                      )}
                    </Typography>
                  </Box>
                  {option.blockedAction && <LockOutlined sx={{ color: "grey.500", width: 24 }} />}
                </Box>

                <KeyboardArrowRightIcon sx={{ color: "text.primary" }} />
              </Paper>
            </Tooltip>
          );
        })}
    </Box>
  );
}

const PlanArea = ({
  planType,
  nextBillingDate,
  blockedAction,
}: {
  planType: PlanType;
  nextBillingDate: string | undefined;
  blockedAction: boolean;
}) => {
  let planName = planTitles[planType][0];
  let suffix;
  if (planType === PlanType.TRIAL) {
    planName = planTitles[planType][1];
  }

  if (planType === PlanType.TRIAL && nextBillingDate) {
    suffix = `: ${getPlanDaysLeftDescription(nextBillingDate)}`;
  }

  return (
    <Typography
      variant="body2"
      sx={{
        color: "text.secondary",
        ...(blockedAction && {
          color: "grey.500",
          cursor: "not-allowed",
          pointerEvents: "none",
        }),
      }}
    >
      {planName}
      {suffix}
    </Typography>
  );
};

const PlanContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  margin: theme.spacing(5, 0),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
}));

const PlanChipsContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "left",
}));
