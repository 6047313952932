import React, { InputHTMLAttributes } from "react";
import InputMask from "react-input-mask";
import ReactDatePicker from "react-datepicker";
import { TextField, TextFieldProps } from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";

interface DatePickerProps {
  onChange: (date: number | null) => void;
  selected: number | null;
  placeholder?: string;
}

export const DatePicker = ({ onChange, selected, placeholder }: DatePickerProps) => {
  const selectedDate = selected ? new Date(selected) : null;

  const onChangeDate = (date: Date | null) => {
    if (date) {
      onChange(date.getTime());
    } else {
      onChange(null);
    }
  };

  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={onChangeDate}
      isClearable
      dateFormat="dd/MM/yyyy"
      placeholderText={placeholder || "Selecione a data"}
      className="react-datepicker-wrapper-full-width"
      wrapperClassName="react-datepicker-wrapper-full-width"
      customInput={
        <InputMask mask="99/99/9999">
          {(inputProps: InputHTMLAttributes<HTMLInputElement>) => (
            <TextField
              {...(inputProps as TextFieldProps)}
              size="small"
              fullWidth
              autoComplete="off"
              sx={{
                "& .MuiOutlinedInput-root": {
                  width: "100%",
                  "& fieldset": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                  },
                },
              }}
            />
          )}
        </InputMask>
      }
    />
  );
};
