import { FileType } from "@/hooks/lexZap/types";
import { RecordType } from "@/hooks/records/types";

interface BaseCaseFileProps {
  name: string;
  createdAt: number;
  id: string | number;
}

interface RecordCaseFileProps extends BaseCaseFileProps {
  type: "RECORD";
  recordType: RecordType;
  threadId: string;
  id: number;
  fileType: FileType;
}

interface WhatsappCaseFileProps extends BaseCaseFileProps {
  type: "WHATSAPP_FILE";
  mimeType: string;
}

interface LegalProceedingAttachedFileProps extends BaseCaseFileProps {
  type: "LEGAL_PROCEEDING_ATTACHED_FILE";
  id: string;
  cnj: string;
  instance: string;
  integration: string;
  integrationFileId: string;
  legalProceedingStepId: string;
  extension: string;
  fileType: FileType;
}

interface PlatformFileProps extends BaseCaseFileProps {
  type: "PLATFORM_FILE";
  id: string;
  name: string;
  fileType: FileType;
  extension: string;
}

export class CaseFileItem {
  get type() {
    return this.props.type;
  }

  get name() {
    return this.props.name;
  }

  get createdAt() {
    return this.props.createdAt;
  }

  get id() {
    return this.props.id;
  }

  get mimeType() {
    if (this.isWhatsappFile()) {
      return (this.props as WhatsappCaseFileProps).mimeType;
    }
    return null;
  }

  get cnj() {
    if (this.isLegalProceedingAttachedFile()) {
      return (this.props as LegalProceedingAttachedFileProps).cnj;
    }
    return null;
  }

  get instance() {
    if (this.isLegalProceedingAttachedFile()) {
      return (this.props as LegalProceedingAttachedFileProps).instance;
    }
    return null;
  }

  get integration() {
    if (this.isLegalProceedingAttachedFile()) {
      return (this.props as LegalProceedingAttachedFileProps).integration;
    }
    return null;
  }

  get integrationFileId() {
    if (this.isLegalProceedingAttachedFile()) {
      return (this.props as LegalProceedingAttachedFileProps).integrationFileId;
    }
    return null;
  }

  get legalProceedingStepId() {
    if (this.isLegalProceedingAttachedFile()) {
      return (this.props as LegalProceedingAttachedFileProps).legalProceedingStepId;
    }
    return null;
  }

  get extension() {
    if (this.isLegalProceedingAttachedFile() || this.isPlatformFile()) {
      return (this.props as LegalProceedingAttachedFileProps | PlatformFileProps).extension;
    }
    return null;
  }

  get recordType() {
    if (this.isRecord()) {
      return (this.props as RecordCaseFileProps).recordType;
    }
    return null;
  }

  get threadId() {
    if (this.isRecord()) {
      return (this.props as RecordCaseFileProps).threadId;
    }
    return null;
  }

  get fileType() {
    if (this.isPlatformFile()) {
      return (this.props as PlatformFileProps).fileType;
    }
    return null;
  }

  private constructor(
    private props: RecordCaseFileProps | WhatsappCaseFileProps | LegalProceedingAttachedFileProps | PlatformFileProps
  ) {}

  static createRecord(props: Omit<RecordCaseFileProps, "type">): CaseFileItem {
    return new CaseFileItem({ ...props, type: "RECORD" });
  }

  static createWhatsappFile(props: Omit<WhatsappCaseFileProps, "type">): CaseFileItem {
    return new CaseFileItem({ ...props, type: "WHATSAPP_FILE" });
  }

  static createLegalProceedingAttachedFile(props: Omit<LegalProceedingAttachedFileProps, "type">): CaseFileItem {
    return new CaseFileItem({
      ...props,
      type: "LEGAL_PROCEEDING_ATTACHED_FILE",
    });
  }

  static createPlatformFile(props: Omit<PlatformFileProps, "type">): CaseFileItem {
    return new CaseFileItem({ ...props, type: "PLATFORM_FILE" });
  }

  isRecord() {
    return this.props.type === "RECORD";
  }

  isWhatsappFile() {
    return this.props.type === "WHATSAPP_FILE";
  }

  isLegalProceedingAttachedFile() {
    return this.props.type === "LEGAL_PROCEEDING_ATTACHED_FILE";
  }

  isPlatformFile() {
    return this.props.type === "PLATFORM_FILE";
  }

  toJSON(): RecordCaseFileProps | WhatsappCaseFileProps | LegalProceedingAttachedFileProps | PlatformFileProps {
    return this.props;
  }
}
