import { create } from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";
import { CaseFilters } from "@/pages/Cases/NewCasesPage/components/FilterCasesModal/types";
import { useCallback } from "react";

interface CaseFiltersState {
  caseFilters: CaseFilters;
  clearCasesFilters: () => void;
  setCasesFilters: ({ caseFilters }: { caseFilters: CaseFilters }) => void;
}

export const defaultCaseFilters: CaseFilters = {
  origin: {
    imported: true,
    manual: true,
    assistant: true,
  },
  boundStatus: "all",
  legalAreas: [],
};

export const useCaseFiltersStore = create<CaseFiltersState>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        caseFilters: defaultCaseFilters,
        clearCasesFilters: () => {
          set({ caseFilters: defaultCaseFilters });
        },
        setCasesFilters: async ({ caseFilters }) => {
          set({ caseFilters });
        },
      }),
      {
        name: "custom-settings-store",
      }
    )
  )
);

export const useCaseFilters = () => {
  const caseFilters = useCaseFiltersStore((state) => state.caseFilters);
  return {
    data: caseFilters,
    clearCaseFilters: useCallback(() => useCaseFiltersStore.getState().clearCasesFilters(), []),
    setCaseFilters: useCallback(
      (data: CaseFilters) => useCaseFiltersStore.getState().setCasesFilters({ caseFilters: data }),
      []
    ),
  };
};
