import React from "react";
// MUI components
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// Types
import { LexZapChat } from "@/hooks/lexZap/types";
// Hooks
import { useChecklist } from "@/hooks/useChecklist";
// Components
import ReactMarkdown from "react-markdown";
// Utils
import { scrollbarStyles } from "@/theme/mixins/scrollbar";
import { ChatWithLastMessage } from "@/services/whatsappServer/types/chat";

interface ChecklistProps {
  chat: LexZapChat | ChatWithLastMessage;
  onClose: () => void;
}

export const Checklist = ({ chat, onClose }: ChecklistProps) => {
  const { checklistData, loading, markdown, fetchChecklist } = useChecklist(chat.endClientNumber);

  React.useEffect(() => {
    if (chat.endClientNumber) {
      void fetchChecklist();
    }
  }, [chat.endClientNumber, fetchChecklist]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: "common.white",
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateRows: "min-content minmax(0, 1fr)",
        borderRadius: 1,
        border: "1px solid",
        borderColor: "common.lightShade",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, px: 3, pt: 2, pb: 1 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "400", color: "text.secondary" }}>
            {checklistData.title}
          </Typography>

          <IconButton
            onClick={onClose}
            sx={{
              m: -1,
            }}
          >
            <CloseIcon sx={{ width: "24px", height: "24px", color: "text.secondary" }} />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "100%",
          pt: 2,
          pr: 0.5,
          pb: 0.5,
          pl: 0.5,
          ...scrollbarStyles,
        }}
      >
        <Box
          sx={{
            mx: 2,
            maxHeight: "65vh",
            "& blockquote": {
              borderLeft: "2px solid",
              borderColor: "grey.300",
              margin: "8px 0px 8px 32px",
              paddingLeft: "8px",
              color: "text.secondary",
            },
            ...scrollbarStyles,
          }}
          className="markdown"
        >
          <ReactMarkdown
            components={{
              ul: ({ ...props }) => <ul style={{ paddingLeft: "20px", margin: "8px 0" }} {...props} />,
              li: ({ ...props }) => <li style={{ marginBottom: "4px" }} {...props} />,
              h1: ({ children }) => (
                <Typography component="h1" variant="h6" sx={{ mt: 2, mb: 1 }}>
                  {children}
                </Typography>
              ),
              h2: ({ children }) => (
                <Typography component="h2" variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                  {children}
                </Typography>
              ),
              h3: ({ children }) => (
                <Typography component="h3" variant="subtitle2" sx={{ mt: 1.5, mb: 0.5 }}>
                  {children}
                </Typography>
              ),
              h4: ({ children }) => (
                <Typography component="h4" variant="body2" sx={{ fontWeight: "bold", mt: 1.5, mb: 0.5 }}>
                  {children}
                </Typography>
              ),
              p: ({ children }) => (
                <Typography component="p" variant="body2" sx={{ my: 1 }}>
                  {children}
                </Typography>
              ),
              blockquote: ({ children }) => (
                <Box
                  component="blockquote"
                  sx={{
                    borderLeft: "3px solid",
                    borderColor: "grey.300",
                    margin: "12px 0px 12px 24px",
                    paddingLeft: "16px",
                    color: "text.secondary",
                    fontSize: "1rem",
                  }}
                >
                  {children}
                </Box>
              ),
            }}
          >
            {markdown}
          </ReactMarkdown>
        </Box>
      </Box>
    </Box>
  );
};
