import { useApi } from "@/hooks/useApi";
import { useMutation } from "@tanstack/react-query";

export const useDeleteCases = () => {
  const api = useApi();
  return useMutation({
    mutationKey: ["deleteCases"],
    mutationFn: ({ casesIds }: { casesIds: string[] }) =>
      Promise.all(casesIds.map((caseId) => api.deleteCase({ caseId }))),
  });
};
