import * as React from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { InternalPageStructure } from "../core/InternalPageStructure";
import { Toast } from "../core/Toast";
import { logger } from "@/core/logger";
import { FeedbackService } from "@/services/feedback";

export function Feedback() {
  const navigate = useNavigate();
  const [message, setMessage] = React.useState<string>("");
  const [error, setError] = React.useState<string | null>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(async () => {
    if (isLoading) {
      return;
    }

    if (!message.length) {
      setError("A mensagem não pode estar vazia.");
      return;
    }

    logger.info("Feedback submit");

    setIsLoading(true);

    try {
      await FeedbackService.sendFeedback(message);
      setIsLoading(false);
      Toast.success("Feedback enviado com sucesso!");
      navigate(ROUTE_PATHS.CHAT);
    } catch (e) {
      if (e instanceof Object && "message" in e) {
        logger.error(`Feedback submit error ${e.message}`, { error: e });
      }
      Toast.error("Falha ao enviar o feedback!");
      setIsLoading(false);
    }
  }, [message]);

  return (
    <InternalPageStructure
      goBack={() => navigate(-1)}
      onSubmit={onSubmit}
      error={error}
      dismissError={() => setError(null)}
      submitButtonText="Enviar Feedback"
      submitButtonDisabled={isLoading || !message.length}
    >
      <Box>
        <Box>
          <Typography variant="preTitle" color={"text.primary"}>
            DAR FEEDBACK
          </Typography>
        </Box>

        <Box sx={{ mt: 1 }}>
          <Typography variant="multiLineBody" color={"text.primary"}>
            Seu feedback é muito importante para que possamos cada vez termos melhores resultados com nossos produtos.
            Caso tenha encontrado algum problema, dificuldade ou dúvida, descreva abaixo o ocorrido que nosso time de
            suporte entrará em contato o mais breve possível.
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <TextField
            multiline
            rows={5}
            type="password"
            variant="standard"
            focused
            placeholder="Descreva aqui sua dúvida, problema ou dificuldade na utilização de nosso assistente."
            fullWidth={true}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            sx={{ input: { color: "common.white" } }}
            disabled={isLoading}
          />
        </Box>
      </Box>
    </InternalPageStructure>
  );
}
