import { authenticatedLexterCopilotApi } from "@/core/api";
import { AxiosError } from "axios";
import { ApplicantConfig, defaultAudioConfig, defaultVoiceConfig } from "./types";

const mockApplicantConfigs: ApplicantConfig[] = [
  {
    company_id: "company-1",
    phone_number: "5511982256236",
    prefers_audio: true,
    voice_config: defaultVoiceConfig,
    audio_config: defaultAudioConfig,
    created_at: new Date("2024-01-01T10:00:00Z"),
    dynamic_status: `# FASE 1: TRIAGEM INICIAL
Status Dinâmico:
- ✅ Tipo de demanda: BPC Loas
    > "Preciso de ajuda para conseguir o benefício do INSS por causa do autismo do meu filho"
- ⏳ Subcategoria: Por autismo
    > "Meu filho tem 8 anos e foi diagnosticado com autismo moderado"
    - ⏳ Perguntar se tem realmente laudo medico
- ⏳ Verificação de requisitos mínimos:
    - ⏳ Documentação médica básica
    - ✅ Comprovação inicial de renda
        > "Nossa renda familiar é de R$ 1.800 mensais, somos eu e meus 2 filhos"
    - ✅ Documentos pessoais
        > "Já tenho RG, CPF e certidão de nascimento dele digitalizados"`,
    pk: "APPLICANT#company-1",
    sk: "PHONE#5511982256236",
    gsi1_pk: "APPLICANT_CONFIG",
    gsi1_sk: "5511982256236#2024-01-01T10:00:00Z",
  },
  {
    company_id: "company-2",
    phone_number: "558587064905",
    prefers_audio: false,
    voice_config: {
      languageCode: "pt-BR",
      name: "pt-BR-Wavenet-B",
    },
    audio_config: defaultAudioConfig,
    created_at: new Date("2024-01-02T14:30:00Z"),
    dynamic_status: `# FASE 1: TRIAGEM INICIAL
Status Dinâmico:
- ✅ Tipo de demanda: BPC Loas
- ✅ Subcategoria: Por autismo
- ✅ Verificação de requisitos mínimos:
    - ✅ Documentação médica básica
    - ✅ Comprovação inicial de renda
    - ✅ Documentos pessoais

# FASE 2: CONTRATAÇÃO
Status Dinâmico:
- ✅ Apresentação da proposta
- 🔄 Alinhamento de honorários
    > "Cliente solicitou desconto, aguardando aprovação do gestor"
- ⏳ Assinatura do contrato:
    - ⏳ Envio da minuta
    - ⏳ Revisão pelo cliente
    - ⏳ Coleta de assinaturas`,
    pk: "APPLICANT#company-2",
    sk: "PHONE#558587064905",
    gsi1_pk: "APPLICANT_CONFIG",
    gsi1_sk: "558587064905#2024-01-02T14:30:00Z",
  },
  {
    company_id: "company-1",
    phone_number: "5511950206297",
    prefers_audio: true,
    created_at: new Date("2024-01-03T09:15:00Z"),
    dynamic_status: `# FASE 1: TRIAGEM INICIAL
Status Dinâmico:
- ✅ Tipo de demanda: BPC Loas
- ✅ Subcategoria: Por autismo
- ✅ Verificação de requisitos mínimos:
    - ✅ Documentação médica básica
    - ✅ Comprovação inicial de renda
    - ✅ Documentos pessoais

# FASE 2: CONTRATAÇÃO
Status Dinâmico:
- ✅ Apresentação da proposta
- ✅ Alinhamento de honorários
- ✅ Assinatura do contrato:
    - ✅ Envio da minuta
    - ✅ Revisão pelo cliente
    - ✅ Coleta de assinaturas

# FASE 3: DOCUMENTAÇÃO COMPLETA
Status Dinâmico:
- 🔄 Documentação médica:
    - 🔄 Consulta agendada para 15/02/2024 com Dr. Silva
    - 🚫 Follow-up necessário após consulta
    - ⏳ Aguardando documentação completa pós-consulta
- ✅ Documentação socioeconômica:
    - ✅ Declaração de composição familiar
    - ✅ Comprovante de residência
- ✅ Documentação de impacto:
    - ✅ Relatórios escolares ou profissionais
    - ✅ Documentação sobre limitações nas atividades diárias
    - ✅ Histórico de adaptações necessárias`,
    pk: "APPLICANT#company-1",
    sk: "PHONE#5511950206297",
    gsi1_pk: "APPLICANT_CONFIG",
    gsi1_sk: "5511950206297#2024-01-03T09:15:00Z",
  },
];

const USE_MOCK = false; // TODO: Set to false when API is ready

export const ApplicantConfigService = {
  create: async (config: ApplicantConfig): Promise<ApplicantConfig> => {
    if (USE_MOCK) {
      const newConfig = { ...config, created_at: new Date() };
      mockApplicantConfigs.push(newConfig);
      return newConfig;
    }

    try {
      const response = await authenticatedLexterCopilotApi.post<ApplicantConfig>("/applicant-config", config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to create applicant config");
    }
  },

  getByPhoneNumber: async (phoneNumber: string): Promise<ApplicantConfig> => {
    if (USE_MOCK) {
      const config = mockApplicantConfigs.find((c) => c.phone_number === phoneNumber);
      if (!config) {
        throw new Error("Applicant config not found");
      }
      return config;
    }

    try {
      const response = await authenticatedLexterCopilotApi.get<ApplicantConfig>(`/applicant-config/${phoneNumber}`);
      return response.data;
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        throw new Error("Applicant config not found");
      }
      throw new Error("Failed to retrieve applicant config");
    }
  },

  update: async (phoneNumber: string, updateData: Partial<ApplicantConfig>): Promise<boolean> => {
    if (USE_MOCK) {
      const index = mockApplicantConfigs.findIndex((c) => c.phone_number === phoneNumber);
      if (index === -1) {
        throw new Error("Applicant config not found");
      }
      mockApplicantConfigs[index] = { ...mockApplicantConfigs[index], ...updateData } as ApplicantConfig;
      return true;
    }

    try {
      const response = await authenticatedLexterCopilotApi.put<boolean>(`/applicant-config/${phoneNumber}`, updateData);
      return response.data;
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        throw new Error("Applicant config not found");
      }
      throw new Error("Failed to update applicant config");
    }
  },

  delete: async (phoneNumber: string): Promise<boolean> => {
    if (USE_MOCK) {
      const index = mockApplicantConfigs.findIndex((c) => c.phone_number === phoneNumber);
      if (index === -1) {
        throw new Error("Applicant config not found");
      }
      mockApplicantConfigs.splice(index, 1);
      return true;
    }

    try {
      const response = await authenticatedLexterCopilotApi.delete<boolean>(`/applicant-config/${phoneNumber}`);
      return response.data;
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        throw new Error("Applicant config not found");
      }
      throw new Error("Failed to delete applicant config");
    }
  },
};
