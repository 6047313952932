import React from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";

import { PageLayout } from "@/components/PageLayout";
import { WebToastColoredWithTitle } from "@/components/core/Toast";
import { useCreateCase } from "@/hooks/lexZap/useCreateCase";
import { useLexZapTicket } from "@/hooks/lexZap/useLexZapTicket";
import { useApplicant } from "@/hooks/applicants/useApplicant";
import { TicketDetails } from "./components/TicketDetails";
import { getCasePath } from "@/routes/routePaths";

export const TicketPage = () => {
  const navigate = useNavigate();
  const { ticketId } = useParams<{ ticketId: string }>();

  const { ticket, isLoading: isLoadingTicket } = useLexZapTicket({ ticketId });
  const { data: applicant } = useApplicant({ applicantPhoneNumber: ticket?.applicantPhoneNumber });

  const { mutateAsync: createCase, isPending: isCreatingCase } = useCreateCase();

  const handleCreateCase = async () => {
    if (!ticket || !applicant) {
      return;
    }

    const data = await createCase({
      name: `Novo caso - ${applicant.name}`,
      applicantId: applicant.id,
      applicantName: applicant.name,
      applicantPhoneNumber: applicant.phoneNumber,
      ticketId: ticket.id,
    });

    WebToastColoredWithTitle.success("Caso criado com sucesso");

    const casePath = getCasePath({ caseId: data.id });
    return navigate(casePath);
  };

  if (!ticket || !applicant) return null;

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
        fullWidth: true,
      }}
      contentSx={{
        height: "100%",
      }}
    >
      <TicketDetails
        loadingTicket={isLoadingTicket || isCreatingCase}
        ticketData={ticket}
        applicant={applicant}
        onCreateCase={handleCreateCase}
      />
      <Outlet />
    </PageLayout>
  );
};
