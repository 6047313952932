import * as React from "react";
import Box from "@mui/material/Box";

import { getSkillGroupPath, getSkillPath } from "@/routes/routePaths";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCreateThread } from "@/hooks/threads/useCreateThread";
import { LoadingChat } from "@//components/Chat/LoadingChat";
import { SkillGroupId } from "@/core/skillGroups/types";
import { SkillId } from "@/core/skills/types";

export function CreateChat() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const { mutateAsync: createThreadAsync } = useCreateThread();

  const navigateToThread = React.useCallback(
    ({ threadId }: { threadId: string }) => {
      const skillId = searchParams.get("skill") as SkillId;
      const skillGroupId = searchParams.get("skillGroup") as SkillGroupId;
      const isOnboarding = searchParams.get("isOnboarding");
      const skillTitle = searchParams.get("skillTitle");

      const url = skillGroupId
        ? getSkillGroupPath({
            skillGroupId: skillGroupId,
            threadId: threadId,
            customTitle: skillTitle || undefined,
            isOnboarding: JSON.parse(isOnboarding ?? "false") || undefined,
          })
        : getSkillPath({
            skillId: skillId,
            threadId: threadId,
            customTitle: skillTitle || undefined,
            isOnboarding: JSON.parse(isOnboarding ?? "false") || undefined,
          });

      navigate(url, {
        replace: true,
        state,
      });
    },
    [searchParams, navigate, state]
  );

  React.useEffect(() => {
    void createThreadAsync({
      caseId: searchParams.get("caseId") || undefined,
    }).then(({ id }) => navigateToThread({ threadId: id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <LoadingChat />
    </Box>
  );
}
