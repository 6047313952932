import { useCallback, useState } from "react";
import { WebToastColoredWithTitle } from "@/components/core/Toast";
import { ApprovalService } from "@/services/approvalRequests";
import * as Types from "@/services/approvalRequests/types";

export const useApprovalManagement = () => {
  const [requests, setRequests] = useState<Types.ApprovalRequest[]>([]);
  const [applicantRequests, setApplicantRequests] = useState<Record<string, Types.ApprovalRequest[]>>({});
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<Types.ApprovalFilters>({
    status: ["PENDING"],
    dateRange: [null, null],
    subagents: [],
    searchText: "",
    reviewers: [],
  });

  const fetchRequests = useCallback(async () => {
    setLoading(true);
    try {
      const data = await ApprovalService.getAll(filters.status);
      setRequests(data);
      return data;
    } catch (error) {
      WebToastColoredWithTitle.error(
        "Erro ao buscar aprovações",
        "Não foi possível carregar as solicitações. Tente novamente mais tarde."
      );
      return [];
    } finally {
      setLoading(false);
    }
  }, [filters]);

  const fetchRequestsByPhoneNumber = useCallback(async (phoneNumber: string, limit?: number) => {
    try {
      const data = await ApprovalService.getByPhoneNumber(phoneNumber, limit, ["PENDING"]);

      // Update the existing requests that match this phone number
      // This is more performant than replacing the entire array
      setRequests((prevRequests) => {
        // Find all requests that don't match this phone number
        const otherRequests = prevRequests.filter((req) => req.applicant_phone_number !== phoneNumber);

        // Combine with the new requests for this phone number
        return [...otherRequests, ...data];
      });

      // Store these requests by applicant phone number for easy access
      setApplicantRequests((prev) => ({
        ...prev,
        [phoneNumber]: data,
      }));

      return data;
    } catch (error) {
      WebToastColoredWithTitle.error(
        "Erro ao buscar aprovações",
        "Não foi possível carregar as solicitações para este usuário. Tente novamente mais tarde."
      );
      return [];
    }
  }, []);

  const approve = async (request: Types.ApprovalRequest, outputEdited?: Types.ProcessSkillOutput) => {
    try {
      // Validate request data
      if (!request?.id) {
        throw new Error("Request ID is required");
      }

      if (!request?.applicant_phone_number) {
        throw new Error("Applicant phone number is required");
      }

      // Attempt to approve the request
      await ApprovalService.approve(request.id, request.applicant_phone_number, outputEdited);

      // Update UI and show success message
      WebToastColoredWithTitle.success("Solicitação aprovada com sucesso", { msg: "A mensagem será enviada em breve" });

      // Only fetch requests for this specific phone number instead of all requests
      if (request.applicant_phone_number) {
        await fetchRequestsByPhoneNumber(request.applicant_phone_number);
      }

      return true;
    } catch (error) {
      // Handle specific error cases
      if (error instanceof Error) {
        if (error.message.includes("phone number")) {
          WebToastColoredWithTitle.error("Dados inválidos", "Número de telefone do solicitante não encontrado");
        } else if (error.message.includes("ID")) {
          WebToastColoredWithTitle.error("Dados inválidos", "ID da solicitação não encontrado");
        } else if (error.message.includes("not found")) {
          WebToastColoredWithTitle.error(
            "Solicitação não encontrada",
            "A solicitação pode ter sido removida ou já processada"
          );
        } else {
          WebToastColoredWithTitle.error(
            "Erro ao aprovar solicitação",
            "Não foi possível completar a operação. Tente novamente mais tarde."
          );
        }
      } else {
        WebToastColoredWithTitle.error("Erro inesperado", "Ocorreu um erro desconhecido. Por favor, tente novamente.");
      }
      return false;
    }
  };
  const requestReprocessing = async (request: Types.ApprovalRequest, instructions: string) => {
    try {
      // Validate request data
      if (!request?.id) {
        throw new Error("Request ID is required");
      }

      if (!request?.applicant_phone_number) {
        throw new Error("Applicant phone number is required");
      }

      // Attempt to request reprocessing
      await ApprovalService.requestReprocessing(request.id, request.applicant_phone_number, instructions);

      // Update UI and show success message
      WebToastColoredWithTitle.success("Reprocessamento solicitado com sucesso", {
        msg: "A solicitação será processada em breve",
      });

      // Only fetch requests for this specific phone number instead of all requests
      if (request.applicant_phone_number) {
        await fetchRequestsByPhoneNumber(request.applicant_phone_number);
      }

      return true;
    } catch (error) {
      // Handle specific error cases
      if (error instanceof Error) {
        if (error.message.includes("phone number")) {
          WebToastColoredWithTitle.error("Dados inválidos", "Número de telefone do solicitante não encontrado");
        } else if (error.message.includes("ID")) {
          WebToastColoredWithTitle.error("Dados inválidos", "ID da solicitação não encontrado");
        } else if (error.message.includes("not found")) {
          WebToastColoredWithTitle.error(
            "Solicitação não encontrada",
            "A solicitação pode ter sido removida ou já processada"
          );
        } else {
          WebToastColoredWithTitle.error(
            "Erro ao solicitar reprocessamento",
            "Não foi possível completar a operação. Tente novamente mais tarde."
          );
        }
      } else {
        WebToastColoredWithTitle.error("Erro inesperado", "Ocorreu um erro desconhecido. Por favor, tente novamente.");
      }
      return false;
    }
  };

  const deleteRequest = async (request: Types.ApprovalRequest) => {
    try {
      // Validate request data
      if (!request?.id) {
        throw new Error("Request ID is required");
      }

      if (!request?.applicant_phone_number) {
        throw new Error("Applicant phone number is required");
      }

      // Attempt to delete the request
      await ApprovalService.delete(request.id, request.applicant_phone_number);

      // Update UI and show success message
      WebToastColoredWithTitle.success("Solicitação excluída com sucesso", {
        msg: "A solicitação foi removida permanentemente",
      });

      // Only fetch requests for this specific phone number instead of all requests
      if (request.applicant_phone_number) {
        await fetchRequestsByPhoneNumber(request.applicant_phone_number);
      }

      return true;
    } catch (error) {
      // Handle specific error cases
      if (error instanceof Error) {
        if (error.message.includes("phone number")) {
          WebToastColoredWithTitle.error("Dados inválidos", "Número de telefone do solicitante não encontrado");
        } else if (error.message.includes("ID")) {
          WebToastColoredWithTitle.error("Dados inválidos", "ID da solicitação não encontrado");
        } else if (error.message.includes("not found")) {
          WebToastColoredWithTitle.error(
            "Solicitação não encontrada",
            "A solicitação pode ter sido removida ou já processada"
          );
        } else {
          WebToastColoredWithTitle.error(
            "Erro ao excluir solicitação",
            "Não foi possível completar a operação. Tente novamente mais tarde."
          );
        }
      } else {
        WebToastColoredWithTitle.error("Erro inesperado", "Ocorreu um erro desconhecido. Por favor, tente novamente.");
      }
      return false;
    }
  };

  return {
    requests,
    applicantRequests,
    loading,
    filters,
    setFilters,
    fetchRequests,
    fetchRequestsByPhoneNumber,
    approve,
    requestReprocessing,
    deleteRequest,
  };
};
