import * as types from "./types";

export const mockApplicantEvents: types.ApplicantEvent[] = [
  {
    eventId: "1",
    companyId: "company-1",
    applicantPhoneNumber: "5511950206297",
    category: types.EventCategory.STAGE_CHANGE,
    eventName: "Application Submitted",
    details: {
      previousStage: "INITIAL",
      newStage: "DOCUMENT_REVIEW",
      notes: "Applicant submitted all required documents",
    },
    createdBy: "user-123",
    creatorType: types.CreatorType.USER,
    createdAt: 1741805355102,
  },
  // ... all other mock events
];

export const USE_MOCK = false; // TODO: Set to false when API is ready
