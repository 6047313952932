import { authenticatedCopilotServer } from "@/core/api";
import { PlatformFile } from "@/hooks/case/useCasePlatformFiles/types";
import { CaseNextStep, LegalProceedingFile, WhatsappFile } from "@/hooks/lexZap/types";

export const CaseService = {
  getCaseNextSteps: async (caseId: string) => {
    const response = await authenticatedCopilotServer.get<CaseNextStep[]>(`/cases/${caseId}/nextSteps`);
    return response.data;
  },

  getCaseLegalProceedingsFiles: async (caseId: string) => {
    const response = await authenticatedCopilotServer.get<LegalProceedingFile[]>(`/cases/${caseId}/files`);
    return response.data;
  },

  getCaseWhatsappFiles: async (caseId: string) => {
    const response = await authenticatedCopilotServer.get<{ data: WhatsappFile[] }>(`/cases/${caseId}/whatsappFiles`);
    return response.data;
  },

  getCasePlatformFiles: async (caseId: string) => {
    const response = await authenticatedCopilotServer.get<PlatformFile[]>(`/cases/${caseId}/platformFiles`);
    return response.data;
  },

  getCaseFileUrl: async (caseId: string, fileId: string) => {
    const response = await authenticatedCopilotServer.get<{ url: string }>(`/cases/${caseId}/files/${fileId}/download`);
    return response.data;
  },

  createCasePlatformFile: async ({
    caseId,
    location,
    name,
    size,
    fileType,
    extension,
  }: {
    caseId: string;
    location: string;
    name: string;
    size: number;
    fileType: string;
    extension: string;
  }) => {
    const response = await authenticatedCopilotServer.post<PlatformFile>(`/cases/${caseId}/platformFiles`, {
      location,
      name,
      size,
      fileType,
      extension,
    });
    return response.data;
  },

  getPlatformFile: async ({ platformFileId }: { platformFileId: string }) => {
    const response = await authenticatedCopilotServer.get(`/platformFiles/${platformFileId}`);
    return response.data;
  },

  deleteCasePlatformFile: async ({ caseId, fileId }: { caseId: string; fileId: string }) => {
    const response = await authenticatedCopilotServer.delete(`/cases/${caseId}/platformFiles/${fileId}`);
    return response.data;
  },

  signCaseContract: async (caseId: string) => {
    const response = await authenticatedCopilotServer.post(`/cases/${caseId}/signContract`);
    return response.data;
  },

  unsignCaseContract: async (caseId: string) => {
    const response = await authenticatedCopilotServer.post(`/cases/${caseId}/unsignContract`);
    return response.data;
  },
};
